import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Spinner from "../layout/Spinner";
import View from "./View";
import Interpret from "./Interpret";
import NavbarBottom from "../navbar/NavbarBottom";
import { getActivities, loadingActivity } from "../../actions/activity";
import { getSettings } from "../../actions/setting";

const Student = ({
  activity: { activities, loading },
  getActivities,
  loadingActivity,
  getSettings,
  setting,
  auth,
}) => {
  // Do not render anything until we request idea to be loaded (will cause spinner icon)
  const [renderReady, setRenderReady] = useState(false);

  // Before render, set <loading> = true (will cause spinner icon until activitivies have loaded)
  useEffect(() => {
    loadingActivity();
    getActivities();
    getSettings();
  }, []);

  const makeActivitiesDone = () => {
    const newActivities = [];
    let order = 0;
    for (let i = 0; i < activities.length; i += 1) {
      if (activities[i].enabled) {
        let myStudentIdFound = false;
        for (let j = 0; j < activities[i].studentsCompleted.length; j += 1) {
          if (activities[i].studentsCompleted[j]._id === auth.user._id) {
            myStudentIdFound = true;
          }
        }
        if (myStudentIdFound) {
          order += 1;
          activities[i].order = order;
          newActivities.push(activities[i]);
        }
      }
    }
    return newActivities;
  };
  const [activitiesDone, setActivitiesDone] = useState(makeActivitiesDone());

  const makeActivitiesToDo = () => {
    const newActivities = [];
    let order = activitiesDone.length;
    for (let i = 0; i < activities.length; i += 1) {
      if (activities[i].enabled) {
        let myStudentIdFound = false;
        for (let j = 0; j < activities[i].studentsCompleted.length; j += 1) {
          if (activities[i].studentsCompleted[j]._id === auth.user._id) {
            myStudentIdFound = true;
          }
        }
        if (!myStudentIdFound) {
          order += 1;
          activities[i].order = order;
          newActivities.push(activities[i]);
        }
      }
    }
    return newActivities;
  };
  const [activitiesToDo, setActivitiesToDo] = useState(makeActivitiesToDo());

  // Update activityNext every time activitiesToDo changes
  const [activityNext, setActivityNext] = useState(null);
  useEffect(() => {
    if (activitiesToDo.length > 0) {
      setActivityNext(activitiesToDo[0]);
    } else {
      setActivityNext(null);
    }
    setRenderReady(true);
  }, [activitiesToDo]);

  // modifyActivity originally modified just the current <activity>, however...
  // on Android mobile devices there was sometimes a noticable delay to change the <activity> and then request a new getActivities() and update the <activities>.
  // Instead, modifyActivity now changes the <activity> and returns the updated <activities> avoiding a need to call getActivities()

  useEffect(() => {
    // Whenever activities change, set renderReady to false... until activityNext is recalculated below
    setRenderReady(false);

    setActivitiesToDo(makeActivitiesToDo());
    setActivitiesDone(makeActivitiesDone());
  }, [activities]);

  const [buttonIndex, setButtonIndex] = useState(0);
  const [tabs, setTabs] = useState([
    { label: "To Do", badge: activitiesToDo.length },
    { label: "Activities Completed", badge: activitiesDone.length },
  ]);

  useEffect(() => {
    setTabs([
      { label: "To Do", badge: activitiesToDo.length },
      { label: "Activities Completed", badge: activitiesDone.length },
    ]);
  }, [activitiesToDo, activitiesDone]);

  return !renderReady || loading ? (
    <Spinner />
  ) : (
    <>
      {buttonIndex === 0 && (
        <Interpret
          activityNext={activityNext}
          numericalLabel={activitiesDone.length + 1}
          setting={
            setting && setting.settings && setting.settings.length > 0 ? setting.settings[0] : null
          }
        />
      )}
      {buttonIndex === 1 && <View activitiesDone={activitiesDone} />}

      <NavbarBottom tabs={tabs} currentTab={buttonIndex} setTab={setButtonIndex} />
    </>
  );
};

const mapStateToProps = (state) => ({
  activity: state.activity,
  auth: state.auth,
  setting: state.setting,
});

export default connect(mapStateToProps, { getActivities, loadingActivity, getSettings })(Student);
