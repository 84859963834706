import React, { useState } from "react";
import PropTypes from "prop-types";

// MUI
import { Checkbox } from "@mui/material";
import { Help } from "@mui/icons-material";

import Frame from "./Frame";
import LabelText from "../styles/LabelText";
import QuestionText from "../styles/QuestionText";
import DialogMessage from "../dialog/DialogMessage";

const GoodIntent = ({
  myContent,
  myPoint,
  goodIntentLevel,
  url,
  isPdf,
  isVideo,
  insightful,
  image,
  setGoodIntentLevel,
  childUpdated,
  setChildUpdated,
  // relatedTo,
  activity, // relates only to activities
  setting, // relates only to activities
  addIdeaScale, // relates only to activities
  auth, // relates only to activities
}) => {
  const onGoodIntent1 = (e) => {
    if (!e) {
      setGoodIntentLevel(0);
    } else {
      setGoodIntentLevel(1);
    }
  };
  const onGoodIntent2 = (e) => {
    if (!e) {
      setGoodIntentLevel(1);
    } else {
      setGoodIntentLevel(2);
    }
  };

  const [deepDiveDialog, setDeepDiveDialog] = useState(false);
  const [potieDialog, setPotieDialog] = useState(false);

  return (
    <div style={{ marginTop: "40px" }}>
      <div style={{ marginBottom: "10px" }}>
        {goodIntentLevel < 1 && myPoint.length === 0 && (
          <QuestionText>Before sharing, please confirm your short message is...</QuestionText>
        )}
        {goodIntentLevel < 1 && myPoint.length > 0 && (
          <QuestionText>Before sharing, please confirm this inTell is...</QuestionText>
        )}
        {goodIntentLevel === 1 && <QuestionText>My inTell is...</QuestionText>}
      </div>

      <LabelText>
        <Checkbox
          style={{ marginTop: "-3px" }}
          checked={goodIntentLevel >= 1}
          onClick={() => onGoodIntent1(!(goodIntentLevel >= 1))}
        />
        Not POTIE (&quot;Pollution Of The Information Ecosystem&quot;)
        <Help
          onClick={() => setPotieDialog(true)}
          color="primary"
          style={{ marginLeft: "3px", marginBottom: "3px" }}
        />
      </LabelText>

      {insightful && goodIntentLevel >= 1 && (
        <LabelText>
          <Checkbox
            style={{ marginTop: "-3px" }}
            checked={goodIntentLevel >= 2}
            onClick={() => onGoodIntent2(!(goodIntentLevel >= 2))}
          />
          Deep diving into a polarizing issue
          <Help
            onClick={() => setDeepDiveDialog(true)}
            color="primary"
            style={{ marginLeft: "3px", marginBottom: "3px" }}
          />
        </LabelText>
      )}

      {insightful && goodIntentLevel >= 2 && (
        <Frame
          myContent={myContent}
          myPoint={myPoint}
          url={url}
          isPdf={isPdf}
          isVideo={isVideo}
          goodIntentLevel={goodIntentLevel}
          childUpdated={childUpdated}
          setChildUpdated={setChildUpdated}
          image={image}
          activity={activity}
          setting={setting}
          addIdeaScale={addIdeaScale}
          auth={auth}
        />
      )}

      {potieDialog && <DialogMessage potie handleDialogClose={() => setPotieDialog(false)} />}
      {deepDiveDialog && (
        <DialogMessage deepDive handleDialogClose={() => setDeepDiveDialog(false)} />
      )}
    </div>
  );
};

GoodIntent.propTypes = {
  goodIntentLevel: PropTypes.number.isRequired,
  insightful: PropTypes.bool.isRequired,
  myContent: PropTypes.string,
  myPoint: PropTypes.string,
  url: PropTypes.string,
  isPdf: PropTypes.bool,
  isVideo: PropTypes.bool,
  image: PropTypes.string,
  setGoodIntentLevel: PropTypes.func.isRequired,
  //  relatedTo: PropTypes.string,
  childUpdated: PropTypes.number,
  setChildUpdated: PropTypes.func,
  activity: PropTypes.oneOfType([PropTypes.object]),
  setting: PropTypes.oneOfType([PropTypes.object]),
  addIdeaScale: PropTypes.oneOfType([PropTypes.object]),
  auth: PropTypes.oneOfType([PropTypes.object]),
};

GoodIntent.defaultProps = {
  myContent: "",
  myPoint: "",
  image: "",
  url: "",
  isPdf: false,
  isVideo: false,
  //  relatedTo: " ",
  childUpdated: 0,
  activity: null,
  setting: null,
  auth: null,
  addIdeaScale: null,
  setChildUpdated: null,
};

export default GoodIntent;
