import {
  LINKIDEAAGENT_ERROR,
  ADD_LINKIDEAAGENT,
  GET_LINKIDEAAGENT,
  GET_LINKIDEAAGENTS,
  ADD_LINKIDEAAGENT_SCALE,
  LOADING_LINKIDEAAGENT,
  LINK_ERROR,
} from "../actions/types";

const initialState = {
  linkIdeaAgents: [],
  linkIdeaAgent: null,
  loading: true,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_LINKIDEAAGENTS:
      return {
        ...state,
        linkIdeaAgents: payload,
        loading: false,
      };
    case GET_LINKIDEAAGENT:
    case ADD_LINKIDEAAGENT_SCALE:
    case ADD_LINKIDEAAGENT:
      return {
        ...state,
        linkIdeaAgent: payload,
        linkIdeaAgents: [],
        loading: false,
      };
    case LINKIDEAAGENT_ERROR:
    case LINK_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case LOADING_LINKIDEAAGENT:
      return {
        ...state,
        linkIdeaAgents: [],
        linkIdeaAgent: null,
        loading: true,
      };
    default:
      return state;
  }
}
