import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// MUI
import { Container } from "@mui/material";
import MKBox from "components/MaterialKit/MKBox";

import Spinner from "../layout/Spinner";
import getSecondsAgo from "../../utils/getSecondsAgo";
import IdeaDetail from "../idea/IdeaDetail";
import NavbarTop from "../navbar/NavbarTop";
import { latestIdeas, loadingIdea } from "../../actions/idea";
import QuestionText from "../styles/QuestionText";

// Enters via router: <PrivateRoute exact path='/welcome' component={Welcome} />
// --------------------------------------------
// ACTIONS
// - For for first-time users (just registered), goto <about>
// - For returning users, display personalized messages

const Welcome = ({ latestIdeas, loadingIdea, idea, auth }) => {
  // Before render, call loadingIdea() to set <idea> = null and <loading> = true (will cause spinner icon)
  useEffect(() => {
    loadingIdea();
  }, [loadingIdea]);

  // For the first 10 minutes user will go to /About page
  const navigate = useNavigate();
  useEffect(() => {
    if (auth && auth.user && auth.user.date && getSecondsAgo(auth.user.date) < 600) {
      console.log("Goto /about");
      navigate("/about");
    }
  }, [auth]);

  useEffect(() => {
    latestIdeas(10);
  }, [latestIdeas]);

  return auth.loading || !auth.isAuthenticated || idea.loading ? (
    <Spinner />
  ) : (
    <>
      <NavbarTop transparent />
      <MKBox sx={{ mt: { xs: 2, sm: 4 } }}>
        <Container>
          <QuestionText>Welcome back {auth.user && auth.user.name}.</QuestionText>
          <QuestionText style={{ marginTop: "15x" }}>
            Check out some of the latest inTell...
          </QuestionText>

          {idea.ideas.map((e) => (
            <div key={e._id} style={{ marginTop: "10px" }}>
              <IdeaDetail hideImage idea={e} />
            </div>
          ))}
        </Container>
      </MKBox>
    </>
  );
};

//  <div style={{ marginTop: "10px" }}>

const mapStateToProps = (state) => ({
  auth: state.auth,
  idea: state.idea,
});

export default connect(mapStateToProps, { latestIdeas, loadingIdea })(Welcome);
