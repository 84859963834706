import React, { useState } from "react";
import PropTypes from "prop-types";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useMediaQuery } from "@react-hook/media-query";

// MUI
import { Stack } from "@mui/material";
import Button from "components/MaterialKit/MKButton";
import Typography from "components/MaterialKit/MKTypography";

const ViewPdf = ({ url, setLoaded }) => {
  // const url = "https://insight-click.herokuapp.com/http://www.pdf995.com/samples/pdf.pdf";
  // PDFjs worker from an external cdn
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [ok, setOk] = useState(false);

  const onDocumentLoadSuccess = (e) => {
    setOk(true);
    if (setLoaded) {
      console.log("pdf loaded");
      setLoaded(true);
    }
    setNumPages(e.numPages);
    setPageNumber(1);
  };

  const onDocumentLoadError = () => {
    setOk(false);
    if (setLoaded) {
      console.log("pdf error");
      setLoaded(false);
    }
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const smSize = useMediaQuery("only screen and (min-width: 600px)");
  const mdSize = useMediaQuery("only screen and (min-width: 900px)");
  const lgSize = useMediaQuery("only screen and (min-width: 1200px)");

  return (
    <div>
      <Stack flexWrap="wrap" justifyContent="center" flexDirection="row" width={1.0}>
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
        >
          {!smSize && <Page width={320} pageNumber={pageNumber} />}
          {smSize && !mdSize && <Page width={360} pageNumber={pageNumber} />}
          {mdSize && !lgSize && <Page width={550} pageNumber={pageNumber} />}
          {lgSize && <Page width={950} pageNumber={pageNumber} />}
        </Document>
      </Stack>

      {ok && numPages && numPages > 1 && (
        <div style={{ textAlign: "center" }}>
          <Button
            style={{ marginRight: "10px", marginTop: "-40px" }}
            color="primary"
            size="small"
            circular
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            Prev
          </Button>
          <Button
            style={{ marginTop: "-40px" }}
            color="primary"
            size="small"
            circular
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            Next
          </Button>

          <div style={{ marginTop: "-15px" }}>
            <Typography style={{ fontSize: "14px" }}>
              Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

ViewPdf.propTypes = {
  url: PropTypes.string.isRequired,
  setLoaded: PropTypes.func,
};

ViewPdf.defaultProps = {
  setLoaded: null,
};

export default ViewPdf;
