const getTimeAgo = (datePreviousString) => {
  const datePrevious = new Date(datePreviousString);
  const dateNow = new Date();
  const elapsed = dateNow - datePrevious;

  const msPerSecond = 1000;
  const msPerMinute = msPerSecond * 60;
  if (elapsed < msPerMinute) {
    if (Math.round(elapsed / msPerSecond) <= 1) {
      return "just now";
    }
    return `${Math.round(elapsed / msPerSecond)} seconds ago`;
  }

  const msPerHour = msPerMinute * 60;
  if (elapsed < msPerHour) {
    if (Math.round(elapsed / msPerMinute) <= 1) {
      return "1 minute ago";
    }
    return `${Math.round(elapsed / msPerMinute)} minutes ago`;
  }

  const msPerDay = msPerHour * 24;
  if (elapsed < msPerDay) {
    if (Math.round(elapsed / msPerHour) <= 1) {
      return "1 hour ago";
    }
    return `${Math.round(elapsed / msPerHour)} hours ago`;
  }

  const msPerMonth = msPerDay * 30;
  if (elapsed < msPerMonth) {
    if (Math.round(elapsed / msPerDay) <= 1) {
      return "1 day ago";
    }
    return `${Math.round(elapsed / msPerDay)} days ago`;
  }

  const msPerYear = msPerDay * 365;
  if (elapsed < msPerYear) {
    if (Math.round(elapsed / msPerMonth) <= 1) {
      return "1 month ago";
    }
    return `${Math.round(elapsed / msPerMonth)} months ago`;
  }

  if (Math.round(elapsed / msPerYear) <= 1) {
    return "1 year ago";
  }
  return `${Math.round(elapsed / msPerYear)} years ago`;
};

export default getTimeAgo;
