import React from "react";
import PropTypes from "prop-types";

// MUI
import Typography from "components/MaterialKit/MKTypography";
import { styled } from "@mui/system";
import { Radio } from "@mui/material";

const AgentSelectItem = ({ updated, checked, agent }) => {
  const LabelTypography = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
      color: "black",
      fontSize: "13px", // md+
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px", // sm-
      },
    })
  );

  return (
    <div style={{ marginBottom: "-10px" }}>
      <LabelTypography noWrap>
        <Radio checked={checked} onClick={() => updated(agent)} />
        {agent.name}
      </LabelTypography>
    </div>
  );
};

AgentSelectItem.propTypes = {
  updated: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  agent: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
};

export default AgentSelectItem;
