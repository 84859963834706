import axios from "axios";
import { setAlert } from "./alert";
import {
  LINKAGENTAGENT_ERROR,
  ADD_LINKAGENTAGENT,
  GET_LINKAGENTAGENT,
  GET_LINKAGENTAGENTS,
  ADD_LINKAGENTAGENT_SCALE,
  LOADING_LINKAGENTAGENT,
} from "./types";

// Loading
export const loadingLinkAgentAgent = () => (dispatch) => {
  dispatch({ type: LOADING_LINKAGENTAGENT });
};

// Get linkAgentAgents attributed to userId
export const getLinkAgentAgents = (userId, limitSize) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/linkAgentAgents/user/${userId}`, {
      params: {
        limitSize,
      },
    });
    dispatch({
      type: GET_LINKAGENTAGENTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: LINKAGENTAGENT_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
    dispatch(
      setAlert(`Failed To Retrieve linkAgentAgents From Server: ${err.response.data}`, "danger")
    );
  }
};

// Add linkAgentAgent
export const addLinkAgentAgent = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post("/api/linkAgentAgents", formData, config);
    dispatch({
      type: ADD_LINKAGENTAGENT,
      payload: res.data,
    });
    //  dispatch(setAlert('linkAgentAgent Created', 'success', 1000));
  } catch (err) {
    // err.response.status = error code - ie, 400, 404, 500...
    // err.response.statusText = Text meaning associated with err.response.status
    // err.response.data.errors[] = An array of all errors
    const { errors } = err.response.data;
    if (errors) {
      errors.forEach((error) =>
        dispatch(setAlert(`Failed To Create linkAgentAgent On Server: ${error.msg}`, "danger"))
      );
    }
    dispatch({
      type: LINKAGENTAGENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get linkAgentAgent
// API uses res.send() - error message text is in err.response.data
export const getLinkAgentAgent = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/linkAgentAgents/${id}`);

    dispatch({
      type: GET_LINKAGENTAGENT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: LINKAGENTAGENT_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
    dispatch(
      setAlert(`Failed To Retrieve linkAgentAgent From Server: ${err.response.data}`, "danger")
    );
  }
};

// Add linkAgentAgent scale
export const addLinkAgentAgentScale = (linkAgentAgentId, formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(`/api/linkAgentAgents/scale/{linkAgentAgentId}`, formData, config);
    dispatch({
      type: ADD_LINKAGENTAGENT_SCALE,
      payload: res.data,
    });
    //  dispatch(setAlert('linkAgentAgent-scale Added', 'success', 1000));
  } catch (err) {
    // err.response.status = error code - ie, 400, 404, 500...
    // err.response.statusText = Text meaning associated with err.response.status
    // err.response.data.errors[] = An array of all errors
    const { errors } = err.response.data;
    if (errors) {
      errors.forEach((error) =>
        dispatch(setAlert(`Failed To Add linkAgentAgent-scale On Server: ${error.msg}`, "danger"))
      );
    }
    dispatch({
      type: LINKAGENTAGENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
