import React from "react";
import PropTypes from "prop-types";

// MUI
import Button from "components/MaterialKit/MKButton";
import { Box, AppBar } from "@mui/material";
import { styled } from "@mui/system";
import Badge from "@mui/material/Badge";

const StyledBadge = styled(Badge)(({ theme }) =>
  theme.unstable_sx({
    "& .MuiBadge-badge": {
      right: -14,
      top: 5,
    },
  })
);

const NavbarBottom = ({ tabs, currentTab, setTab }) => {
  return (
    <AppBar position="fixed" color="primary" sx={{ top: "auto", bottom: 0 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {tabs.map((tab, i) => (
          <Button
            key={tab.label}
            variant={currentTab === i ? "outlined" : "text"}
            onClick={() => setTab(i)}
            size="small"
            style={{ width: "250px" }}
          >
            <StyledBadge
              badgeContent={tab.badge}
              color="warning"
              sx={{ fontSize: { xs: "11px", md: "12px" } }}
            >
              {tab.label}
            </StyledBadge>
          </Button>
        ))}
      </Box>
    </AppBar>
  );
};

NavbarBottom.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  currentTab: PropTypes.number.isRequired,
  setTab: PropTypes.func.isRequired,
};

export default NavbarBottom;
