import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

// MUI
import { Container } from "@mui/material";
import MKBox from "components/MaterialKit/MKBox";

import Spinner from "../layout/Spinner";
import NavbarTop from "../navbar/NavbarTop";
import { getProfileById, loadingProfile } from "../../actions/profile";
import ProfileEdit from "./ProfileEdit";
import ProfileView from "./ProfileView";

// Enters via router: PrivateRoute exact path='/profile/:id' component={Profile} />
// --------------------------------------------
// ACTIONS
// - getProfileById action (requests user profile detail)
// - if :id is current user, allow user to edit profile (else view-only)

const Profile = ({ getProfileById, loadingProfile, profile: { profile, loading }, auth }) => {
  const { id } = useParams();

  // Do not render anything until we request a profile to be loaded (will cause spinner icon)
  const [renderReady, setRenderReady] = useState(false);

  // Before render, call loadingProfile() to set <profile> = null and <loading> = true (will cause spinner icon)
  useEffect(() => {
    loadingProfile();
  }, [loadingProfile]);

  // Load profile on first render
  useEffect(() => {
    if (id) {
      getProfileById(id);
      setRenderReady(true);
    }
  }, [getProfileById, id]);

  const [editProfile, setEditProfile] = useState(false);

  return !renderReady || profile == null || loading || profile.user == null ? (
    <Spinner />
  ) : (
    <>
      <NavbarTop transparent />
      <MKBox pt={20}>
        <Container>
          {/* View profile */}
          {!editProfile && (
            <>
              {auth && auth.isAuthenticated && auth.user._id === profile.user._id && (
                <ProfileView profile={profile} editProfile={setEditProfile} canEdit />
              )}

              {auth && auth.isAuthenticated && auth.user._id !== profile.user._id && (
                <ProfileView profile={profile} editProfile={setEditProfile} />
              )}
            </>
          )}

          {/* Edit profile */}
          {editProfile && <ProfileEdit profile={profile} editProfile={setEditProfile} />}
        </Container>
      </MKBox>
    </>
  );
};

Profile.propTypes = {
  getProfileById: PropTypes.func.isRequired,
  loadingProfile: PropTypes.func.isRequired,
  profile: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
  auth: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
});

export default connect(mapStateToProps, { getProfileById, loadingProfile })(Profile);
