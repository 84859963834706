import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// MUI
import Typography from "components/MaterialKit/MKTypography";
import Button from "components/MaterialKit/MKButton";
import { Checkbox, Stack } from "@mui/material";

import Spinner from "../layout/Spinner";
import SliderConfidence from "../slider/SliderConfidence";
import SliderSupportOppose from "../slider/SliderSupportOppose";
import IdeaDetail from "../idea/IdeaDetail";
import QuestionText from "../styles/QuestionText";
import ClickText from "../styles/ClickText";
import LabelText from "../styles/LabelText";
import { modifyActivity } from "../../actions/activity";

const InterpretLink = ({
  inTellEffect,
  inTellCause,
  activity,
  supportOpposeInterpretation,
  setSupportOpposeInterpretation,
  childUpdated,
  setChildUpdated,
  modifyActivity,
  auth,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [supportLevel, setSupportLevel] = useState(-2); // Typically -1/0/1 (-2 represents uninitialized)
  const [confidence, setConfidence] = useState(-2); // Typically ranges 0->1 (-2 represents uninitialized)
  const [skip, setSkip] = useState(false);

  useEffect(() => {
    setSupportLevel(supportOpposeInterpretation);
  }, [supportOpposeInterpretation]);

  const handleSkip = (e) => {
    setSkip(e);
    if (setSupportOpposeInterpretation) {
      setSupportOpposeInterpretation(-2);
    }
    setConfidence(-2);
    setChildUpdated(childUpdated + 1);
  };

  const handleSupportOpposeInterpretation = (e) => {
    if (setSupportOpposeInterpretation) {
      setSupportOpposeInterpretation(e);
    }
    setSupportLevel(e);
    setConfidence(-2);
    setChildUpdated(childUpdated + 1);
  };

  const onSubmit = () => {
    // Submit link scale
    if (activity) {
      if (activity.linkIdeaIdea && activity.linkIdeaIdea._id) {
        setSubmitting(true);
        modifyActivity({
          activityID: activity._id,
          studentIDCompleted: auth.user._id,
          addLinkIdeaIdeaScale: {
            id: activity.linkIdeaIdea._id,
            level: supportLevel > -2 ? supportLevel : null,
            confidence: confidence > -2 ? confidence : null,
            isSimilar: false,
            isComplex: false,
            isReversed: false,
          },
        });
      } else {
        console.log("Unexpected error submitting link scale");
      }
    } else {
      console.log("ERROR: No activity");
    }
  };

  return submitting ? (
    <Spinner />
  ) : (
    <>
      {inTellCause && (
        <>
          <LabelText>
            <Checkbox checked={skip} onClick={() => handleSkip(!skip)} />I would like to skip this
            activity.
          </LabelText>

          {skip && (
            <Stack
              flexWrap="wrap"
              justifyContent="center"
              flexDirection="row"
              width={1.0}
              style={{ marginTop: "40px" }}
            >
              <Button onClick={() => onSubmit()} color="primary" circular>
                Confirm skip
              </Button>
            </Stack>
          )}
        </>
      )}

      {((inTellCause && !skip) || !inTellCause) && (
        <>
          <QuestionText style={{ textAlign: "center" }}>
            Our community topic is: <i>&quot;{inTellEffect.myPoint}&quot;</i>
          </QuestionText>
          <QuestionText large={supportLevel <= -2 ? 1 : 0} style={{ textAlign: "center" }}>
            {supportLevel <= 0.01 && supportLevel >= -0.01
              ? "Community topic is unaffected by this inTell"
              : supportLevel > 0
              ? "Community topic is supported by this inTell"
              : supportLevel < 0 && supportLevel > -2
              ? "Community topic is damaged by this inTell"
              : "Is our community topic damaged or supported by this inTell?"}
          </QuestionText>

          {supportLevel <= -2 && (
            <ClickText style={{ textAlign: "center", marginBottom: "0px" }}>
              CLICK ON THE SCALE BELOW
            </ClickText>
          )}

          <div>
            <SliderSupportOppose
              userInitLevel={supportLevel}
              updated={handleSupportOpposeInterpretation}
            />
          </div>

          {/* intell Cause */}
          {inTellCause && (
            <div style={{ marginTop: "25px" }}>
              <Typography style={{ fontSize: "10px", marginBottom: "5px" }}>inTell</Typography>
              <IdeaDetail
                idea={inTellCause}
                hideImage
                hrefHiddenInternal
                hrefHiddenExternal={inTellCause.isVideo}
                greyOut
                showExtended
              />
            </div>
          )}

          {inTellCause && supportLevel > -2 && (
            <>
              <div style={{ marginTop: "60px", marginBottom: "15px", textAlign: "center" }}>
                <QuestionText large={confidence <= -2 ? 1 : 0}>
                  How confident are you in your interpretation?
                </QuestionText>
                {confidence <= -2 && <ClickText>CLICK ON THE SCALE BELOW</ClickText>}
              </div>

              <SliderConfidence userInitLevel={confidence} updated={setConfidence} />
            </>
          )}

          {inTellCause && supportLevel > -2 && confidence > -2 && (
            <Stack
              flexWrap="wrap"
              flexDirection="row"
              width={1.0}
              justifyContent="center"
              style={{ marginTop: "40px" }}
            >
              <Button onClick={() => onSubmit()} color="primary" circular>
                Submit
              </Button>
            </Stack>
          )}
        </>
      )}
    </>
  );
};

InterpretLink.propTypes = {
  inTellEffect: PropTypes.oneOfType([PropTypes.object]).isRequired,
  inTellCause: PropTypes.oneOfType([PropTypes.object]),
  supportOpposeInterpretation: PropTypes.number,
  setSupportOpposeInterpretation: PropTypes.func,
  activity: PropTypes.oneOfType([PropTypes.object]),
  childUpdated: PropTypes.number.isRequired,
  setChildUpdated: PropTypes.func.isRequired,
};

InterpretLink.defaultProps = {
  inTellCause: null,
  activity: null,
  supportOpposeInterpretation: -2,
  setSupportOpposeInterpretation: null,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { modifyActivity })(InterpretLink);
