// MUI
import { styled } from "@mui/system";
import Typography from "components/MaterialKit/MKTypography";

const QuestionText = styled(Typography)(({ theme, large }) =>
  theme.unstable_sx({
    fontSize: large ? "16px" : "14px",
    [theme.breakpoints.up("sm")]: {
      fontSize: large ? "20px" : "16px",
    },
  })
);

export default QuestionText;
