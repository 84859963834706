import {
  SEARCH_IDEAS,
  LATEST_IDEAS,
  IDEA_ERROR,
  ADD_IDEA,
  GET_IDEA,
  GET_IDEAS,
  ADD_IDEA_SCALE,
  LOADING_IDEA,
} from "../actions/types";

const initialState = {
  ideas: [],
  idea: null,
  loading: true,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_IDEAS:
    case LATEST_IDEAS:
    case SEARCH_IDEAS:
      return {
        ...state,
        ideas: payload,
        loading: false,
      };
    case GET_IDEA:
    case ADD_IDEA:
    case ADD_IDEA_SCALE:
      return {
        ...state,
        idea: payload,
        ideas: [],
        loading: false,
      };
    case IDEA_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case LOADING_IDEA:
      return {
        ...state,
        ideas: [],
        idea: null,
        loading: true,
      };
    default:
      return state;
  }
}
