/* eslint-disable import/prefer-default-export */

import axios from "axios";
import { setAlert } from "./alert";
import { REPORT_ERROR, ADD_REPORT } from "./types";

export const addReport = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.post("/api/report", formData, config);

    dispatch({
      type: ADD_REPORT,
      payload: res.data,
    });
    //  dispatch(setAlert('Report Created', 'success', 1000));
  } catch (err) {
    // err.response.status = error code - ie, 400, 404, 500...
    // err.response.statusText = Text meaning associated with err.response.status
    // err.response.data.errors[] = An array of all errors
    const { errors } = err.response.data;
    if (errors) {
      errors.forEach((error) =>
        dispatch(setAlert(`Failed To Create Report On Server: ${error.msg}`, "danger"))
      );
    }
    dispatch({
      type: REPORT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
