import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// MUI
import { Checkbox, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { Close } from "@mui/icons-material";

import LabelText from "../styles/LabelText";

const CoreIssue = ({ atCore, setAtCore, setCoreDone, childUpdated, setChildUpdated }) => {
  const [expanded, setExpanded] = useState(false);
  const [confirm1, setConfirm1] = useState(false);
  const [confirm2, setConfirm2] = useState(false);
  const [confirm3, setConfirm3] = useState(false);
  const [confirm4, setConfirm4] = useState(false);

  const handleCoreIssue = (e) => {
    if (!e) {
      if (setAtCore) {
        setAtCore(false);
      }
      setConfirm1(false);
      setConfirm2(false);
      setConfirm3(false);
      setConfirm4(false);
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  };

  // Strictly used to set <atCore>
  useEffect(() => {
    if (confirm1 && confirm2 && confirm3 && confirm4) {
      if (setAtCore) {
        setAtCore(true);
      }
      setExpanded(false);
    } else if (setAtCore) {
      setAtCore(false);
    }
  }, [confirm1, confirm2, confirm3, confirm4]);

  // Strictly used to notifify parent
  useEffect(() => {
    if (expanded) {
      if (setCoreDone) {
        setCoreDone(false);
      }
      if (setChildUpdated) {
        setChildUpdated(childUpdated + 1);
      }
    } else if (setCoreDone) {
      setCoreDone(true);
    }
  }, [expanded]);

  return (
    <div style={{ textAlign: "left" }}>
      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={
            expanded ? (
              <Close
                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                onClick={() => setExpanded(!expanded)}
              />
            ) : null
          }
          aria-label="Expand core issue"
          id="core-issue-header"
        >
          {!expanded && (
            <LabelText>
              <Checkbox
                disabled={!setAtCore}
                checked={atCore}
                style={{ marginTop: "-3px" }}
                onClick={() => handleCoreIssue(!atCore)}
              />
              This inTell gets to the core of the issue.
            </LabelText>
          )}
          {expanded && (
            <LabelText style={{ marginLeft: "10px" }}>
              As an authority on this topic (top 1% of the population), I confirm:
            </LabelText>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ marginTop: "-20px", paddingLeft: "5px" }}>
            <LabelText>
              <Checkbox
                checked={confirm1}
                onClick={() => setConfirm1(!confirm1)}
                style={{ marginTop: "-2px", marginBottom: "-2px" }}
              />
              This inTell reflects MY OWN INDEPENDENT CONCLUSIONS.
            </LabelText>
            <LabelText>
              <Checkbox
                checked={confirm2}
                onClick={() => setConfirm2(!confirm2)}
                style={{ marginTop: "-2px", marginBottom: "-2px" }}
              />
              I understand the MOTIVES of the waveMakers: Who benefits? Follow the money, follow the
              power...
            </LabelText>
            <LabelText>
              <Checkbox
                checked={confirm3}
                onClick={() => setConfirm3(!confirm3)}
                style={{ marginTop: "-2px", marginBottom: "-2px" }}
              />
              I can almost &quot;taste&quot; the MOST RELEVANT PERSPECTIVES from all sides.
            </LabelText>
            <LabelText>
              <Checkbox
                checked={confirm4}
                onClick={() => setConfirm4(!confirm4)}
                style={{ marginTop: "-2px", marginBottom: "-2px" }}
              />
              I have WEIGHED the entirety guided by TRUTH, above all else.
            </LabelText>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

CoreIssue.propTypes = {
  atCore: PropTypes.bool.isRequired,
  setAtCore: PropTypes.func,
  setCoreDone: PropTypes.func,
  childUpdated: PropTypes.number,
  setChildUpdated: PropTypes.func,
};

CoreIssue.defaultProps = {
  setAtCore: null,
  setCoreDone: null,
  childUpdated: 0,
  setChildUpdated: null,
};

export default CoreIssue;
