import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "@react-hook/media-query";

// MUI
import Typography from "components/MaterialKit/MKTypography";

import Slider from "./Slider";

const marks = [
  {
    value: 0,
    label: (
      <div style={{ marginRight: "-55px" }}>
        <Typography sx={{ fontSize: { xs: "10px", sm: "12px" } }}>Questionable</Typography>
        <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, marginLeft: "6px" }}>
          arguments
        </Typography>
      </div>
    ),
  },
  {
    value: 1,
    label: (
      <div style={{ marginLeft: "-50px" }}>
        <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, marginLeft: "12px" }}>
          Sound
        </Typography>
        <Typography sx={{ fontSize: { xs: "10px", sm: "12px" } }}>arguments</Typography>
      </div>
    ),
  },
];

const SliderSound = ({ userInitLevel, updated }) => {
  const smSize = useMediaQuery("only screen and (min-width: 600px");
  const onSliderChange = (e, value) => {
    if (updated) {
      updated(value);
    }
  };

  return (
    // Increments: 0.01
    // Min: 0 (text label)
    // Max: 1 (text label)
    <div style={{ paddingLeft: "20px", paddingRight: "20px", paddingTop: "0px" }}>
      <Slider
        visible={userInitLevel < 0 ? 0 : smSize ? 1 : 0.7}
        step={0.01}
        aria-label="Slider"
        value={userInitLevel}
        min={0}
        max={1}
        onChange={onSliderChange}
        marks={marks}
        disabled={!updated}
      />
    </div>
  );
};

SliderSound.propTypes = {
  userInitLevel: PropTypes.number.isRequired,
  updated: PropTypes.func,
};

SliderSound.defaultProps = {
  updated: null,
};

export default SliderSound;
