import React from "react";
import PropTypes from "prop-types";

// MUI
import { Tooltip } from "@mui/material";
import Button from "components/MaterialKit/MKButton";

import meaningIconWhite from "assets/images/icons/insight_white.png";
import meaningIconBlue from "assets/images/icons/insight_blue1.png";
import ideaIconWhite from "assets/images/icons/bulb_white.png";
import ideaIconBlue from "assets/images/icons/bulb_blue.png";
import fightIconWhite from "assets/images/icons/fight_white.png";
import fightIconBlue from "assets/images/icons/fight_blue.png";
import agentsIconWhite from "assets/images/icons/agents_white.png";
import agentsIconBlue from "assets/images/icons/agents_blue.png";

// PROPS
// Name                Type      Default   Description
// controlMode         number    Undefined If defined and >0, show agent stats header:
//                                         1 = Intepretations / replies
//                                         2 = Associated inTell
//                                         3 = Participation
//                                         4 = Related waveMakers
// setControlMode      func

const AgentDetailHeader = ({ controlMode, setControlMode }) => {
  return (
    <>
      {/* Scoreboard header */}
      {typeof controlMode !== "undefined" && controlMode !== "" && controlMode > 0 && (
        <>
          {/* Interpret icon button */}
          {controlMode !== 1 && (
            <Tooltip title="Intepret" placement="top-end">
              <Button
                iconOnly
                style={{ padding: "7px", marginLeft: "10px", marginTop: "20px" }}
                onClick={() => setControlMode(1)}
              >
                <img src={meaningIconBlue} alt="" width="15" height="15" />
              </Button>
            </Tooltip>
          )}
          {controlMode === 1 && (
            <Button
              color="primary"
              style={{ padding: "7px", marginLeft: "10px", marginTop: "20px" }}
              circular
            >
              <img src={meaningIconWhite} alt="" width="15" height="15" />
            </Button>
          )}

          {/* inTell icon button */}
          {controlMode !== 2 && (
            <Tooltip title="inTell" placement="top-end">
              <Button
                iconOnly
                style={{ padding: "7px", marginLeft: "25px", marginTop: "20px" }}
                onClick={() => setControlMode(2)}
              >
                <img src={ideaIconBlue} alt="" width="15" height="15" />
              </Button>
            </Tooltip>
          )}
          {controlMode === 2 && (
            <Button
              color="primary"
              style={{ padding: "7px", marginLeft: "25px", marginTop: "20px" }}
              circular
            >
              <img src={ideaIconWhite} alt="" width="15" height="15" />
            </Button>
          )}

          {/* inTell icon button */}
          {controlMode !== 3 && (
            <Tooltip title="Arguments" placement="top-end">
              <Button
                iconOnly
                style={{ padding: "7px", marginLeft: "25px", marginTop: "20px" }}
                onClick={() => setControlMode(3)}
              >
                <img src={fightIconBlue} alt="" width="18" height="18" />
              </Button>
            </Tooltip>
          )}
          {controlMode === 3 && (
            <Button
              color="primary"
              style={{ padding: "7px", marginLeft: "25px", marginTop: "20px" }}
              circular
            >
              <img src={fightIconWhite} alt="" width="18" height="18" />
            </Button>
          )}

          {/* Related waveMaker icon button */}
          {controlMode !== 4 && (
            <Tooltip title="Related waveMakers" placement="top-end">
              <Button
                iconOnly
                style={{ padding: "7px", marginLeft: "25px", marginTop: "20px" }}
                onClick={() => setControlMode(4)}
              >
                <img src={agentsIconBlue} alt="" width="15" height="15" />
              </Button>
            </Tooltip>
          )}
          {controlMode === 4 && (
            <Button
              color="primary"
              style={{ padding: "7px", marginLeft: "25px", marginTop: "20px" }}
              circular
            >
              <img src={agentsIconWhite} alt="" width="15" height="15" />
            </Button>
          )}

          {/* Info icon button */}
          {/*
          <div style={{float: "right", marginTop: "10px", marginRight: "20px"}}>
            { controlMode===2 && (
              <Button
                justIcon
                color="primary"
                onClick={() => setOpenModal(true)}
              >
              <Info/>
              </Button>
            )}
          </div>
          */}
        </>
      )}
    </>
  );
};

AgentDetailHeader.propTypes = {
  controlMode: PropTypes.number,
  setControlMode: PropTypes.func,
};

AgentDetailHeader.defaultProps = {
  controlMode: null,
  setControlMode: null,
};

export default AgentDetailHeader;
