import React, { useState } from "react";
import PropTypes from "prop-types";

import Chart from "../chart/Chart";

const ViewElephant = ({ inTellExisting }) => {
  const [showElephant, setShowElephant] = useState(true);

  return (
    <>
      {showElephant && (
        <div style={{ marginTop: "40px" }}>
          <Chart setShowElephant={setShowElephant} idea={inTellExisting} />
        </div>
      )}
      {!showElephant && (
        <div style={{ textAlign: "center", marginTop: "40px" }}>
          <p>Activity skipped. Unable to display community interpretations.</p>
        </div>
      )}
    </>
  );
};

ViewElephant.propTypes = {
  inTellExisting: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default ViewElephant;
