// This code was adapted from "Udemy MERN Stack Front to Back"

import React from "react";
import spinner from "./spinner.gif";

export default () => (
  <>
    <img
      src={spinner}
      style={{ width: "200px", margin: "auto", display: "block" }}
      alt="Loading..."
    />
  </>
);
