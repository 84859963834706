import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "@react-hook/media-query";

// MUI
import { Container } from "@mui/material";

import Completed from "assets/images/student/Completed.png";

import Interpret1 from "./Interpret1";
import Interpret100 from "./Interpret100";
import Interpret101 from "./Interpret101";
import Interpret102 from "./Interpret102";
import Interpret201 from "./Interpret201";
import NavbarTop from "../navbar/NavbarTop";

const Interpret = ({ activityNext, setting, numericalLabel }) => {
  const smSize = useMediaQuery("only screen and (min-width: 600px)");
  const mdSize = useMediaQuery("only screen and (min-width: 900px)");

  return (
    <>
      <Container maxWidth="sm" disableGutters sx={{ mb: 7 }}>
        {!activityNext && (
          <>
            <NavbarTop
              title="You completed all activities"
              content1="Additional activities may be assigned later."
            />
            <Container sx={{ mt: { xs: 16, sm: 18 }, mb: 12 }}>
              <div style={{ textAlign: "center" }}>
                <img
                  src={Completed}
                  style={{ width: mdSize ? "600px" : smSize ? "450px" : "350px" }}
                  alt="..."
                />
              </div>
            </Container>
          </>
        )}

        {activityNext && (
          <>
            {activityNext.selection === 1 && (
              <Interpret1
                activity={activityNext}
                setting={setting}
                numericalLabel={numericalLabel}
              />
            )}
            {activityNext.selection === 100 && (
              <Interpret100 activity={activityNext} numericalLabel={numericalLabel} />
            )}

            {activityNext.selection === 101 && (
              <Interpret101
                activity={activityNext}
                setting={setting}
                numericalLabel={numericalLabel}
              />
            )}

            {activityNext.selection === 102 && (
              <Interpret102
                activity={activityNext}
                setting={setting}
                numericalLabel={numericalLabel}
              />
            )}

            {activityNext.selection === 201 && (
              <Interpret201 activity={activityNext} numericalLabel={numericalLabel} />
            )}
          </>
        )}
      </Container>
    </>
  );
};

Interpret.propTypes = {
  activityNext: PropTypes.oneOfType([PropTypes.object]),
  numericalLabel: PropTypes.number.isRequired,
  setting: PropTypes.oneOfType([PropTypes.object]),
};

Interpret.defaultProps = {
  activityNext: null,
  setting: null,
};

export default Interpret;
