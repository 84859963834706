import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

// MUI
import { Checkbox, Radio } from "@mui/material";
import Typography from "components/MaterialKit/MKTypography";
import Box from "components/MaterialKit/MKBox";
import Input from "components/MaterialKit/MKInput";
import MultiSelect from "../styles/MultiSelect";

import LabelText from "../styles/LabelText";

const Create102 = ({
  instructions102,
  setInstructions102,
  interpretConfirmDeeperUnderstanding,
  interpretEvaluateValues,
  interpretPauseEmotionFlow,
  options,
  afterOptionInterconnectNewInTell,
  setAfterOptionInterconnectNewInTell,
  setInterpretConfirmDeeperUnderstanding,
  setInterpretEvaluateValues,
  setInterpretPauseEmotionFlow,
  setInterpretIntentWords,
  handleOnChange,
  handleInputChange,
}) => {
  const [interpretIntentWordsSpecial, setInterpretIntentWordsSpecial] = useState("");
  const [selectIntentWords, setSelectIntentWords] = useState(0);

  const handleIntentWordsSpecial = (e) => {
    setInterpretIntentWordsSpecial(e);
    setInterpretIntentWords(e);
  };

  const handleIntentWords = (e) => {
    setSelectIntentWords(e);
    setInterpretIntentWordsSpecial("");
    if (e === 0) {
      setInterpretIntentWords("I found the way the author presented this information to be...");
    }
    if (e === 1) {
      setInterpretIntentWords("I found the way the selection was explained to be...");
    }
    if (e === 2) {
      setInterpretIntentWords("");
    }
  };

  // Run on first render only
  useEffect(() => {
    setInterpretIntentWords("I found the way the author presented this information to be...");
  }, []);

  return (
    <>
      <LabelText>Additional prompt for students prior to them interpreting InTell...</LabelText>
      <Box
        onChange={(e) => setInstructions102(e.target.value)}
        style={{ marginTop: "10px", marginBottom: "20px" }}
      >
        <Input
          type="instructions102"
          label="Specify instructions to students (optional)"
          fullWidth
          multiline
          variant="outlined"
          value={instructions102}
          inputProps={{ maxLength: 140 }}
        />
      </Box>

      <LabelText>If student encounters significant emotions...</LabelText>
      <LabelText>
        <Checkbox
          checked={interpretPauseEmotionFlow}
          onClick={() => setInterpretPauseEmotionFlow(!interpretPauseEmotionFlow)}
        />
        Pause, and allow emotions to flow through prior to asking next question
      </LabelText>

      {interpretPauseEmotionFlow && (
        <div style={{ paddingLeft: "32px" }}>
          <LabelText>
            <Checkbox
              checked={interpretEvaluateValues}
              onClick={() => setInterpretEvaluateValues(!interpretEvaluateValues)}
            />
            If positively or negatively triggered, request student to evaluate associated values
          </LabelText>
        </div>
      )}

      <LabelText style={{ marginTop: "8px" }}>
        Use the following words when asking author intent...
      </LabelText>

      <LabelText>
        <Radio checked={selectIntentWords === 0} onClick={() => handleIntentWords(0)} />I found the
        way the author presented this information to be...
      </LabelText>

      <LabelText style={{ marginTop: "-6px" }}>
        <Radio checked={selectIntentWords === 1} onClick={() => handleIntentWords(1)} />I found the
        way the selection was explained to be...
      </LabelText>

      <LabelText style={{ marginTop: "-6px", marginBottom: "4px" }}>
        <Radio checked={selectIntentWords === 2} onClick={() => handleIntentWords(2)} />
        Alternative prompt (must specify)
      </LabelText>

      {selectIntentWords === 2 && (
        <Box
          onChange={(e) => handleIntentWordsSpecial(e.target.value)}
          style={{ marginTop: "20px", marginBottom: "10px" }}
        >
          <Input
            type="Alternative prompt"
            label="Alternative prompt"
            fullWidth
            multiline
            value={interpretIntentWordsSpecial}
          />
        </Box>
      )}

      <LabelText>
        <Checkbox
          checked={interpretConfirmDeeperUnderstanding}
          onClick={() =>
            setInterpretConfirmDeeperUnderstanding(!interpretConfirmDeeperUnderstanding)
          }
        />
        Request student to consider if inTell gets to the core of the issue.
      </LabelText>

      {setAfterOptionInterconnectNewInTell && (
        <>
          <LabelText>
            <Checkbox
              checked={afterOptionInterconnectNewInTell}
              onClick={() => setAfterOptionInterconnectNewInTell(!afterOptionInterconnectNewInTell)}
            />
            [CHAIN EVENT] INTERCONNECT newly created inTell to guiding question
          </LabelText>

          {afterOptionInterconnectNewInTell && handleOnChange && (
            <div style={{ paddingLeft: "32px", marginTop: "10px" }}>
              <Typography style={{ fontSize: "10px", marginTop: "20px" }}>
                Community topic
              </Typography>
              <div style={{ flex: "1" }}>
                <Select
                  options={options}
                  placeholder="Select pre-existing inTell as guiding question..."
                  onChange={handleOnChange}
                  onInputChange={handleInputChange}
                  styles={MultiSelect}
                  noOptionsMessage={() => (
                    <Typography textTransform="uppercase" style={{ fontSize: "14px" }}>
                      No matching search results found
                    </Typography>
                  )}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

// TODO: Request a comparative weighting of best competing perspectives.

Create102.propTypes = {
  instructions102: PropTypes.string.isRequired,
  setInstructions102: PropTypes.func.isRequired,
  interpretConfirmDeeperUnderstanding: PropTypes.bool.isRequired,
  interpretEvaluateValues: PropTypes.bool.isRequired,
  interpretPauseEmotionFlow: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  afterOptionInterconnectNewInTell: PropTypes.bool,
  setAfterOptionInterconnectNewInTell: PropTypes.func,
  setInterpretConfirmDeeperUnderstanding: PropTypes.func.isRequired,
  setInterpretEvaluateValues: PropTypes.func.isRequired,
  setInterpretPauseEmotionFlow: PropTypes.func.isRequired,
  setInterpretIntentWords: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func,
  handleInputChange: PropTypes.func,
};

Create102.defaultProps = {
  handleOnChange: null,
  afterOptionInterconnectNewInTell: false,
  setAfterOptionInterconnectNewInTell: null,
  handleInputChange: null,
  options: [],
};

export default Create102;
