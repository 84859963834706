import React, { useEffect, useState, useRef } from "react";
import * as d3 from "d3";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useMediaQuery } from "@react-hook/media-query";

// MUI
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

import empowerment from "assets/images/emotions/Empowerment2.svg";
import care from "assets/images/emotions/Care2.svg";
import fairness from "assets/images/emotions/Fairness2.svg";
import unity from "assets/images/emotions/Unity2.svg";
import sacredness from "assets/images/emotions/Sacredness2.svg";
import harm from "assets/images/emotions/Harm2.svg";
import unfairness from "assets/images/emotions/Unfairness2.svg";
import oppression from "assets/images/emotions/Oppression2.svg";
import division from "assets/images/emotions/Division2.svg";
import disgust from "assets/images/emotions/Disgust2.svg";

import Table from "./Table";

const Chart = ({ setShowElephant, idea, auth }) => {
  const smSize = useMediaQuery("only screen and (min-width: 600px)");
  const svgRef = useRef(null);
  let myStarIndex = -1;

  const [avgX, setAvgX] = useState(0);
  const [avgY, setAvgY] = useState(0);
  const [count, setCount] = useState(0);
  const [stdevX, setStdevX] = useState(0);
  const [stdevY, setStdevY] = useState(0);
  const [sharpeX, setSharpeX] = useState(0);
  const [sharpeY, setSharpeY] = useState(0);

  const [selected, setSelected] = useState([]);

  const standardDeviation = (arr) => {
    const mean = arr.reduce((acc, val) => acc + val, 0) / arr.length;
    return Math.sqrt(
      arr
        .reduce((acc, val) => acc.concat((val - mean) ** 2), [])
        .reduce((acc, val) => acc + val, 0) /
        (arr.length - 1)
    );
  };

  const avg = (arr) => {
    return arr.reduce((acc, val) => acc + val, 0) / arr.length;
  };

  useEffect(() => {
    const data = [];
    const dataX = [];
    const dataY = [];
    let index = 0;
    idea.scales.forEach((node) => {
      if (node.myEmotion !== null) {
        const obj = {};
        let confidence = node.confidence; // eslint-disable-line prefer-destructuring
        let sound = node.sound; // eslint-disable-line prefer-destructuring
        let authorIntent = node.authorIntent; // eslint-disable-line prefer-destructuring

        switch (node.values) {
          case 1:
            //          obj.values = `CARE`;
            obj.values = care;
            break;
          case 2:
            //          obj.values = `FAIRNESS`;
            obj.values = fairness;
            break;
          case 3:
            //          obj.values = `EMPOWERMENT`;
            obj.values = empowerment;
            break;
          case 4:
            //          obj.values = `UNITY`;
            obj.values = unity;
            break;
          case 5:
            //          obj.values = `SACREDNESS`;
            obj.values = sacredness;
            break;
          case -1:
            //          obj.values = `HARM`;
            obj.values = harm;
            break;
          case -2:
            //          obj.values = `UNFAIRNESS`;
            obj.values = unfairness;
            break;
          case -3:
            //          obj.values = `OPPRESSION`;
            obj.values = oppression;
            break;
          case -4:
            //          obj.values = `DIVISION`;
            obj.values = division;
            break;
          case -5:
            //          obj.values = `DISGUST`;
            obj.values = disgust;
            break;
          default:
        }

        if (node.valuesAgent && obj.values && obj.values.length > 0) {
          obj.valuesAgentName = node.valuesAgent.name;
        }

        // For divisive entries replace 'sound' with 0 and 'confidence' with 1
        obj.divisive = 0;
        if (node.divisive > 0) {
          confidence = 1;
          sound = 0;
          obj.divisive = node.divisive;
        }

        obj.manipulative = false;
        if (node.goodFaith !== null) {
          authorIntent = node.authorIntent * node.goodFaith; // penalize authorIntent further
          if (node.goodFaith < 0.5) {
            obj.manipulative = true;
          }
        }

        obj.heartOfIssue = node.heartOfIssue;
        obj.note = node.note ? node.note : "";
        obj.whyManipulative = node.whyManipulative ? node.whyManipulative : "";

        let signal = authorIntent * sound * confidence;
        if (node.heartOfIssue && node.heartOfIssue > 0.5) {
          signal += (1.0 - signal) * 2.0 * (node.heartOfIssue - 0.5);
        }
        const noise = (1.0 - authorIntent) * (1.0 - sound) * confidence;
        obj.y = node.myEmotion;
        obj.x = signal - noise;
        data.push(obj);
        dataX.push(signal - noise);
        dataY.push(node.myEmotion);

        if (
          auth &&
          auth.user &&
          auth.user._id &&
          node.user &&
          node.user._id &&
          auth.user._id === node.user._id
        ) {
          myStarIndex = index;
        }
        index += 1;
      }
    });
    setCount(data.length);
    setAvgX(avg(dataX));
    setAvgY(avg(dataY));
    setStdevX(standardDeviation(dataX));
    setStdevY(standardDeviation(dataY));
    setSharpeX(standardDeviation(dataX) !== 0 ? avg(dataX) / standardDeviation(dataX) : 0);
    setSharpeY(standardDeviation(dataY) !== 0 ? avg(dataY) / standardDeviation(dataY) : 0);
    if (myStarIndex < 0) {
      setShowElephant(false);
    }

    // Set the dimensions and margins of the graph
    const innerSize = smSize ? 395 : 195; // Max width = 480px, max height = 450px
    const widthYLegend = 55; // 55 pixels are added on left size to show the Y-axis legend
    const extraHeightTop = 5; // 5 pixels are added on top so as not to chop off "1.0" label
    const extraHeightBottom = 25; // 20 pixels are added on bottom so as not to chop off x-axis label
    const margin = { top: 15, right: 15, bottom: 15, left: 15 };
    const width = innerSize + margin.left + margin.right + widthYLegend; // 395 + 15 + 15 + 55
    const height = innerSize + margin.top + margin.bottom + extraHeightTop + extraHeightBottom; // 395 + 15 + 15 + 5 + 20

    const svgElement = d3.select(svgRef.current);
    svgElement.selectAll("*").remove(); // Clear svg content before adding new elements

    const svg = svgElement
      .attr("width", width)
      .attr("height", height)
      .attr("transform", `translate(${margin.left}, ${margin.bottom})`);

    // Y Axis
    const y = d3
      .scaleLinear()
      .domain([-1.15, 1.15])
      .range([innerSize + extraHeightTop, extraHeightTop]); // axis height
    svg
      .append("g")
      .attr("transform", `translate(${widthYLegend},0)`)
      .call(d3.axisLeft(y).tickSizeOuter(0).ticks(5));
    svg
      .append("g")
      .attr("transform", `translate(${widthYLegend + innerSize / 2},0)`)
      .call(d3.axisLeft(y).tickValues([]).tickSizeOuter(0));
    svg
      .append("g")
      .attr("transform", `translate(${widthYLegend + innerSize},0)`)
      .call(d3.axisLeft(y).tickValues([]).tickSizeOuter(0));
    svg
      .append("text") // text label for the y axis
      .attr("transform", "rotate(-90)")
      .attr("y", 15)
      .attr("x", -innerSize / 2)
      .style("text-anchor", "middle")
      .text("Emotional response")
      .attr("font-size", "16");

    // X Axis
    const x = d3
      .scaleLinear()
      .domain([-1.15, 1.15])
      .range([widthYLegend, widthYLegend + innerSize]); // axis width
    svg
      .append("g")
      .attr("transform", `translate(0, ${innerSize + extraHeightTop})`)
      .call(d3.axisBottom(x).tickSizeOuter(0).ticks(5));
    svg
      .append("g")
      .attr("transform", `translate(0, ${innerSize / 2 + extraHeightTop})`)
      .call(d3.axisBottom(x).tickValues([]).tickSizeOuter(0));
    svg
      .append("g")
      .attr("transform", `translate(0, ${extraHeightTop})`)
      .call(d3.axisBottom(x).tickValues([]).tickSizeOuter(0));
    svg
      .append("text") // Noise text label for the x axis
      .attr("transform", `translate(${-85 + widthYLegend + innerSize / 2}, ${height - 5})`)
      .style("text-anchor", "middle")
      .text("Distortion")
      .attr("font-size", "16");

    svg
      .append("text") // Noise text label for the x axis
      .attr("transform", `translate(${85 + widthYLegend + innerSize / 2}, ${height - 5})`)
      .style("text-anchor", "middle")
      .text("Signal")
      .attr("font-size", "16");

    const densityData = d3
      .contourDensity()
      .y((d) => {
        return y(d.y);
      })
      .x((d) => {
        return x(d.x);
      })
      .size([width, height])
      .bandwidth(30)(data);

    // Prepare a color palette
    const color = d3
      .scaleLinear()
      .domain([0, 0.65 * Math.max(...densityData.map((o) => o.value))]) // 0.65 provides darker colors on top (1.0 represenst max)
      .range(["white", "#0074F4"]);

    // Draw density image
    svg
      .selectAll("path")
      .data(densityData)
      .enter()
      .append("path")
      .attr("d", d3.geoPath())
      .attr("fill", (d) => {
        return color(d.value);
      });

    // Community member dots
    for (let j = 0; j < data.length; j += 1) {
      const Xpx = widthYLegend + ((data[j].x + 1.15) / 2.3) * innerSize;
      const Ypx = extraHeightTop + ((-data[j].y + 1.15) / 2.3) * innerSize;

      let bMsg = false;
      if (
        (data[j].values && data[j].values.length > 0) ||
        data[j].divisive > 0 ||
        data[j].manipulative ||
        (data[j].heartOfIssue && data[j].heartOfIssue > 0.5) ||
        (data[j].note && data[j].note.length > 0)
      ) {
        bMsg = true;
      }

      if (myStarIndex !== j) {
        svg
          .append("circle")
          .attr("cx", Xpx)
          .attr("cy", Ypx)
          .attr("r", 3)
          // Extra space around the dot that is still active
          .attr("stroke-width", 1)
          .attr("stroke", bMsg ? "invisible" : "black")
          .style("fill", bMsg > 0 ? "black" : "white");
      }
    }

    // My star
    if (myStarIndex >= 0) {
      const Xpx = widthYLegend + ((data[myStarIndex].x + 1.15) / 2.3) * innerSize;
      const Ypx = extraHeightTop + ((-data[myStarIndex].y + 1.15) / 2.3) * innerSize;

      const sym = d3.symbol().type(d3.symbolStar).size(400);
      d3.select(svgRef.current)
        .append("path")
        .attr("d", sym)
        .attr("fill", "yellow")
        .attr("transform", `translate(${Xpx}, ${Ypx})`)
        .attr("stroke-width", 1)
        .attr("stroke", "black");
    }

    const goBrush = (e) => {
      const rows = [];
      for (let j = 0; j < data.length; j += 1) {
        const Xpx = widthYLegend + ((data[j].x + 1.15) / 2.3) * innerSize;
        const Ypx = extraHeightTop + ((-data[j].y + 1.15) / 2.3) * innerSize;

        if (
          Xpx >= e.selection[0][0] &&
          Xpx <= e.selection[1][0] &&
          Ypx >= e.selection[0][1] &&
          Ypx <= e.selection[1][1]
        ) {
          // An empty line
          const obj = {
            reactions: ["", "-"],
            interpretations: "-",
            notes: "-",
          };
          let foundText = false;

          // Reactions
          if (data[j].values && data[j].values.length > 0) {
            foundText = true;
            // obj.reactions = [data[j].values, data[j].valuesAgentName];
            obj.reactions = [data[j].values, data[j].valuesAgentName];
          } else {
            obj.reactions = ["", "-"];
          }

          // Interpretations (non divisive)
          let interpretations = "";
          if (data[j].manipulative) {
            foundText = true;
            interpretations += "Manipulative\n";
          }
          if (data[j].heartOfIssue && data[j].heartOfIssue > 0.8) {
            foundText = true;
            interpretations += "Requires everyone's attention\n";
          } else if (data[j].heartOfIssue && data[j].heartOfIssue > 0.5) {
            foundText = true;
            interpretations += "Recommended\n";
          }

          // If divisive, create divisive "accordion" to interpretations
          if (data[j].divisive > 0) {
            foundText = true;
            // Create list of reasons why divisive (we can assume at least one reason must be provided)
            let divisiveReasons = "";
            const a = data[j].divisive.toString(2);
            const base2 = "0".repeat(4 - a.length) + a;
            if (base2[3] === "1") {
              divisiveReasons += "• Promotes hate, violence, or suffering\n";
            }
            if (base2[2] === "1") {
              divisiveReasons +=
                "• Attacks a person’s character (or dehumanizes) rather than debating the issue\n";
            }
            if (base2[1] === "1") {
              divisiveReasons +=
                "• Claims a moral high ground at the expense of others (virtue signalling)\n";
            }
            if (base2[0] === "1") {
              divisiveReasons +=
                "• Criticizes someone for arguments that they never made (straw man)\n";
            }
            obj.interpretations = (
              <>
                <Accordion sx={{ boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ padding: "0px", marginBottom: "-15px" }}
                    id="header"
                  >
                    <Typography
                      fontWeight="medium"
                      sx={{
                        fontSize: { xs: "10px", lg: "12px", xl: "14px" },
                        display: "inline-block",
                      }}
                    >
                      Divisive
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: "5px", marginTop: "-10px" }}>
                    <Typography
                      sx={{
                        fontSize: { xs: "10px", lg: "12px", xl: "14px" },
                        display: "inline-block",
                      }}
                    >
                      {divisiveReasons}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Typography
                  fontWeight="medium"
                  sx={{ fontSize: { xs: "10px", lg: "12px", xl: "14px" }, display: "inline-block" }}
                >
                  {interpretations.length > 0 ? interpretations : ""}
                </Typography>
              </>
            );
          } else if (interpretations.length > 0) {
            obj.interpretations = interpretations;
          } else {
            obj.interpretations = "-";
          }

          // Notes
          if (data[j].note && data[j].note.length > 0) {
            foundText = true;
            obj.notes = <i>{data[j].note}</i>;
          }
          if (data[j].whyManipulative && data[j].whyManipulative.length > 0) {
            foundText = true;
            obj.notes = <i>{data[j].whyManipulative}</i>;
          }

          if (foundText) {
            rows.push(obj);
          }
        }
      }
      setSelected(rows);
    };

    // Pause for half second before generating table
    let timer = null;
    const handleBrush = (e) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        goBrush(e);
      }, 500);
    };
    svg.append("g").call(d3.brush().on("brush", handleBrush));
  }, [smSize]);

  return (
    <>
      <div style={{ textAlign: "center" }}>
        {count >= 4 && (
          <>
            <div>
              {sharpeX < 0.15 && sharpeX > -0.15 && <p>Lack of consensus.</p>}
              {sharpeX >= 0.15 && sharpeX < 0.35 && (
                <p>Overall, slightly more signal than distortion.</p>
              )}
              {sharpeX >= 0.35 && sharpeX < 0.65 && <p>More signal than distortion.</p>}
              {sharpeX >= 0.65 && sharpeX < 1 && <p>Much more signal than distortion.</p>}
              {sharpeX >= 1 && sharpeX < 2 && <p>Clear signal.</p>}
              {sharpeX >= 2 && <p>Signal is loud and clear.</p>}
              {sharpeX <= -0.15 && sharpeX > -0.35 && (
                <p>Overall, slightly more distortion than signal.</p>
              )}
              {sharpeX <= -0.35 && sharpeX > -0.65 && <p>More distortion than signal.</p>}
              {sharpeX <= -0.65 && sharpeX > -1 && <p>Much more distortion than signal.</p>}
              {sharpeX <= -1 && <p>Distortion dominates.</p>}
            </div>
            <div>
              {sharpeY >= 1 && <p>Very positive sentiment.</p>}
              {sharpeY >= 0.5 && sharpeY < 1 && <p>Generally, positive sentiment.</p>}
              {sharpeY >= 0.3 && sharpeY < 0.5 && <p>Somewhat positive sentiment.</p>}
              {sharpeY <= -0.3 && sharpeY > -0.5 && <p>Somewhat negative sentiment.</p>}
              {sharpeY <= -0.5 && sharpeY > -1 && <p>Generally, negative sentiment.</p>}
              {sharpeY <= -1 && <p>Very negative sentiment.</p>}
            </div>
            <div>
              {stdevX - Math.abs(avgX) > 0.4 && stdevY - Math.abs(avgY) <= 0.4 && (
                <p>Somewhat divisive?</p>
              )}
              {stdevX - Math.abs(avgX) > 0.4 && stdevY - Math.abs(avgY) > 0.4 && (
                <p>Emotionally charged and likely divisive.</p>
              )}
              {stdevX - Math.abs(avgX) <= 0.4 && stdevY - Math.abs(avgY) > 0.4 && (
                <p>A wide range of emotional responses.</p>
              )}
            </div>
          </>
        )}
        {count < 4 && (
          <p style={{ textAlign: "center" }}>
            Limited data points so far. Please check back later.
          </p>
        )}

        <svg ref={svgRef} />
      </div>

      {selected.length > 0 && (
        <div style={{ marginTop: "20px" }}>
          <Table
            columns={[
              { name: "reactions", align: "left" },
              { name: "interpretations", align: "left" },
              { name: "notes", align: "center" },
            ]}
            rows={selected}
          />
        </div>
      )}
    </>
  );
};

Chart.propTypes = {
  idea: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setShowElephant: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Chart);
