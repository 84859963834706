import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// MUI
import { Card, Stack } from "@mui/material";
import Button from "components/MaterialKit/MKButton";
import Box from "components/MaterialKit/MKBox";
import Input from "components/MaterialKit/MKInput";
import Typography from "components/MaterialKit/MKTypography";
import { Help, FilterFrames } from "@mui/icons-material";

import Spinner from "../layout/Spinner";
import QuestionText from "../styles/QuestionText";
import SelectAgent from "./SelectAgent";
import CoreIssue from "./CoreIssue";
import DialogMessage from "../dialog/DialogMessage";
import ClickText from "../styles/ClickText";
import SliderProxy from "../slider/SliderProxy";
import { addIdea } from "../../actions/idea";
import { setAlert } from "../../actions/alert";
import { modifyActivity } from "../../actions/activity";

const Frame = ({
  myContent,
  myPoint,
  goodIntentLevel,
  image,
  url,
  isPdf,
  isVideo,
  childUpdated,
  setChildUpdated,
  addIdea,
  modifyActivity,
  setAlert,
  activity, // relates only to activities
  setting, // relates only to activities
  addIdeaScale, // relates only to activities
  auth, // relates only to activities
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [requestAgents] = useState(!(setting && setting.defaultWaveMakers));

  const [frameContext, setFrameContext] = useState("");
  const [frameResolution, setFrameResolution] = useState("");
  const [myConcern, setMyConcern] = useState("");
  const [errorCorrection, setErrorCorrection] = useState("");
  const [criticContent, setCriticContent] = useState("");
  const [criticConcern, setCriticConcern] = useState("");
  const [proxyLevel, setProxyLevel] = useState(-2);
  const [proxySources, setProxySources] = useState([]);
  const [players, setPlayers] = useState([]);
  const [playersNA, setPlayersNA] = useState(false);
  const [proxyNA, setProxyNA] = useState(false);
  const [atCore, setAtCore] = useState(false);
  const [coreDone, setCoreDone] = useState(true); // if false, we are in an intertermediate state

  // Everytime there is any user interaction, notify parent by updating a counter
  useEffect(() => {
    setChildUpdated(childUpdated + 1);
  }, [frameResolution, atCore]);
  useEffect(() => {
    if (frameResolution.length === 0) {
      setChildUpdated(childUpdated + 1);
    }
  }, [players, playersNA]);
  useEffect(() => {
    if (frameResolution.length === 0 && players.length === 0) {
      setChildUpdated(childUpdated + 1);
    }
  }, [proxyLevel, proxySources, proxyNA]);
  useEffect(() => {
    if (frameResolution.length === 0 && players.length === 0 && proxySources.length === 0) {
      setChildUpdated(childUpdated + 1);
    }
  }, [errorCorrection]);
  useEffect(() => {
    if (
      frameResolution.length === 0 &&
      players.length === 0 &&
      proxySources.length === 0 &&
      errorCorrection.length === 0
    ) {
      setChildUpdated(childUpdated + 1);
    }
  }, [criticConcern]);
  useEffect(() => {
    if (
      frameResolution.length === 0 &&
      players.length === 0 &&
      proxySources.length === 0 &&
      errorCorrection.length === 0 &&
      criticConcern.length === 0
    ) {
      setChildUpdated(childUpdated + 1);
    }
  }, [criticContent]);
  useEffect(() => {
    if (
      frameResolution.length === 0 &&
      players.length === 0 &&
      proxySources.length === 0 &&
      errorCorrection.length === 0 &&
      criticConcern.length === 0 &&
      criticContent.length === 0
    ) {
      setChildUpdated(childUpdated + 1);
    }
  }, [myConcern]);
  useEffect(() => {
    if (
      frameResolution.length === 0 &&
      players.length === 0 &&
      proxySources.length === 0 &&
      errorCorrection.length === 0 &&
      criticConcern.length === 0 &&
      criticContent.length === 0 &&
      myConcern.length === 0
    ) {
      setChildUpdated(childUpdated + 1);
    }
  }, [frameContext]);

  const [frameContextDialog, setFrameContextDialog] = useState(false);
  const [frameResolutionDialog, setFrameResolutionDialog] = useState(false);
  const [myConcernDialog, setMyConcernDialog] = useState(false);
  const [errorCorrectionDialog, setErrorCorrectionDialog] = useState(false);
  const [criticContentDialog, setCriticContentDialog] = useState(false);
  const [criticConcernDialog, setCriticConcernDialog] = useState(false);
  const [proxyDialog, setProxyDialog] = useState(false);
  const [playersDialog, setPlayersDialog] = useState(false);

  const onSetProxyLevel = (e) => {
    setProxyLevel(e < 0.05 ? 0 : e);
  };

  const onSubmitIdea = () => {
    let bValid = true;
    if (myPoint.length <= 2) {
      setAlert('Invalid "headline". Please be more descriptive.', "danger");
      bValid = false;
    } else if (goodIntentLevel < 2) {
      setAlert('"Good intent" level inadequate.', "danger");
      bValid = false;
    } else if (isPdf && !image) {
      setAlert("Please upload a valid pdf file.", "danger");
      bValid = false;
    } else if (isVideo && url.length <= 2) {
      setAlert("Invalid url.", "danger");
      bValid = false;
    } else if (frameContext.length === 0) {
      setAlert("Inadequate big picture context. Please be more descriptive.", "danger");
      bValid = false;
    } else if (frameResolution.length === 0) {
      setAlert("Inadequate bottom line. Please be more descriptive.", "danger");
      bValid = false;
    } else if (myConcern.length === 0) {
      setAlert("Inadequate why this matters to me. Please be more descriptive.", "danger");
      bValid = false;
    } else if (criticConcern.length === 0) {
      setAlert("Inadequate why this matters to critics. Please be more descriptive.", "danger");
      bValid = false;
    } else if (criticContent.length === 0) {
      setAlert("Inadequate arguments from critics. Please be more descriptive.", "danger");
      bValid = false;
    } else if (errorCorrection.length === 0) {
      setAlert(
        "Inadequate response to what would change your mind. Please be more descriptive.",
        "danger"
      );
      bValid = false;
    } else if (proxyLevel < -1) {
      setAlert("Position on proxy slider must be selected", "danger");
      bValid = false;
    }

    if (bValid) {
      setSubmitting(true);

      if (!activity) {
        addIdea({
          goodIntentLevel,
          myPoint,
          myContent,
          url,
          image,
          isVideo,
          isPdf,
          frameContext,
          frameResolution,
          myConcern,
          criticConcern,
          criticContent,
          errorCorrection,
          proxyLevel,
          proxySources: proxySources.map((e) => ({ _id: e.value, name: e.label })),
          players: players.map((e) => ({ _id: e.value, name: e.label })),
          atCore,
        });
      } else if (!activity.afterOptionInterpretNewInTell) {
        modifyActivity({
          activityID: activity._id,
          studentIDCompleted: auth.user._id,
          addIdea: {
            goodIntentLevel,
            myPoint,
            myContent,
            url,
            image,
            isVideo,
            isPdf,
            frameContext,
            frameResolution,
            myConcern,
            criticConcern,
            criticContent,
            errorCorrection,
            proxyLevel,
            proxySources: proxySources.map((e) => ({ _id: e.value, name: e.label })),
            players: players.map((e) => ({ _id: e.value, name: e.label })),
            atCore,
          },
          addIdeaScale,
        });
      } else {
        modifyActivity({
          activityID: activity._id,
          studentIDCompleted: auth.user._id,
          addIdea: {
            goodIntentLevel,
            myPoint,
            myContent,
            url,
            image,
            isVideo,
            isPdf,
            frameContext,
            frameResolution,
            myConcern,
            criticConcern,
            criticContent,
            errorCorrection,
            proxyLevel,
            proxySources: proxySources.map((e) => ({ _id: e.value, name: e.label })),
            players: players.map((e) => ({ _id: e.value, name: e.label })),
            atCore,
          },
          newActivities: [
            {
              selection: 102,
              interpretConfirmDeeperUnderstanding: activity.interpretConfirmDeeperUnderstanding,
              interpretEvaluateValues: activity.interpretEvaluateValues,
              interpretPauseEmotionFlow: activity.interpretPauseEmotionFlow,
              interpretIntentWords: activity.interpretIntentWords,
              afterOptionInterconnectNewInTell: activity.afterOptionInterconnectNewInTell,
              communityTopic: activity.communityTopic,
            },
          ],
          addIdeaScale,
        });
      }
    }
  };

  return submitting ? (
    <Spinner />
  ) : (
    <>
      <Card style={{ marginTop: "30px", padding: "30px", marginBottom: "20px" }}>
        <Typography
          variant="h4"
          textTransform="uppercase"
          style={{ textAlign: "center" }}
          sx={{ fontSize: { xs: "14px", sm: "17px", md: "20px" } }}
        >
          Beautiful!
          <FilterFrames
            fontSize="large"
            style={{ marginLeft: "10px", marginRight: "10px", marginBottom: "-10px" }}
          />
          Let&apos;s now &quot;frame it&quot;
        </Typography>

        <QuestionText
          large={frameContext.length > 0 ? 0 : 1}
          style={{ marginTop: "30px", marginBottom: "10px" }}
        >
          Big picture, what are we talking about here? (and what are we not talking about?)
          <Help
            onClick={() => setFrameContextDialog(true)}
            color="primary"
            style={{ marginLeft: "3px", marginBottom: "3px" }}
          />
        </QuestionText>
        <Box onChange={(e) => setFrameContext(e.target.value)}>
          <Input
            type="frameContext"
            label="Stepping back, this inTell is really all about..."
            fullWidth
            multiline
            value={frameContext}
          />
        </Box>

        {frameContext.length > 0 && (
          <>
            <QuestionText
              large={myConcern.length > 0 ? 0 : 1}
              style={{ marginTop: "30px", marginBottom: "10px" }}
            >
              Why does this inTell matter?
              <Help
                onClick={() => setMyConcernDialog(true)}
                color="primary"
                style={{ marginLeft: "3px", marginBottom: "3px" }}
              />
            </QuestionText>
            <Box onChange={(e) => setMyConcern(e.target.value)}>
              <Input
                type="myConcern"
                label="Why do I care?"
                fullWidth
                multiline
                value={myConcern}
              />
            </Box>

            {myConcern.length > 0 && (
              <>
                <QuestionText
                  large={criticContent.length > 0 ? 0 : 1}
                  style={{ marginTop: "30px" }}
                >
                  My most competent critics would argue...
                  <Help
                    onClick={() => setCriticContentDialog(true)}
                    color="primary"
                    style={{ marginLeft: "3px", marginBottom: "3px" }}
                  />
                </QuestionText>
                <Box
                  onChange={(e) => setCriticContent(e.target.value)}
                  style={{ marginTop: "10px" }}
                >
                  <Input
                    type="criticContent"
                    label="Their best arguments are..."
                    fullWidth
                    multiline
                    value={criticContent}
                  />
                </Box>

                {criticContent.length > 0 && (
                  <>
                    <QuestionText
                      large={criticConcern.length > 0 ? 0 : 1}
                      style={{ marginTop: "30px" }}
                    >
                      My most competent critics are driven by values and needs such as...
                      <Help
                        onClick={() => setCriticConcernDialog(true)}
                        color="primary"
                        style={{ marginLeft: "3px", marginBottom: "3px" }}
                      />
                    </QuestionText>
                    <Box
                      onChange={(e) => setCriticConcern(e.target.value)}
                      style={{ marginTop: "10px" }}
                    >
                      <Input
                        type="criticConcern"
                        label="What do they care about?"
                        fullWidth
                        multiline
                        value={criticConcern}
                      />
                    </Box>

                    {criticConcern.length > 0 && (
                      <>
                        <QuestionText
                          large={errorCorrection.length > 0 ? 0 : 1}
                          style={{ marginTop: "30px", marginBottom: "10px" }}
                        >
                          What would change your position?
                          <Help
                            onClick={() => setErrorCorrectionDialog(true)}
                            color="primary"
                            style={{ marginLeft: "3px", marginBottom: "3px" }}
                          />
                        </QuestionText>
                        <Box onChange={(e) => setErrorCorrection(e.target.value)}>
                          <Input
                            type="errorCorrection"
                            label="I would change my position if..."
                            fullWidth
                            multiline
                            value={errorCorrection}
                          />
                        </Box>

                        {errorCorrection.length > 0 && (
                          <>
                            <QuestionText
                              large={
                                proxyLevel === 0 ||
                                (proxyLevel > 0 && (proxySources.length > 0 || proxyNA))
                                  ? 0
                                  : 1
                              }
                              style={{ marginTop: "30px" }}
                            >
                              Who is ultimately responsible for the underlying message in this
                              inTell?
                              <Help
                                onClick={() => setProxyDialog(true)}
                                color="primary"
                                style={{ marginLeft: "3px", marginBottom: "3px" }}
                              />
                            </QuestionText>
                            {proxyLevel < -1 && (
                              <div style={{ marginBottom: "10px", textAlign: "center" }}>
                                <ClickText style={{ textAlign: "center" }}>
                                  CLICK ON THE SCALE BELOW
                                </ClickText>
                              </div>
                            )}
                            <SliderProxy userInitLevel={proxyLevel} updated={onSetProxyLevel} />

                            {requestAgents && (
                              <>
                                {proxyLevel > 0 && (
                                  <SelectAgent
                                    selectedAgents={proxySources}
                                    setSelectedAgents={setProxySources}
                                    title="Select the sources that you used that would be most supportive of your position."
                                    subTitle="For example: Tucker Carlson, CNN, Reuters, etc."
                                    NA={proxyNA}
                                    setNA={setProxyNA}
                                  />
                                )}

                                {(proxyLevel === 0 ||
                                  (proxyLevel > 0 && (proxySources.length > 0 || proxyNA))) && (
                                  <>
                                    <QuestionText
                                      large={players.length > 0 || playersNA ? 0 : 1}
                                      style={{ marginTop: "30px", marginBottom: "-10px" }}
                                    >
                                      Who are the additional waveMaker(s) in this inTell?
                                      <Help
                                        onClick={() => setPlayersDialog(true)}
                                        color="primary"
                                        style={{ marginLeft: "3px", marginBottom: "3px" }}
                                      />
                                    </QuestionText>
                                    <SelectAgent
                                      selectedAgents={players}
                                      ignoreAgents={proxySources}
                                      setSelectedAgents={setPlayers}
                                      NA={playersNA}
                                      setNA={setPlayersNA}
                                      title="Who benefits? Who loses? Who is affected?"
                                      subTitle="Not including you (or the sources selected above)"
                                    />
                                  </>
                                )}
                              </>
                            )}

                            {((!requestAgents && proxyLevel > 0) ||
                              (requestAgents && (players.length > 0 || playersNA))) && (
                              <>
                                <QuestionText
                                  large={frameResolution.length > 0 ? 0 : 1}
                                  style={{ marginTop: "30px", marginBottom: "10px" }}
                                >
                                  Now, step back and take everything into consideration...
                                  <Help
                                    onClick={() => setFrameResolutionDialog(true)}
                                    color="primary"
                                    style={{ marginLeft: "3px", marginBottom: "3px" }}
                                  />
                                </QuestionText>
                                <Box onChange={(e) => setFrameResolution(e.target.value)}>
                                  <Input
                                    type="frameResolution"
                                    label="The bottom line is..."
                                    fullWidth
                                    multiline
                                    value={frameResolution}
                                  />
                                </Box>
                                {frameResolution.length > 0 && (
                                  <div style={{ marginTop: "30px" }}>
                                    <CoreIssue
                                      atCore={atCore}
                                      setAtCore={setAtCore}
                                      setCoreDone={setCoreDone}
                                      childUpdated={childUpdated}
                                      setChildUpdated={setChildUpdated}
                                    />
                                    {atCore && (
                                      <QuestionText large={1} style={{ marginTop: "20px" }}>
                                        Thank-you for sharing your expertise and helping us all to
                                        see more clearly!
                                      </QuestionText>
                                    )}

                                    {coreDone && (
                                      <Stack
                                        flexWrap="wrap"
                                        flexDirection="row"
                                        width={1.0}
                                        justifyContent="center"
                                        style={{ marginTop: "40px" }}
                                      >
                                        <Button
                                          onClick={() => onSubmitIdea()}
                                          color="primary"
                                          circular
                                        >
                                          Share your inTell
                                        </Button>
                                      </Stack>
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </Card>

      {frameContextDialog && (
        <DialogMessage frameContext handleDialogClose={() => setFrameContextDialog(false)} />
      )}
      {frameResolutionDialog && (
        <DialogMessage frameResolution handleDialogClose={() => setFrameResolutionDialog(false)} />
      )}
      {myConcernDialog && (
        <DialogMessage myConcern handleDialogClose={() => setMyConcernDialog(false)} />
      )}
      {errorCorrectionDialog && (
        <DialogMessage errorCorrection handleDialogClose={() => setErrorCorrectionDialog(false)} />
      )}
      {criticContentDialog && (
        <DialogMessage criticContent handleDialogClose={() => setCriticContentDialog(false)} />
      )}
      {criticConcernDialog && (
        <DialogMessage criticConcern handleDialogClose={() => setCriticConcernDialog(false)} />
      )}
      {proxyDialog && <DialogMessage proxy handleDialogClose={() => setProxyDialog(false)} />}
      {playersDialog && <DialogMessage players handleDialogClose={() => setPlayersDialog(false)} />}
    </>
  );
};

Frame.propTypes = {
  goodIntentLevel: PropTypes.number.isRequired,
  myContent: PropTypes.string.isRequired,
  myPoint: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isPdf: PropTypes.bool.isRequired,
  isVideo: PropTypes.bool.isRequired,
  childUpdated: PropTypes.number,
  setChildUpdated: PropTypes.func,
  activity: PropTypes.oneOfType([PropTypes.object]),
  setting: PropTypes.oneOfType([PropTypes.object]),
  addIdeaScale: PropTypes.oneOfType([PropTypes.object]),
  auth: PropTypes.oneOfType([PropTypes.object]),
};

Frame.defaultProps = {
  childUpdated: 0,
  addIdeaScale: null,
  setChildUpdated: null,
  activity: null,
  setting: null,
  auth: null,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { addIdea, modifyActivity, setAlert })(Frame);
