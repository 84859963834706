import React from "react";
import PropTypes from "prop-types";

// @mui
import { Hidden } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import Button from "components/MaterialKit/MKButton";

const EditConfirm = ({
  coord,
  readyToSubmit,
  hideAbortButton,
  handleConfidenceClick,
  handleAbortClick,
}) => {
  return (
    <>
      {/* Big screens = big buttons */}
      <Hidden smDown implementation="css">
        <div style={{ textAlign: "center" }}>
          <br />

          {readyToSubmit && (
            <>
              {/* Display confidence buttons: #4 & #6 (buttons darkened for #3 & equivalent to abort if pressed) */}
              {(coord.changed ||
                (coord.existsInDb && !coord.changed && coord.confidence !== 0.1)) && (
                <Button
                  style={{ backgroundColor: "#291AE0", marginRight: "5px" }}
                  onClick={() => handleConfidenceClick(0.1)}
                >
                  I guess
                </Button>
              )}
              {coord.existsInDb && !coord.changed && coord.confidence === 0.1 && (
                <Button style={{ backgroundColor: "#291AE0", marginRight: "5px" }} disabled>
                  I guess
                </Button>
              )}

              {(coord.changed ||
                (coord.existsInDb && !coord.changed && coord.confidence !== 0.5)) && (
                <Button
                  style={{ backgroundColor: "#291AE0", marginRight: "5px" }}
                  onClick={() => handleConfidenceClick(0.5)}
                >
                  I think so
                </Button>
              )}
              {coord.existsInDb && !coord.changed && coord.confidence === 0.5 && (
                <Button style={{ backgroundColor: "#291AE0", marginRight: "5px" }} disabled>
                  I think so
                </Button>
              )}

              {(coord.changed ||
                (coord.existsInDb && !coord.changed && coord.confidence !== 1.0)) && (
                <Button
                  style={{ backgroundColor: "#291AE0", marginRight: "10px" }}
                  onClick={() => handleConfidenceClick(1.0)}
                >
                  I know
                </Button>
              )}
              {coord.existsInDb && !coord.changed && coord.confidence === 1.0 && (
                <Button style={{ backgroundColor: "#291AE0", marginRight: "10px" }} disabled>
                  I know
                </Button>
              )}
            </>
          )}

          {!hideAbortButton && (
            <Button onClick={() => handleAbortClick()}>
              <Cancel />
              Abort edit
            </Button>
          )}
        </div>
      </Hidden>

      {/* Small screens = small buttons */}
      <Hidden mdUp implementation="css">
        <div style={{ textAlign: "center" }}>
          <br />

          {readyToSubmit && (
            <>
              {/* Display confidence buttons: #4 & #6 (buttons darkened for #3 & equivalent to abort if pressed) */}
              {(coord.changed ||
                (coord.existsInDb && !coord.changed && coord.confidence !== 0.1)) && (
                <Button
                  size="sm"
                  style={{ backgroundColor: "#291AE0", marginRight: "5px" }}
                  onClick={() => handleConfidenceClick(0.1)}
                >
                  I guess
                </Button>
              )}
              {coord.existsInDb && !coord.changed && coord.confidence === 0.1 && (
                <Button
                  size="sm"
                  style={{ backgroundColor: "#291AE0", marginRight: "5px" }}
                  disabled
                >
                  I guess
                </Button>
              )}

              {(coord.changed ||
                (coord.existsInDb && !coord.changed && coord.confidence !== 0.5)) && (
                <Button
                  size="sm"
                  style={{ backgroundColor: "#291AE0", marginRight: "5px" }}
                  onClick={() => handleConfidenceClick(0.5)}
                >
                  I think so
                </Button>
              )}
              {coord.existsInDb && !coord.changed && coord.confidence === 0.5 && (
                <Button
                  size="sm"
                  style={{ backgroundColor: "#291AE0", marginRight: "5px" }}
                  disabled
                >
                  I think so
                </Button>
              )}

              {(coord.changed ||
                (coord.existsInDb && !coord.changed && coord.confidence !== 1.0)) && (
                <Button
                  size="sm"
                  style={{ backgroundColor: "#291AE0", marginRight: "10px" }}
                  onClick={() => handleConfidenceClick(1.0)}
                >
                  I know
                </Button>
              )}
              {coord.existsInDb && !coord.changed && coord.confidence === 1.0 && (
                <Button
                  size="sm"
                  style={{ backgroundColor: "#291AE0", marginRight: "10px" }}
                  disabled
                >
                  I know
                </Button>
              )}
            </>
          )}

          {!hideAbortButton && (
            <Button size="sm" onClick={() => handleAbortClick()}>
              <Cancel />
              Abort edit
            </Button>
          )}
        </div>
      </Hidden>
    </>
  );
};

EditConfirm.propTypes = {
  coord: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
  readyToSubmit: PropTypes.bool,
  hideAbortButton: PropTypes.bool,
  handleConfidenceClick: PropTypes.func.isRequired,
  handleAbortClick: PropTypes.func.isRequired,
};

EditConfirm.defaultProps = {
  readyToSubmit: false,
  hideAbortButton: false,
};

export default EditConfirm;
