import { useMemo } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

// MUI
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import MKBox from "components/MaterialKit/MKBox";
import MKAvatar from "components/MaterialKit/MKAvatar";
import MKTypography from "components/MaterialKit/MKTypography";

const Table = ({ columns, rows }) => {
  const renderColumns = columns.map(({ name, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <MKBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        opacity={0.7}
        sx={({ typography: { size, fontWeightBold }, borders: { borderWidth, borderColor } }) => ({
          fontSize: size.xxs,
          fontWeight: fontWeightBold,
          borderBottom: `${borderWidth[1]} solid ${borderColor}`,
        })}
      >
        {name.toUpperCase()}
      </MKBox>
    );
  });

  const renderRows = rows.map((row, key) => {
    const rowKey = `row-${key}`;

    const tableRow = columns.map(({ name, align }) => {
      let template;

      if (Array.isArray(row[name])) {
        template = (
          <MKBox
            key={uuidv4()}
            component="td"
            p={0.25}
            sx={({ borders: { borderWidth, borderColor } }) => ({
              borderBottom: row.hasBorder ? `${borderWidth[1]} solid ${borderColor}` : 0,
            })}
          >
            <MKBox display="flex" alignItems="center" py={0.5} px={1}>
              <MKBox mr={2}>
                <MKAvatar src={row[name][0]} name={row[name][1]} variant="square" size="sm" />
              </MKBox>
              <MKTypography
                variant="button"
                fontWeight="medium"
                sx={{
                  width: { xs: 65, sm: 110, md: 160, lg: 250, xl: 300 },
                  fontSize: { xs: "10px", lg: "12px", xl: "14px" },
                  whiteSpace: "pre-line",
                }}
              >
                {row[name][1]}
              </MKTypography>
            </MKBox>
          </MKBox>
        );
      } else {
        template = (
          <MKBox
            key={uuidv4()}
            component="td"
            p={0.25}
            textAlign={align}
            sx={({ borders: { borderWidth, borderColor } }) => ({
              borderBottom: row.hasBorder ? `${borderWidth[1]} solid ${borderColor}` : 0,
            })}
          >
            <MKTypography
              variant="button"
              fontWeight="medium"
              sx={{
                display: "inline-block",
                width: { xs: 65, sm: 110, md: 160, lg: 250, xl: 300 },
                fontSize: { xs: "10px", lg: "12px", xl: "14px" },
                whiteSpace: "pre-line",
              }}
            >
              {row[name]}
            </MKTypography>
          </MKBox>
        );
      }

      return template;
    });

    return <TableRow key={rowKey}>{tableRow}</TableRow>;
  });

  return useMemo(
    () => (
      <TableContainer>
        <MuiTable>
          <MKBox component="thead">
            <TableRow>{renderColumns}</TableRow>
          </MKBox>
          <TableBody>{renderRows}</TableBody>
        </MuiTable>
      </TableContainer>
    ),
    [columns, rows]
  );
};

// Setting default values for the props of Table
Table.defaultProps = {
  columns: [],
  rows: [{}],
};

// Typechecking props for the Table
Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default Table;
