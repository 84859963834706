import axios from "axios";
import { setAlert } from "./alert";
import {
  SEARCH_IDEAS,
  LATEST_IDEAS,
  IDEA_ERROR,
  ADD_IDEA,
  GET_IDEA,
  GET_IDEAS,
  ADD_IDEA_SCALE,
  LOADING_IDEA,
} from "./types";

// Loading
export const loadingIdea = () => (dispatch) => {
  dispatch({ type: LOADING_IDEA });
};

// Get ideas attributed to userId
export const getIdeas = (userId, limitSize) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/ideas/user/${userId}`, {
      params: {
        limitSize,
      },
    });
    dispatch({
      type: GET_IDEAS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: IDEA_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
    dispatch(setAlert(`Failed To Retrieve Ideas From Server: ${err.response.data}`, "danger"));
  }
};

// Search ideas
export const searchIdeas = (searchString, limitSize) => async (dispatch) => {
  try {
    const res = await axios.get("/api/ideas", {
      params: {
        searchString,
        limitSize,
      },
    });
    dispatch({
      type: SEARCH_IDEAS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: IDEA_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
    dispatch(setAlert(`Failed To Retrieve Ideas From Server: ${err.response.data}`, "danger"));
  }
};

// Get the latest ideas
export const latestIdeas = (limitSize) => async (dispatch) => {
  try {
    const res = await axios.get("/api/ideas", {
      params: {
        limitSize,
      },
    });
    dispatch({
      type: LATEST_IDEAS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: IDEA_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
    dispatch(
      setAlert(`Failed To Retrieve Latest Ideas From Server: ${err.response.data}`, "danger")
    );
  }
};

// Add idea
export const addIdea = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.post("/api/ideas", formData, config);

    dispatch({
      type: ADD_IDEA,
      payload: res.data,
    });
    //  dispatch(setAlert('Idea Created', 'success', 1000));
  } catch (err) {
    // err.response.status = error code - ie, 400, 404, 500...
    // err.response.statusText = Text meaning associated with err.response.status
    // err.response.data.errors[] = An array of all errors
    const { errors } = err.response.data;
    if (errors) {
      errors.forEach((error) =>
        dispatch(setAlert(`Failed To Create Link On Server: ${error.msg}`, "danger"))
      );
    }
    dispatch({
      type: IDEA_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get idea
export const getIdea = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/ideas/${id}`);

    dispatch({
      type: GET_IDEA,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: IDEA_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
    dispatch(setAlert(`Failed To Retrieve Idea From Server: ${err.response.data}`, "danger"));
  }
};

// Add idea scale
export const addIdeaScale = (ideaId, formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.post(`/api/ideas/scale/${ideaId}`, formData, config);
    dispatch({
      type: ADD_IDEA_SCALE,
      payload: res.data,
    });
    //  dispatch(setAlert('Idea-Scale Added', 'success', 1000));
  } catch (err) {
    // err.response.status = error code - ie, 400, 404, 500...
    // err.response.statusText = Text meaning associated with err.response.status
    // err.response.data.errors[] = An array of all errors
    const { errors } = err.response.data;
    if (errors) {
      errors.forEach((error) =>
        dispatch(setAlert(`Failed To Add Idea-Scale On Server: ${error.msg}`, "danger"))
      );
    }
    dispatch({
      type: IDEA_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
