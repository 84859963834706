import {
  SETTING_ERROR,
  ADD_SETTING,
  MODIFY_SETTING,
  DELETE_SETTING,
  GET_SETTING,
  GET_SETTINGS,
  LOADING_SETTING,
} from "../actions/types";

const initialState = {
  settings: [],
  setting: null,
  loading: true,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SETTINGS:
    case DELETE_SETTING:
      return {
        ...state,
        settings: payload,
        loading: false,
      };
    case GET_SETTING:
    case MODIFY_SETTING:
    case ADD_SETTING:
      return {
        ...state,
        setting: payload,
        settings: [],
        loading: false,
      };
    case SETTING_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case LOADING_SETTING:
      return {
        ...state,
        settings: [],
        setting: null,
        loading: true,
      };
    default:
      return state;
  }
}
