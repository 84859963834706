import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Select, { components } from "react-select";

// @mui
import { Tooltip, Button } from "@mui/material";
import { WbIncandescent, People, AddToPhotos } from "@mui/icons-material";

import { searchIdeas } from "../../actions/idea";
import { searchAgents } from "../../actions/agent";

// PROPS
// Name              Type     Description
// mode              string   Encoded instructions for link creation if new inTell/waveMaker is created (see create/Create.js)
// existingAgentList array    List of existing linked agents (prevents duplicate links)
// existingIdeaList  array    List of existing linked ideas (prevents duplicate links)
// --------------------------------------------
// Props used (from calling components):
// Idea [mode] [existingAgentList] [existingIdeaList]
// --------------------------------------------
// ACTIONS
// - searchIdeas action (via request to backend); updates the following: <idea.ideas> containing the updated search list
// - searchAgents action (via request to backend); updates the following: <agent.agents> containing the updated search list

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#D5E2F6" : null,
      color: "#0052CC",
    };
  },
};

const Svg = (p) => (
  // eslint-disable-next-line
  <svg width="24" height="24" viewBox="0 -8 30 30" focusable="false" role="presentation" {...p} />
);

const DropdownIndicator = () => (
  <div>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="#A4A4A4A4"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);

const SearchList = ({
  mode,
  searchIdeas,
  searchAgents,
  existingIdeaList,
  existingAgentList,
  requestComponentUnload,
  idea,
  agent,
}) => {
  // Icon in list
  const { Option } = components;
  const IconOption = (props) => {
    const { data } = props;
    const { icon, label } = data;
    return (
      <Option {...props}>
        {icon && <span> {icon} </span>}
        {label}
      </Option>
    );
  };

  const navigate = useNavigate();

  // Capture event when user loses interest in searching/selecting a link
  const handleBlur = () => {
    requestComponentUnload(true);
  };
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Options list (ideas) will be updated any time <idea.ideas> changes
  useEffect(() => {
    if (existingIdeaList && idea && idea.ideas) {
      const newOptions = idea.ideas
        .filter((e) => e._id !== idea.idea._id) // filters out current ideaId
        .map((e) => ({
          value: e._id,
          label: e.myPoint ? e.myPoint : e.text ? (e.url ? e.text : `"${e.text}"`) : e.myContent,
          icon: <WbIncandescent style={{ width: "16px", height: "16px", marginRight: "8px" }} />,
        }))
        // filters out duplicate linked ideaId's (fyi: backend tested to refuse as well)
        .filter(
          (x) =>
            !existingIdeaList.filter(
              (y) => y.ideaCause._id === x.value || y.ideaEffect._id === x.value
            ).length
        );

      setOptions(newOptions);
      setIsLoading(false);
    }
  }, [idea.ideas, existingIdeaList]);

  // Options list (agents) will be updated any time <agent.agents> changes
  useEffect(() => {
    if (existingAgentList && agent && agent.agents) {
      const newOptions = agent.agents
        .map((e) => ({
          value: e._id,
          label: e.name,
          icon: <People style={{ width: "16px", height: "16px", marginRight: "8px" }} />,
        }))
        // filters out duplicate linked ideaId's (fyi: backend tested to refuse as well)
        .filter((x) => !existingAgentList.filter((y) => y.agent._id === x.value).length);

      setOptions(newOptions);
      setIsLoading(false);
    }
    // esliddnt-disable-next-line react-hooks/exhaustive-deps
  }, [agent.agents, existingAgentList]);

  // Add link when inSighter selects an inTell/waveMaker from dropdown list
  // Note: "Add link" will be delegated to <Idea> or <Agent> (with appropriate encoded "mode" instructions)
  const handleOnUserSelect = (e) => {
    // Link idea to idea; mode:
    // - "20"=support linkIdeaIdea, ideaEffect was the original center of attention
    // - "21"=support linkIdeaIdea, ideaCause was the original center of attention
    // - "22"=oppose linkIdeaIdea, ideaEffect was the original center of attention
    // - "23"=oppose linkIdeaIdea, ideaCause was the original center of attention
    // - "24"=complex linkIdeaIdea, ideaEffect was the original center of attention
    // - "25"=complex linkIdeaIdea, ideaCause was the original center of attention
    // - "26"=similar linkIdeaIdea, ideaEffect was the original center of attention
    // - "27"=similar linkIdeaIdea, ideaCause was the original center of attention
    // <Idea> component will first check that the selected inTell has been interpreted (will first force interpretation as necessary)
    // ... then will then add LinkIdeaIdea as directed in "mode" instructions
    if (
      mode === "20" ||
      mode === "22" ||
      mode === "24" ||
      mode === "26" ||
      mode === "21" ||
      mode === "23" ||
      mode === "25" ||
      mode === "27"
    ) {
      navigate(`/idea/${e.value}/${mode}/${idea.idea._id}`);
    }

    // Link idea to agent; mode:
    // - "40"=support linkIdeaAgent, idea was the original center of attention
    // - "41"=oppose linkIdeaAgent, idea was the original center of attention
    // <Agent> component will first check that the selected agent has been interpreted (will first force interpretation as necessary)
    // ... then will then add LinkIdeaAgent as directed in "mode" instruction
    if (mode === "40" || mode === "41") {
      navigate(`/agent/${e.value}/${mode}/${idea.idea._id}`);
    }
  };

  // Update <idea.ideas> and <agent.agents> when user types in search box
  const handleInputChange = (e) => {
    setIsLoading(true);
    if (existingAgentList) {
      searchAgents(e, 10);
    }
    if (existingIdeaList) {
      searchIdeas(e, 10);
    }
  };

  const createNewObject = () => {
    navigate(`/create/${mode}/${idea.idea._id}`);
  };

  const [tooltipText] = useState(existingIdeaList ? "Submit new inTell" : "Submit new waveMaker");

  return (
    <div style={{ display: "flex" }}>
      <Tooltip title={tooltipText} placement="top-end">
        <Button
          onMouseDown={createNewObject}
          style={{ color: "#291AE0", marginRight: "10px", paddingTop: "10px" }}
        >
          <AddToPhotos />
        </Button>
      </Tooltip>

      <div style={{ flex: "1" }}>
        <Select
          autoFocus
          defaultMenuIsOpen
          onBlur={handleBlur}
          options={options}
          components={{ Option: IconOption, DropdownIndicator, IndicatorSeparator: null }}
          onChange={handleOnUserSelect}
          onInputChange={handleInputChange}
          placeholder={
            existingIdeaList
              ? "Create link to existing inTell"
              : "Create link to existing waveMaker"
          }
          isLoading={isLoading}
          styles={colourStyles}
        />
      </div>
    </div>
  );
};

SearchList.propTypes = {
  mode: PropTypes.string.isRequired,
  searchIdeas: PropTypes.func.isRequired,
  searchAgents: PropTypes.func.isRequired,
  existingIdeaList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  existingAgentList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  idea: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
  agent: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
  requestComponentUnload: PropTypes.func.isRequired,
};

SearchList.defaultProps = {
  existingIdeaList: [],
  existingAgentList: [],
};

const mapStateToProps = (state) => ({
  idea: state.idea,
  agent: state.agent,
});

export default connect(mapStateToProps, { searchIdeas, searchAgents })(SearchList);
