import axios from "axios";
import { setAlert } from "./alert";
import {
  SETTING_ERROR,
  ADD_SETTING,
  MODIFY_SETTING,
  GET_SETTING,
  GET_SETTINGS,
  DELETE_SETTING,
  LOADING_SETTING,
} from "./types";

// Loading
export const loadingSetting = () => (dispatch) => {
  dispatch({ type: LOADING_SETTING });
};

// Get all settings
export const getSettings = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/setting`);
    dispatch({
      type: GET_SETTINGS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SETTING_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
    dispatch(setAlert(`Failed To Retrieve Settings From Server: ${err.response.data}`, "danger"));
  }
};

// Add new setting
export const addSetting = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.post("/api/setting", formData, config);

    dispatch({
      type: ADD_SETTING,
      payload: res.data,
    });
    //  dispatch(setAlert('Setting Created', 'success', 1000));
  } catch (err) {
    // err.response.status = error code - ie, 400, 404, 500...
    // err.response.statusText = Text meaning associated with err.response.status
    // err.response.data.errors[] = An array of all errors
    const { errors } = err.response.data;
    if (errors) {
      errors.forEach((error) =>
        dispatch(setAlert(`Failed To Add Setting On Server: ${error.msg}`, "danger"))
      );
    }
    dispatch({
      type: SETTING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Modify existing setting
export const modifySetting = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.post(`/api/setting/${formData.settingID}`, formData, config);
    dispatch({
      type: MODIFY_SETTING,
      payload: res.data,
    });
  } catch (err) {
    // err.response.status = error code - ie, 400, 404, 500...
    // err.response.statusText = Text meaning associated with err.response.status
    // err.response.data.errors[] = An array of all errors
    const { errors } = err.response.data;
    if (errors) {
      errors.forEach((error) =>
        dispatch(setAlert(`Failed To Modify Setting On Server: ${error.msg}`, "danger"))
      );
    }
    dispatch({
      type: SETTING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get setting
export const getSetting = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/setting/${id}`);

    dispatch({
      type: GET_SETTING,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SETTING_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
    dispatch(setAlert(`Failed To Retrieve Setting From Server: ${err.response.data}`, "danger"));
  }
};

// Delete setting
export const deleteSetting = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`/api/setting/${id}`);
    dispatch({
      type: DELETE_SETTING,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SETTING_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
    dispatch(setAlert(`Failed To Delete Setting From Server: ${err.response.data}`, "danger"));
  }
};
