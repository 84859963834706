import React from "react";
import PropTypes from "prop-types";

// @mui
import Button from "components/MaterialKit/MKButton";
import { Card, Grid } from "@mui/material";
import Typography from "components/MaterialKit/MKTypography";

import getTimeAgo from "../../utils/getTimeAgo";

// PROPS
// Name           Type     Description
// showIdea       object   Linked idea to display
// showAgent      object   Linked agent to display
// linkIdeaIdea   object   LinkIdeaIdea to display
// linkIdeaAgent  object   LinkIdeaAgent to display
// linkAgentAgent object   LinkAgentAgent to display
// cardColor      string   Display color of linked idea (i.e. "lightgreen"...)
// --------------------------------------------
// ACTIONS
// - no input/output requests to backend
// - display specified linked idea

const ShowLinkItem = ({
  showIdea,
  showAgent,
  linkIdeaIdea,
  linkIdeaAgent,
  linkAgentAgent,
  cardColor,
}) => {
  //
  //    button: {
  //      fontSize: "14px",
  //      textTransform: "none",
  //      color: "#291AE0",
  //      backgroundColor: "transparent",
  //      whiteSpace: "normal",
  //      padding: "0px 0px",
  //      "&:hover,&:focus": {
  //        textDecoration: "underline", // underline text when mouse hovers
  //        color: "#291AE0", // stay blue (otherwise defaults to black on hover)
  //      },
  //    },
  //    card: {
  //      margin: "8px 0px", // in each list of support/oppose/similar, 8px space between cards
  //      // Setting 2nd option to 0px remmove bottom arrow on phone size
  //      padding: "0px 5px", // text starts 5px from card edge
  //      backgroundColor: cardColor,
  //      opacity: 0.85,
  //      "&:hover,&:focus": {
  //        backgroundColor: "white",
  //        opacity: 1,
  //      },
  //    },
  //  });

  return (
    <>
      {showIdea && (
        <Card
          style={{
            margin: "8px 0px",
            padding: "0px 5px",
            backgroundColor: cardColor,
            opacity: 0.85,
            "&:hover,&:focus": { backgroundColor: "white", opacity: 1 },
          }}
        >
          <div style={{ marginBottom: "-10px" }}>
            <Button
              href={`/idea/${showIdea._id}`}
              style={{
                textAlign: "left",
                fontSize: "14px",
                textTransform: "none",
                color: "#291AE0",
                backgroundColor: "transparent",
                whiteSpace: "normal",
                padding: "0px 0px",
                "&:hover,&:focus": {
                  textDecoration: "underline",
                  color: "#291AE0",
                },
              }}
            >
              <Typography style={{ fontWeight: 600 }}>
                {showIdea.myPoint
                  ? showIdea.myPoint
                  : showIdea.myContent
                  ? showIdea.myContent.substring(0, 60)
                  : `"${showIdea.text}"`.substring(0, 60)}
              </Typography>
            </Button>
          </div>

          <Grid container direction="row">
            <Grid item xs={9} md={10}>
              <i style={{ fontSize: "10px" }}>{getTimeAgo(showIdea.date)}</i>
            </Grid>

            <Grid item xs style={{ textAlign: "right" }}>
              {(linkIdeaIdea || linkIdeaAgent) && (
                <Button
                  href={
                    linkIdeaIdea
                      ? `/idea/${showIdea._id}/100/${linkIdeaIdea._id}`
                      : `/idea/${showIdea._id}/200/${linkIdeaAgent._id}`
                  }
                  style={{
                    textAlign: "right",
                    fontSize: "14px",
                    textTransform: "none",
                    color: "#291AE0",
                    backgroundColor: "transparent",
                    whiteSpace: "normal",
                    padding: "0px 0px",
                    "&:hover,&:focus": {
                      textDecoration: "underline",
                      color: "#291AE0",
                    },
                  }}
                >
                  <b style={{ fontSize: "12px" }}>link</b>
                </Button>
              )}
            </Grid>
          </Grid>
        </Card>
      )}

      {showAgent && (
        <Card
          style={{
            margin: "8px 0px",
            padding: "0px 5px",
            backgroundColor: cardColor,
            opacity: 0.85,
            "&:hover,&:focus": { backgroundColor: "white", opacity: 1 },
          }}
        >
          <div>
            <Button
              href={`/agent/${showAgent._id}`}
              style={{
                textAlign: "left",
                fontSize: "14px",
                textTransform: "none",
                color: "#291AE0",
                backgroundColor: "transparent",
                whiteSpace: "normal",
                padding: "0px 0px",
                "&:hover,&:focus": {
                  textDecoration: "underline",
                  color: "#291AE0",
                },
              }}
            >
              <Typography style={{ fontWeight: 600 }}>{showAgent.name}</Typography>
            </Button>
          </div>

          <Grid container direction="row">
            <Grid item xs={9} md={10}>
              <i style={{ fontSize: "10px" }}>{getTimeAgo(showAgent.date)}</i>
            </Grid>

            <Grid item xs style={{ textAlign: "right" }}>
              {(linkIdeaIdea || linkIdeaAgent) && (
                <Button
                  href={
                    linkIdeaAgent
                      ? `/idea/i${showAgent._id}/300/${linkIdeaAgent._id}`
                      : `/agent/${showAgent._id}/400/${linkAgentAgent._id}`
                  }
                  style={{
                    textAlign: "right",
                    fontSize: "14px",
                    textTransform: "none",
                    color: "#291AE0",
                    backgroundColor: "transparent",
                    whiteSpace: "normal",
                    padding: "0px 0px",
                    "&:hover,&:focus": {
                      textDecoration: "underline",
                      color: "#291AE0",
                    },
                  }}
                >
                  <b style={{ fontSize: "12px" }}>link</b>
                </Button>
              )}
            </Grid>
          </Grid>
        </Card>
      )}
    </>
  );
};

ShowLinkItem.propTypes = {
  showIdea: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]),
  showAgent: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]),
  linkIdeaIdea: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]),
  linkIdeaAgent: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]),
  linkAgentAgent: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]),
  cardColor: PropTypes.string.isRequired,
};

ShowLinkItem.defaultProps = {
  showIdea: null,
  showAgent: null,
  linkIdeaIdea: null,
  linkIdeaAgent: null,
  linkAgentAgent: null,
};

export default ShowLinkItem;
