import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Spinner from "../layout/Spinner";
import View from "./View";
import Create from "./Create";
import NavbarBottom from "../navbar/NavbarBottom";
import { getActivities, loadingActivity } from "../../actions/activity";
import { getSettings } from "../../actions/setting";

const Instructor = ({
  activity: { activity, activities, loading },
  getActivities,
  loadingActivity,
  getSettings,
  setting,
}) => {
  // Do not render anything until we request idea to be loaded (will cause spinner icon)
  const [renderReady, setRenderReady] = useState(false);
  const handleSetRenderReady = (e) => {
    setRenderReady(e);
  };

  // Before render, set <loading> = true (will cause spinner icon)
  useEffect(() => {
    loadingActivity();
  }, []);

  // Load all Activity objects
  useEffect(() => {
    setRenderReady(true);
    getActivities();
  }, [activity]);

  useEffect(() => {
    getSettings();
  }, [setting.setting]);

  const [buttonIndex, setButtonIndex] = useState(0);
  const [tabs, setTabs] = useState([
    { label: "View all activities", badge: activities.length },
    { label: "Add new activity", badge: null },
  ]);

  useEffect(() => {
    setButtonIndex(0);
    setTabs([
      { label: "View all activities", badge: activities.length },
      { label: "Add new activity", badge: null },
    ]);
  }, [activities]);

  return !renderReady || loading ? (
    <Spinner />
  ) : (
    <>
      {buttonIndex === 0 && <View activities={activities} />}
      {buttonIndex === 1 && (
        <Create
          renderReady={handleSetRenderReady}
          setting={
            setting && setting.settings && setting.settings.length > 0 ? setting.settings[0] : null
          }
        />
      )}
      <NavbarBottom tabs={tabs} currentTab={buttonIndex} setTab={setButtonIndex} />
    </>
  );
};

const mapStateToProps = (state) => ({
  activity: state.activity,
  setting: state.setting,
});

export default connect(mapStateToProps, { getActivities, loadingActivity, getSettings })(
  Instructor
);
