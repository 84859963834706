// MUI
import { styled } from "@mui/system";
import Typography from "components/MaterialKit/MKTypography";

const ClickText = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: "10px",
    marginBottom: "-10px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "12px",
    },
  })
);

export default ClickText;
