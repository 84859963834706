// MUI
import { Public, Logout, Surfing, BugReport } from "@mui/icons-material";

const RoutesStudent = () => [
  {
    name: "About inSight",
    icon: <Public />,
    route: "/about",
  },
  {
    name: "Activities",
    icon: <Surfing />,
    route: "/activity-students",
  },
  {
    name: "Suggestions and Bug Reporting",
    icon: <BugReport />,
    action: 1,
  },
  {
    name: "Logout",
    icon: <Logout />,
    route: "/logout",
  },
];

export default RoutesStudent;
