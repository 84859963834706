import React from "react";
import PropTypes from "prop-types";
import ReactImageGallery from "react-image-gallery";

// MUI
import { styled } from "@mui/system";
import "react-image-gallery/styles/css/image-gallery.css";

const ProductContainer = styled("div")(({ theme }) =>
  theme.unstable_sx({
    "& .image-gallery-slide img": {
      maxWidth: "300px",
      height: "200px",
    },
    "& .image-gallery-left-nav": {
      bottom: "10%",
      left: "0px",
    },
    "& .image-gallery-left-nav & before": {
      content: '""',
    },
    "& .image-gallery-right-nav": {
      bottom: "10%",
      right: "0px",
      "&::before": {
        content: '""',
      },
    },
  })
);

const ImageGallery = ({ imageList, imageIndexSelected }) => {
  const images = [];
  for (let i = 0; i < imageList.length; i += 1) {
    const obj = {
      original: imageList[i],
      thumbnail: imageList[i],
    };
    images.push(obj);
  }

  const handleImageSelected = (index) => {
    imageIndexSelected(index);
  };

  const handleImageError = (e) => {
    console.log("Image Error:", e);
  };

  return (
    <ProductContainer>
      <ReactImageGallery
        showFullscreenButton={false}
        showPlayButton={false}
        showIndex
        showThumbnails={false}
        onSlide={(e) => handleImageSelected(e)}
        onImageError={(e) => handleImageError(e)}
        items={images}
      />
    </ProductContainer>
  );
};

ImageGallery.propTypes = {
  imageList: PropTypes.arrayOf(PropTypes.string).isRequired,
  imageIndexSelected: PropTypes.func.isRequired,
};

export default ImageGallery;
