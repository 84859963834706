import React, { useState } from "react";
import PropTypes from "prop-types";

// mui
import { Card, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { Add } from "@mui/icons-material";
import Button from "components/MaterialKit/MKButton";

import BackgroundImage from "assets/images/Tree/agents.png";
import ShowLinkList from "./ShowLinkList";

const GridImage = styled(Grid)(({ theme }) =>
  theme.unstable_sx({
    backgroundImage: `url(${BackgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "50%",
    backgroundPosition: "center",
    minHeight: "240px",
    [theme.breakpoints.up("sm")]: {
      minHeight: "360px",
      backgroundSize: "40%",
    },
  })
);

const RelatedAgents = ({ idea, auth, showAddButton, requestSearchRight, requestSearchLeft }) => {
  // Filter out ideas to display in which current idea is the "cause" (and other ideas are the "effects")
  const [supportAgents] = useState(idea.linkedAgents.filter((e) => e.avgLevel >= 0));

  const [opposeAgents] = useState(idea.linkedAgents.filter((e) => e.avgLevel < 0));

  return (
    <GridImage container>
      {/* The selected/central idea opposes (undermines/damages) these individuals/organizations (listed below-left) */}
      <Grid item xs>
        <Card style={{ marginTop: "10px", marginBottom: "10px", marginLeft: "0px" }}>
          <div
            style={{
              color: "white",
              backgroundColor: "black",
              padding: "0px 0px",
              fontSize: "14px",
              boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",
            }}
          >
            <Grid container>
              <Grid item xs={1} style={{ paddingTop: "2px", paddingLeft: "30px" }}>
                {auth.isAuthenticated && showAddButton && (
                  <Button
                    iconOnly
                    onClick={() => requestSearchLeft(true)}
                    style={{
                      marginLeft: "-20px",
                      backgroundColor: "transparent",
                      color: "white",
                      padding: "0px 10px",
                    }}
                  >
                    {" "}
                    <Add />{" "}
                  </Button>
                )}
              </Grid>
              <Grid item xs style={{ textAlign: "right", padding: "5px 30px" }}>
                Weakens those below
              </Grid>
            </Grid>
          </div>
        </Card>

        <ShowLinkList
          relatedAgentLinks={opposeAgents}
          parentIdeaID={idea._id}
          cardColor="#ffcccb"
        />
      </Grid>

      {/* The selected/central idea supports (re-inforces) these individuals/organizations (listed below-right) */}
      <Grid item xs>
        <Card style={{ marginTop: "10px", marginBottom: "10px" }}>
          <div
            style={{
              color: "white",
              backgroundColor: "black",
              padding: "0px 0px",
              fontSize: "14px",
              boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",
            }}
          >
            <Grid container>
              <Grid item xs style={{ textAlign: "left", padding: "5px 30px" }}>
                Strengthens those below
              </Grid>
              <Grid
                item
                xs={1}
                style={{ textAlign: "right", paddingRight: "50px", paddingTop: "2px" }}
              >
                {auth.isAuthenticated && showAddButton && (
                  <Button
                    iconOnly
                    style={{ backgroundColor: "transparent", color: "white", padding: "0px 10px" }}
                    onClick={() => requestSearchRight(true)}
                  >
                    {" "}
                    <Add />{" "}
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>
        </Card>

        <ShowLinkList
          relatedAgentLinks={supportAgents}
          parentIdeaID={idea._id}
          cardColor="lightgreen"
        />
      </Grid>
    </GridImage>
  );
};

RelatedAgents.propTypes = {
  idea: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
  auth: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
  showAddButton: PropTypes.bool.isRequired,
  requestSearchRight: PropTypes.func.isRequired,
  requestSearchLeft: PropTypes.func.isRequired,
};

export default RelatedAgents;
