const getSecondsAgo = (datePreviousString) => {
  const datePrevious = new Date(datePreviousString);
  const dateNow = new Date();
  const elapsed = dateNow - datePrevious;

  const msPerSecond = 1000;
  return Math.round(elapsed / msPerSecond);
};

export default getSecondsAgo;
