import React from "react";
import PropTypes from "prop-types";

// MUI
import Box from "components/MaterialKit/MKBox";
import { styled } from "@mui/system";

import AgentSelect from "./AgentSelect";
import QuestionText from "../styles/QuestionText";

const VerticalListBox = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: 360,
    [theme.breakpoints.up("sm")]: {
      width: 500,
    },
  })
);

const ValuesMap = ({ idea, setting, values, valuesAgent, handleValuesAgent }) => {
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <div style={{ textAlign: "center" }}>
          {values === 1 && (
            <QuestionText large={!valuesAgent ? 1 : 0}>
              Care in this inTell is coming mostly from...
            </QuestionText>
          )}
          {values === 2 && (
            <QuestionText large={!valuesAgent ? 1 : 0}>
              Fairness in this inTell is coming mostly from...
            </QuestionText>
          )}
          {values === 3 && (
            <QuestionText large={!valuesAgent ? 1 : 0}>
              Empowerment in this inTell is coming mostly from...
            </QuestionText>
          )}
          {values === 4 && (
            <QuestionText large={!valuesAgent ? 1 : 0}>
              Unity in this inTell is coming mostly from...
            </QuestionText>
          )}
          {values === 5 && (
            <QuestionText large={!valuesAgent ? 1 : 0}>
              Sacredness in this inTell is coming mostly from...
            </QuestionText>
          )}
          {values === -1 && (
            <QuestionText large={!valuesAgent ? 1 : 0}>
              Harm in this inTell is coming mostly from...
            </QuestionText>
          )}
          {values === -2 && (
            <QuestionText large={!valuesAgent ? 1 : 0}>
              Unfairness in this inTell is coming mostly from...
            </QuestionText>
          )}
          {values === -3 && (
            <QuestionText large={!valuesAgent ? 1 : 0}>
              Oppression in this inTell is coming mostly from...
            </QuestionText>
          )}
          {values === -4 && (
            <QuestionText large={!valuesAgent ? 1 : 0}>
              Division in this inTell is coming mostly from...
            </QuestionText>
          )}
          {values === -5 && (
            <QuestionText large={!valuesAgent ? 1 : 0}>
              Disgust in this inTell is coming mostly from...
            </QuestionText>
          )}
        </div>

        <VerticalListBox>
          <div style={{ textAlign: "left" }}>
            <AgentSelect
              setting={setting}
              idea={idea}
              initAgent={valuesAgent}
              createNewWaveMaker={false}
              updated={handleValuesAgent}
            />
          </div>
        </VerticalListBox>
      </Box>
    </>
  );
};

ValuesMap.propTypes = {
  idea: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
  values: PropTypes.number.isRequired,
  valuesAgent: PropTypes.oneOfType([PropTypes.object]),
  setting: PropTypes.oneOfType([PropTypes.object]),
  handleValuesAgent: PropTypes.func.isRequired,
};

ValuesMap.defaultProps = {
  valuesAgent: null,
  setting: null,
};

export default ValuesMap;
