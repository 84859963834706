import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ShowLinkItem from "./ShowLinkItem";

// PROPS
// Name              Type     Description
// relatedIdeaLinks  array    List of linkIdeaIdeas
// relatedAgentLinks array    List of inkIdeaAgents
// parentIdeaID      string   ID of parent ID
// cardColor         string   Display color of linked ideas (i.e. "lightgreen"...)
// --------------------------------------------
// Props used (from calling components):
// Idea: [relatedIdeaLinks] [relatedAgentLinks] [parentIdeaID] cardColor
// --------------------------------------------
// ACTIONS
// - no input/output requests to backend
// - via component ShowLinkItem, display linked ideas

const ShowLinkList = ({ relatedIdeaLinks, relatedAgentLinks, parentIdeaID, cardColor }) => {
  return (
    <>
      {relatedIdeaLinks && (
        <>
          {parentIdeaID &&
            relatedIdeaLinks
              .sort((a, b) => a.date - b.date)
              .map((e) => (
                <ShowLinkItem
                  key={e.ideaEffect._id === parentIdeaID ? e.ideaCause._id : e.ideaEffect._id}
                  showIdea={e.ideaEffect._id === parentIdeaID ? e.ideaCause : e.ideaEffect}
                  linkIdeaIdea={e}
                  cardColor={cardColor}
                />
              ))}
          {!parentIdeaID &&
            relatedIdeaLinks
              .sort((a, b) => a.date - b.date)
              .map((e) => <ShowLinkItem key={e._id} showIdea={e} cardColor={cardColor} />)}
        </>
      )}

      {relatedAgentLinks && (
        <>
          {parentIdeaID &&
            relatedAgentLinks.map((e) => (
              <ShowLinkItem
                key={e.agent._id}
                showAgent={e.agent}
                linkIdeaAgent={e}
                cardColor={cardColor}
              />
            ))}
          {!parentIdeaID &&
            relatedAgentLinks.map((e) => (
              <ShowLinkItem key={e._id} showAgent={e} cardColor={cardColor} />
            ))}
        </>
      )}
    </>
  );
};

ShowLinkList.propTypes = {
  relatedIdeaLinks: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  relatedAgentLinks: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  parentIdeaID: PropTypes.string,
  cardColor: PropTypes.string.isRequired,
};

ShowLinkList.defaultProps = {
  relatedIdeaLinks: [],
  relatedAgentLinks: [],
  parentIdeaID: "",
};

export default connect()(ShowLinkList);
