import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";

// MUI
import Box from "components/MaterialKit/MKBox";

const ViewVideo = ({ url, setLoaded }) => {
  const [ready, setReady] = useState(false);
  const handleReady = () => {
    console.log("handleReady");
    setReady(true);
    if (setLoaded) {
      setLoaded(true);
    }
  };

  const handleError = () => {
    console.log("handleError");
    setReady(false);
    if (setLoaded) {
      setLoaded(false);
    }
  };

  //  const handlePlay = e => {
  //    console.log( "video play" );
  //     if( playing ) {
  //      playing(true);
  //    }
  //  };

  //  const handleEnded = e => {
  //    console.log( "video ended" );
  //    if( playing ) {
  //      playing(false);
  //    }
  //  }

  // const handlePause = e => {
  //    console.log( "video pause" );
  //    if( playing ) {
  //      playing(false);
  //    }
  //  }

  //  onPlay={handlePlay}
  //  onEnded={handleEnded}
  //  onPause={handlePause}
  //
  // <div style={{position: "relative", paddingTop: "56.25%", maxWidth: "500px"}}>

  return (
    <Box mb={2} mt={2} style={{ maxWidth: ready ? "5000px" : "0px" }}>
      <div style={{ position: "relative", paddingTop: "56.25%" }}>
        <ReactPlayer
          url={url}
          style={{ position: "absolute", top: "0", left: "0" }}
          width="100%"
          height="100%"
          controls
          onReady={handleReady}
          onError={handleError}
        />
      </div>
    </Box>
  );
};

ViewVideo.propTypes = {
  url: PropTypes.string.isRequired,
  setLoaded: PropTypes.func,
};

ViewVideo.defaultProps = {
  setLoaded: null,
};

export default ViewVideo;
