import React from "react";
import PropTypes from "prop-types";

// MUI
import { styled } from "@mui/system";
import { Grid, Card } from "@mui/material";

import BackgroundImage from "assets/images/Tree/chat.png";
import ShowLinkList from "./ShowLinkList";

const GridImage = styled(Grid)(({ theme }) =>
  theme.unstable_sx({
    backgroundImage: `url(${BackgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "50%",
    backgroundPosition: "center",
    [theme.breakpoints.down("xs")]: {
      minHeight: "240px",
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "360px",
      backgroundSize: "30%",
    },
  })
);

const TheirIdeas = ({ agent }) => {
  return (
    <GridImage container>
      <Grid item xs>
        <Card style={{ marginTop: "10px", marginBottom: "10px", marginLeft: "0px" }}>
          <div
            style={{
              color: "white",
              backgroundColor: "black",
              padding: "0px 0px",
              boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",
              fontSize: "14px",
            }}
          >
            <Grid container>
              <Grid item xs style={{ textAlign: "right", padding: "5px 30px" }}>
                Their inTell
              </Grid>
            </Grid>
          </div>
        </Card>
        <ShowLinkList relatedIdeaLinks={agent.attributed} cardColor="white" />
      </Grid>

      <Grid item xs>
        <Card style={{ marginTop: "10px", marginBottom: "10px" }}>
          <div
            style={{
              color: "white",
              backgroundColor: "black",
              padding: "0px 0px",
              boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",
              fontSize: "14px",
            }}
          >
            <Grid container>
              <Grid item xs style={{ textAlign: "left", padding: "5px 30px" }}>
                inTell attributable (by proxy)
              </Grid>
            </Grid>
          </div>
        </Card>
        <ShowLinkList relatedIdeaLinks={agent.proxied} cardColor="white" />
      </Grid>
    </GridImage>
  );
};

TheirIdeas.propTypes = {
  agent: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
};

export default TheirIdeas;
