import React from "react";
import PropTypes from "prop-types";

// MUI
import { Checkbox } from "@mui/material";
import Input from "components/MaterialKit/MKInput";

import LabelText from "../styles/LabelText";
import IdeaDetail from "../idea/IdeaDetail";

const View102 = ({ activity }) => {
  return (
    <div style={{ marginTop: "0px" }}>
      {activity.inTellExisting && (
        <div style={{ marginBottom: "25px" }}>
          <IdeaDetail
            idea={activity.inTellExisting}
            hideImage
            hrefHiddenInternal
            hrefHiddenExternal
            greyOut
            boldOutline
          />
        </div>
      )}

      {activity.instructions102 && activity.instructions102.length > 0 && (
        <div style={{ marginBottom: "15px" }}>
          <Input
            disabled
            type="instructions102"
            label="Additional instructions to students"
            fullWidth
            multiline
            variant="outlined"
            value={activity.instructions102}
          />
        </div>
      )}

      <LabelText>If student encounters significant emotions...</LabelText>

      <LabelText>
        <Checkbox disabled checked={activity.interpretPauseEmotionFlow} />
        Pause, and allow emotions to flow through prior to asking next question
      </LabelText>

      {activity.interpretPauseEmotionFlow && (
        <>
          <LabelText style={{ paddingLeft: "32px" }}>
            <Checkbox disabled checked={activity.interpretEvaluateValues} />
            If positively or negatively triggered, request student to evaluate associated values
          </LabelText>
        </>
      )}

      <div style={{ marginTop: "10px", marginBottom: "5px" }}>
        <Input
          disabled
          type="interpretIntentWords"
          label="Prompt requesting author intent"
          fullWidth
          multiline
          variant="outlined"
          value={activity.interpretIntentWords}
        />
      </div>

      <LabelText>
        <Checkbox disabled checked={activity.interpretConfirmDeeperUnderstanding} />
        Request student to consider if inTell gets to the core of the issue
      </LabelText>

      <LabelText>
        <Checkbox disabled checked={activity.afterOptionInterconnectNewInTell} />
        [CHAIN EVENT] INTERCONNECT newly created inTell to guiding question
      </LabelText>
      {activity.afterOptionInterconnectNewInTell && (
        <div style={{ paddingLeft: "32px", marginTop: "0px" }}>
          <IdeaDetail
            idea={activity.communityTopic}
            hideImage
            hrefHiddenInternal
            hrefHiddenExternal
            greyOut
            boldOutline
          />
        </div>
      )}
    </div>
  );
};

View102.propTypes = {
  activity: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
};

export default View102;
