import React from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";

import { EDUCATION_BUILD } from "config/personalize";

// Public Routes
import Alert from "../layout/Alert";
import Register from "../auth/Register";
import Login from "../auth/Login";
import Logout from "../auth/Logout";
import ForgotPassword from "../auth/ForgotPassword";
import Confirm from "../auth/Confirm";
import NotFound from "../layout/NotFound";

// Private routes: Must be authenticated
import Student from "../student/Student";

// Private routes: Must be authenticated, && must also be non-education build OR or non-student/instructor
import Welcome from "../welcome/Welcome";
import About from "../about/About";
import Instructor from "../instructor/Instructor";
import Profiles from "../profiles/Profiles";
import Profile from "../profile/Profile";
// import History from '../history/History';
// import Search from '../search/Search';
// import Create from "../create/Create";
import Create1 from "../create/Create1";
import Create2 from "../create/Create2";
import Idea from "../idea/Idea";
import Agent from "../agent/Agent";
// import LinkIdeaIdea from '../link-idea-idea/LinkIdeaIdea';
// import LinkIdeaAgent from '../link-idea-agent/LinkIdeaAgent';
// import LinkAgentAgent from '../link-agent-agent/LinkAgentAgent';
// import QuoteExtract from '../create/QuoteExtract';

const Routing = ({ auth }) => {
  return (
    <>
      {/* Display alert messages */}
      <Alert />

      <Routes>
        {/* Routes available to everyone */}
        <Route path="/" element={<Login />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/logout" element={<Logout />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/confirm/:id/:code" element={<Confirm />} />

        {/* Private routes: Must be authenticated */}
        {(auth.isAuthenticated || auth.loading) && auth.user && (
          <>
            {/* All authenticated */}
            <Route exact path="/about" element={<About />} />

            {/* EDUCATION BUILD ONLY */}

            {/* Must also be education build (accessible by both students and admin/instructors) */}
            {EDUCATION_BUILD && (
              <>
                <Route exact path="/activity-students" element={<Student />} />
              </>
            )}

            {/* Must also be education build && admin/instructors (non-student only) */}
            {EDUCATION_BUILD && !auth.user.isStudent && (
              <>
                <Route exact path="/activity-edit" element={<Instructor />} />
              </>
            )}

            {/* Must also be non-education build OR or admin/instructors (non-student only) */}
            {(!EDUCATION_BUILD || !auth.user.isStudent) && (
              <>
                <Route exact path="/welcome" element={<Welcome />} />
                <Route exact path="/create1" element={<Create1 />} />
                <Route exact path="/create2" element={<Create2 />} />
                <Route exact path="/idea/:id" element={<Idea />} key={Math.random()} />
                <Route exact path="/idea/:id/:mode" element={<Idea />} key={Math.random()} />
                <Route exact path="/idea/:id/:mode/:field" element={<Idea />} key={Math.random()} />
                <Route exact path="/agent/:id" element={<Agent />} key={Math.random()} />
                <Route exact path="/agent/:id/:mode" element={<Agent />} key={Math.random()} />
                <Route
                  exact
                  path="/agent/:id/:mode/:field"
                  element={<Agent />}
                  key={Math.random()}
                />
                <Route exact path="/profiles" element={<Profiles />} />
                <Route exact path="/profile/:id" element={<Profile />} />
              </>
            )}
          </>
        )}

        {/* Catch-all route  */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Routing);
