import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// MUI
import MKBox from "components/MaterialKit/MKBox";
import Typography from "components/MaterialKit/MKTypography";

import bgImage from "assets/images/campfire/desertcampfire-blurred1.png";
import BasicLayout from "../auth/BasicLayout";

const NotFound = () => {
  // Wait one second before showing page not found
  const [ready, setReady] = useState(false);
  useEffect(() => {
    const _timer1 = setTimeout(() => {
      setReady(true);
    }, 1000);
    return () => {
      clearInterval(_timer1);
    };
  }, []);

  // Wait five seconds & return to main page
  const navigate = useNavigate();
  useEffect(() => {
    const _timer2 = setTimeout(() => {
      navigate("/");
    }, 5000);
    return () => {
      clearInterval(_timer2);
    };
  }, []);

  return (
    <>
      {ready && (
        <BasicLayout image={bgImage}>
          <MKBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <Typography variant="h4" fontWeight="medium" color="white" mt={1}>
              Page does not exist
            </Typography>
            <Typography display="block" variant="button" color="white" my={1}>
              Returning back to login screen.
            </Typography>
          </MKBox>
        </BasicLayout>
      )}
    </>
  );
};

export default NotFound;
