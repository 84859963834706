import axios from "axios";
import { setAlert } from "./alert";
import {
  LINKIDEAAGENT_ERROR,
  ADD_LINKIDEAAGENT,
  GET_LINKIDEAAGENT,
  GET_LINKIDEAAGENTS,
  ADD_LINKIDEAAGENT_SCALE,
  LOADING_LINKIDEAAGENT,
} from "./types";

// Loading
export const loadingLinkIdeaAgent = () => (dispatch) => {
  dispatch({ type: LOADING_LINKIDEAAGENT });
};

// Get linkIdeaAgents attributed to userId
export const getLinkIdeaAgents = (userId, limitSize) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/linkIdeaAgents/user/${userId}`, {
      params: {
        limitSize,
      },
    });
    dispatch({
      type: GET_LINKIDEAAGENTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: LINKIDEAAGENT_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
    dispatch(
      setAlert(`Failed To Retrieve linkIdeaAgents From Server: ${err.response.data}`, "danger")
    );
  }
};

// Add linkIdeaAgent
export const addLinkIdeaAgent = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post("/api/linkIdeaAgents", formData, config);
    dispatch({
      type: ADD_LINKIDEAAGENT,
      payload: res.data,
    });
    //  dispatch(setAlert('linkIdeaAgent Created', 'success', 1000));
  } catch (err) {
    // err.response.status = error code - ie, 400, 404, 500...
    // err.response.statusText = Text meaning associated with err.response.status
    // err.response.data.errors[] = An array of all errors
    const { errors } = err.response.data;
    if (errors) {
      errors.forEach((error) =>
        dispatch(setAlert(`Failed To Create linkIdeaAgent On Server: ${error.msg}`, "danger"))
      );
    }
    dispatch({
      type: LINKIDEAAGENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get linkIdeaAgent
// API uses res.send() - error message text is in err.response.data
export const getLinkIdeaAgent = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/linkIdeaAgents/${id}`);

    dispatch({
      type: GET_LINKIDEAAGENT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: LINKIDEAAGENT_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
    dispatch(
      setAlert(`Failed To Retrieve linkIdeaAgent From Server: ${err.response.data}`, "danger")
    );
  }
};

// Add linkIdeaAgent scale
export const addLinkIdeaAgentScale = (linkIdeaAgentId, formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(`/api/linkIdeaAgents/scale/${linkIdeaAgentId}`, formData, config);
    dispatch({
      type: ADD_LINKIDEAAGENT_SCALE,
      payload: res.data,
    });
    //  dispatch(setAlert('linkIdeaAgent-scale Added', 'success', 1000));
  } catch (err) {
    // err.response.status = error code - ie, 400, 404, 500...
    // err.response.statusText = Text meaning associated with err.response.status
    // err.response.data.errors[] = An array of all errors
    const { errors } = err.response.data;
    if (errors) {
      errors.forEach((error) =>
        dispatch(setAlert(`Failed To Add linkIdeaAgent-scale On Server: ${error.msg}`, "danger"))
      );
    }
    dispatch({
      type: LINKIDEAAGENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
