export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_FAIL_UNINVITED = "REGISTER_FAIL_UNINVITED";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const LOADING_PROFILE = "LOADING_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";

export const CONFIRMATION_WAITING = "CONFIRMATION_WAITING";
export const CONFIRMATION_SUCCESS = "CONFIRMATION_SUCCESS";
export const CONFIRMATION_PASSWORD_ACK = "CONFIRMATION_PASSWORD_ACK";
export const CONFIRMATION_OLD = "CONFIRMATION_OLD";
export const CONFIRMATION_FAIL_BANNED = "CONFIRMATION_FAIL_BANNED";
export const CONFIRMATION_FAIL_RESENT = "CONFIRMATION_FAIL_RESENT";
export const CONFIRMATION_FAIL_UNINVITED = "CONFIRMATION_FAIL_UNINVITED";
export const CONFIRMATION_FAIL_CHANGE = "CONFIRMATION_FAIL_CHANGE";
export const CONFIRMATION_UNKNOWN = "CONFIRMATION_UNKNOWN";

export const ADD_IDEA = "ADD_IDEA";
export const GET_IDEA = "GET_IDEA";
export const GET_IDEAS = "GET_IDEAS";
export const SEARCH_IDEAS = "SEARCH_IDEAS";
export const LATEST_IDEAS = "LATEST_IDEAS";
export const ADD_IDEA_SCALE = "ADD_IDEA_SCALE";
export const IDEA_ERROR = "IDEA_ERROR";
export const LOADING_IDEA = "LOADING_IDEA";

export const ADD_AGENT = "ADD_AGENT";
export const GET_AGENT = "GET_AGENT";
export const GET_AGENTS = "GET_AGENTS";
export const SEARCH_AGENTS = "SEARCH_AGENTS";
export const LATEST_AGENTS = "LATEST_AGENTS";
export const ADD_AGENT_SCALE = "ADD_AGENT_SCALE";
export const AGENT_ERROR = "AGENT_ERROR";
export const LOADING_AGENT = "LOADING_AGENT";

export const ADD_LINKIDEAIDEA = "ADD_LINKIDEAIDEA";
export const GET_LINKIDEAIDEA = "GET_LINKIDEAIDEA";
export const GET_LINKIDEAIDEAS = "GET_LINKIDEAIDEAS";
export const ADD_LINKIDEAIDEA_SCALE = "ADD_LINKIDEAIDEA_SCALE";
export const LINKIDEAIDEA_ERROR = "LINKIDEAIDEA_ERROR";
export const LOADING_LINKIDEAIDEA = "LOADING_LINKIDEAIDEA";

export const ADD_LINKAGENTAGENT = "ADD_LINKAGENTAGENT";
export const GET_LINKAGENTAGENT = "GET_LINKAGENTAGENT";
export const GET_LINKAGENTAGENTS = "GET_LINKAGENTAGENTS";
export const ADD_LINKAGENTAGENT_SCALE = "ADD_LINKAGENTAGENT_SCALE";
export const LINKAGENTAGENT_ERROR = "LINKAGENTAGENT_ERROR";
export const LOADING_LINKAGENTAGENT = "LOADING_LINKAGENTAGENT";

export const ADD_LINKIDEAAGENT = "ADD_LINKIDEAAGENT";
export const GET_LINKIDEAAGENT = "GET_LINKIDEAAGENT";
export const GET_LINKIDEAAGENTS = "GET_LINKIDEAAGENTS";
export const ADD_LINKIDEAAGENT_SCALE = "ADD_LINKIDEAAGENT_SCALE";
export const LINKIDEAAGENT_ERROR = "LINKIDEAAGENT_ERROR";
export const LOADING_LINKIDEAAGENT = "LOADING_LINKIDEAAGENT";

export const LINK_ERROR = "LINK_ERROR";

export const GET_SCALES = "GET_SCALES";
export const SCALES_ERROR = "SCALES_ERROR";
export const LOADING_SCALES = "LOADING_SCALES";

export const GET_LINKSCALES = "GET_LINKSCALES";
export const LINKSCALES_ERROR = "LINKSCALES_ERROR";
export const LOADING_LINKSCALES = "LOADING_LINKSCALES";

export const ADD_ACTIVITY = "ADD_ACTIVITY";
export const MODIFY_ACTIVITY = "MODIFY_ACTIVITY";
export const GET_ACTIVITY = "GET_ACTIVITY";
export const GET_ACTIVITIES = "GET_ACTIVITIES";
export const ACTIVITY_ERROR = "ACTIVITY_ERROR";
export const LOADING_ACTIVITY = "LOADING_ACTIVITY";
export const DELETE_ACTIVITY = "DELETE_ACTIVITY";

export const ADD_REPORT = "ADD_REPORT";
export const REPORT_ERROR = "REPORT_ERROR";

export const SETTING_ERROR = "SETTING_ERROR";
export const ADD_SETTING = "ADD_SETTING";
export const MODIFY_SETTING = "MODIFY_SETTING";
export const GET_SETTING = "GET_SETTING";
export const GET_SETTINGS = "GET_SETTINGS";
export const DELETE_SETTING = "DELETE_SETTING";
export const LOADING_SETTING = "LOADING_SETTING";
