import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Rellax from "rellax";

// MUI
import { Container, Grid, Card, useScrollTrigger, Fade, Fab } from "@mui/material";
import Box from "components/MaterialKit/MKBox";
import Button from "components/MaterialKit/MKButton";
import Typography from "components/MaterialKit/MKTypography";
import { KeyboardArrowUp } from "@mui/icons-material";

import bgImage from "assets/images/about/elephant.jpg";
import Information from "./Information";
import Team from "./Team";
import Why from "./Why";
import NavbarTop from "../navbar/NavbarTop";

function ScrollTop(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector("#back-to-top-anchor");
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

const About = ({ auth }) => {
  const headerRef = useRef(null);

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []);

  // <Toolbar id="back-to-top-anchor" />

  return (
    <>
      <div id="back-to-top-anchor" />
      <NavbarTop transparent white />

      <Box
        ref={headerRef}
        minHeight="75vh"
        width="100%"
        sx={{
          mt: -8,
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <Typography
              variant="h1"
              color="white"
              sx={({ breakpoints }) => ({
                [breakpoints.down("lg")]: {
                  fontSize: "28px",
                },
                [breakpoints.down("md")]: {
                  fontSize: "24px",
                },
                [breakpoints.down("sm")]: {
                  fontSize: "20px",
                },
              })}
            >
              Let curiosity, humility and community be our guides.
            </Typography>
            <Typography
              variant="body1"
              color="white"
              opacity={0.8}
              mt={2}
              mb={{ xs: 4, sm: 6 }}
              sx={({ breakpoints }) => ({
                [breakpoints.down("lg")]: {
                  fontSize: "24px",
                },
                [breakpoints.down("md")]: {
                  fontSize: "20px",
                },
                [breakpoints.down("sm")]: {
                  fontSize: "14px",
                },
              })}
            >
              In the spirit of the parable of the blind men and the elephant, we honor the value of
              embracing diverse perspectives. It is through unity that we strive to unlock profound
              insights and to tap into the genuine wisdom found within our communities. Join us on
              our journey of discovery, illumination, and mutual growth. Together, let us see the
              elephants for what they truly are.
            </Typography>

            {auth && auth.user && auth.user.isStudent && (
              <Button component={Link} to="/activity-students" color="primary" circular>
                Get started
              </Button>
            )}
          </Grid>
        </Container>
      </Box>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Why />
        <Information />
        <Team />
      </Card>

      <ScrollTop>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUp />
        </Fab>
      </ScrollTop>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(About);
