import React from "react";
import PropTypes from "prop-types";

// MUI
import { Radio, Grid } from "@mui/material";
import { styled } from "@mui/system";
import MKTypography from "components/MaterialKit/MKTypography";

import empowerment from "assets/images/emotions/Empowerment_Icon.svg";
import care from "assets/images/emotions/Care_Icon.svg";
import fairness from "assets/images/emotions/Fairness_Icon.svg";
import unity from "assets/images/emotions/Unity_Icon.svg";
import sacredness from "assets/images/emotions/Sacredness_Icon.svg";
import harm from "assets/images/emotions/Harm_Icon.svg";
import unfairness from "assets/images/emotions/Unfairness_Icon.svg";
import oppression from "assets/images/emotions/Oppression_Icon.svg";
import division from "assets/images/emotions/Division_Icon.svg";
import disgust from "assets/images/emotions/Disgust_Icon.svg";

const Typography = styled(MKTypography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: "11px",
    [theme.breakpoints.up("lg")]: {
      fontSize: "12px",
    },
  })
);

// style={{ width: "55px", opacity: 0.9, marginRight: "3px", marginBottom: "-2px" }}

const ValuesSelect = ({ initValues, positiveValuesActive, negativeValuesActive, updated }) => {
  // <Stack flexWrap="wrap" justifyContent="center" flexDirection="row" width={1.0}>
  return (
    <div style={{ marginTop: "10px" }}>
      <Grid container justifyContent="center" direction="row">
        {/* Care/Harm */}
        <Grid item xs={2} lg={1}>
          {positiveValuesActive && (
            <>
              <Typography>
                <img src={care} style={{ width: "55px" }} alt="..." />
              </Typography>
              <Typography sx={{ fontSize: { xs: "12px", sm: "13px", md: "14px" } }} variant="body1">
                Care
              </Typography>
              <Radio onChange={() => updated(1)} checked={initValues === 1} />
            </>
          )}
          {negativeValuesActive && (
            <>
              <Typography>
                <img src={harm} style={{ width: "55px" }} alt="..." />
              </Typography>
              <Typography sx={{ fontSize: { xs: "12px", sm: "13px", md: "14px" } }} variant="body1">
                Harm
              </Typography>
              <Radio onChange={() => updated(-1)} checked={initValues === -1} />
            </>
          )}
        </Grid>

        {/* Fairness/Unfairness */}
        <Grid item xs={2} lg={1}>
          {positiveValuesActive && (
            <>
              <Typography>
                <img src={fairness} style={{ width: "55px" }} alt="..." />
              </Typography>
              <Typography sx={{ fontSize: { xs: "12px", sm: "13px", md: "14px" } }} variant="body1">
                Fairness
              </Typography>
              <Radio onChange={() => updated(2)} checked={initValues === 2} />
            </>
          )}
          {negativeValuesActive && (
            <>
              <Typography>
                <img src={unfairness} style={{ width: "55px" }} alt="..." />
              </Typography>
              <Typography sx={{ fontSize: { xs: "12px", sm: "13px", md: "14px" } }} variant="body1">
                Unfairness
              </Typography>
              <Radio onChange={() => updated(-2)} checked={initValues === -2} />
            </>
          )}
        </Grid>

        {/* Empowerment/Oppression */}
        <Grid item xs={2} lg={1}>
          {positiveValuesActive && (
            <>
              <Typography>
                <img src={empowerment} style={{ width: "55px" }} alt="..." />
              </Typography>
              <Typography sx={{ fontSize: { xs: "12px", sm: "13px", md: "14px" } }} variant="body1">
                Empowerment
              </Typography>
              <Radio onChange={() => updated(3)} checked={initValues === 3} />
            </>
          )}
          {negativeValuesActive && (
            <>
              <Typography>
                <img src={oppression} style={{ width: "55px" }} alt="..." />
              </Typography>
              <Typography sx={{ fontSize: { xs: "12px", sm: "13px", md: "14px" } }} variant="body1">
                Oppression
              </Typography>
              <Radio onChange={() => updated(-3)} checked={initValues === -3} />
            </>
          )}
        </Grid>

        {/* Unity/Division */}
        <Grid item xs={2} lg={1}>
          {positiveValuesActive && (
            <>
              <Typography>
                <img src={unity} style={{ width: "55px" }} alt="..." />
              </Typography>
              <Typography sx={{ fontSize: { xs: "12px", sm: "13px", md: "14px" } }} variant="body1">
                Unity
              </Typography>
              <Radio onChange={() => updated(4)} checked={initValues === 4} />
            </>
          )}
          {negativeValuesActive && (
            <>
              <Typography>
                <img src={division} style={{ width: "55px" }} alt="..." />
              </Typography>
              <Typography sx={{ fontSize: { xs: "12px", sm: "13px", md: "14px" } }} variant="body1">
                Division
              </Typography>
              <Radio onChange={() => updated(-4)} checked={initValues === -4} />
            </>
          )}
        </Grid>

        {/* Sacredness/Disgust */}
        <Grid item xs={2} lg={1}>
          {positiveValuesActive && (
            <>
              <Typography>
                <img src={sacredness} style={{ width: "55px" }} alt="..." />
              </Typography>
              <Typography sx={{ fontSize: { xs: "12px", sm: "13px", md: "14px" } }} variant="body1">
                Sacredness
              </Typography>
              <Radio onChange={() => updated(5)} checked={initValues === 5} />
            </>
          )}
          {negativeValuesActive && (
            <>
              <Typography>
                <img src={disgust} style={{ width: "55px" }} alt="..." />
              </Typography>
              <Typography sx={{ fontSize: { xs: "12px", sm: "13px", md: "14px" } }} variant="body1">
                Disgust
              </Typography>
              <Radio onChange={() => updated(-5)} checked={initValues === -5} />
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

ValuesSelect.propTypes = {
  initValues: PropTypes.number.isRequired,
  positiveValuesActive: PropTypes.bool,
  negativeValuesActive: PropTypes.bool,
  updated: PropTypes.func.isRequired,
};

ValuesSelect.defaultProps = {
  positiveValuesActive: false,
  negativeValuesActive: false,
};

export default ValuesSelect;
