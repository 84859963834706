import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// MUI
import { Radio, Stack } from "@mui/material";
import Button from "components/MaterialKit/MKButton";
import Box from "components/MaterialKit/MKBox";
import Input from "components/MaterialKit/MKInput";

import Spinner from "../layout/Spinner";
import GoodIntent from "./GoodIntent";
import QuestionText from "../styles/QuestionText";
import LabelText from "../styles/LabelText";
import ImageUpload from "../image/ImageUpload";
import { addIdea } from "../../actions/idea";
import { modifyActivity } from "../../actions/activity";
import { setAlert } from "../../actions/alert";

const CreateMyContent = ({
  instructions,
  requestImage,
  requestSelfEval,
  initSelection,
  newCreatedIdea,
  addIdea,
  modifyActivity,
  setAlert,
  idea,
  activity, // relates only to activities
  noUploads, // typically relates only to activities (no image uploads requested)
  setting, // relates only to activities
  addIdeaScale, // relates only to activities
  auth, // relates only to activities
  notifyParent, // notify parent of user interaction
  setNotifyParent, // notify parent of user interaction
}) => {
  // When a child process gets updated due to user interation, this counter in incremented
  // This triggers a scroll to the bottom of the screen
  const [childUpdated, setChildUpdated] = useState(0);

  // 1 = Directly, 2 = PDF
  const [selection, setSelection] = useState(initSelection || 1);

  const [insightful, setInsightful] = useState(false);
  const [myContent, setMyContent] = useState("");
  const [myPoint, setMyPoint] = useState("");
  const [textOK, setTextOK] = useState(false);
  const [textConfirmed, setTextConfirmed] = useState(false);
  const [image, setImage] = useState("");
  const [pdfImageSuccess, setPdfImageSuccess] = useState(false);
  const [goodIntentLevel, setGoodIntentLevel] = useState(0);

  // Everytime there is any user interaction, notify parent by updating a counter
  useEffect(() => {
    if (setNotifyParent) {
      setNotifyParent(notifyParent + 1);
    }
  }, [goodIntentLevel, childUpdated]);
  useEffect(() => {
    if (setNotifyParent && goodIntentLevel === 0) {
      setNotifyParent(notifyParent + 1);
    }
  }, [insightful, textConfirmed, image, pdfImageSuccess]);

  const handleSelection = (e) => {
    setSelection(e);
    setInsightful(true);
    setMyContent("");
    setMyPoint("");
    setTextOK(false);
    setTextConfirmed(false);
    setImage("");
    setPdfImageSuccess(false);
    setGoodIntentLevel(0);
  };
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (selection === 1) {
      if (!requestSelfEval) {
        if (myContent.length > 2) {
          setTextOK(true); // meets minimum submission threshold
        } else {
          setTextOK(false);
        }
      }
      // Must be requestSelfEval===true for all conditions below
      else if (myContent && myContent.length > 60 && myPoint && myPoint.length > 2) {
        setInsightful(true); // change default (looks like a more meaty submission)
        setTextOK(true); // meets minimum submission threshold
      } else if (myContent && myContent.length <= 60) {
        setMyPoint("");
        setGoodIntentLevel(0);
        setInsightful(false); // not meaty
        if (myContent.length > 2) {
          setTextOK(true); // meets minimum submission threshold
        } else {
          setTextOK(false);
          setTextConfirmed(false); // require reconfirmation (if already confirmed)
        }
      } else {
        setGoodIntentLevel(0);
        setInsightful(false); // not meaty
        setTextOK(false);
        setTextConfirmed(false); // require reconfirmation (if already confirmed)
      }
    } else if (selection === 2 && myPoint && myPoint.length > 2) {
      setInsightful(true); // change default (looks like a more meaty submission)
      setTextOK(true); // meets minimum submission threshold
    } else {
      setInsightful(false);
      setTextOK(false);
    }
  }, [myPoint, myContent]);

  const onSubmit = () => {
    // selection===1 Directly
    // selection===2 PDF
    let bValid = true;

    if (selection === 1) {
      // image is optional
      if (!myContent || myContent.length <= 2) {
        setAlert("Invalid content. Please be more descriptive.", "danger");
        bValid = false;
      } else if (requestSelfEval && myContent.length > 60) {
        if (!myPoint || myPoint.length <= 2) {
          setAlert('Invalid "title". Please be more descriptive.', "danger");
          bValid = false;
        }
      }
    }

    if (selection === 2) {
      // image is mandatory (includes PDF)
      if (!myPoint || myPoint.length <= 2) {
        setAlert('Invalid "title". Please be more descriptive.', "danger");
        bValid = false;
      }
      if (!(image && pdfImageSuccess)) {
        setAlert("Please upload a valid pdf file.", "danger");
        bValid = false;
      }
    }

    if (requestSelfEval && goodIntentLevel < 1) {
      setAlert('"Good intent" not confirmed.', "danger");
      bValid = false;
    }

    if (bValid) {
      setSubmitting(true);

      if (!activity) {
        addIdea({
          goodIntentLevel,
          myPoint: myPoint.length === 0 ? myContent : myPoint,
          myContent: myPoint.length === 0 ? "" : myContent,
          image,
          isPdf: selection === 2,
        });
      } else if (!activity.afterOptionInterpretNewInTell) {
        modifyActivity({
          activityID: activity._id,
          studentIDCompleted: auth.user._id,
          addIdea: {
            goodIntentLevel,
            myPoint: myPoint.length === 0 ? myContent : myPoint,
            myContent: myPoint.length === 0 ? "" : myContent,
            image,
            isPdf: selection === 2,
          },
          addIdeaScale,
        });
      } else {
        modifyActivity({
          activityID: activity._id,
          studentIDCompleted: auth.user._id,
          addIdea: {
            goodIntentLevel,
            myPoint: myPoint.length === 0 ? myContent : myPoint,
            myContent: myPoint.length === 0 ? "" : myContent,
            image,
            isPdf: selection === 2,
          },
          newActivities: [
            {
              selection: 102,
              instructions102: activity.instructions102,
              interpretConfirmDeeperUnderstanding: activity.interpretConfirmDeeperUnderstanding,
              interpretEvaluateValues: activity.interpretEvaluateValues,
              interpretPauseEmotionFlow: activity.interpretPauseEmotionFlow,
              interpretIntentWords: activity.interpretIntentWords,
              afterOptionInterconnectNewInTell: activity.afterOptionInterconnectNewInTell,
              communityTopic: activity.communityTopic,
            },
          ],
          addIdeaScale,
        });
      }
    }
  };

  // Exit strategy to exit this component and scale new idea (report back to parent once back-end confirms submission)
  // - Store initial idea (when entering this component)
  const [initIdeaID] = useState(idea.idea && idea.idea._id ? idea.idea._id : 0);
  useEffect(() => {
    if (idea.idea && idea.idea._id) {
      if (idea.idea._id !== initIdeaID) {
        console.log("New idea created.");
        if (newCreatedIdea) {
          newCreatedIdea(idea.idea);
        }
      }
    }
  }, [idea, submitting]);

  return submitting ? (
    <Spinner />
  ) : (
    <>
      {instructions && instructions.length > 0 && (
        <QuestionText style={{ marginBottom: "15px" }}>{instructions}</QuestionText>
      )}

      {/* Radio button selection of different online types */}
      {!(initSelection === 1 || initSelection === 2) && (
        <>
          <QuestionText style={{ marginBottom: "10px" }}>I will submit my insights:</QuestionText>
          <div style={{ marginBottom: "40px" }}>
            <LabelText>
              <Radio
                checked={selection === 1}
                onClick={() => handleSelection(1)}
                style={{ marginTop: "-3px", marginBottom: "-3px" }}
              />
              Directly in the form below
            </LabelText>
            <LabelText>
              <Radio
                checked={selection === 2}
                onClick={() => handleSelection(2)}
                style={{ marginTop: "-3px", marginBottom: "-3px" }}
              />
              As a PDF file (upload)
            </LabelText>
          </div>
        </>
      )}

      {selection === 1 && (
        <>
          <Box onChange={(e) => setMyContent(e.target.value)}>
            <Input
              type="myContent"
              label={requestSelfEval ? "Write your inTell here..." : "Your thoughts?"}
              fullWidth
              multiline
              value={myContent}
            />
          </Box>

          {requestSelfEval && myContent && myContent.length > 60 && (
            <>
              <QuestionText
                large={myPoint.length > 2 ? 0 : 1}
                style={{ marginTop: "20px", marginBottom: "10px" }}
              >
                Once you have finished entering your inTell above...
              </QuestionText>
              <Box onChange={(e) => setMyPoint(e.target.value)}>
                <Input
                  type="title"
                  label="Please provide a title (in 60 characters or less)"
                  fullWidth
                  value={myPoint}
                  inputProps={{ maxLength: 60 }}
                />
              </Box>
            </>
          )}
        </>
      )}

      {selection === 2 && (
        <>
          <div style={{ marginBottom: "30px" }}>
            <ImageUpload
              title={image ? "Upload a different PDF" : "Upload PDF"}
              setPdfImageSuccess={setPdfImageSuccess}
              pdf
              image={image}
              setImage={setImage}
            />
          </div>

          {image && pdfImageSuccess && (
            <Box onChange={(e) => setMyPoint(e.target.value)}>
              <Input
                type="title"
                label="Please provide a title (in 60 characters or less)"
                fullWidth
                value={myPoint}
                inputProps={{ maxLength: 60 }}
              />
            </Box>
          )}
        </>
      )}

      {/* Text OK, need to confirm */}
      {!textConfirmed && textOK && (
        <Stack
          flexWrap="wrap"
          flexDirection="row"
          width={1.0}
          justifyContent="center"
          style={{ marginTop: "40px" }}
        >
          {requestSelfEval && (
            <Button onClick={() => setTextConfirmed(true)} color="dark" variant="outlined" circular>
              Share your inTell
            </Button>
          )}
          {!requestSelfEval && (
            <Button onClick={() => onSubmit()} color="primary" circular>
              Share your thoughts
            </Button>
          )}
        </Stack>
      )}

      {/* Can only get here if requestSelfEval === true */}
      {textOK && textConfirmed && (
        <>
          {requestImage && insightful && selection === 1 && !noUploads && (
            <div style={{ marginTop: "30px" }}>
              <QuestionText style={{ marginBottom: "10px" }}>
                Select an image to be associated with this inTell
              </QuestionText>
              <ImageUpload
                title={image ? "Upload a different image" : "Upload image (optional)"}
                image={image}
                setImage={setImage}
              />
            </div>
          )}

          <GoodIntent
            insightful={insightful}
            isPdf={selection === 2}
            myPoint={myPoint.length === 0 ? myContent : myPoint}
            myContent={myPoint.length === 0 ? "" : myContent}
            image={selection === 2 && image && !pdfImageSuccess ? "" : image}
            goodIntentLevel={goodIntentLevel}
            setGoodIntentLevel={setGoodIntentLevel}
            childUpdated={childUpdated}
            setChildUpdated={setChildUpdated}
            activity={activity}
            setting={setting}
            addIdeaScale={addIdeaScale}
            auth={auth}
          />

          {goodIntentLevel === 1 && (
            <Stack
              flexWrap="wrap"
              flexDirection="row"
              width={1.0}
              justifyContent="center"
              style={{ marginTop: "40px" }}
            >
              <Button onClick={() => onSubmit()} color="primary" circular>
                Share your inTell
              </Button>
            </Stack>
          )}
        </>
      )}
    </>
  );
};

CreateMyContent.propTypes = {
  instructions: PropTypes.string,
  requestImage: PropTypes.bool,
  noUploads: PropTypes.bool,
  requestSelfEval: PropTypes.bool,
  initSelection: PropTypes.number,
  newCreatedIdea: PropTypes.func,
  activity: PropTypes.oneOfType([PropTypes.object]),
  setting: PropTypes.oneOfType([PropTypes.object]),
  addIdeaScale: PropTypes.oneOfType([PropTypes.object]),
  auth: PropTypes.oneOfType([PropTypes.object]),
  notifyParent: PropTypes.number,
  setNotifyParent: PropTypes.func,
};

CreateMyContent.defaultProps = {
  noUploads: true,
  instructions: "",
  newCreatedIdea: null,
  requestImage: true,
  requestSelfEval: true,
  initSelection: 0,
  activity: null,
  setting: null,
  addIdeaScale: null,
  auth: null,
  notifyParent: 0,
  setNotifyParent: null,
};

const mapStateToProps = (state) => ({
  idea: state.idea,
});

export default connect(mapStateToProps, { addIdea, modifyActivity, setAlert })(CreateMyContent);
