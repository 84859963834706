import axios from "axios";
import { setAlert } from "./alert";

import {
  GET_PROFILE,
  UPDATE_PROFILE,
  GET_PROFILES,
  PROFILE_ERROR,
  LOADING_PROFILE,
  CLEAR_PROFILE,
} from "./types";

// Loading
export const loadingProfile = () => (dispatch) => {
  dispatch({ type: LOADING_PROFILE });
};

// Get current users profile
export const getCurrentProfile = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/profile/me");
    // if returned without error, dispatch GET_PROFILE action & payload=profile
    dispatch({
      type: GET_PROFILE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
    dispatch(
      setAlert(`Failed To Retieve User Profile From Server: ${err.response.data}`, "danger")
    );
  }
};

// Get all profiles
export const getProfiles = () => async (dispatch) => {
  dispatch({ type: CLEAR_PROFILE });
  try {
    const res = await axios.get("/api/profile");
    // if returned without error, dispatch GET_PROFILES action & payload=profiles
    dispatch({
      type: GET_PROFILES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
    dispatch(
      setAlert(`Failed To Retieve All User Profiles From Server: ${err.response.data}`, "danger")
    );
  }
};

// Get profile by ID
export const getProfileById = (userId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/profile/user/${userId}`);
    // if returned without error, dispatch GET_PROFILE action & payload=profile
    dispatch({
      type: GET_PROFILE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
    dispatch(
      setAlert(
        `Failed To Retieve Specified User Profile From Server: ${err.response.data}`,
        "danger"
      )
    );
  }
};

// Update profile
export const updateProfile = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post("/api/profile", formData, config);
    // if returned without error, dispatch UPDATE_PROFILE action & payload=profile & Success message
    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data,
    });
    //  dispatch(setAlert("Profile Updated", "success", 1000));
  } catch (err) {
    const { errors } = err.response.data;
    if (errors) {
      errors.forEach((error) =>
        dispatch(setAlert(`Failed To Update User Profile On Server: ${error.msg}`, "danger"))
      );
    }
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
