import React, { useState, useEffect } from "react";
import cheerio from "cheerio";
import PropTypes from "prop-types";

// MUI
import Typography from "components/MaterialKit/MKTypography";
import Box from "components/MaterialKit/MKBox";

import ImageGallery from "./ImageGallery";
import Spinner from "../layout/Spinner50";

const ImageScrape = ({ url, showGallery, setImage, setValidUrl, setTitle }) => {
  const [imageScraperResponded, setImageScraperResponded] = useState(true);
  const [submittedURL, setSubmittedURL] = useState("");

  const [maybeValid, setMaybeValid] = useState(false);
  const [definatelyValid, setDefinatelyValid] = useState(false);
  const [recheckUrl, setRecheckUrl] = useState(false);

  useEffect(() => {
    // Is it really valid?
    if (maybeValid) {
      if (submittedURL === url) {
        setDefinatelyValid(true);
        setValidUrl(true);
      } else {
        setDefinatelyValid(false);
        setValidUrl(false);
        setRecheckUrl(!recheckUrl);
      }
    }
  }, [maybeValid]);

  const handleValid = (e) => {
    setImageScraperResponded(true);
    setMaybeValid(e);
    if (!e) {
      setDefinatelyValid(false);
      setValidUrl(false);
    }
  };

  function loadImage(url) {
    return new Promise((resolve) => {
      const img = new Image();
      img.addEventListener("load", () => {
        if (img.naturalWidth > 10 && img.naturalHeight > 10) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
      img.addEventListener("error", () => {
        resolve(false);
      });
      img.src = url;
    });
  }
  const [images, setImages] = useState([]);
  async function filterOutInvalidImages(e) {
    const validImageList = [];
    const imageListNames = e;
    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < imageListNames.length; i += 1) {
      if (await loadImage(imageListNames[i])) {
        validImageList.push(imageListNames[i]);
      }
    }
    setImages(validImageList);
  }

  const [scrapedImages, setScrapedImages] = useState([]);
  const [scrapedImagesCnt, setScrapedImagesCnt] = useState(0);

  // Monitor scrapedImages.length to avoid unnecesary calls to filterOutInvalidImages()
  useEffect(() => {
    if (scrapedImages.length !== scrapedImagesCnt) {
      setScrapedImagesCnt(scrapedImages.length);
      filterOutInvalidImages(scrapedImages);
    }
  }, [scrapedImages, scrapedImagesCnt]);

  // Simple url validator
  const isValidUrl = (urlString) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // validate fragment locator
    console.log(urlPattern.test(urlString));
    return urlPattern.test(urlString);
  };

  // urlScrape(url)
  useEffect(() => {
    if (!isValidUrl(url)) {
      setDefinatelyValid(false);
      setValidUrl(false);
      setMaybeValid(false);
    } else {
      if (imageScraperResponded) {
        setSubmittedURL(url);
        setMaybeValid(false);
      }
      setImageScraperResponded(false);
      if (url.length === 0) {
        handleValid(false);
        return;
      }
      const proxyUrl = "https://insight-click.herokuapp.com/";

      console.log(proxyUrl + url);

      fetch(proxyUrl + url)
        .then((response) => {
          if (response && response.status) {
            // CLUGE for protected URL's (cannot scrape any images if response.status === 403, but page exists)
            if (response.status === 403) {
              console.log("NO SCRAPE");
              setValidUrl(true);
              handleValid(true);
              return "";
            }
          }
          if (response.ok) {
            handleValid(true);
            return response.text();
          }
          throw new Error("Invalid URL");
        })
        .then((html) => {
          const $ = cheerio.load(html);

          // Limit title retrieval to 60 characters
          const titleTags = $("Title");
          //    console.log(titleTags[0].children[0].data);
          if (
            titleTags &&
            titleTags.length > 0 &&
            titleTags[0].children &&
            titleTags[0].children.length > 0 &&
            titleTags[0].children[0].data
          ) {
            setTitle(titleTags[0].children[0].data.replace(" - Wikipedia", "").substring(0, 60));
          }

          const imgTags = $("img");
          const imageList = [];
          for (let i = 0; i < imgTags.length; i += 1) {
            if (imgTags[i]["attribs"] && imgTags[i]["attribs"]["data-src"]) {
              imageList.push(imgTags[i]["attribs"]["data-src"]);
            } else {
              imageList.push(imgTags[i].attribs.src);
            }
          }
          setScrapedImages(imageList);
        })
        .catch(() => {
          setSubmittedURL("");
          handleValid(false);
        });
    }
  }, [url, recheckUrl]);

  const [imageSelected, setImageSelected] = useState(0);
  useEffect(() => {
    if (imageSelected === 0) {
      setImage("");
    } else {
      setImage(images[imageSelected - 1]);
    }
  }, [imageSelected]);

  return !imageScraperResponded ? (
    <Spinner />
  ) : (
    <>
      {definatelyValid && showGallery && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ width: "600px" }} bgColor="white" borderRadius="lg" shadow="lg" p={2}>
            <Typography
              variant="h4"
              textTransform="uppercase"
              style={{ textAlign: "center" }}
              sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
            >
              Images scraped from the web page...
            </Typography>
            <ImageGallery imageList={images} imageIndexSelected={setImageSelected} />
          </Box>
        </Box>
      )}
    </>
  );
};

ImageScrape.propTypes = {
  url: PropTypes.string.isRequired,
  showGallery: PropTypes.bool.isRequired,
  setImage: PropTypes.func.isRequired,
  setValidUrl: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
};

export default ImageScrape;
