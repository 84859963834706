import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// MUI
import Typography from "components/MaterialKit/MKTypography";
import Input from "components/MaterialKit/MKInput";
import Button from "components/MaterialKit/MKButton";
import {
  ArrowUpward,
  ArrowDownward,
  Group,
  Delete,
  VerticalAlignTop,
  VerticalAlignBottom,
  ExpandMore,
} from "@mui/icons-material";
import {
  Paper,
  Checkbox,
  Grid,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

import View1 from "./View1";
import View102 from "./View102";
import IdeaDetail from "../idea/IdeaDetail";
import NavbarTop from "../navbar/NavbarTop";
import LabelText from "../styles/LabelText";
import QuestionText from "../styles/QuestionText";
import { modifyActivity, deleteActivity } from "../../actions/activity";

const View = ({ activities, modifyActivity, deleteActivity, auth }) => {
  const [lastOrder, setLastOrder] = useState(0);

  // Prevent user from submitting idea twice (wait before allowing a second attempt)
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {
    if (submitting) {
      // eslint-disable-next-line no-unused-vars
      const timeout = setTimeout(() => {
        setSubmitting(false);
      }, 500);
    }
  }, [submitting]);

  const onSetEnabled = (order) => {
    if (auth && auth.user && auth.user._id) {
      setSubmitting(true);
      modifyActivity({
        activityID: activities[order - 1]._id,
        enabled: !activities[order - 1].enabled,
      });
    }
  };

  const onSetOrder = (order, newOrder) => {
    if (auth && auth.user && auth.user._id) {
      setSubmitting(true);
      modifyActivity({ activityID: activities[order - 1]._id, order: newOrder });
      setLastOrder(newOrder);
    }
  };

  const onDelete = (order) => {
    if (auth && auth.user && auth.user._id) {
      setSubmitting(true);
      const cloneActivities = [...activities];
      deleteActivity(cloneActivities[order - 1]._id);
    }
  };

  return (
    <>
      <NavbarTop transparent />
      <Container maxWidth="sm" style={{ marginTop: "30px", marginBottom: "30px" }}>
        {activities &&
          activities.length > 0 &&
          activities.map((e) => (
            <Paper
              key={e._id}
              style={
                e.enabled
                  ? {
                      padding: "10px",
                      paddingBottom: "5px",
                      marginTop: "15px",
                      backgroundColor: "#e0ffdd",
                    }
                  : { padding: "10px", paddingBottom: "5px", marginTop: "15px" }
              }
            >
              {e.selection === 1 && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMore style={{ marginTop: "-10px", marginBottom: "-10px" }} />
                    }
                    id="header1"
                  >
                    <QuestionText large={1}>
                      #{e.order}: Add new inTell (which does not yet exist in the platform)
                    </QuestionText>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div style={{ marginTop: "-10px" }}>
                      <View1 activity={e} />
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}

              {e.selection === 100 && (
                <Grid container direction="column" style={{ marginRight: "20px" }}>
                  <QuestionText large={1}>#{e.order}: INFO message for students</QuestionText>
                  <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                    <IdeaDetail
                      instructions
                      idea={e.inTellExisting}
                      hrefHiddenInternal
                      hrefHiddenExternal
                      greyOut
                      boldOutline
                    />
                  </div>
                </Grid>
              )}

              {e.selection === 101 && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMore style={{ marginTop: "-10px", marginBottom: "-10px" }} />
                    }
                    id="header101"
                  >
                    <QuestionText large={1}>#{e.order}: Interpret guiding question</QuestionText>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div style={{ marginBottom: "25px", marginTop: "-10px" }}>
                      <IdeaDetail
                        communityTopic
                        idea={e.communityTopic}
                        hideImage
                        hrefHiddenInternal
                        hrefHiddenExternal
                        greyOut
                        boldOutline
                      />
                    </div>

                    {e.instructions101 && e.instructions101.length > 0 && (
                      <div style={{ marginBottom: "20px" }}>
                        <Input
                          disabled
                          type="instructions101"
                          label="Additional instructions to students"
                          fullWidth
                          multiline
                          variant="outlined"
                          value={e.instructions101}
                        />
                      </div>
                    )}

                    <LabelText>
                      <Checkbox disabled checked={e.afterOptionAddNewIntellAfter} />
                      [CHAIN EVENT] Prompt each student to add their own new inTell
                      supporting/opposing the guiding question.
                    </LabelText>

                    {e.afterOptionAddNewIntellAfter && (
                      <div style={{ paddingLeft: "32px" }}>
                        <div style={{ marginTop: "0px" }}>
                          <View1 activity={e} />
                        </div>
                      </div>
                    )}
                  </AccordionDetails>
                </Accordion>
              )}

              {e.selection === 102 && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMore style={{ marginTop: "-10px", marginBottom: "-10px" }} />
                    }
                    id="header102"
                  >
                    <QuestionText large={1}>#{e.order}: Interpret inTell</QuestionText>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div style={{ marginTop: "-10px" }}>
                      <View102 activity={e} />
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}

              {e.selection === 201 && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMore style={{ marginTop: "-10px", marginBottom: "-10px" }} />
                    }
                    id="header201"
                  >
                    <QuestionText large={1}>
                      #{e.order}: Interconnect inTell to guiding question
                    </QuestionText>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography style={{ fontSize: "10px", marginTop: "-10px" }}>
                      Community topic
                    </Typography>
                    <div style={{ marginTop: "5px", marginBottom: "20px" }}>
                      <IdeaDetail
                        communityTopic
                        idea={e.communityTopic}
                        hideImage
                        hrefHiddenInternal
                        hrefHiddenExternal
                        greyOut
                        boldOutline
                      />
                    </div>

                    <Typography style={{ fontSize: "10px" }}>inTell</Typography>
                    <div style={{ marginTop: "5px" }}>
                      <IdeaDetail
                        idea={e.inTellExisting}
                        hideImage
                        hrefHiddenInternal
                        hrefHiddenExternal
                        greyOut
                        boldOutline
                      />
                    </div>

                    {e.instructions201 && e.instructions201.length > 0 && (
                      <div style={{ marginTop: "35px", marginBottom: "10px" }}>
                        <Input
                          disabled
                          type="instructions201"
                          label="Additional instructions to students"
                          fullWidth
                          multiline
                          variant="outlined"
                          value={e.instructions201}
                        />
                      </div>
                    )}
                  </AccordionDetails>
                </Accordion>
              )}

              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{ marginTop: "5px" }}
              >
                <LabelText>
                  <Checkbox
                    checked={e.enabled}
                    onChange={() => onSetEnabled(e.order)}
                    value="enabled"
                  />
                  Go Live
                  {e.enabled && (
                    <Button variant="text" disabled>
                      <Group />
                      <Typography style={{ marginTop: "-15px", fontSize: "10px" }}>
                        {e.studentsCompleted.length}
                      </Typography>
                    </Button>
                  )}
                </LabelText>
                <div>
                  {e.order > 1 && (
                    <Button
                      onClick={() => onSetOrder(e.order, e.order - 1)}
                      iconOnly
                      variant="contained"
                      color="primary"
                      size="small"
                      circular
                    >
                      <ArrowUpward />
                    </Button>
                  )}

                  {e.order > 1 && lastOrder > 0 && e.order - lastOrder > 2 && (
                    <Button
                      style={{ fontSize: "12px", marginLeft: "25px" }}
                      onClick={() => onSetOrder(e.order, lastOrder + 1)}
                      iconOnly
                      variant="contained"
                      color="primary"
                      size="small"
                      circular
                    >
                      <VerticalAlignTop />
                      {e.order - lastOrder - 1}
                    </Button>
                  )}

                  {lastOrder > 0 && lastOrder - e.order > 1 && (
                    <Button
                      style={{ fontSize: "12px", marginLeft: "25px" }}
                      onClick={() => onSetOrder(e.order, lastOrder)}
                      iconOnly
                      variant="contained"
                      color="primary"
                      size="small"
                      circular
                    >
                      <VerticalAlignBottom />
                      {lastOrder - e.order}
                    </Button>
                  )}

                  {e.order < activities.length && (
                    <Button
                      onClick={() => onSetOrder(e.order, e.order + 1)}
                      style={{ marginLeft: "25px" }}
                      iconOnly
                      variant="contained"
                      color="primary"
                      size="small"
                      circular
                    >
                      <ArrowDownward />
                    </Button>
                  )}

                  <Button
                    onClick={() => onDelete(e.order)}
                    style={{ marginLeft: "25px" }}
                    iconOnly
                    variant="contained"
                    color="primary"
                    size="small"
                    circular
                  >
                    <Delete />
                  </Button>
                </div>
              </Grid>
            </Paper>
          ))}
        <br />
      </Container>
    </>
  );
};

View.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { modifyActivity, deleteActivity })(View);
