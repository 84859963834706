import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// MUI
import Button from "components/MaterialKit/MKButton";
import Box from "components/MaterialKit/MKBox";
import Input from "components/MaterialKit/MKInput";
import { Help } from "@mui/icons-material";
import { Checkbox, Stack, Card } from "@mui/material";

import Spinner from "../layout/Spinner";
import SliderEmotion from "../slider/SliderEmotion";
import SliderIntent from "../slider/SliderIntent";
import SliderSound from "../slider/SliderSound";
import SliderConfidence from "../slider/SliderConfidence";
import SliderGoodFaith from "../slider/SliderGoodFaith";
import SliderHeartOfIssue from "../slider/SliderHeartOfIssue";
import DialogTriggered from "../dialog/DialogTriggered";
import DialogMessage from "../dialog/DialogMessage";
import { addIdeaScale } from "../../actions/idea";
import QuestionText from "../styles/QuestionText";
import ClickText from "../styles/ClickText";
import LabelText from "../styles/LabelText";
import ValuesSelect from "../values/ValuesSelect";
import ValuesMap from "../values/ValuesMap";
import DivisiveIntent from "./DivisiveIntent";
import InterpretLink from "./InterpretLink";

const InterpretIntell = ({
  activity,
  setting,
  childUpdated,
  setChildUpdated,
  setInterpretedIntell,
  addIdeaScale,
  idea,
  auth,
}) => {
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    // If submitting
    if (idea.idea && idea.idea.scales && submitting) {
      for (let i = 0; i < idea.idea.scales.length; i += 1) {
        if (
          idea.idea.scales[i].user &&
          idea.idea.scales[i].user._id &&
          JSON.stringify(idea.idea.scales[i].user._id) === JSON.stringify(auth.user._id)
        ) {
          setInterpretedIntell(idea.idea);
        }
      }
    }
  }, [idea.idea]);

  useEffect(() => {
    // first time entering
    if (activity.inTellExisting && activity.inTellExisting.scales && !submitting) {
      for (let i = 0; i < activity.inTellExisting.scales.length; i += 1) {
        if (
          activity.inTellExisting.scales[i].user &&
          activity.inTellExisting.scales[i].user._id &&
          JSON.stringify(activity.inTellExisting.scales[i].user._id) ===
            JSON.stringify(auth.user._id)
        ) {
          setInterpretedIntell(activity.inTellExisting);
        }
      }
    }
  }, [activity.inTellExisting]);

  // CONSTANTS
  const [triggerPauseLevel] = useState(0.5);
  const [intentPossiblyDivisiveLevel] = useState(0.2);
  const [intentPossiblyGoodFaithLevel] = useState(0.5);
  const [intentManipulative] = useState(0.5);
  const [possiblyBottomLine] = useState(0.5);

  // DIALOGS
  const [triggeredDialog, setTriggeredDialog] = useState(false);
  const [goodFaithDialog, setGoodFaithDialog] = useState(false);
  const [potieDialog, setPotieDialog] = useState(false);

  const [skip, setSkip] = useState(false);
  const [values, setValues] = useState(0);
  const [valuesAgent, setValuesAgent] = useState(null); // if valuesAgent._id===0, "none of the above" has been selected
  const [myEmotion, setMyEmotion] = useState(-2); // Typically ranges -1->1 (-2 represents uninitialized)
  const [authorIntent, setAuthorIntent] = useState(-2); // Typically ranges  0->1 (-2 represents uninitialized)
  const [goodFaith, setGoodFaith] = useState(-2); // Typically ranges  0->1 (-2 represents uninitialized)
  const [sound, setSound] = useState(-2); // Typically ranges  0->1 (-2 represents uninitialized)
  const [confidence, setConfidence] = useState(-2); // Typically ranges  0->1 (-2 represents uninitialized)
  const [divisive, setDivisive] = useState(0);
  const [isDivisive, setIsDivisive] = useState(0);
  const [heartOfIssue, setAtHeartOfIssue] = useState(-2);
  const [note, setNote] = useState("");
  const [whyManipulative, setWhyManipulative] = useState("");

  // Interconnect
  const [supportOpposeInterpretation, setSupportOpposeInterpretation] = useState(-2); // Typically -1/0/1 (-2 represents uninitialized)
  //  const [confidenceLink, setConfidenceLink] = useState(-2); // Typically ranges 0->1 (-2 represents uninitialized)

  // Everytime there is any user interaction, notify parent by updating a counter
  useEffect(() => {
    setChildUpdated(childUpdated + 1);
  }, [
    values,
    valuesAgent,
    myEmotion,
    authorIntent,
    goodFaith,
    sound,
    confidence,
    divisive,
    heartOfIssue,
    isDivisive,
    skip,
    supportOpposeInterpretation,
  ]);

  const handleSkip = (e) => {
    setSkip(e);
    setMyEmotion(-2);
    setAuthorIntent(-2);
    setSound(-2);
    setGoodFaith(-2);
    setConfidence(-2);
    setAtHeartOfIssue(-2);
    setValues(0);
    setValuesAgent(null);
    setDivisive(0);
    setIsDivisive(0);
    setNote("");
    setWhyManipulative("");
    setSupportOpposeInterpretation(-2);
  };

  const handleMyEmotion = (e) => {
    setMyEmotion(e);
    if (
      (e > triggerPauseLevel || e < -triggerPauseLevel) &&
      (!activity || (activity && activity.interpretPauseEmotionFlow))
    ) {
      setTriggeredDialog(true);
    }
    setAuthorIntent(-2);
    setSound(-2);
    setGoodFaith(-2);
    setConfidence(-2);
    setAtHeartOfIssue(-2);
    setValues(0);
    setValuesAgent(null);
    setDivisive(0);
    setIsDivisive(0);
    setNote("");
    setWhyManipulative("");
    setSupportOpposeInterpretation(-2);
  };

  const handleTriggeredDialogClose = () => {
    setTriggeredDialog(false);
    setAuthorIntent(-2);
    setSound(-2);
    setGoodFaith(-2);
    setConfidence(-2);
    setAtHeartOfIssue(-2);
    setValuesAgent(null);
    setIsDivisive(0);
    setDivisive(0);
    setNote("");
    setWhyManipulative("");
    setSupportOpposeInterpretation(-2);
  };

  const handleValues = (e) => {
    setValues(e);
    setAuthorIntent(-2);
    setSound(-2);
    setGoodFaith(-2);
    setConfidence(-2);
    setAtHeartOfIssue(-2);
    setValuesAgent(null);
    setDivisive(0);
    setIsDivisive(0);
    setNote("");
    setWhyManipulative("");
    setSupportOpposeInterpretation(-2);
  };

  const handleValuesAgent = (e) => {
    setValuesAgent(e);
    setAuthorIntent(-2);
    setSound(-2);
    setGoodFaith(-2);
    setConfidence(-2);
    setAtHeartOfIssue(-2);
    setDivisive(0);
    setIsDivisive(0);
    setNote("");
    setWhyManipulative("");
    setSupportOpposeInterpretation(-2);
  };

  const handleAuthorIntent = (e) => {
    setAuthorIntent(e);
    setSound(-2);
    setGoodFaith(-2);
    setConfidence(-2);
    setAtHeartOfIssue(-2);
    setDivisive(0);
    setIsDivisive(0);
    setNote("");
    setWhyManipulative("");
    setSupportOpposeInterpretation(-2);
  };

  const handleGoodFaith = (e) => {
    setGoodFaith(e);
    setSound(-2);
    setConfidence(-2);
    setAtHeartOfIssue(-2);
    setDivisive(0);
    setIsDivisive(0);
    setNote("");
    setWhyManipulative("");
    setSupportOpposeInterpretation(-2);
  };

  const handleDivisiveReasons = (e) => {
    setDivisive(e);
    setSound(-2);
    setConfidence(-2);
    setAtHeartOfIssue(-2);
    setNote("");
    setSupportOpposeInterpretation(-2);
  };

  const handleIsDivisive = (e) => {
    setIsDivisive(e);
    setSound(-2);
    setConfidence(-2);
    setAtHeartOfIssue(-2);
    setNote("");
    setSupportOpposeInterpretation(-2);
  };

  const handleSound = (e) => {
    setSound(e);
    setConfidence(-2);
    setAtHeartOfIssue(-2);
    setNote("");
    setSupportOpposeInterpretation(-2);
  };

  const handleSupportOpposeInterpretation = (e) => {
    setSupportOpposeInterpretation(e);
    setConfidence(-2);
    setNote("");
    setAtHeartOfIssue(-2);
  };

  const handleConfidence = (e) => {
    setConfidence(e);
    setNote("");
    setAtHeartOfIssue(-2);
  };

  const handleHeartOfIssue = (e) => {
    setNote("");
    setAtHeartOfIssue(e);
  };

  const onSubmit = () => {
    setSubmitting(true);
    if (activity.afterOptionInterconnectNewInTell) {
      addIdeaScale(activity.inTellExisting._id, {
        cannotOverwriteExisting: true,
        authorIntent: authorIntent > -2 ? authorIntent : null,
        sound: sound > -2 ? sound : null,
        myEmotion: myEmotion > -2 ? myEmotion : null,
        values,
        valuesAgent: valuesAgent && valuesAgent._id === "0" ? null : valuesAgent,
        confidence: confidence > -2 ? confidence : null,
        activityType: 102,
        divisive,
        heartOfIssue,
        note,
        whyManipulative,
        goodFaith: goodFaith > -2 ? goodFaith : null,
        addLinkIdeaIdeaScale: {
          id: activity.linkIdeaIdea._id,
          level: supportOpposeInterpretation > -2 ? supportOpposeInterpretation : null,
          confidence: confidence > -2 ? confidence : null,
          isSimilar: false,
          isComplex: false,
          isReversed: false,
        },
      });
    } else {
      addIdeaScale(activity.inTellExisting._id, {
        cannotOverwriteExisting: true,
        authorIntent: authorIntent > -2 ? authorIntent : null,
        sound: sound > -2 ? sound : null,
        myEmotion: myEmotion > -2 ? myEmotion : null,
        values,
        valuesAgent: valuesAgent && valuesAgent._id === "0" ? null : valuesAgent,
        confidence: confidence > -2 ? confidence : null,
        activityType: 102,
        divisive,
        heartOfIssue,
        note,
        whyManipulative,
        goodFaith: goodFaith > -2 ? goodFaith : null,
      });
    }
  };

  return submitting ? (
    <Spinner />
  ) : (
    <>
      <LabelText>
        <Checkbox checked={skip} onClick={() => handleSkip(!skip)} />I would like to skip this
        activity.
      </LabelText>

      {skip && (
        <Stack
          flexWrap="wrap"
          flexDirection="row"
          width={1.0}
          justifyContent="center"
          style={{ marginTop: "40px" }}
        >
          <Button onClick={() => onSubmit()} color="primary" circular>
            Confirm skip
          </Button>
        </Stack>
      )}

      {!skip && (
        <>
          {/* My elephant feels... */}
          <div style={{ marginTop: "20px", marginBottom: "10px", textAlign: "center" }}>
            <QuestionText large={myEmotion <= -2 ? 1 : 0}>
              This inTell makes me feel...
            </QuestionText>
            {myEmotion <= -2 && <ClickText>CLICK ON THE SCALE BELOW</ClickText>}
          </div>
          <div>
            <SliderEmotion userInitLevel={myEmotion} updated={handleMyEmotion} />
          </div>

          {/* Student has provided an elephant self-analysis */}
          {myEmotion > -2 && !triggeredDialog && (
            <>
              {/* Select a value */}
              {activity.interpretEvaluateValues &&
                (myEmotion > triggerPauseLevel || myEmotion < -triggerPauseLevel) &&
                (!activity || (activity && activity.interpretPauseEmotionFlow)) && (
                  <div style={{ marginTop: "30px", marginBottom: "10px", textAlign: "center" }}>
                    <QuestionText large={values === 0 ? 1 : 0}>
                      I feel this way because there is a sense of...
                    </QuestionText>
                    <ValuesSelect
                      positiveValuesActive={myEmotion > 0}
                      negativeValuesActive={myEmotion < 0}
                      updated={handleValues}
                      initValues={values}
                    />
                    {values !== 0 && (
                      <Box mt={5} mb={7} sx={{ display: "flex", justifyContent: "center" }}>
                        <ValuesMap
                          idea={activity.inTellExisting}
                          setting={setting}
                          valuesAgent={valuesAgent}
                          handleValuesAgent={handleValuesAgent}
                          values={values}
                        />
                      </Box>
                    )}
                  </div>
                )}

              {(!activity.interpretEvaluateValues ||
                (activity.interpretEvaluateValues && valuesAgent) ||
                (activity.interpretEvaluateValues &&
                  !(
                    (myEmotion > triggerPauseLevel || myEmotion < -triggerPauseLevel) &&
                    (!activity || (activity && activity.interpretPauseEmotionFlow))
                  ))) && (
                <>
                  {/* Author intent */}
                  <div style={{ marginTop: "40px", marginBottom: "10px", textAlign: "center" }}>
                    <QuestionText large={authorIntent <= -2 ? 1 : 0}>
                      {activity.interpretIntentWords}
                    </QuestionText>
                    {authorIntent <= -2 && <ClickText>CLICK ON THE SCALE BELOW</ClickText>}
                  </div>

                  <SliderIntent userInitLevel={authorIntent} updated={handleAuthorIntent} />

                  {authorIntent > -2 && authorIntent < intentPossiblyGoodFaithLevel && (
                    <>
                      {/* Manipulations or good faith? */}
                      <div style={{ marginTop: "40px", marginBottom: "10px", textAlign: "center" }}>
                        <QuestionText large={goodFaith <= -2 ? 1 : 0}>
                          In regards to this one-sidedness, I believe the author is...
                          <Help
                            onClick={() => setGoodFaithDialog(true)}
                            color="primary"
                            style={{ marginLeft: "3px", marginBottom: "3px" }}
                          />
                        </QuestionText>
                        {goodFaith <= -2 && <ClickText>CLICK ON THE SCALE BELOW</ClickText>}
                      </div>
                      <SliderGoodFaith userInitLevel={goodFaith} updated={handleGoodFaith} />

                      {goodFaith > -2 && goodFaith < intentManipulative && (
                        <Box
                          onChange={(e) => setWhyManipulative(e.target.value)}
                          style={{ marginTop: "40px" }}
                        >
                          <Input
                            type="whyManipulative"
                            label="Who do you believe benefits from this manipulation? What advantages are gained? (OPTIONAL, 160 characters or less)"
                            fullWidth
                            inputProps={{ maxLength: 160 }}
                            value={whyManipulative}
                          />
                        </Box>
                      )}
                    </>
                  )}

                  {authorIntent < intentPossiblyDivisiveLevel &&
                    authorIntent > -2 &&
                    (authorIntent >= intentPossiblyGoodFaithLevel ||
                      (authorIntent < intentPossiblyGoodFaithLevel && goodFaith > -2)) && (
                      <>
                        {/* Divisive? */}
                        <div
                          style={{ marginTop: "40px", marginBottom: "50px", textAlign: "center" }}
                        >
                          <QuestionText
                            large={isDivisive === 0 || (isDivisive === 1 && divisive === 0) ? 1 : 0}
                          >
                            Would you consider this inTell to be POTIE?
                            <Help
                              onClick={() => setPotieDialog(true)}
                              color="primary"
                              style={{ marginLeft: "3px", marginBottom: "3px" }}
                            />
                          </QuestionText>
                          {(isDivisive === 0 || (isDivisive === 1 && divisive === 0)) && (
                            <QuestionText>
                              (&quot;Pollution Of The Information Ecosystem&quot;)
                            </QuestionText>
                          )}
                          <DivisiveIntent
                            divisiveReasons={divisive}
                            setDivisiveReasons={handleDivisiveReasons}
                            isDivisive={isDivisive}
                            setIsDivisive={handleIsDivisive}
                          />
                        </div>

                        {isDivisive === 1 && divisive > 0 && (
                          <>
                            {(goodFaith <= -2 || goodFaith >= intentManipulative) && (
                              <Box
                                onChange={(e) => setNote(e.target.value)}
                                style={{ marginTop: "40px" }}
                              >
                                <Input
                                  type="note"
                                  label="Any additional thoughts to share with the community about this inTell? (OPTIONAL, 160 characters or less)"
                                  fullWidth
                                  inputProps={{ maxLength: 160 }}
                                  value={note}
                                />
                              </Box>
                            )}

                            <Stack
                              flexWrap="wrap"
                              flexDirection="row"
                              width={1.0}
                              justifyContent="center"
                              style={{ marginTop: "40px" }}
                            >
                              <Button onClick={() => onSubmit()} color="primary" circular>
                                Submit
                              </Button>
                            </Stack>
                          </>
                        )}
                      </>
                    )}

                  {(authorIntent >= intentPossiblyGoodFaithLevel ||
                    (authorIntent < intentPossiblyGoodFaithLevel && goodFaith > -2)) &&
                    (authorIntent >= intentPossiblyDivisiveLevel || isDivisive < 0) && (
                      <>
                        {/* inTell sound? */}
                        <div
                          style={{ marginTop: "40px", marginBottom: "10px", textAlign: "center" }}
                        >
                          <QuestionText large={sound <= -2 ? 1 : 0}>
                            The content of this inTell provides...
                          </QuestionText>
                          {sound <= -2 && <ClickText>CLICK ON THE SCALE BELOW</ClickText>}
                        </div>
                        <SliderSound userInitLevel={sound} updated={handleSound} />
                      </>
                    )}
                </>
              )}

              {/* How confident? */}
              {sound > -2 && (
                <>
                  {activity.afterOptionInterconnectNewInTell && (
                    <>
                      <div style={{ marginTop: "40px", marginBottom: "60px" }}>
                        <Card style={{ padding: "20px" }}>
                          <InterpretLink
                            inTellEffect={activity.communityTopic}
                            supportOpposeInterpretation={supportOpposeInterpretation}
                            setSupportOpposeInterpretation={handleSupportOpposeInterpretation}
                            childUpdated={childUpdated}
                            setChildUpdated={setChildUpdated}
                          />
                        </Card>
                      </div>
                    </>
                  )}
                  {(!activity.afterOptionInterconnectNewInTell ||
                    (activity.afterOptionInterconnectNewInTell &&
                      supportOpposeInterpretation > -2)) && (
                    <>
                      <div style={{ marginTop: "40px", marginBottom: "10px", textAlign: "center" }}>
                        <QuestionText large={confidence <= -2 ? 1 : 0}>
                          How confident are you in ALL your interpretations above?
                        </QuestionText>
                        {confidence <= -2 && <ClickText>CLICK ON THE SCALE BELOW</ClickText>}
                      </div>
                      <SliderConfidence userInitLevel={confidence} updated={handleConfidence} />

                      {(!activity || (activity && activity.interpretConfirmDeeperUnderstanding)) &&
                        sound >= possiblyBottomLine &&
                        confidence >= possiblyBottomLine &&
                        authorIntent >= possiblyBottomLine &&
                        confidence > -2 && (
                          <>
                            <div
                              style={{
                                marginTop: "40px",
                                marginBottom: "10px",
                                textAlign: "center",
                              }}
                            >
                              <QuestionText large={heartOfIssue <= -2 ? 1 : 0}>
                                Would you advocate that everyone in our community carefully review
                                this inTell?
                              </QuestionText>
                              {heartOfIssue <= -2 && (
                                <ClickText>CLICK ON THE SCALE BELOW</ClickText>
                              )}
                            </div>
                            <SliderHeartOfIssue
                              userInitLevel={heartOfIssue}
                              updated={handleHeartOfIssue}
                            />
                          </>
                        )}

                      {(!(
                        (!activity || (activity && activity.interpretConfirmDeeperUnderstanding)) &&
                        sound >= possiblyBottomLine &&
                        confidence >= possiblyBottomLine &&
                        authorIntent >= possiblyBottomLine
                      ) ||
                        ((!activity ||
                          (activity && activity.interpretConfirmDeeperUnderstanding)) &&
                          sound >= possiblyBottomLine &&
                          confidence >= possiblyBottomLine &&
                          authorIntent >= possiblyBottomLine &&
                          heartOfIssue > -2)) &&
                        confidence > -2 && (
                          <>
                            {(goodFaith <= -2 || goodFaith >= intentManipulative) && (
                              <Box
                                onChange={(e) => setNote(e.target.value)}
                                style={{ marginTop: "40px" }}
                              >
                                <Input
                                  type="note"
                                  label="Any additional thoughts to share with the community about this inTell? (OPTIONAL, 160 characters or less)"
                                  fullWidth
                                  inputProps={{ maxLength: 160 }}
                                  value={note}
                                />
                              </Box>
                            )}
                            <Stack
                              flexWrap="wrap"
                              flexDirection="row"
                              width={1.0}
                              justifyContent="center"
                              style={{ marginTop: "40px" }}
                            >
                              <Button onClick={() => onSubmit()} color="primary" circular>
                                Submit
                              </Button>
                            </Stack>
                          </>
                        )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}

      {potieDialog && <DialogMessage potie handleDialogClose={() => setPotieDialog(false)} />}
      {goodFaithDialog && (
        <DialogMessage goodFaith handleDialogClose={() => setGoodFaithDialog(false)} />
      )}

      {triggeredDialog && <DialogTriggered handleDialogClose={handleTriggeredDialogClose} />}
    </>
  );
};

InterpretIntell.propTypes = {
  childUpdated: PropTypes.number.isRequired,
  setChildUpdated: PropTypes.func.isRequired,
  setInterpretedIntell: PropTypes.func,
  activity: PropTypes.oneOfType([PropTypes.object]),
  setting: PropTypes.oneOfType([PropTypes.object]),
};

InterpretIntell.defaultProps = {
  activity: null,
  setting: null,
  setInterpretedIntell: null,
};

const mapStateToProps = (state) => ({
  idea: state.idea,
  auth: state.auth,
});

// export default connect(mapStateToProps, { modifyActivity })(InterpretIntell);
export default connect(mapStateToProps, { addIdeaScale })(InterpretIntell);
