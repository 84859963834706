import React from "react";
import PropTypes from "prop-types";

// MUI
import { Dialog, DialogActions, DialogContent, Stack } from "@mui/material";
import Button from "components/MaterialKit/MKButton";

import CreateAgent from "./CreateAgent";

const DialogCreateAgent = ({ newCreatedAgent, handleDialogClose }) => {
  return (
    <Dialog open>
      <DialogContent style={{ marginTop: "10px", marginRight: "10px", marginLeft: "10px" }}>
        <CreateAgent newCreatedAgent={newCreatedAgent} hideSubmitNotReady />
      </DialogContent>
      <DialogActions style={{ margin: "0px", border: 1 }}>
        <Stack
          flexWrap="wrap"
          justifyContent="center"
          flexDirection="row"
          width={1.0}
          style={{ marginTop: "-20px" }}
        >
          <Button onClick={() => handleDialogClose()} color="primary" variant="outlined" circular>
            Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

DialogCreateAgent.propTypes = {
  newCreatedAgent: PropTypes.func.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
};

export default DialogCreateAgent;
