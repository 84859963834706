import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// MUI
import { Card } from "@mui/material";
import MKBox from "components/MaterialKit/MKBox";
import Typography from "components/MaterialKit/MKTypography";
import MKInput from "components/MaterialKit/MKInput";
import MKButton from "components/MaterialKit/MKButton";

// import BasicLayout from "pages/Authentication/components/BasicLayout";
import bgImage from "assets/images/campfire/desertcampfire-blurred1.png";
import Spinner from "../layout/Spinner";
import { getConfirm } from "../../actions/confirm";
import { setAlert } from "../../actions/alert";
import { changePassword } from "../../actions/auth";
import BasicLayout from "./BasicLayout";

// Enters via router:  <Route exact path='/confirm/:id/:code' component={Confirm}
// --------------------------------------------
// ACTIONS
// - If account registration confirmation code matches, acknoweldge and allow user to goto login screen
// - If new password confirmation code matches, allow user to change his/her password
// - Otherwise, display message

const Confirm = ({ setAlert, getConfirm, changePassword, confirmationStatus }) => {
  const { id, code } = useParams();

  // Check confirm code on first render (or if ever null!)
  useEffect(() => {
    if (confirmationStatus === null) {
      console.log("getConfirm()", id, code);
      getConfirm(id, code);
    }
  }, [getConfirm, id, code, confirmationStatus]);

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const [password2Success, setPassword2Success] = useState(false);
  const [password1Error, setPassword1Error] = useState(false);
  const [password2Error, setPassword2Error] = useState(false);

  useEffect(() => {
    if (password1.length >= 6) {
      setPassword1Error(false);
    }
  }, [password1]);

  useEffect(() => {
    if (password1.length > 0 && password2.length > 0) {
      if (password1 === password2) {
        setPassword2Success(true);
        setPassword2Error(false);
      } else {
        setPassword2Success(false);
      }
    } else {
      setPassword2Success(false);
    }
  }, [password1, password2]);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (confirmationStatus === -202) {
      setTitle("Confirmation failed");
      setDescription("Unexepected error. Try requesting a password change again.");
    }
    if (confirmationStatus === -201) {
      setTitle("Confirmation failed");
      setDescription(
        "Another email was just sent to your email account. Please confirm to complete registration. (Check your spam folder if you do not see a message in your inbox)"
      );
    }
    if (confirmationStatus === -200) {
      setTitle("Confirmation failed");
      setDescription("Did you use the latest email confirmation we sent you?");
    }
    if (confirmationStatus === -100) {
      setTitle("Confirmation failed");
      setDescription("Your email address has been banned from the platform.");
    }
    if (confirmationStatus === -101) {
      setTitle("Confirmation failed");
      setDescription(
        "The email address entered does not match the invitation list for this exploration. Please use the same email address that you provided inSight. To join this exploration, or to change/correct your email details, please send an email to spark@insight.click."
      );
    }
    if (confirmationStatus === 1 || confirmationStatus === 201) {
      setTitle("Your inSight account is confirmed");
      setDescription("Click below to get started");
    }
    if (confirmationStatus === 200) {
      setTitle("Set new password");
      setDescription("");
    }
  }, [confirmationStatus]);

  const onSubmit = () => {
    if (password1.length < 6) {
      setAlert("Invalid Password - must be at least 6 characters", "danger");
      setPassword1Error(true);
    } else if (password1 !== password2) {
      setAlert("Invalid - passwords must match", "danger");
      setPassword2Error(true);
    } else {
      const userId = id;
      const confirmationString = code;
      console.log(id, code);
      console.log("Attempting password change", userId, confirmationString, password1);
      changePassword({ userId, confirmationString, password: password1 });
    }
  };

  return confirmationStatus === null ? (
    <Spinner />
  ) : (
    <BasicLayout image={bgImage}>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <Typography variant="h4" fontWeight="medium" color="white" mt={1}>
            {title}
          </Typography>
          <Typography display="block" variant="button" color="white" my={1}>
            {description}
          </Typography>
        </MKBox>

        {confirmationStatus === 200 && (
          <MKBox p={3}>
            <MKBox component="form" role="form">
              <MKBox mb={2} onChange={(e) => setPassword1(e.target.value)}>
                <MKInput error={password1Error} label="Password" fullWidth />
              </MKBox>
              <MKBox mb={2} onChange={(e) => setPassword2(e.target.value)}>
                <MKInput
                  success={password2Success}
                  error={password2Error}
                  label="Confirm Password"
                  fullWidth
                />
              </MKBox>
              <MKBox mt={4} mb={1}>
                <MKButton variant="gradient" color="primary" fullWidth onClick={onSubmit}>
                  Submit
                </MKButton>
              </MKBox>
            </MKBox>
          </MKBox>
        )}

        {confirmationStatus !== 200 && (
          <MKBox mt={3} mb={1} textAlign="center">
            <Typography
              component={Link}
              to="/login"
              variant="button"
              color="primary"
              fontWeight="medium"
              textGradient
            >
              Sign in
            </Typography>
          </MKBox>
        )}
      </Card>
    </BasicLayout>
  );
};

Confirm.propTypes = {
  getConfirm: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  confirmationStatus: state.auth.confirmationStatus,
});

export default connect(mapStateToProps, { setAlert, getConfirm, changePassword })(Confirm);
