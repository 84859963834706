import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

// MUI
import Box from "components/MaterialKit/MKBox";
import { Container } from "@mui/material";

import InterpretCommunityTopic from "../meaning/InterpretCommunityTopic";
import IdeaDetail from "../idea/IdeaDetail";
import NavbarTop from "../navbar/NavbarTop";

const Interpret101 = ({ activity, setting, numericalLabel }) => {
  const [childUpdated, setChildUpdated] = useState(0);
  const divRef = useRef(null);
  useEffect(() => {
    if (childUpdated > 1) {
      setTimeout(() => {
        if (divRef && divRef.current && divRef.current.scrollIntoView) {
          divRef.current.scrollIntoView({ behavior: "smooth" });
        }
        setTimeout(() => {
          if (divRef && divRef.current && divRef.current.scrollIntoView) {
            divRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }, 500);
      }, 500);
    }
  }, [childUpdated]);

  return (
    <>
      <NavbarTop
        title={`ACTIVITY #${numericalLabel}`}
        content1="The guiding question we are exploring..."
        content2={activity.instructions101}
      />

      <Container
        sx={{
          top: 0,
          zIndex: 10,
          mt: { xs: 8, sm: 11 },
        }}
        maxWidth="sm"
      >
        <Box sx={{ width: "100%", marginTop: "0px" }}>
          <IdeaDetail
            communityTopic
            idea={activity.communityTopic}
            hrefHiddenInternal
            hrefHiddenExternal
            greyOut
            showExtended
          />
        </Box>
      </Container>

      <Container maxWidth="sm" sx={{ mb: 12 }}>
        <InterpretCommunityTopic
          setting={setting}
          activity={activity}
          childUpdated={childUpdated}
          setChildUpdated={setChildUpdated}
        />
      </Container>
      <div ref={divRef} />
    </>
  );
};

Interpret101.propTypes = {
  activity: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
  numericalLabel: PropTypes.number.isRequired,
  setting: PropTypes.oneOfType([PropTypes.object]),
};

Interpret101.defaultProps = {
  setting: null,
};

export default Interpret101;
