import {
  LINKAGENTAGENT_ERROR,
  ADD_LINKAGENTAGENT,
  GET_LINKAGENTAGENT,
  GET_LINKAGENTAGENTS,
  ADD_LINKAGENTAGENT_SCALE,
  LOADING_LINKAGENTAGENT,
  LINK_ERROR,
} from "../actions/types";

const initialState = {
  linkAgentAgents: [],
  linkAgentAgent: null,
  loading: true,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_LINKAGENTAGENTS:
      return {
        ...state,
        linkAgentAgents: payload,
        loading: false,
      };
    case GET_LINKAGENTAGENT:
    case ADD_LINKAGENTAGENT_SCALE:
    case ADD_LINKAGENTAGENT:
      return {
        ...state,
        linkAgentAgent: payload,
        linkAgentAgents: [],
        loading: false,
      };
    case LINKAGENTAGENT_ERROR:
    case LINK_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case LOADING_LINKAGENTAGENT:
      return {
        ...state,
        linkAgentAgents: [],
        linkAgentAgent: null,
        loading: true,
      };
    default:
      return state;
  }
}
