import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

// MUI
import { styled } from "@mui/system";
import Typography from "components/MaterialKit/MKTypography";
import { Card, Hidden, Grid, Tooltip } from "@mui/material";
import { EmojiPeople } from "@mui/icons-material";

import defaultAvatar from "assets/images/faces/placeholder.jpg";
import AgentDetailHeader from "./AgentDetailHeader";
import getTimeAgo from "../../utils/getTimeAgo";

// PROPS
// Name                       Type      Default   Description
// agent                      object              Agent to be displayed
// controlMode                number    Undefined If defined, show agent stats header
//                                                1 = Meaning
//                                                2 = Tree logic
//                                                3 = Similars
// setControlMode             func
// hideImage                  boolean   false     Do not show associated image of agent
// hrefHiddenInternal             boolean   false     No href back to self
// requiresURLVisit           boolean   false     If true, make sure the current user has seen the associated external URL (if exists)
// --------------------------------------------
// ACTIONS
// - display agent detail
// - via AgentDetailHeader, display optional header
//
// CALLED BY:
// <AgentDetail agent controlMode setControlMode hrefHiddenInternal requiresURLVisit/> (agent/Agent.js)
// <AgentDetail agent hideImage requiresURLVisit/> (link-agent-agent/Meaning.js, link-idea-agent/Meaning.js)
// <AgentDetail agent/> (search/Search.js)
//
const AgentTitleActive = styled("a")(({ theme }) =>
  theme.unstable_sx({
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px",
    },
    textDecoration: "none", // remove default underline
    color: "#291AE0", // inSight blue
    "&:hover,&:focus": {
      textDecoration: "underline", // underline text when mouse hovers
    },
  })
);

const AgentDetail = ({
  agent: {
    _id,
    user,
    member,
    name,
    url,
    date,
    image,
    scales,
    linkedIdeas,
    //    linkedAgents,
    //    proxied,
    //    attributed,
  },
  hideImage,
  controlMode,
  hrefHiddenInternal,
  requiresURLVisit,
  setControlMode,
  auth,
}) => {
  const [showImage] = useState(!(hideImage || !image));

  // If a "member" attribute exists (platform member), use it instead of the user attribute (which is just a submitted agent)
  // "member" always supercedes "user" when considering agents
  const [userObj] = useState(member || user);

  // Store agent _id of last external url visited
  const navigate = useNavigate();
  const handleExternalLink = () => {
    localStorage.setItem("insightLastAgent", `${_id}`);
    window.open(url);
    navigate(`/agent/${_id}`);
  };

  useEffect(() => {
    //  console.log("useEffect() Change in agent or auth");
    if (requiresURLVisit && scales && auth && auth.user && auth.user._id) {
      // Handle both cases: .user is populated or unpopulated
      let priorScaleExists = null;
      if (scales.length > 0) {
        if (scales[0].user && scales[0].user._id) {
          priorScaleExists = scales.find((e) => e.user._id === auth.user._id);
        } else {
          priorScaleExists = scales.find((e) => e.user === auth.user._id);
        }
      }
      console.log("priorScaleExists", priorScaleExists, "scales", scales);
      if (
        !priorScaleExists &&
        url &&
        userObj._id !== auth.user._id &&
        _id !== localStorage.getItem("insightLastAgent")
      ) {
        // Current user scale DOES NOT exist && agent is an URL && agent was not submitted by the current user && its URL not recently opened
        console.log(
          "This agent (external URL link) was not submitted by the current user and was not recently opened -- open external agent URL"
        );
        localStorage.setItem("insightLastAgent", `${_id}`);
        window.open(url);
      }
    }
  }, [requiresURLVisit, scales, url, _id, userObj, auth]);

  return (
    <Card
      style={{
        margin: "0px",
        marginTop: "10px",
        marginBottom: "15px",
        backgroundColor: "#e9e8fb",
        boxShadow: "1px 5px 8px 5px grey",
      }}
    >
      <Grid item xs={12} sm container>
        <Grid item xs container direction="column">
          <Grid item xs>
            {typeof controlMode !== "undefined" && controlMode !== "" && controlMode > 0 && (
              <div style={{ marginBottom: "10px" }}>
                <AgentDetailHeader
                  agentID={_id}
                  agentRepliesLength={linkedIdeas.length}
                  controlMode={controlMode}
                  setControlMode={setControlMode}
                />
              </div>
            )}

            <div style={{ padding: "5px 20px" }}>
              {/* Active/clickable name (blue) */}
              {!hrefHiddenInternal && (
                <AgentTitleActive href={`/agent/${_id}`}>
                  <b>{name} </b>
                </AgentTitleActive>
              )}
              {hrefHiddenInternal && (
                <Typography>
                  <b>{name} </b>
                </Typography>
              )}
              {/* Agent link (dark green) */}
              {url && (
                // eslint-disable-next-line
                <div onClick={handleExternalLink} style={{ marginTop: "5px", marginBottom: "5px" }}>
                  <Typography
                    noWrap
                    style={{
                      fontSize: "12px",
                      textDecoration: "none",
                      color: "#397D02",
                      "&:hover,&:focus": { textDecoration: "underline" },
                    }}
                  >
                    {url}
                  </Typography>
                </div>
              )}
            </div>
          </Grid>

          {/* Footer: Author, elapsed time since submission */}
          <div style={{ padding: "5px 20px", paddingTop: "0px" }}>
            {/* Author avatar */}
            <div>
              {member && member.name && (
                <>
                  <Tooltip title={`${member.name}'s Profile`} placement="top-end">
                    <a href={`/profile/${member._id}`} style={{ color: "#291AE0" }}>
                      <EmojiPeople />
                    </a>
                  </Tooltip>
                  <div style={{ fontSize: "10px", color: "grey" }}>
                    <i> joined {getTimeAgo(date)}</i>
                  </div>
                </>
              )}

              {!member && user && user.name && (
                <>
                  <Tooltip title={`${user.name}'s Profile`} placement="top-end">
                    <a href={`/profile/${user._id}`} style={{ color: "#291AE0" }}>
                      <img
                        src={user.avatar ? user.avatar : defaultAvatar}
                        alt="..."
                        style={{ width: "30px", height: "30px" }}
                      />
                    </a>
                  </Tooltip>
                  <div style={{ fontSize: "10px", color: "grey" }}>
                    <i> submitted · {getTimeAgo(date)}</i>
                  </div>
                </>
              )}
            </div>
          </div>
        </Grid>

        {/* Image display md+ */}
        {showImage && (
          <Hidden xsDown>
            <Grid item xs>
              <img
                src={image}
                alt="..."
                style={{
                  minWidth: "150px",
                  maxHeight: "250px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "0px",
                  borderRadius: "4px",
                }}
              />
            </Grid>
          </Hidden>
        )}
      </Grid>

      {/* Image display sm- */}
      {showImage && (
        <Hidden smUp>
          <img src={image} alt="..." style={{ borderRadius: "4px" }} />
        </Hidden>
      )}
    </Card>
  );
};

AgentDetail.propTypes = {
  agent: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
  hideImage: PropTypes.bool,
  controlMode: PropTypes.number,
  hrefHiddenInternal: PropTypes.bool,
  requiresURLVisit: PropTypes.bool,
  setControlMode: PropTypes.func,
  auth: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
};

AgentDetail.defaultProps = {
  hideImage: false,
  controlMode: 0,
  hrefHiddenInternal: false,
  requiresURLVisit: false,
  setControlMode: null,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AgentDetail);
