import React from "react";
import PropTypes from "prop-types";

// MUI
import { Checkbox } from "@mui/material";
import Box from "components/MaterialKit/MKBox";
import Input from "components/MaterialKit/MKInput";

import Create102 from "./Create102";
import LabelText from "../styles/LabelText";

const Create1 = ({
  instructions1,
  instructions102,
  newInTellSelfEvaluate,
  afterOptionInterpretNewInTell,
  afterOptionInterconnectNewInTell,
  interpretConfirmDeeperUnderstanding,
  interpretEvaluateValues,
  interpretPauseEmotionFlow,
  options,
  setInstructions1,
  setInstructions102,
  setNewInTellSelfEvaluate,
  setAfterOptionInterpretNewInTell,
  setAfterOptionInterconnectNewInTell,
  setInterpretConfirmDeeperUnderstanding,
  setInterpretEvaluateValues,
  setInterpretPauseEmotionFlow,
  setInterpretIntentWords,
  handleOnChange,
  handleInputChange,
}) => {
  return (
    <>
      <LabelText style={{ marginTop: "10px" }}>
        Additional prompt for students prior to them inputing their own InTell...
      </LabelText>
      <Box
        onChange={(e) => setInstructions1(e.target.value)}
        style={{ marginTop: "10px", marginBottom: "10px" }}
      >
        <Input
          type="instructions1"
          label="Specify instructions to students (optional)"
          fullWidth
          multiline
          variant="outlined"
          value={instructions1}
          inputProps={{ maxLength: 140 }}
        />
      </Box>

      <LabelText>
        <Checkbox
          checked={newInTellSelfEvaluate}
          onClick={() => setNewInTellSelfEvaluate(!newInTellSelfEvaluate)}
        />
        Request student to self-evaluate his/her own submission prior to submitting
      </LabelText>

      <LabelText>
        <Checkbox
          checked={afterOptionInterpretNewInTell}
          onClick={() => setAfterOptionInterpretNewInTell(!afterOptionInterpretNewInTell)}
        />
        [CHAIN EVENT] ADD new activity after this inTell is created: INTERPRET newly created inTell
        with the following rules:
      </LabelText>

      {afterOptionInterpretNewInTell && (
        <div style={{ paddingLeft: "32px", marginTop: "10px" }}>
          <Create102
            instructions102={instructions102}
            setInstructions102={setInstructions102}
            afterOptionInterconnectNewInTell={afterOptionInterconnectNewInTell}
            interpretConfirmDeeperUnderstanding={interpretConfirmDeeperUnderstanding}
            interpretEvaluateValues={interpretEvaluateValues}
            interpretPauseEmotionFlow={interpretPauseEmotionFlow}
            options={options}
            setAfterOptionInterconnectNewInTell={setAfterOptionInterconnectNewInTell}
            setInterpretConfirmDeeperUnderstanding={setInterpretConfirmDeeperUnderstanding}
            setInterpretEvaluateValues={setInterpretEvaluateValues}
            setInterpretPauseEmotionFlow={setInterpretPauseEmotionFlow}
            setInterpretIntentWords={setInterpretIntentWords}
            handleOnChange={handleOnChange}
            handleInputChange={handleInputChange}
          />
        </div>
      )}
    </>
  );
};

Create1.propTypes = {
  instructions1: PropTypes.string.isRequired,
  instructions102: PropTypes.string.isRequired,
  newInTellSelfEvaluate: PropTypes.bool.isRequired,
  afterOptionInterpretNewInTell: PropTypes.bool.isRequired,
  afterOptionInterconnectNewInTell: PropTypes.bool.isRequired,
  interpretConfirmDeeperUnderstanding: PropTypes.bool.isRequired,
  interpretEvaluateValues: PropTypes.bool.isRequired,
  interpretPauseEmotionFlow: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  setInstructions1: PropTypes.func.isRequired,
  setInstructions102: PropTypes.func.isRequired,
  setNewInTellSelfEvaluate: PropTypes.func.isRequired,
  setAfterOptionInterpretNewInTell: PropTypes.func.isRequired,
  setAfterOptionInterconnectNewInTell: PropTypes.func.isRequired,
  setInterpretConfirmDeeperUnderstanding: PropTypes.func.isRequired,
  setInterpretEvaluateValues: PropTypes.func.isRequired,
  setInterpretPauseEmotionFlow: PropTypes.func.isRequired,
  setInterpretIntentWords: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func,
  handleInputChange: PropTypes.func.isRequired,
};

Create1.defaultProps = {
  handleOnChange: null,
};

export default Create1;
