import React, { useState } from "react";
import PropTypes from "prop-types";

// MUI
import Button from "components/MaterialKit/MKButton";
import { Tooltip, Dialog } from "@mui/material";
// import { Info } from "@mui/icons-material";

import insightIconWhite from "assets/images/icons/insight_white.png";
import insightIconBlue from "assets/images/icons/insight_blue1.png";
import fightIconWhite from "assets/images/icons/fight_white.png";
import fightIconBlue from "assets/images/icons/fight_blue.png";
import zoomIconWhite from "assets/images/icons/zoom_white.png";
import zoomIconBlue from "assets/images/icons/zoom_blue.png";
import agentsIconWhite from "assets/images/icons/agents_white.png";
import agentsIconBlue from "assets/images/icons/agents_blue.png";

import Tour from "./Tour";

// PROPS
// Name               Type      Default   Description
// controlMode        number    Undefined If defined and >0, show idea stats header:
//                                        1 = Interpretation & replies
//                                        2 = Tree (rationlity)
//                                        3 = Synthesis/related/essence/quote
//                                        4 = waveMakers-related
// setControlMode     func

const IdeaDetailHeader = ({ controlMode, setControlMode }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      {/* Scoreboard header */}
      {typeof controlMode !== "undefined" && controlMode !== "" && controlMode > 0 && (
        <>
          <div
            style={{
              textAlign: "center",
            }}
          >
            {/* Interpret icon button */}
            {controlMode !== 1 && (
              <Tooltip title="Interpret meaning" placement="top-end">
                <Button onClick={() => setControlMode(1)}>
                  <img src={insightIconBlue} alt="" width="15" height="15" />
                </Button>
              </Tooltip>
            )}
            {controlMode === 1 && (
              <Button color="primary" size="small">
                <img src={insightIconWhite} alt="" width="15" height="15" />
              </Button>
            )}

            {/* Tree icon button */}
            {controlMode !== 2 && (
              <Tooltip title="Arguments" placement="top-end">
                <Button onClick={() => setControlMode(2)}>
                  <img src={fightIconBlue} alt="" width="18" height="18" />
                </Button>
              </Tooltip>
            )}
            {controlMode === 2 && (
              <Button color="primary" size="small">
                <img src={fightIconWhite} alt="" width="18" height="18" />
              </Button>
            )}

            {/* Synthesis icon button */}
            {controlMode !== 3 && (
              <Tooltip title="Zoom" placement="top-end">
                <Button onClick={() => setControlMode(3)}>
                  <img src={zoomIconBlue} alt="" width="25" height="15" />
                </Button>
              </Tooltip>
            )}
            {controlMode === 3 && (
              <Button color="primary" size="small">
                <img src={zoomIconWhite} alt="" width="25" height="15" />
              </Button>
            )}

            {/* Agent icon button */}
            {controlMode !== 4 && (
              <Tooltip title="waveMakers" placement="top-end">
                <Button onClick={() => setControlMode(4)}>
                  <img src={agentsIconBlue} alt="" width="15" height="15" />
                </Button>
              </Tooltip>
            )}
            {controlMode === 4 && (
              <Button color="primary" size="small">
                <img src={agentsIconWhite} alt="" width="15" height="15" />
              </Button>
            )}

            {/* Info icon button */}
            {/*            <div style={{ float: "right", marginTop: "10px" }}>  */}
            {/*              {controlMode === 2 && (                            */}
            {/*                <Button onClick={() => setOpenModal(true)}>      */}
            {/*                  <Info color="primary" />                       */}
            {/*                </Button>                                        */}
            {/*              )}                                                 */}
            {/*            </div>                                               */}
          </div>
        </>
      )}

      {/* Tour Modal */}
      <Dialog
        open={openModal}
        aria-labelledby="tip-dialog-title"
        aria-describedby="tip-dialog-description"
      >
        <Tour openModal={setOpenModal} />
      </Dialog>
    </>
  );
};

IdeaDetailHeader.propTypes = {
  controlMode: PropTypes.number,
  setControlMode: PropTypes.func,
};

IdeaDetailHeader.defaultProps = {
  controlMode: null,
  setControlMode: null,
};

export default IdeaDetailHeader;
