import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// MUI
import Button from "components/MaterialKit/MKButton";
import { Radio, Stack } from "@mui/material";

import Spinner from "../layout/Spinner";
import CreateMyContent from "../create/CreateMyContent";
import SliderConfidence from "../slider/SliderConfidence";
import { modifyActivity } from "../../actions/activity";
import QuestionText from "../styles/QuestionText";
import LabelText from "../styles/LabelText";
import ClickText from "../styles/ClickText";

const InterpretCommunityTopic = ({
  activity,
  setting,
  childUpdated,
  setChildUpdated,
  modifyActivity,
  auth,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const [communityInterpretation, setCommunityInterpretation] = useState(-2); // Typically -1/0/1 (-2 represents uninitialized)
  const [confidence, setConfidence] = useState(-2); // Typically ranges 0->1 (-2 represents uninitialized)
  const [instructions, setInstructions] = useState("");

  // Everytime there is any user interaction, notify parent by updating a counter
  useEffect(() => {
    setChildUpdated(childUpdated + 1);
  }, [communityInterpretation, confidence]);

  const handleCommunityTopicInterpretation = (e) => {
    setCommunityInterpretation(e);
    setConfidence(-2);

    if (e === 0) {
      setInstructions("Please explain why you are unsure about the community topic statement...");
    }
    if (e > 0) {
      setInstructions("Please explain why you agree with the community topic statement...");
    }
    if (e < 0) {
      setInstructions("Please explain why you disagree with the community topic statement...");
    }
  };

  const onSubmit = () => {
    setSubmitting(true);
    modifyActivity({
      cannotOverwriteExisting: true,
      activityID: activity._id,
      studentIDCompleted: auth.user._id,
      addIdeaScale: {
        id: activity.communityTopic._id,
        authorIntent: communityInterpretation,
        confidence: confidence > -2 ? confidence : null,
        activityType: 101,
      },
    });
    console.log("onSubmit", communityInterpretation, confidence);
  };

  return submitting ? (
    <Spinner />
  ) : (
    <>
      <div style={{ marginTop: "40px", marginBottom: "10px" }}>
        <QuestionText large={communityInterpretation <= -2 ? 1 : 0}>
          Select below what best describes your position.
        </QuestionText>
      </div>

      <div>
        <LabelText>
          <Radio
            checked={communityInterpretation === -1}
            onClick={() => handleCommunityTopicInterpretation(-1)}
          />
          I disagree
        </LabelText>

        <LabelText>
          <Radio
            checked={communityInterpretation === 1}
            onClick={() => handleCommunityTopicInterpretation(1)}
          />
          I agree
        </LabelText>

        <LabelText>
          <Radio
            checked={communityInterpretation === 0}
            onClick={() => handleCommunityTopicInterpretation(0)}
          />
          I am unsure
        </LabelText>
      </div>

      {/* How confident? */}
      {(communityInterpretation === 1 || communityInterpretation === -1) && (
        <>
          <div style={{ marginTop: "20px", marginBottom: "15px", textAlign: "center" }}>
            <QuestionText large={confidence <= -2 ? 1 : 0}>
              How confident are you in your position?
            </QuestionText>
            {confidence <= -2 && <ClickText>CLICK ON THE SCALE BELOW</ClickText>}
          </div>

          <SliderConfidence userInitLevel={confidence} updated={setConfidence} />
        </>
      )}

      {communityInterpretation > -2 && (confidence > -2 || communityInterpretation === 0) && (
        <>
          {activity.afterOptionAddNewIntellAfter && (
            <div style={{ marginTop: "50px" }}>
              {(communityInterpretation === 0 ||
                (communityInterpretation > -2 && confidence > -2)) && (
                <>
                  {activity.instructions1 && activity.instructions1.length > 0 && (
                    <QuestionText style={{ marginBottom: "10px" }}>
                      {activity.instructions1}
                    </QuestionText>
                  )}

                  <CreateMyContent
                    instructions={instructions}
                    initSelection={1}
                    activity={activity}
                    setting={setting}
                    requestSelfEval={activity.newInTellSelfEvaluate}
                    addIdeaScale={{
                      id: activity.communityTopic._id,
                      authorIntent: communityInterpretation,
                      confidence: confidence > -2 ? confidence : null,
                      activityType: 101,
                    }}
                    auth={auth}
                    notifyParent={childUpdated}
                    setNotifyParent={setChildUpdated}
                  />
                </>
              )}
            </div>
          )}

          {!activity.afterOptionAddNewIntellAfter &&
            communityInterpretation > -2 &&
            (confidence > -2 || communityInterpretation === 0) && (
              <Stack
                flexWrap="wrap"
                flexDirection="row"
                width={1.0}
                justifyContent="center"
                style={{ marginTop: "40px" }}
              >
                <Button onClick={() => onSubmit()} color="primary" circular>
                  Submit
                </Button>
              </Stack>
            )}
        </>
      )}
    </>
  );
};

InterpretCommunityTopic.propTypes = {
  childUpdated: PropTypes.number.isRequired,
  setChildUpdated: PropTypes.func.isRequired,
  activity: PropTypes.oneOfType([PropTypes.object]),
  setting: PropTypes.oneOfType([PropTypes.object]),
};

InterpretCommunityTopic.defaultProps = {
  activity: null,
  setting: null,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { modifyActivity })(InterpretCommunityTopic);
