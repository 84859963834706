import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// MUI
import { Card, CardHeader } from "@mui/material";
import Button from "components/MaterialKit/MKButton";
import Input from "components/MaterialKit/MKInput";

import { updateProfile } from "../../actions/profile";
// import { setAlert } from "../../actions/alert";

const ProfileEdit = ({ updateProfile, profile: { profile }, editProfile }) => {
  const [formData, setFormData] = useState({
    aka: !profile.aka ? "" : profile.aka,
    bio: !profile.bio ? "" : profile.bio,
    //    twitter: !profile.social || !profile.social.twitter ? "" : profile.social.twitter,
    //    facebook: !profile.social || !profile.social.facebook ? "" : profile.social.facebook,
    //    linkedin: !profile.social || !profile.social.linkedin ? "" : profile.social.linkedin,
    //    youtube: !profile.social || !profile.social.youtube ? "" : profile.social.youtube,
    //    instagram: !profile.social || !profile.social.instagram ? "" : profile.social.instagram,
  });

  //  const { aka, bio, twitter, facebook, linkedin, youtube, instagram } = formData;

  const [editFormMade, setEditFormMade] = useState(false);
  const onChange = (e) => {
    setEditFormMade(true);
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  //  const [editAvatarMade, setEditAvatarMade] = useState(false);
  const [editAvatarMade] = useState(false);
  const saveChanges = () => {
    updateProfile(formData);
    editProfile(false); // return to view profile screen
  };

  // Upload file (from https://codepen.io/team/Cloudinary/pen/QgpyOK)
  // In Cloudindary settings, all uploads are cropped to a width of 300px / good quality
  //  const uploadFile = (file) => {
  //    if (file.length >= 10000000) {
  //      setAlert("Unable to load image (image file exceeds 10MB)", "danger");
  //      return;
  //    }

  //    const cloudName = "big-idea-lab-inc";
  //    const unsignedUploadPreset = "xjj3zo91";
  //    const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;
  //    const xhr = new XMLHttpRequest();
  //    const fd = new FormData();
  //    xhr.open("POST", url, true);
  //    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

  //    xhr.onreadystatechange = function () {
  //      if (xhr.readyState === 4 && xhr.status === 200) {
  //        const response = JSON.parse(xhr.responseText);
  //        setEditAvatarMade(true);
  //        setFormData({ ...formData, avatar: response.secure_url });
  //        console.log(response.secure_url);
  //      } else if (xhr.status >= 300) {
  //        setAlert("Failed to load image", "danger");
  //        console.log("WTF", xhr.readyState, xhr.status);
  //      }
  //    };
  //    fd.append("upload_preset", unsignedUploadPreset);
  //    fd.append("file", file);
  //    xhr.send(fd);
  //  };

  // from https://github.com/creativetimofficial/ct-material-kit-pro-react/issues/64
  //  const onFileInputChange = (e) => {
  //    if (e) {
  // Upload selected file
  //      uploadFile(e);
  //    } else {
  // File removed
  //      setFormData({ ...formData, avatar: "" });
  //      setEditAvatarMade(true);
  //    }
  //  };

  //  <div>
  //             <ImageUpload
  //                avatar
  //                initialImage={profile.user.avatar}
  //               onChange={e => onFileInputChange(e)}
  //                addButtonProps={{
  //                 circular: true,
  //                 color: "primary"
  //                }}
  //                changeButtonProps={{
  //                  circular: true,
  //                 color: "primary"
  //                }}
  //                removeButtonProps={{
  //                  circular: true,
  //                 color: "primary"
  //                }}
  //              />
  //            </div>

  return (
    <div>
      <Card style={{ textAlign: "center" }}>
        <CardHeader color="info">
          <b>
            Details below are visible to others at inSight. Promote your Insight profile to the
            world (or you can be relatively anonymous). Remember, you are always in control.
          </b>
        </CardHeader>
        <div>
          <h3>{profile.user.name}</h3>

          <form onChange={(e) => onChange(e)}>
            <Input style={{ marginTop: "20px" }} label="aka (I am also known as...)" id="aka" />
            <br />
            <Input style={{ marginTop: "20px" }} label="A short bio of yourself" id="bio" />

            <br />
          </form>

          <div style={{ float: "right" }}>
            <Button
              style={{ margin: "10px" }}
              color="primary"
              circular
              onClick={() => editProfile(false)}
            >
              Cancel Edits
            </Button>

            {(editFormMade || editAvatarMade) && (
              <Button
                style={{ margin: "10px" }}
                color="primary"
                circular
                onClick={() => saveChanges()}
              >
                Save Changes
              </Button>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

ProfileEdit.propTypes = {
  updateProfile: PropTypes.func.isRequired,
  profile: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
  editProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { updateProfile })(ProfileEdit);
