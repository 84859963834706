import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// MUI
import { Card } from "@mui/material";
import Box from "components/MaterialKit/MKBox";
import Typography from "components/MaterialKit/MKTypography";
import Input from "components/MaterialKit/MKInput";
import Button from "components/MaterialKit/MKButton";
import bgImage from "assets/images/campfire/desertcampfire.png";

import { EDUCATION_BUILD, EDUCATION_ANONYMOUS, EDUCATION_ADMIN } from "config/personalize";
import LoginLayout from "./LoginLayout";
import { login } from "../../actions/auth";
import { setAlert } from "../../actions/alert";
import getSecondsAgo from "../../utils/getSecondsAgo";

// ACTIONS
// - this is the first route when entering inSight
// - user can login or register or already login, re-direct to /welcome or /activity-students

const Login = ({ login, setAlert, auth }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordReqd, setPasswordReqd] = useState(false);
  const [adminIAm, setadminIAm] = useState(false);

  useEffect(() => {
    if (password.length >= 6) {
      setPasswordError(false);
    }
  }, [password]);

  useEffect(() => {
    if (email.length >= 1) {
      setEmailError(false);
    }
    if (
      EDUCATION_BUILD &&
      EDUCATION_ANONYMOUS &&
      (email.length === 0 || (email.length > 0 && email.trim() !== EDUCATION_ADMIN.trim()))
    ) {
      setPasswordReqd(false);
    } else {
      setPasswordReqd(true);
    }

    if (EDUCATION_BUILD && email.trim() === EDUCATION_ADMIN.trim()) {
      setadminIAm(true);
    } else {
      setadminIAm(false);
    }
  }, [email]);

  const onSubmitLogin = () => {
    if (email.length < 1) {
      setAlert("Invalid - mandatory field", "danger");
      setEmailError(true);
    } else if (passwordReqd && password.length < 6) {
      setAlert("Invalid Password - must be at least 6 characters", "danger");
      setPasswordError(true);
    } else {
      login(email, password);
    }
  };

  if (!auth.loading && auth.isAuthenticated && auth.user) {
    if (EDUCATION_BUILD && !adminIAm) {
      if (auth && auth.user && auth.user.date && getSecondsAgo(auth.user.date) > 3600) {
        return <Navigate to="/activity-students" />;
      }
      return <Navigate to="/about" />;
    }
    return <Navigate to="/welcome" />;
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSubmitLogin();
      event.preventDefault();
    }
  };

  return (
    <LoginLayout image={bgImage}>
      <Card>
        <Box pt={4} pb={3} px={3}>
          <Box component="form" role="form">
            <Box mb={2} onChange={(e) => setEmail(e.target.value)}>
              <Input
                error={emailError}
                label={passwordReqd ? "Email" : "Your login ID"}
                fullWidth
                onKeyDown={handleKeyDown}
              />
            </Box>
            {passwordReqd && (
              <Box mb={2} onChange={(e) => setPassword(e.target.value)}>
                <Input error={passwordError} label="Password" fullWidth onKeyDown={handleKeyDown} />
              </Box>
            )}
            <Box mt={4} mb={1}>
              <Button variant="gradient" color="primary" fullWidth onClick={onSubmitLogin}>
                Login
              </Button>
            </Box>
            {passwordReqd && (
              <Box mt={3} mb={1} textAlign="center">
                <Typography variant="button" color="text">
                  Don&apos;t have an account?{" "}
                  <Typography
                    component={Link}
                    to="/register"
                    variant="button"
                    color="primary"
                    fontWeight="medium"
                    textGradient
                  >
                    Register
                  </Typography>
                </Typography>
              </Box>
            )}
            {!(
              EDUCATION_BUILD &&
              EDUCATION_ANONYMOUS &&
              (email.length === 0 || (email.length > 0 && email.trim() !== EDUCATION_ADMIN.trim()))
            ) && (
              <Box mt={3} mb={1} textAlign="center" style={{ marginTop: "0px" }}>
                <Typography
                  component={Link}
                  to="/forgot-password"
                  variant="button"
                  color="primary"
                  fontWeight="medium"
                  textGradient
                >
                  Forgot my password
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Card>
    </LoginLayout>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { login, setAlert })(Login);
