import React, { useState } from "react";
import PropTypes from "prop-types";

// MUI
import { styled } from "@mui/system";
import { Grid, Card } from "@mui/material";
import { Add } from "@mui/icons-material";
import Button from "components/MaterialKit/MKButton";

import BackgroundImage from "assets/images/Tree/bulb.png";
import ShowLinkList from "./ShowLinkList";

const GridImage = styled(Grid)(({ theme }) =>
  theme.unstable_sx({
    backgroundImage: `url(${BackgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "50%",
    backgroundPosition: "center",
    [theme.breakpoints.down("xs")]: {
      minHeight: "240px",
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "360px",
      backgroundSize: "40%",
    },
  })
);

const RelatedIdeas = ({ agent, auth, showAddButton, requestSearchRight, requestSearchLeft }) => {
  const [supportIdeas] = useState(agent.linkedIdeas.filter((e) => e.avgLevel >= 0));
  const [opposeIdeas] = useState(agent.linkedIdeas.filter((e) => e.avgLevel < 0));

  return (
    <>
      <GridImage container>
        {/* These inTell oppose (undermine) the selected/central waveMaker (listed above-left) */}
        <Grid item xs>
          <Card style={{ marginTop: "10px", marginBottom: "10px", marginLeft: "0px" }}>
            <div
              style={{
                color: "white",
                backgroundColor: "black",
                padding: "0px 0px",
                boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",
                fontSize: "14px",
              }}
            >
              <Grid container>
                <Grid item xs={1} style={{ paddingTop: "2px", paddingLeft: "30px" }}>
                  {auth.isAuthenticated && showAddButton && (
                    <Button
                      iconOnly
                      onClick={() => requestSearchLeft(true)}
                      style={{
                        marginLeft: "-20px",
                        backgroundColor: "transparent",
                        color: "white",
                        padding: "0px 10px",
                      }}
                    >
                      {" "}
                      <Add />{" "}
                    </Button>
                  )}
                </Grid>
                <Grid item xs style={{ textAlign: "right", padding: "5px 30px" }}>
                  inTell below weakens waveMaker
                </Grid>
              </Grid>
            </div>
          </Card>

          <ShowLinkList
            relatedIdeaLinks={opposeIdeas}
            parentAgentID={agent._id}
            cardColor="#ffcccb"
          />
        </Grid>

        {/* These inTell support (re-inforce) the selected/central waveMaker (listed above-right) */}
        <Grid item xs>
          <Card style={{ marginTop: "10px", marginBottom: "10px" }}>
            <div
              style={{
                color: "white",
                backgroundColor: "black",
                padding: "0px 0px",
                boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",
                fontSize: "14px",
              }}
            >
              <Grid container>
                <Grid item xs style={{ textAlign: "left", padding: "5px 30px" }}>
                  inTell below strengthens waveMaker
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{ textAlign: "right", paddingRight: "50px", paddingTop: "2px" }}
                >
                  {auth.isAuthenticated && showAddButton && (
                    <Button
                      iconOnly
                      style={{
                        backgroundColor: "transparent",
                        color: "white",
                        padding: "0px 10px",
                      }}
                      onClick={() => requestSearchRight(true)}
                    >
                      {" "}
                      <Add />{" "}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </div>
          </Card>

          <ShowLinkList
            relatedIdeaLinks={supportIdeas}
            parentAgentID={agent._id}
            cardColor="lightgreen"
          />
        </Grid>
      </GridImage>
    </>
  );
};

RelatedIdeas.propTypes = {
  agent: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
  auth: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
  showAddButton: PropTypes.bool.isRequired,
  requestSearchRight: PropTypes.func.isRequired,
  requestSearchLeft: PropTypes.func.isRequired,
};

export default RelatedIdeas;
