import React, { useState, useEffect, useRef } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

// MUI
import { Checkbox, Radio, Stack } from "@mui/material";
import Button from "components/MaterialKit/MKButton";
import Box from "components/MaterialKit/MKBox";
import Input from "components/MaterialKit/MKInput";

import Spinner from "../layout/Spinner";
import ImageScrape from "../image/ImageScrape";
import SelectAgent from "./SelectAgent";
import ViewVideo from "../idea/ViewVideo";
import LabelText from "../styles/LabelText";
import QuestionText from "../styles/QuestionText";
import GoodIntent from "./GoodIntent";
import { addIdea } from "../../actions/idea";
import { modifyActivity } from "../../actions/activity";
import { setAlert } from "../../actions/alert";

// TODO: Lacking parallelism: CreateMyContent does no useRef and relies on parent while this does it all...

// const CreateWebContent = ({ initUrl, newCreatedIdea, addIdea, setAlert, idea }) => {
const CreateWebContent = ({
  requestImage,
  requestSelfEval,
  initSelection,
  newCreatedIdea,
  addIdea,
  modifyActivity,
  setAlert,
  idea,
  activity, // relates only to activities
  setting, // reelats only to activities
  addIdeaScale, // relates only to activities
  auth, // relates only to activities
}) => {
  const [requestAgents] = useState(!(setting && setting.defaultWaveMakers));

  // 1 = Webpage, 2 = Online web link
  const [selection, setSelection] = useState(initSelection || 1);
  const [url, setUrl] = useState("");
  const [myPoint, setMyPoint] = useState("");
  const [image, setImage] = useState("");
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [associateImage, setAssociateImage] = useState(false);
  const [submitterIsWriter, setSubmitterIsWriter] = useState(false);
  const [validUrl, setValidUrl] = useState(false);
  const [goodIntentLevel, setGoodIntentLevel] = useState(0);
  const [videoLoaded, setVideoLoaded] = useState(false);

  const handleSelection = (e) => {
    setSelection(e);
    setUrl("");
    setMyPoint("");
    setImage("");
    setSelectedAgents([]);
    setAssociateImage(false);
    setSubmitterIsWriter(false);
    setValidUrl(false);
    setGoodIntentLevel(0);
    setVideoLoaded(false);
  };

  const [submitting, setSubmitting] = useState(false);

  // When a child process gets updated due to user interation, this counter in incremented
  // This triggers a scroll to the bottom of the screen
  const [childUpdated, setChildUpdated] = useState(0);
  const divRef = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      if (divRef && divRef.current && divRef.current.scrollIntoView) {
        divRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 10);
  }, [childUpdated]);
  useEffect(() => {
    setTimeout(() => {
      if (divRef && divRef.current && divRef.current.scrollIntoView) {
        divRef.current.scrollIntoView({ behavior: "smooth" });
      }
      setTimeout(() => {
        if (divRef && divRef.current && divRef.current.scrollIntoView) {
          divRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
    }, 500);
  }, [childUpdated, selection, selectedAgents, submitterIsWriter, goodIntentLevel]);

  const onSubmit = () => {
    // selection===1 Web URL
    // selection===2 Video URL
    let bValid = true;

    if (!myPoint || myPoint.length <= 2) {
      setAlert('Invalid "headline". Please be more descriptive.', "danger");
      bValid = false;
    }
    if (!url || url.length <= 2 || !validUrl || (selection === 2 && !videoLoaded)) {
      setAlert("Invalid url.", "danger");
      bValid = false;
    }
    if (submitterIsWriter && goodIntentLevel < 1) {
      setAlert('"Good intent" not confirmed.', "danger");
      bValid = false;
    }

    if (bValid) {
      setSubmitting(true);
      if (!activity) {
        addIdea({
          goodIntentLevel, // Will be "0" if !submitterIsWriter
          myPoint,
          url,
          image,
          isVideo: selection === 2,
          agents: selectedAgents.length > 0 ? selectedAgents.map((a) => a.value) : [], // Will be [] if submitterIsWriter
        });
      } else if (!activity.afterOptionInterpretNewInTell) {
        modifyActivity({
          activityID: activity._id,
          studentIDCompleted: auth.user._id,
          addIdea: {
            goodIntentLevel, // Will be "0" if !submitterIsWriter
            myPoint,
            url,
            image,
            isVideo: selection === 2,
            agents: selectedAgents.length > 0 ? selectedAgents.map((a) => a.value) : [], // Will be [] if submitterIsWriter
          },
          addIdeaScale,
        });
      } else {
        modifyActivity({
          activityID: activity._id,
          studentIDCompleted: auth.user._id,
          addIdea: {
            goodIntentLevel, // Will be "0" if !submitterIsWriter
            myPoint,
            url,
            image,
            isVideo: selection === 2,
            agents: selectedAgents.length > 0 ? selectedAgents.map((a) => a.value) : [], // Will be [] if submitterIsWriter
          },
          newActivities: [
            {
              selection: 102,
              interpretConfirmDeeperUnderstanding: activity.interpretConfirmDeeperUnderstanding,
              interpretEvaluateValues: activity.interpretEvaluateValues,
              interpretPauseEmotionFlow: activity.interpretPauseEmotionFlow,
              interpretIntentWords: activity.interpretIntentWords,
              afterOptionInterconnectNewInTell: activity.afterOptionInterconnectNewInTell,
              communityTopic: activity.communityTopic,
            },
          ],
          addIdeaScale,
        });
      }
    }
  };

  // Exit strategy to exit this component and scale new idea (report back to parent once back-end confirms submission)
  // - Store initial idea (when entering this component)
  const [initIdeaID] = useState(idea.idea && idea.idea._id ? idea.idea._id : 0);
  useEffect(() => {
    if (idea.idea && idea.idea._id) {
      if (idea.idea._id !== initIdeaID) {
        console.log("New idea created");
        if (newCreatedIdea) {
          newCreatedIdea(idea.idea);
        }
      }
    }
  }, [idea, submitting]);

  return submitting ? (
    <Spinner />
  ) : (
    <>
      {/* Radio button selection of different online types */}
      {!(initSelection === 1 || initSelection === 2) && (
        <>
          <QuestionText style={{ marginBottom: "10px" }}>
            Select the web content type you wish to submit
          </QuestionText>

          <LabelText>
            <Radio
              checked={selection === 1}
              onClick={() => handleSelection(1)}
              style={{ marginTop: "-3px", marginBottom: "-3px" }}
            />
            Web page
          </LabelText>
          <LabelText>
            <Radio
              checked={selection === 2}
              onClick={() => handleSelection(2)}
              style={{ marginTop: "-3px", marginBottom: "-3px" }}
            />
            Online Video
          </LabelText>
        </>
      )}

      {/* Input URL */}
      <Box mb={2} mt={2} onChange={(e) => setUrl(e.target.value)}>
        <Input
          type="url"
          value={url}
          success={validUrl && (selection === 1 || (selection === 2 && videoLoaded))}
          label="URL"
          fullWidth
        />
      </Box>

      {validUrl && (selection === 1 || (selection === 2 && videoLoaded)) && (
        <>
          <Box onChange={(e) => setMyPoint(e.target.value)}>
            <Input
              type="title"
              label="Title (60 characters or less)"
              fullWidth
              autoFocus
              value={myPoint}
              inputProps={{
                maxLength: 60,
              }}
            />
          </Box>

          {requestImage && selection === 1 && (
            <LabelText style={{ marginTop: "20px" }}>
              <Checkbox
                checked={associateImage}
                onClick={() => setAssociateImage(!associateImage)}
              />
              Select an image to be associated with this inTell (optional)
            </LabelText>
          )}
        </>
      )}

      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <ImageScrape
          url={url}
          showGallery={selection === 1 ? associateImage : false}
          setImage={setImage}
          setValidUrl={setValidUrl}
          setTitle={setMyPoint}
        />
      </div>

      {validUrl && selection === 2 && (
        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          <ViewVideo url={url} setLoaded={setVideoLoaded} />
        </div>
      )}

      {validUrl &&
        (selection === 1 || (selection === 2 && videoLoaded)) &&
        myPoint &&
        myPoint.length > 2 && (
          <>
            <LabelText>
              <Checkbox
                checked={submitterIsWriter}
                onClick={() => setSubmitterIsWriter(!submitterIsWriter)}
              />
              {`${
                selection === 1
                  ? "I am the author of this online article."
                  : "I made this video and uploaded it to the web."
              }`}
            </LabelText>

            {submitterIsWriter && (
              <>
                {requestSelfEval && (
                  <GoodIntent
                    myPoint={myPoint}
                    isVideo={selection === 2}
                    url={validUrl ? url : ""}
                    goodIntentLevel={goodIntentLevel}
                    insightful
                    image={image}
                    setGoodIntentLevel={setGoodIntentLevel}
                    childUpdated={childUpdated}
                    setChildUpdated={setChildUpdated}
                    activity={activity}
                    setting={setting}
                    addIdeaScale={addIdeaScale}
                    auth={auth}
                  />
                )}
                {((requestSelfEval && goodIntentLevel === 1) || !requestSelfEval) && (
                  <Stack
                    flexWrap="wrap"
                    flexDirection="row"
                    width={1.0}
                    justifyContent="center"
                    style={{ marginTop: "40px" }}
                  >
                    <Button onClick={() => onSubmit()} color="primary" circular>
                      Share your inTell
                    </Button>
                  </Stack>
                )}
              </>
            )}

            {!submitterIsWriter && (
              <>
                {requestAgents && (
                  <SelectAgent
                    selectedAgents={selectedAgents}
                    setSelectedAgents={setSelectedAgents}
                    title="Who is the author(s) of this InTell and their affiliations?"
                    subTitle="For example: Tucker Carlson, CNN, Reuters, etc."
                  />
                )}
                {(selectedAgents.length > 0 || !requestAgents) && (
                  <Stack
                    flexWrap="wrap"
                    flexDirection="row"
                    width={1.0}
                    justifyContent="center"
                    style={{ marginTop: "40px" }}
                  >
                    <Button onClick={() => onSubmit()} color="primary" circular>
                      Share your inTell
                    </Button>
                  </Stack>
                )}
              </>
            )}
          </>
        )}

      <div ref={divRef} />
    </>
  );
};

CreateWebContent.propTypes = {
  //  initUrl: PropTypes.string,
  requestImage: PropTypes.bool,
  requestSelfEval: PropTypes.bool,
  initSelection: PropTypes.number,
  newCreatedIdea: PropTypes.func,
  activity: PropTypes.oneOfType([PropTypes.object]),
  setting: PropTypes.oneOfType([PropTypes.object]),
  addIdeaScale: PropTypes.oneOfType([PropTypes.object]),
  auth: PropTypes.oneOfType([PropTypes.object]),
};

CreateWebContent.defaultProps = {
  //  initUrl: "",
  requestImage: true,
  requestSelfEval: true,
  initSelection: 0,
  newCreatedIdea: null,
  activity: null,
  setting: null,
  addIdeaScale: null,
  auth: null,
};

const mapStateToProps = (state) => ({
  idea: state.idea,
});

export default connect(mapStateToProps, { addIdea, modifyActivity, setAlert })(CreateWebContent);
