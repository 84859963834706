import React from "react";
import { useNavigate, useParams } from "react-router-dom";

// MUI
import { Container } from "@mui/material";

import NavbarTop from "../navbar/NavbarTop";
import CreateMyContent from "./CreateMyContent";

const Create1 = () => {
  const { mode, field } = useParams();

  const navigate = useNavigate();

  // Exit strategy to exit this component and scale new inTell
  const onNewCreatedIdea = (e) => {
    if (e) {
      // New inTell created
      console.log("onNewCreatedIdea", e);

      // Create a link after new agent is scaled
      if (mode && field && field !== "10") {
        navigate(`/idea/${e._id}/${mode}/${field}`);
      } else {
        // No additional link requested to be added
        navigate(`/idea/${e._id}`);
      }
    }
  };

  return (
    <>
      <NavbarTop transparent />
      <Container maxWidth="sm" style={{ marginTop: "60px" }}>
        <CreateMyContent newCreatedIdea={onNewCreatedIdea} noUploads={false} />
      </Container>
    </>
  );
};

export default Create1;
