import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { logout } from "../../actions/auth";

const Logout = ({ logout, auth }) => {
  // Trigger on component mount
  useEffect(() => {
    console.log("auth is", auth);
    if (auth.isAuthenticated) {
      console.log("Logging out");
      logout();
    }
  }, []);

  if (!auth.isAuthenticated) {
    console.log("Goto /login");
    return <Navigate to="/login" />;
  }

  return <p>Logging out...</p>;
};

Logout.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Logout);
