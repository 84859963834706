import React from "react";
import PropTypes from "prop-types";

// MUI
import { Checkbox } from "@mui/material";
import Input from "components/MaterialKit/MKInput";

import View102 from "./View102";
import LabelText from "../styles/LabelText";

const View1 = ({ activity }) => {
  return (
    <>
      {activity.instructions1 && activity.instructions1.length > 0 && (
        <div style={{ marginTop: "10px" }}>
          <Input
            disabled
            type="instructions1"
            label="Additional instructions to students"
            fullWidth
            multiline
            variant="outlined"
            value={activity.instructions1}
          />
        </div>
      )}

      <LabelText>
        <Checkbox disabled checked={activity.newInTellSelfEvaluate} />
        Request student to self-evaluate his/her own submission prior to submitting
      </LabelText>

      <LabelText>
        <Checkbox disabled checked={activity.afterOptionInterpretNewInTell} />
        [CHAIN EVENT] Add new activity after this inTell is created: INTERPRET newly created inTell
      </LabelText>

      {activity.afterOptionInterpretNewInTell && (
        <div style={{ paddingLeft: "32px" }}>
          <View102 activity={activity} />
        </div>
      )}
    </>
  );
};

View1.propTypes = {
  activity: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
};

export default View1;
