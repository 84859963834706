import {
  LINKIDEAIDEA_ERROR,
  ADD_LINKIDEAIDEA,
  GET_LINKIDEAIDEA,
  GET_LINKIDEAIDEAS,
  ADD_LINKIDEAIDEA_SCALE,
  LOADING_LINKIDEAIDEA,
  LINK_ERROR,
} from "../actions/types";

const initialState = {
  linkIdeaIdeas: [],
  linkIdeaIdea: null,
  loading: true,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_LINKIDEAIDEAS:
      return {
        ...state,
        linkIdeaIdeas: payload,
        loading: false,
      };
    case GET_LINKIDEAIDEA:
    case ADD_LINKIDEAIDEA_SCALE:
    case ADD_LINKIDEAIDEA:
      return {
        ...state,
        linkIdeaIdea: payload,
        linkIdeaIdeas: [],
        loading: false,
      };
    case LINKIDEAIDEA_ERROR:
    case LINK_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case LOADING_LINKIDEAIDEA:
      return {
        ...state,
        linkIdeaIdeas: [],
        linkIdeaIdea: null,
        loading: true,
      };
    default:
      return state;
  }
}
