import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// MUI
import Box from "components/MaterialKit/MKBox";
import { Checkbox, Radio } from "@mui/material";
import { styled } from "@mui/system";

import LabelText from "../styles/LabelText";

const DivisiveIntent = ({ divisiveReasons, setDivisiveReasons, isDivisive, setIsDivisive }) => {
  const VerticalListBox = styled(Box)(({ theme }) =>
    theme.unstable_sx({
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: isDivisive > 0 ? 720 : 80,
    })
  );

  const [reason0, setReason0] = useState(false);
  const [reason1, setReason1] = useState(false);
  const [reason2, setReason2] = useState(false);
  const [reason3, setReason3] = useState(false);

  useEffect(() => {
    // Convert to 4 digit string binary representation: Reason3,Reason2,Reason1,Reason0 = base2[0],base2[1],base2[2],base2[3]
    const a = divisiveReasons.toString(2);
    const base2 = "0".repeat(4 - a.length) + a;
    //  0123
    setReason0(base2[3] === "1"); // ...X (1)
    setReason1(base2[2] === "1"); // ..X. (2)
    setReason2(base2[1] === "1"); // .X.. (4)
    setReason3(base2[0] === "1"); // X... (8)
  }, [divisiveReasons]);

  useEffect(() => {
    setDivisiveReasons(
      Number(reason0 ? 1 : 0) +
        (reason1 ? 1 : 0) * 2 +
        (reason2 ? 1 : 0) * 4 +
        (reason3 ? 1 : 0) * 8
    );
  }, [reason0, reason1, reason2, reason3]);

  const handleIsDivisive = (e) => {
    setIsDivisive(e);
    if (e <= 0) {
      setReason0(false);
      setReason1(false);
      setReason2(false);
      setReason3(false);
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <VerticalListBox>
        <div style={{ textAlign: "left" }}>
          <div style={{ marginBottom: "-7px" }}>
            <LabelText>
              <Radio checked={isDivisive === -1} onClick={() => handleIsDivisive(-1)} />
              No
            </LabelText>
          </div>

          <div style={{ marginBottom: "-7px" }}>
            <LabelText>
              <Radio checked={isDivisive === 1} onClick={() => handleIsDivisive(1)} />
              {isDivisive === 1 ? "Yes; select all that apply:" : "Yes"}
            </LabelText>
          </div>

          {isDivisive > 0 && (
            <>
              <div style={{ marginBottom: "-7px", marginLeft: "32px" }}>
                <LabelText>
                  <Checkbox onClick={() => setReason0(!reason0)} checked={reason0} />
                  Promotes hate, violence, or suffering
                </LabelText>
              </div>

              <div style={{ marginBottom: "-7px", marginLeft: "32px" }}>
                <LabelText>
                  <Checkbox onClick={() => setReason1(!reason1)} checked={reason1} />
                  Attacks a person’s character (or dehumanizes) rather than debating the issue
                </LabelText>
              </div>

              <div style={{ marginBottom: "-7px", marginLeft: "32px" }}>
                <LabelText>
                  <Checkbox onClick={() => setReason2(!reason2)} checked={reason2} />
                  Claims a moral high ground at the expense of others (virtue signalling)
                </LabelText>
              </div>

              <div style={{ marginBottom: "-7px", marginLeft: "32px" }}>
                <LabelText>
                  <Checkbox onClick={() => setReason3(!reason3)} checked={reason3} />
                  Criticizes someone for arguments that they never made (straw man)
                </LabelText>
              </div>
            </>
          )}
        </div>
      </VerticalListBox>
    </Box>
  );
};

DivisiveIntent.propTypes = {
  divisiveReasons: PropTypes.number,
  setDivisiveReasons: PropTypes.func.isRequired,
  isDivisive: PropTypes.number,
  setIsDivisive: PropTypes.func.isRequired,
};

DivisiveIntent.defaultProps = {
  divisiveReasons: 0,
  isDivisive: 0,
};

export default DivisiveIntent;
