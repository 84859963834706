import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ShowLinkItem from "../idea/ShowLinkItem";

// PROPS
// Name              Type     Description
// relatedAgentLinks array    List of linkAgentAgents
// relatedIdeaLinks  array    List of linkIdeaAgents
// parentAgentID     string   ID of parent ID
// cardColor         string   Display color of linked agents (i.e. "lightgreen"...)
// --------------------------------------------
// Props used (from calling components):
// Agent: [relatedAgentLinks] [relatedIdeaLinks] [parentAgentID] cardColor
// --------------------------------------------
// ACTIONS
// - no input/output requests to backend
// - via component ShowLinkItem, display linked agents

const ShowLinkList = ({ relatedAgentLinks, relatedIdeaLinks, parentAgentID, cardColor }) => {
  console.log("relatedAgentLinks:", relatedAgentLinks);
  console.log("relatedIdeaLinks:", relatedIdeaLinks);

  return (
    <>
      {relatedAgentLinks && (
        <>
          {parentAgentID &&
            relatedAgentLinks.map((e) => (
              <ShowLinkItem
                key={e.agent1._id === parentAgentID ? e.agent2._id : e.agent1._id}
                showAgent={e.agent1._id === parentAgentID ? e.agent2 : e.agent1}
                linkAgentAgent={e}
                cardColor={cardColor}
              />
            ))}
          {!parentAgentID &&
            relatedAgentLinks.map((e) => (
              <ShowLinkItem key={e._id} showAgent={e} cardColor={cardColor} />
            ))}
        </>
      )}

      {relatedIdeaLinks && (
        <>
          {parentAgentID &&
            relatedIdeaLinks.map((e) => (
              <ShowLinkItem
                key={e.idea._id}
                showIdea={e.idea}
                linkIdeaAgent={e}
                cardColor={cardColor}
              />
            ))}
          {!parentAgentID &&
            relatedIdeaLinks.map((e) => (
              <ShowLinkItem key={e._id} showIdea={e} cardColor={cardColor} />
            ))}
        </>
      )}
    </>
  );
};

ShowLinkList.propTypes = {
  relatedIdeaLinks: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  relatedAgentLinks: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  parentAgentID: PropTypes.string,
  cardColor: PropTypes.string.isRequired,
};

ShowLinkList.defaultProps = {
  relatedIdeaLinks: [],
  relatedAgentLinks: [],
  parentAgentID: "",
};

export default connect()(ShowLinkList);
