import Container from "@mui/material/Container";

import Typography from "components/MaterialKit/MKTypography";
import { Grid, Card } from "@mui/material";

function Why() {
  return (
    <Container>
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="flex-start"
        sx={{ mt: "0px" }}
      >
        <Grid item xs={12} sm={4}>
          <Card style={{ padding: "10px", textAlign: "center", backgroundColor: "#E5F1FD" }}>
            <Typography variant="h6">Humility</Typography>
            <Typography
              variant="body2"
              sx={({ breakpoints }) => ({
                [breakpoints.down("md")]: {
                  fontSize: "14px",
                },
              })}
            >
              Who can know everything?
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card style={{ padding: "10px", textAlign: "center", backgroundColor: "#E5F1FD" }}>
            <Typography variant="h6">Diversity of opinion</Typography>
            <Typography
              variant="body2"
              sx={({ breakpoints }) => ({
                [breakpoints.down("md")]: {
                  fontSize: "14px",
                },
              })}
            >
              Can you taste all the perspectives?
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card style={{ padding: "10px", textAlign: "center", backgroundColor: "#E5F1FD" }}>
            <Typography variant="h6">Curiosity</Typography>
            <Typography
              variant="body2"
              sx={({ breakpoints }) => ({
                [breakpoints.down("md")]: {
                  fontSize: "14px",
                },
              })}
            >
              Like seriously, why?
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Why;
