import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";

// MUI
import { Checkbox, Stack } from "@mui/material";
import Button from "components/MaterialKit/MKButton";
import Box from "components/MaterialKit/MKBox";
import Typography from "components/MaterialKit/MKTypography";

import Spinner from "../layout/Spinner";
import MultiSelect from "../styles/MultiSelect";
import { searchAgents } from "../../actions/agent";
import DialogCreateAgent from "../dialog/DialogCreateAgent";
import QuestionText from "../styles/QuestionText";
import LabelText from "../styles/LabelText";

const SelectAgent = ({
  selectedAgents,
  // ignoreAgents,
  setSelectedAgents,
  NA,
  setNA,
  title,
  subTitle,
  searchAgents,
  agent: { agent, agents, loading },
}) => {
  // Refresh componenent whenever a new agent is created
  // (Failing to do so, the <Select> component will not update the new agent created)
  const [ready, setReady] = useState(false);
  useEffect(() => {
    setReady(true);
  }, [selectedAgents]);

  // console.log("ignoreAgents",ignoreAgents);

  // Run on first render only
  useEffect(() => {
    searchAgents("", 10);
  }, []);

  // Option list will be updated any time <agents> changes
  const [options, setOptions] = useState([]);
  useEffect(() => {
    //    console.log("agents",agents);
    const newOptions = agents.map((e) => ({
      value: e._id,
      label: e.name,
    }));
    //      .filter((x) => !ignoreAgents.filter((y) => y.value !== x._value).length);

    setOptions(newOptions);
  }, [agents, agent]);

  // Update <agents> when user types in search box
  const handleInputChange = (e) => {
    searchAgents(e, 10);
  };

  // Add agent when user selects an agent from a dropdown list (or removes an agent from the selection list)
  const handleOnChange = (e) => {
    if (e) {
      setSelectedAgents(e);
    }
  };

  const [newAgentDialog, setNewAgentDialog] = useState(false);
  const onCreatedNewAgent = (e) => {
    setNewAgentDialog(false);
    setReady(false);
    const newDefaultValue = [...selectedAgents];
    newDefaultValue.push({ label: e.name, value: e._id });
    setSelectedAgents(newDefaultValue);
  };

  return loading || agents === null || !ready ? (
    <Spinner />
  ) : (
    <Box
      sx={{ width: "100%", marginTop: "20px" }}
      bgColor="white"
      borderRadius="lg"
      shadow="lg"
      p={2}
    >
      <QuestionText>{title}</QuestionText>
      <Typography style={{ fontSize: "10px" }}>{subTitle}</Typography>

      {!NA && !newAgentDialog && (
        <div style={{ marginTop: "20px" }}>
          <Select
            options={options}
            isMulti
            placeholder="Select waveMaker..."
            onChange={handleOnChange}
            onInputChange={handleInputChange}
            defaultValue={selectedAgents}
            styles={MultiSelect}
            noOptionsMessage={() => (
              <Typography textTransform="uppercase" style={{ fontSize: "14px" }}>
                No matching search results found
              </Typography>
            )}
          />
        </div>
      )}

      {/* Shown N/A box only if function setNA was provided by parent */}
      {setNA && selectedAgents.length === 0 && (
        <div style={{ marginTop: "20px", marginBottom: "-10px", textAlign: "center" }}>
          <LabelText>
            <Checkbox onClick={() => setNA(!NA)} checked={NA} />
            Skip this step
          </LabelText>
        </div>
      )}

      {!NA && (
        <Stack
          flexWrap="wrap"
          flexDirection="row"
          width={1.0}
          justifyContent="center"
          style={{ marginTop: "20px" }}
        >
          <Button onClick={() => setNewAgentDialog(true)} color="primary" variant="text">
            The waveMaker I want to select is not in the dropdown list above
          </Button>
        </Stack>
      )}

      {/* DIALOG */}
      {newAgentDialog && (
        <DialogCreateAgent
          newCreatedAgent={onCreatedNewAgent}
          handleDialogClose={() => setNewAgentDialog(false)}
        />
      )}
    </Box>
  );
};

SelectAgent.propTypes = {
  selectedAgents: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  //  ignoreAgents: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  title: PropTypes.string,
  subTitle: PropTypes.string,
  setSelectedAgents: PropTypes.func.isRequired,
  NA: PropTypes.bool,
  setNA: PropTypes.func,
};

SelectAgent.defaultProps = {
  //  ignoreAgents: [],
  title: "",
  subTitle: "",
  NA: false,
  setNA: null,
};

const mapStateToProps = (state) => ({
  agent: state.agent,
});

export default connect(mapStateToProps, { searchAgents })(SelectAgent);
