import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// MUI
import Button from "components/MaterialKit/MKButton";
import Box from "components/MaterialKit/MKBox";
import { Container } from "@mui/material";

import Spinner from "../layout/Spinner";
import IdeaDetail from "../idea/IdeaDetail";
import NavbarTop from "../navbar/NavbarTop";
import { modifyActivity } from "../../actions/activity";

const Interpret100 = ({ activity, numericalLabel, modifyActivity, auth }) => {
  const [submitting, setSubmitting] = useState(false);
  const onSubmit = () => {
    setSubmitting(true);
    modifyActivity({
      activityID: activity._id,
      studentIDCompleted: auth.user._id,
    });
  };

  return submitting ? (
    <Spinner />
  ) : (
    <>
      <NavbarTop title={`ACTIVITY #${numericalLabel}`} content1="Instructions" />

      <Container
        sx={{
          top: 0,
          zIndex: 10,
          mt: { xs: 6, sm: 9 },
        }}
        maxWidth="sm"
      >
        <Box sx={{ width: "100%", marginTop: "0px" }}>
          <IdeaDetail
            instructions
            idea={activity.inTellExisting}
            hrefHiddenInternal
            hrefHiddenExternal
            greyOut
            showExtended
          />
        </Box>
      </Container>

      <div style={{ marginTop: "60px", marginBottom: "40px", textAlign: "center" }}>
        <Button onClick={() => onSubmit()} color="primary" circular>
          Continue
        </Button>
      </div>
    </>
  );
};

Interpret100.propTypes = {
  activity: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
  numericalLabel: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { modifyActivity })(Interpret100);
