import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";

// MUI
import Typography from "components/MaterialKit/MKTypography";
import Button from "components/MaterialKit/MKButton";
import { Paper, Checkbox, Container, Radio, Stack } from "@mui/material";
import Box from "components/MaterialKit/MKBox";
import Input from "components/MaterialKit/MKInput";

import Create1 from "./Create1";
import Create102 from "./Create102";
import { searchIdeas } from "../../actions/idea";
import { addActivity } from "../../actions/activity";
import { addSetting, modifySetting } from "../../actions/setting";
import LabelText from "../styles/LabelText";
import MultiSelect from "../styles/MultiSelect";
import NavbarTop from "../navbar/NavbarTop";
import DialogMessage from "../dialog/DialogMessage";
import SelectAgent from "../create/SelectAgent";

const Create = ({
  renderReady,
  setting,
  addSetting,
  modifySetting,
  addActivity,
  searchIdeas,
  idea: { idea, ideas },
}) => {
  const [defaultWaveMakers, setDefaultWaveMakers] = useState([]);
  useEffect(() => {
    if (setting && setting.defaultWaveMakers && setting.defaultWaveMakers.length > 0) {
      setDefaultWaveMakers(setting.defaultWaveMakers.map((e) => ({ value: e._id, label: e.name })));
    } else if (setting && setting.defaultWaveMakers && setting.defaultWaveMakers.length === 0) {
      setDefaultWaveMakers([]);
    }
  }, [setting]);

  const divRef = useRef(null);
  useEffect(() => {
    if (divRef && divRef.current && divRef.current.scrollIntoView) {
      divRef.current.scrollIntoView({ behavior: "smooth" });
    }
  });

  const [newInTellSelfEvaluate, setNewInTellSelfEvaluate] = useState(true);
  const [interpretConfirmDeeperUnderstanding, setInterpretConfirmDeeperUnderstanding] =
    useState(true);
  const [interpretEvaluateValues, setInterpretEvaluateValues] = useState(true);
  const [interpretPauseEmotionFlow, setInterpretPauseEmotionFlow] = useState(true);
  const [interpretIntentWords, setInterpretIntentWords] = useState("");
  const [afterOptionInterpretNewInTell, setAfterOptionInterpretNewInTell] = useState(false);
  const [afterOptionInterconnectNewInTell, setAfterOptionInterconnectNewInTell] = useState(false);
  const [afterOptionAddNewIntellAfter, setAfterOptionAddNewIntellAfter] = useState(false);
  const [instructions1, setInstructions1] = useState("");
  const [instructions101, setInstructions101] = useState("");
  const [instructions102, setInstructions102] = useState("");
  const [instructions201, setInstructions201] = useState("");

  // Run on first render only
  useEffect(() => {
    searchIdeas("", 10);
  }, []);

  // 1: Add inTell [activity.inTellNew], with options to:
  //    - Create an additional student activity to interpret newly created inTell [activity.inTellNew]
  //    - Create a support/oppose link with newly created inTell [activity.inTellNew] (cause) to existing inTell [activity.communityTopic] (effect)
  //      ... FOLLOWED by creating an additional student activity to interpret this newly created link [activity.linkIdeaIdea]
  // *11: ExpanSighting
  // *12: Key point summary of entirety
  // *13: Key point summary of quote
  // 101: Interpret guiding question [activity.communityTopic], with options to add inTell (#1)
  // 102: Interpret existing intTell [activity.inTellExisting]
  // Interconnect pre-existing inTell activity.inTellExisting (cause) to activity.communityTopic  (effect) [201-300]
  const [selection, setSelection] = useState(-1);

  const handleDefaultWaveMakers = (e) => {
    if (!setting) {
      //    console.log("addSetting");
      addSetting({
        name: "Original",
        defaultWaveMakers: e.map((f) => ({ _id: f.value, name: f.label })),
      });
    } else {
      //    console.log("modifySetting");
      modifySetting({
        settingID: setting._id,
        defaultWaveMakers: e.map((f) => ({ _id: f.value, name: f.label })),
      });
    }
    //  setDefaultWaveMakers(e);
  };

  const [communityTopic, setCommunityTopic] = useState(null);
  const [inTellExisting, setInTellExisting] = useState(null);
  //  console.log("communityTopic:", communityTopic);
  //  console.log("inTellExisting:", inTellExisting);

  const onRadioButtonChange = (e) => {
    setSelection(e);
    setCommunityTopic(null);
    setInTellExisting(null);
    setNewInTellSelfEvaluate(true);
    setInterpretPauseEmotionFlow(true);
    setInterpretIntentWords("");
    setInterpretConfirmDeeperUnderstanding(true);
    setInterpretEvaluateValues(true);
    setAfterOptionInterpretNewInTell(false);
    setAfterOptionInterconnectNewInTell(false);
    setAfterOptionAddNewIntellAfter(false);
    setInstructions1("");
    setInstructions101("");
    setInstructions102("");
    setInstructions201("");
  };

  // Whenever afterOptionInterconnectNewInTell changes, nullify communityTopic
  //  useEffect(() => {
  //  i      console.log("useEffect1");
  //    setCommunityTopic(null);
  //  }, [afterOptionInterconnectNewInTell]);

  // Option list will be updated any time <ideas> changes
  const [options, setOptions] = useState([]);
  useEffect(() => {
    //  console.log("useEffect2");
    const newOptions = ideas
      .filter((e) => e._id !== communityTopic) // cannot select existing inTell
      .filter((e) => e._id !== inTellExisting) // cannot select existing inTell
      .map((e) => ({
        value: e._id,
        label: e.myPoint ? e.myPoint : e.text ? (e.url ? e.text : `"${e.text}"`) : e.myContent,
      }));
    setOptions(newOptions);
  }, [ideas, idea, communityTopic]);

  // Update <ideas> when user types in search box
  const handleInputChange = (e) => {
    //  console.log("handleInputChange");
    searchIdeas(e.replaceAll("\\", ""), 10);
  };

  // Fill idea into box when user selects an idea from a dropdown list (or removes an idea from the selection list)
  const handleOnChangeCommunityTopic = (e) => {
    //  console.log("handleOnChangeCommunityTopic");
    if (e) {
      // User selected idea from list
      setCommunityTopic(e.value);
    }
  };

  const handleOnChangeExisting = (e) => {
    //  console.log("handleOnChangeNew");
    if (e) {
      // User selected idea from list
      setInTellExisting(e.value);
    }
  };

  const onSubmit = () => {
    if (selection === 201) {
      // 201: Create new linkIdeaIdea first -> add activity reference new linkIdeaIdea
      addActivity({
        linkIdeaIdea: {
          ideaEffect: communityTopic, // guiding question
          ideaCause: inTellExisting, // inTell being linked
        },
        instructions201,
        selection: 201,
        inTellExisting,
        communityTopic,
      });
    } else if (selection === 102 && afterOptionInterconnectNewInTell) {
      addActivity({
        linkIdeaIdea: {
          ideaEffect: communityTopic, // guiding question
          ideaCause: inTellExisting, // inTell being linked
        },
        instructions1,
        instructions101,
        instructions102,
        instructions201,
        communityTopic,
        inTellExisting,
        selection,
        newInTellSelfEvaluate,
        interpretConfirmDeeperUnderstanding,
        interpretEvaluateValues,
        interpretPauseEmotionFlow,
        interpretIntentWords,
        afterOptionInterpretNewInTell,
        afterOptionInterconnectNewInTell,
        afterOptionAddNewIntellAfter,
      });
    } else {
      addActivity({
        instructions1,
        instructions101,
        instructions102,
        instructions201,
        communityTopic,
        inTellExisting,
        selection,
        newInTellSelfEvaluate,
        interpretConfirmDeeperUnderstanding,
        interpretEvaluateValues,
        interpretPauseEmotionFlow,
        interpretIntentWords,
        afterOptionInterpretNewInTell,
        afterOptionInterconnectNewInTell,
        afterOptionAddNewIntellAfter,
      });
    }
    renderReady(false);
  };

  const [helpDialog, setHelpDialog] = useState(false);

  return (
    <>
      <NavbarTop title="Request student..." />
      <Container maxWidth="sm" sx={{ mb: 6, mt: { xs: 2, sm: 5 } }}>
        <LabelText>
          <Radio checked={selection === 1} onClick={() => onRadioButtonChange(1)} />
          ADD &quot;new&quot; inTell (which does not yet exist in the platform)
        </LabelText>

        {/*     <LabelText>  */}
        {/*          <Radio checked={selection === 11} disabled onClick={() => onRadioButtonChange(11)} /> */}
        {/*          ADD &quot;expanSighting&quot; inTell (referencing pre-existing inTell) */}
        {/*        </LabelText> */}

        {/*        <LabelText> */}
        {/*          <Radio checked={selection === 12} disabled onClick={() => onRadioButtonChange(12)} /> */}
        {/*          ADD &quot;key point summary of entirety&quot; inTell (referencing the entirety of */}
        {/*          pre-existing inTell) */}
        {/*        </LabelText> */}

        {/*        <LabelText> */}
        {/*          <Radio checked={selection === 13} disabled onClick={() => onRadioButtonChange(13)} /> */}
        {/*          ADD &quot;key point summary of quote&quot; inTell (referencing a quoted section of */}
        {/*          pre-existing inTell) */}
        {/*        </LabelText> */}

        <LabelText>
          <Radio checked={selection === 101} onClick={() => onRadioButtonChange(101)} />
          INTERPRET guiding question with support/oppose (referencing it as pre-existing inTell)
        </LabelText>

        <LabelText>
          <Radio checked={selection === 102} onClick={() => onRadioButtonChange(102)} />
          INTERPRET inTell (referencing pre-existing inTell)
        </LabelText>

        <LabelText>
          <Radio checked={selection === 201} onClick={() => onRadioButtonChange(201)} />
          INTERCONNECT inTell to guiding question (Support ... Oppose)
        </LabelText>

        <hr style={{ marginTop: "10px", marginBottom: "10px" }} />

        <LabelText>
          <Radio checked={selection === 100} onClick={() => onRadioButtonChange(100)} />
          INFO message for students (referencing it as pre-existing inTell)
        </LabelText>

        <LabelText>
          <Radio checked={selection === 0} onClick={() => onRadioButtonChange(0)} />
          Default waveMaker list
        </LabelText>

        <br />

        {selection === 0 && (
          <SelectAgent
            selectedAgents={defaultWaveMakers}
            setSelectedAgents={handleDefaultWaveMakers}
            title="Select the waveMakers that will be used as the default waveMaker list for all these activities."
          />
        )}

        {selection > 0 && (
          <Paper style={{ padding: "15px" }}>
            {selection === 1 && (
              <>
                <Create1
                  instructions1={instructions1}
                  instructions102={instructions102}
                  newInTellSelfEvaluate={newInTellSelfEvaluate}
                  afterOptionInterpretNewInTell={afterOptionInterpretNewInTell}
                  afterOptionInterconnectNewInTell={afterOptionInterconnectNewInTell}
                  interpretConfirmDeeperUnderstanding={interpretConfirmDeeperUnderstanding}
                  interpretEvaluateValues={interpretEvaluateValues}
                  interpretPauseEmotionFlow={interpretPauseEmotionFlow}
                  options={options}
                  setInstructions1={setInstructions1}
                  setInstructions102={setInstructions102}
                  setNewInTellSelfEvaluate={setNewInTellSelfEvaluate}
                  setAfterOptionInterpretNewInTell={setAfterOptionInterpretNewInTell}
                  setAfterOptionInterconnectNewInTell={setAfterOptionInterconnectNewInTell}
                  setInterpretConfirmDeeperUnderstanding={setInterpretConfirmDeeperUnderstanding}
                  setInterpretEvaluateValues={setInterpretEvaluateValues}
                  setInterpretPauseEmotionFlow={setInterpretPauseEmotionFlow}
                  setInterpretIntentWords={setInterpretIntentWords}
                  handleOnChange={handleOnChangeCommunityTopic}
                  handleInputChange={handleInputChange}
                />
                <Stack
                  flexWrap="wrap"
                  justifyContent="center"
                  flexDirection="row"
                  width={1.0}
                  style={{ paddingTop: "40px" }}
                >
                  {((afterOptionInterconnectNewInTell && communityTopic) ||
                    !afterOptionInterconnectNewInTell) && (
                    <Button onClick={() => onSubmit()} color="primary" circular>
                      Submit
                    </Button>
                  )}
                  {!(
                    (afterOptionInterconnectNewInTell && communityTopic) ||
                    !afterOptionInterconnectNewInTell
                  ) && (
                    <Button
                      onClick={() => setHelpDialog(true)}
                      color="dark"
                      variant="outlined"
                      circular
                    >
                      Submit
                    </Button>
                  )}
                </Stack>
              </>
            )}

            {selection === 100 && (
              <>
                <Typography style={{ fontSize: "10px" }}>INFO messsage for students</Typography>

                <div style={{ flex: "1" }}>
                  <Select
                    options={options}
                    placeholder="Select pre-existing inTell as INFO message..."
                    onChange={handleOnChangeExisting}
                    onInputChange={handleInputChange}
                    styles={MultiSelect}
                    noOptionsMessage={() => (
                      <Typography textTransform="uppercase" style={{ fontSize: "14px" }}>
                        No matching search results found
                      </Typography>
                    )}
                  />
                </div>

                {inTellExisting && (
                  <Stack
                    flexWrap="wrap"
                    justifyContent="center"
                    flexDirection="row"
                    width={1.0}
                    style={{ paddingTop: "40px" }}
                  >
                    <Button onClick={() => onSubmit()} color="primary" circular>
                      Submit
                    </Button>
                  </Stack>
                )}
                {!inTellExisting && <br />}
              </>
            )}

            {selection === 101 && (
              <>
                <Typography style={{ fontSize: "10px" }}>
                  Community topic to be interpreted
                </Typography>

                <div style={{ flex: "1" }}>
                  <Select
                    options={options}
                    placeholder="Select pre-existing inTell as guiding question..."
                    onChange={handleOnChangeCommunityTopic}
                    onInputChange={handleInputChange}
                    styles={MultiSelect}
                    noOptionsMessage={() => (
                      <Typography textTransform="uppercase" style={{ fontSize: "14px" }}>
                        No matching search results found
                      </Typography>
                    )}
                  />
                </div>

                {communityTopic && (
                  <>
                    <LabelText style={{ marginTop: "20px" }}>
                      Additional prompt for students prior to them interpreting guiding question...
                    </LabelText>
                    <Box
                      onChange={(e) => setInstructions101(e.target.value)}
                      style={{ marginTop: "10px" }}
                    >
                      <Input
                        type="instructions101"
                        label="Specify instructions to students (optional)"
                        fullWidth
                        multiline
                        variant="outlined"
                        value={instructions101}
                        inputProps={{ maxLength: 140 }}
                      />
                    </Box>

                    <LabelText style={{ marginTop: "10px" }}>
                      <Checkbox
                        checked={afterOptionAddNewIntellAfter}
                        onClick={() =>
                          setAfterOptionAddNewIntellAfter(!afterOptionAddNewIntellAfter)
                        }
                      />
                      [CHAIN EVENT] Request student to then ADD their own &quot;new&quot; inTell
                      supporting/opposing the guiding question
                    </LabelText>
                    {afterOptionAddNewIntellAfter && (
                      <div style={{ paddingLeft: "32px" }}>
                        <Create1
                          instructions1={instructions1}
                          instructions102={instructions102}
                          newInTellSelfEvaluate={newInTellSelfEvaluate}
                          afterOptionInterpretNewInTell={afterOptionInterpretNewInTell}
                          afterOptionInterconnectNewInTell={afterOptionInterconnectNewInTell}
                          interpretConfirmDeeperUnderstanding={interpretConfirmDeeperUnderstanding}
                          interpretEvaluateValues={interpretEvaluateValues}
                          interpretPauseEmotionFlow={interpretPauseEmotionFlow}
                          options={options}
                          setInstructions1={setInstructions1}
                          setInstructions102={setInstructions102}
                          setNewInTellSelfEvaluate={setNewInTellSelfEvaluate}
                          setAfterOptionInterpretNewInTell={setAfterOptionInterpretNewInTell}
                          setAfterOptionInterconnectNewInTell={setAfterOptionInterconnectNewInTell}
                          setInterpretConfirmDeeperUnderstanding={
                            setInterpretConfirmDeeperUnderstanding
                          }
                          setInterpretEvaluateValues={setInterpretEvaluateValues}
                          setInterpretPauseEmotionFlow={setInterpretPauseEmotionFlow}
                          setInterpretIntentWords={setInterpretIntentWords}
                          handleInputChange={handleInputChange}
                        />
                      </div>
                    )}
                    <Stack
                      flexWrap="wrap"
                      justifyContent="center"
                      flexDirection="row"
                      width={1.0}
                      style={{ paddingTop: "40px" }}
                    >
                      <Button onClick={() => onSubmit()} color="primary" circular>
                        Submit
                      </Button>
                    </Stack>
                  </>
                )}

                {!communityTopic && <br />}
              </>
            )}

            {selection === 102 && (
              <>
                <Typography style={{ fontSize: "10px" }}>inTell to be interpreted</Typography>
                <div style={{ flex: "1", marginBottom: "20px" }}>
                  <Select
                    options={options}
                    placeholder="Select pre-existing inTell..."
                    onChange={handleOnChangeExisting}
                    onInputChange={handleInputChange}
                    styles={MultiSelect}
                    noOptionsMessage={() => (
                      <Typography textTransform="uppercase" style={{ fontSize: "14px" }}>
                        No matching search results found
                      </Typography>
                    )}
                  />
                </div>

                {inTellExisting && (
                  <>
                    <Create102
                      instructions102={instructions102}
                      setInstructions102={setInstructions102}
                      interpretConfirmDeeperUnderstanding={interpretConfirmDeeperUnderstanding}
                      interpretEvaluateValues={interpretEvaluateValues}
                      interpretPauseEmotionFlow={interpretPauseEmotionFlow}
                      setInterpretConfirmDeeperUnderstanding={
                        setInterpretConfirmDeeperUnderstanding
                      }
                      options={options}
                      afterOptionInterconnectNewInTell={afterOptionInterconnectNewInTell}
                      setAfterOptionInterconnectNewInTell={setAfterOptionInterconnectNewInTell}
                      setInterpretEvaluateValues={setInterpretEvaluateValues}
                      setInterpretPauseEmotionFlow={setInterpretPauseEmotionFlow}
                      setInterpretIntentWords={setInterpretIntentWords}
                      handleOnChange={handleOnChangeCommunityTopic}
                      handleInputChange={handleInputChange}
                    />
                    <Stack
                      flexWrap="wrap"
                      justifyContent="center"
                      flexDirection="row"
                      width={1.0}
                      style={{ paddingTop: "40px" }}
                    >
                      {((afterOptionInterconnectNewInTell && communityTopic) ||
                        !afterOptionInterconnectNewInTell) && (
                        <Button onClick={() => onSubmit()} color="primary" circular>
                          Submit
                        </Button>
                      )}
                      {!(
                        (afterOptionInterconnectNewInTell && communityTopic) ||
                        !afterOptionInterconnectNewInTell
                      ) && (
                        <Button
                          onClick={() => setHelpDialog(true)}
                          color="dark"
                          variant="outlined"
                          circular
                        >
                          Submit
                        </Button>
                      )}
                    </Stack>
                  </>
                )}
                {!communityTopic && (
                  <>
                    <br />
                  </>
                )}
              </>
            )}

            {selection === 201 && (
              <>
                <Typography style={{ fontSize: "10px" }}>Community topic</Typography>
                <div style={{ flex: "1" }}>
                  <Select
                    options={options}
                    placeholder="Select pre-existing inTell as guiding question..."
                    onChange={handleOnChangeCommunityTopic}
                    onInputChange={handleInputChange}
                    styles={MultiSelect}
                    noOptionsMessage={() => (
                      <Typography textTransform="uppercase" style={{ fontSize: "14px" }}>
                        No matching search results found
                      </Typography>
                    )}
                  />
                </div>
                <br />
                <Typography style={{ fontSize: "10px" }}>inTell</Typography>
                <div style={{ flex: "1" }}>
                  <Select
                    options={options}
                    placeholder="Select pre-existing inTell..."
                    onChange={handleOnChangeExisting}
                    onInputChange={handleInputChange}
                    styles={MultiSelect}
                    noOptionsMessage={() => (
                      <Typography textTransform="uppercase" style={{ fontSize: "14px" }}>
                        No matching search results found
                      </Typography>
                    )}
                  />
                </div>
                {communityTopic && inTellExisting && (
                  <>
                    <LabelText style={{ marginTop: "50px" }}>
                      Additional prompt for students prior to them linking these inTells...
                    </LabelText>
                    <Box
                      onChange={(e) => setInstructions201(e.target.value)}
                      style={{ marginTop: "10px" }}
                    >
                      <Input
                        type="instructions201"
                        label="Specify instructions to students (optional)"
                        fullWidth
                        multiline
                        variant="outlined"
                        value={instructions201}
                        inputProps={{ maxLength: 140 }}
                      />
                    </Box>

                    <Stack
                      flexWrap="wrap"
                      justifyContent="center"
                      flexDirection="row"
                      width={1.0}
                      style={{ paddingTop: "40px" }}
                    >
                      <Button onClick={() => onSubmit()} color="primary" circular>
                        Submit
                      </Button>
                    </Stack>
                  </>
                )}
                {!(communityTopic && inTellExisting) && (
                  <>
                    <br />
                  </>
                )}
              </>
            )}
          </Paper>
        )}

        {helpDialog && (
          <DialogMessage communityTopic handleDialogClose={() => setHelpDialog(false)} />
        )}
      </Container>
      <div ref={divRef} />
    </>
  );
};

Create.propTypes = {
  renderReady: PropTypes.func.isRequired,
  setting: PropTypes.oneOfType([PropTypes.object]),
};

Create.defaultProps = {
  setting: null,
};

const mapStateToProps = (state) => ({
  idea: state.idea,
});

export default connect(mapStateToProps, { searchIdeas, addActivity, addSetting, modifySetting })(
  Create
);
