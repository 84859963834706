import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// MUI
import { Container, Icon, Grow } from "@mui/material";
import { styled } from "@mui/system";
import MKBox from "components/MaterialKit/MKBox";
import MKTypography from "components/MaterialKit/MKTypography";

import { EDUCATION_BUILD } from "config/personalize";
import inSightLogoBlack from "assets/images/icons/logo.svg";
import inSightLogoWhite from "assets/images/icons/insight-icon-white.png";
import Spinner from "../layout/Spinner";
import Dropdown from "./Dropdown";
import RoutesStudent from "./RoutesStudent";
import RoutesAdmin from "./RoutesAdmin";

const RoundedBox = styled(MKBox)(({ theme }) =>
  theme.unstable_sx({
    borderRadius: "0px",
    [theme.breakpoints.up("sm")]: {
      borderRadius: "12px",
    },
  })
);

const NavbarTop = ({ auth, transparent, white, sticky, title, content1, content2 }) => {
  const [routes, setRoutes] = useState(null);
  useEffect(() => {
    if (EDUCATION_BUILD && auth && auth.user) {
      if (auth.user.isStudent) {
        setRoutes(RoutesStudent);
      } else {
        setRoutes(RoutesAdmin(auth.user._id.toString()));
      }
    }
  }, [auth]);

  const [showDropdownNavbar, setShowDropdownNavbar] = useState(false);

  // Routes dropdown menu
  const dropdownMenu = (
    <>
      <Grow
        sx={{
          transformOrigin: "left top",
          background: ({ palette: { white } }) => white.main,
        }}
      >
        <MKBox borderRadius="lg">
          <MKTypography variant="h1" color="white">
            <Icon sx={{ mt: -3 }}>arrow_drop_up</Icon>
          </MKTypography>
        </MKBox>
      </Grow>
    </>
  );

  return routes === null ? (
    <Spinner />
  ) : (
    <Container sx={sticky ? { position: "sticky", top: 0, zIndex: 10 } : null}>
      <RoundedBox
        py={1}
        px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2 }}
        my={{ xs: 0, sm: 2 }}
        mx={{ xs: 0, sm: 3 }}
        width={{ xs: "100%", sm: "calc(100% - 48px)" }}
        shadow={transparent ? "none" : "md"}
        color="dark"
        position="absolute"
        left={0}
        zIndex={13}
        sx={({ palette: { transparent: transparentColor, primary }, functions: { rgba } }) => ({
          backgroundColor: transparent ? transparentColor.main : rgba(primary.main, 1),
          backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
        })}
      >
        <MKBox display="flex" justifyContent="space-between" alignItems="center">
          <MKBox
            lineHeight={1}
            py={transparent ? 1.5 : 0.75}
            pl={transparent ? 0 : { xs: 0, lg: 1 }}
          >
            <img
              src={transparent && !white ? inSightLogoBlack : inSightLogoWhite}
              style={{ width: "22px", opacity: 0.9, marginRight: "3px", marginBottom: "-2px" }}
              alt="Logo loading..."
            />
            <MKTypography
              variant="button"
              fontWeight="bold"
              color={transparent && !white ? "dark" : "white"}
              sx={{
                display: { xs: "none", md: "inline-block" },
              }}
            >
              inSight
            </MKTypography>
          </MKBox>
          <MKBox
            display="inline-block"
            lineHeight={0}
            py={1.5}
            pl={1.5}
            color={transparent && !white ? "dark" : "white"}
            sx={{ cursor: "pointer" }}
            onClick={() => setShowDropdownNavbar(!showDropdownNavbar)}
          >
            <Icon fontSize="default">{showDropdownNavbar ? "close" : "menu"}</Icon>
          </MKBox>
        </MKBox>
        {!transparent && (title.length > 0 || content1.length > 0) && (
          <div style={{ marginTop: "-36px", marginBottom: "5px" }}>
            <MKBox display="flex" justifyContent="center">
              <MKTypography
                variant="h5"
                color="white"
                sx={{ fontSize: { xs: "16px", sm: "18px", md: "20px" } }}
              >
                {title}
              </MKTypography>
            </MKBox>
            <MKBox display="flex" justifyContent="center">
              <MKTypography color="white" sx={{ fontSize: { xs: "14px", md: "16px" }, mt: 1 }}>
                {content1}
              </MKTypography>
            </MKBox>
            <MKBox display="flex" justifyContent="center">
              <MKTypography color="white" sx={{ fontSize: { xs: "11px", md: "12px" }, mt: 0 }}>
                {content2}
              </MKTypography>
            </MKBox>
          </div>
        )}

        <MKBox
          bgColor="white"
          shadow="lg"
          px={2}
          borderRadius="xl"
          width="200px"
          display="flex"
          ml="auto"
        >
          <Dropdown routes={routes} open={showDropdownNavbar} />
        </MKBox>
      </RoundedBox>
      {dropdownMenu}
    </Container>
  );
};

NavbarTop.propTypes = {
  auth: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
  transparent: PropTypes.bool,
  white: PropTypes.bool,
  title: PropTypes.string,
  content1: PropTypes.string,
  content2: PropTypes.string,
  sticky: PropTypes.bool,
};

NavbarTop.defaultProps = {
  transparent: false,
  white: false,
  title: "",
  content1: "",
  content2: "",
  sticky: false,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(NavbarTop);
