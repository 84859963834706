import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "@react-hook/media-query";

// MUI
import Typography from "components/MaterialKit/MKTypography";

import Slider from "./Slider";

const marks = [
  {
    value: 0,
    label: (
      <div style={{ marginRight: "-85px" }}>
        <Typography
          sx={{ fontSize: { xs: "9px", sm: "10px", md: "11px", lg: "12px" }, marginLeft: "25px" }}
        >
          THIS IS 100% ME,
        </Typography>
        <Typography sx={{ fontSize: { xs: "9px", sm: "10px", md: "11px", lg: "12px" } }}>
          I TAKE FULL RESPONSIBILITY
        </Typography>
      </div>
    ),
  },
  {
    value: 1,
    label: (
      <div style={{ marginLeft: "-70px" }}>
        <Typography sx={{ fontSize: { xs: "9px", sm: "10px", md: "11px", lg: "12px" } }}>
          I AM JUST A MESSENGER
        </Typography>
        <Typography
          sx={{ fontSize: { xs: "9px", sm: "10px", md: "11px", lg: "12px" }, marginLeft: "25px" }}
        >
          OF MY SOURCES
        </Typography>
      </div>
    ),
  },
];

const SliderProxy = ({ userInitLevel, updated }) => {
  const smSize = useMediaQuery("only screen and (min-width: 600px");
  const onSliderChange = (e, value) => {
    if (updated) {
      updated(value);
    }
  };

  return (
    // Increments: 0.01
    // Min: 0 (text label)
    // Max: 1 (text label)
    <div style={{ paddingLeft: "20px", paddingRight: "20px", paddingTop: "0px" }}>
      <Slider
        visible={userInitLevel < 0 ? 0 : smSize ? 1 : 0.7}
        step={0.01}
        aria-label="Slider"
        value={userInitLevel}
        min={0}
        max={1}
        onChange={onSliderChange}
        marks={marks}
        disabled={!updated}
      />
    </div>
  );
};

SliderProxy.propTypes = {
  userInitLevel: PropTypes.number.isRequired,
  updated: PropTypes.func,
};

SliderProxy.defaultProps = {
  updated: null,
};

export default SliderProxy;
