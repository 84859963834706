import axios from "axios";
import { setAlert } from "./alert";
import {
  ACTIVITY_ERROR,
  ADD_ACTIVITY,
  MODIFY_ACTIVITY,
  GET_ACTIVITY,
  GET_ACTIVITIES,
  DELETE_ACTIVITY,
  LOADING_ACTIVITY,
} from "./types";

// Loading
export const loadingActivity = () => (dispatch) => {
  dispatch({ type: LOADING_ACTIVITY });
};

// Get all activities
export const getActivities = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/activity`);
    dispatch({
      type: GET_ACTIVITIES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ACTIVITY_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
    dispatch(setAlert(`Failed To Retrieve Activities From Server: ${err.response.data}`, "danger"));
  }
};

// Add new activity
export const addActivity = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.post("/api/activity", formData, config);

    dispatch({
      type: ADD_ACTIVITY,
      payload: res.data,
    });
    //  dispatch(setAlert('Activity Created', 'success', 1000));
  } catch (err) {
    // err.response.status = error code - ie, 400, 404, 500...
    // err.response.statusText = Text meaning associated with err.response.status
    // err.response.data.errors[] = An array of all errors
    const { errors } = err.response.data;
    if (errors) {
      errors.forEach((error) =>
        dispatch(setAlert(`Failed To Add Activity On Server: ${error.msg}`, "danger"))
      );
    }
    dispatch({
      type: ACTIVITY_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Modify existing activity
export const modifyActivity = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.post(`/api/activity/${formData.activityID}`, formData, config);

    dispatch({
      type: MODIFY_ACTIVITY,
      payload: res.data,
    });
  } catch (err) {
    // err.response.status = error code - ie, 400, 404, 500...
    // err.response.statusText = Text meaning associated with err.response.status
    // err.response.data.errors[] = An array of all errors
    const { errors } = err.response.data;
    if (errors) {
      errors.forEach((error) =>
        dispatch(setAlert(`Failed To Modify Activity On Server: ${error.msg}`, "danger"))
      );
    }
    dispatch({
      type: ACTIVITY_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get activity
export const getActivity = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/activity/${id}`);

    dispatch({
      type: GET_ACTIVITY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ACTIVITY_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
    dispatch(setAlert(`Failed To Retrieve Activity From Server: ${err.response.data}`, "danger"));
  }
};

// Delete activity
export const deleteActivity = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`/api/activity/${id}`);
    dispatch({
      type: DELETE_ACTIVITY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ACTIVITY_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
    dispatch(setAlert(`Failed To Delete Activity From Server: ${err.response.data}`, "danger"));
  }
};
