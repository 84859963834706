import React, { useState } from "react";
import PropTypes from "prop-types";

// MUI
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

import Fig1 from "assets/images/view/fig1.png";
import Fig2 from "assets/images/view/fig2.png";
import Fig3 from "assets/images/view/fig3.png";
import Fig4 from "assets/images/view/fig4.png";

const Tour = ({ openModal }) => {
  const [messageCount, setMessageCount] = useState(1);

  return (
    <>
      {messageCount === 1 && (
        <>
          <DialogTitle id="tip-dialog-title">inSight inTell are Linked</DialogTitle>
          <DialogContent>
            <DialogContentText id="tip-dialog-description">
              Like a judge in the courtroom, inSight weighs the evidence – how do the supporting
              arguments compare to the opposing arguments? How credible is each piece of evidence?
              How credible is a witness?
            </DialogContentText>
            <img
              src={Fig1}
              style={{ width: "300px", margin: "auto", display: "block" }}
              alt="Loading..."
            />
            <DialogContentText id="tip-dialog-description">
              At inSight, support for inTell is shown in green and opposition in red. To get
              started, think of roots in an inSight tree as the underlying causes/evidence while
              branches represent the effects/results.
            </DialogContentText>
          </DialogContent>
        </>
      )}

      {messageCount === 2 && (
        <>
          <DialogTitle id="tip-dialog-title">A Tree is Only as Strong as its Roots</DialogTitle>
          <DialogContent>
            <DialogContentText id="tip-dialog-description">
              Supporting roots (green) strengthen the tree while opposing roots (red) weaken the
              tree.
            </DialogContentText>
            <img
              src={Fig2}
              style={{ width: "300px", margin: "auto", display: "block" }}
              alt="Loading..."
            />
            <DialogContentText id="tip-dialog-description">
              As there is stronger support than opposition for the “Fast food is unhealthy” inTell,
              inSight would rate this inTell as reasonably credible.
            </DialogContentText>
          </DialogContent>
        </>
      )}

      {messageCount === 3 && (
        <>
          <DialogTitle id="tip-dialog-title">
            Branches are Implications, Resulting Ideas, Effects...
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="tip-dialog-description">
              inSight is most concerned about comparing support arguments versus opposition
              arguments (regardless of whether they are roots or branches).
            </DialogContentText>
            <img
              src={Fig3}
              style={{ width: "300px", margin: "auto", display: "block" }}
              alt="Loading..."
            />
            <DialogContentText id="tip-dialog-description">
              Sometimes, there is no obvious direction of cause-effect or evidence-result (or the
              direction can even change depending on context). Someone may link supporting inTell as
              a root of the inTell while someone else may link the same inTell as a branch – both
              links are OK.
            </DialogContentText>
          </DialogContent>
        </>
      )}

      {messageCount === 4 && (
        <>
          <DialogTitle id="tip-dialog-title">Navigating an inSight Tree</DialogTitle>
          <DialogContent>
            <img
              src={Fig4}
              style={{ width: "300px", margin: "auto", display: "block" }}
              alt="Loading..."
            />
            <DialogContentText id="tip-dialog-description">
              When you select branch inTell (or root inTell), that selected inTell becomes the
              central inTell when the screen is refreshed. Try it out yourself.
            </DialogContentText>
          </DialogContent>
        </>
      )}

      <DialogActions>
        <i style={{ fontSize: "11px", marginLeft: "20px" }}>{messageCount} of 4</i>

        {/* from https://stackoverflow.com/questions/48919843/left-alignment-of-material-ui-dialog-buttons */}
        <div style={{ flex: "1 0 0" }} />

        {messageCount > 1 && (
          <Button onClick={() => setMessageCount(messageCount - 1)} color="primary">
            Back
          </Button>
        )}

        {messageCount < 4 && (
          <Button onClick={() => setMessageCount(messageCount + 1)} color="primary">
            Next
          </Button>
        )}

        {messageCount === 4 && (
          <Button onClick={() => openModal(false)} color="primary">
            Close
          </Button>
        )}
      </DialogActions>
    </>
  );
};

Tour.propTypes = {
  openModal: PropTypes.func.isRequired,
};

export default Tour;
