import React from "react";
import PropTypes from "prop-types";

// MUI
import { Typography, TextField, Grid, Checkbox } from "@mui/material";

import LabelText from "../styles/LabelText";
import SliderProxy from "../slider/SliderProxy";

const ViewFrame = ({
  atCore,
  frameContext,
  frameResolution,
  myConcern,
  errorCorrection,
  criticContent,
  criticConcern,
  proxyLevel,
}) => {
  return (
    <>
      {/* Frame context */}
      <div style={{ marginTop: "-5px" }}>
        <TextField
          id="frameContext"
          size="small"
          fullWidth
          label="Stepping back, this inTell is really all about..."
          multiline
          variant="filled"
          value={frameContext}
          inputProps={{ style: { fontSize: "14px" } }}
        />
      </div>
      {/* My concern */}
      <div style={{ marginTop: "15px", marginLeft: "15px", marginRight: "15px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm>
            <TextField
              style={{ marginTop: "25px" }}
              id="myConcern"
              size="small"
              fullWidth
              label="What do I care about?"
              multiline
              variant="outlined"
              value={myConcern}
            />

            <TextField
              style={{ marginTop: "15px" }}
              id="errorCorrection"
              size="small"
              fullWidth
              label="I would change my position if..."
              multiline
              variant="outlined"
              value={errorCorrection}
            />
          </Grid>

          <Grid item xs={12} sm>
            <Typography style={{ fontSize: "10px", marginBottom: "10px" }}>
              MY MOST COMPETENT CRITICS...
            </Typography>
            <TextField
              id="criticConcern"
              size="small"
              fullWidth
              label="What do they care about?"
              multiline
              variant="outlined"
              value={criticConcern}
            />

            <TextField
              style={{ marginTop: "15px" }}
              id="criticContent"
              size="small"
              fullWidth
              label="Their best arguments"
              multiline
              variant="outlined"
              value={criticContent}
            />
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: "20px", marginLeft: "15px", marginRight: "15px" }}>
        <Typography style={{ fontSize: "10px", textAlign: "center" }}>
          WHO IS ULTIMATELY RESPONSIBLE FOR THE UNDERYING MESSAGE IN THIS INTELL?
        </Typography>
        <div
          style={{
            marginTop: "5px",
            marginLeft: "15px",
            marginRight: "15px",
            marginBottom: "25px",
          }}
        >
          <SliderProxy userInitLevel={proxyLevel} inactive />
        </div>
      </div>

      {atCore && (
        <LabelText>
          <Checkbox disabled checked style={{ marginTop: "-3px" }} />
          This inTell gets to the core of the issue.
        </LabelText>
      )}

      {/* Frame resolution */}
      <div style={{ marginTop: "15px" }}>
        <TextField
          id="frameResolution"
          size="small"
          fullWidth
          label="The bottom line is..."
          multiline
          variant="filled"
          value={frameResolution}
          inputProps={{ style: { fontSize: "14px" } }}
        />
      </div>
    </>
  );
};

ViewFrame.propTypes = {
  atCore: PropTypes.bool.isRequired,
  frameContext: PropTypes.string.isRequired,
  frameResolution: PropTypes.string.isRequired,
  myConcern: PropTypes.string.isRequired,
  errorCorrection: PropTypes.string.isRequired,
  criticContent: PropTypes.string.isRequired,
  criticConcern: PropTypes.string.isRequired,
  proxyLevel: PropTypes.number.isRequired,
};

export default ViewFrame;
