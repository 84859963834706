import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

// MUI
import { Container } from "@mui/material";

import NavbarTop from "../navbar/NavbarTop";
import InterpretLink from "../meaning/InterpretLink";

const Interpret201 = ({ activity, numericalLabel }) => {
  const [childUpdated, setChildUpdated] = useState(0);
  const divRef = useRef(null);
  useEffect(() => {
    if (childUpdated > 1) {
      setTimeout(() => {
        if (divRef && divRef.current && divRef.current.scrollIntoView) {
          divRef.current.scrollIntoView({ behavior: "smooth" });
        }
        setTimeout(() => {
          if (divRef && divRef.current && divRef.current.scrollIntoView) {
            divRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }, 500);
      }, 500);
    }
  }, [childUpdated]);

  return (
    <>
      <NavbarTop
        title={`ACTIVITY #${numericalLabel}`}
        content1="Community topic damaged or supported?"
        content2={activity.instructions201}
      />

      <Container maxWidth="sm" sx={{ mt: { xs: 9, sm: 12 }, mb: 8 }}>
        <InterpretLink
          activity={activity}
          inTellEffect={activity.communityTopic}
          inTellCause={activity.inTellExisting}
          childUpdated={childUpdated}
          setChildUpdated={setChildUpdated}
        />
      </Container>
      <div ref={divRef} />
    </>
  );
};

Interpret201.propTypes = {
  activity: PropTypes.oneOfType([PropTypes.object]).isRequired,
  numericalLabel: PropTypes.number.isRequired,
};

export default Interpret201;
