import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useSwipeable } from "react-swipeable";

// MUI
import { Slide, Container } from "@mui/material";

import Spinner from "../layout/Spinner";

import NavbarTop from "../navbar/NavbarTop";
import IdeaDetail from "./IdeaDetail";
import TreeBranches from "./TreeBranches";
import TreeRoots from "./TreeRoots";
import RelatedAgents from "./RelatedAgents";
import MoreComplexIdeas from "./MoreComplexIdeas";
import LessComplexIdeas from "./LessComplexIdeas";
// import Chart from "../chart/Chart";
// import Meaning from "./Meaning";
import SearchList from "./SearchList";
import { getIdea, searchIdeas, loadingIdea } from "../../actions/idea";
import { addLinkIdeaIdea } from "../../actions/linkIdeaIdea";
import { addLinkIdeaAgent } from "../../actions/linkIdeaAgent";

// Enters via router: <PrivateRoute exact path='/idea/:id/:mode?/:field?' component={Idea} />
//
// If :mode and :field do not exist, we have simple (non-sequencial) operation
// If :mode and :field exists, create link-idea-idea or link-idea-agent as soon as inTell can be viewed (no longer requires inSighter edit)
//
// EXPLANATION: When we add a new link between inTell/waveMakers, a number of things need to happen:
// - new waveMaker/inTell needs to created [OPTIONAL, as it may simply be a selection as the inTell/waveMaker may already exist]
// - A link needs to be created between inTell in focus and the selected (new) inTell/waveMaker
// - That link needs to interpreted, ideally fully in context (i.e. If adding a support-cause was selected by the inSighter, only that needs to displayed)
// - Ideally, we want to return the view back to the exact context where we intiated the action
//
// Adding a new link therefore requires "sequencing mechanisms" to shift between the modules guiding inSighters seamlessly through that process
// (1) Let's start off in default mode (no special "Mode"). inSighter wishes to add a link.
// (2) He/she selects an Add+ icon, and selects the intell/waveMaker from <SearchList>.
//     - <SearchList> encodes instructions ("mode", see below) and calls the <Agent> or <Idea> component with the selected object:
//     - navigate(`/idea/${e._id}/${mode}/${field}`) or navigate(`/agent/${e._id}/${mode}/${field}`)
//     - As required, <Agent> or <Idea> may require the inTell/waveMaker to be interpreted
//     - Finally, <Idea> (or <Agent>) translate mode instructions into: addLinkIdeaIdea(), addLinkIdeaAgent(), addLinkAgentAgent()
// (2b) Alteratively, <SearchList> can also create a new inTell/waveMaker (ie, navigate(`/create/${mode}/${idea.idea._id}`))
//      - After the new inTell/waveMaker is created (following "mode" instrutions), "mode" instructions are re-transmitted to <Idea> or <Agent> component as:
//      - navigate(`/idea/${e._id}/${mode}/${field}`) or navigate(`/agent/${e._id}/${mode}/${field}`)
//      - As required, <Agent> or <Idea> may require the inTell/waveMaker to be interpreted
//     - Finally, <Idea> (or <Agent>) translate mode instructions into: addLinkIdeaIdea(), addLinkIdeaAgent(), addLinkAgentAgent()
// (3) Once the <Idea> or <Agent> component see a new linkIdeaIdea or linkIdeaAgent or linkAgentAgent, the new link requires interpretation (in respective link <Meaning>)
// (4) Within <Meaning>, when inSighter successfully interprets the link (and if <Meaning> was encoded with an instruction, jump back to <Idea> or <Agent> component
//
// Essentially, this component can be used as a transitionary component:
// (1) Forcing an inSighter to interpret inTell/waveMaker (if necessary)
// (2) Create a link-idea-idea or link-idea-agent or link-agent-agent (and passing off to control to interpret mew link)
//
// Mode explanations:
// ----------------------------------------------------------------------
// Created new inTell (newly created inTell is now in focus), then create link-idea-idea
// Mode 20: Create a support link to specified inTell (field is the id of the specified inTell to link -- now the "effect", original center of attention)
// Mode 21: Create a support link to specified inTell (field is the id of the specified inTell to link -- now the "cause", original center of attention)
// Mode 22: Create an oppose link to specified inTell (field is the id of the specified inTell to link -- now the "effect", original center of attention)
// Mode 23: Create an oppose link to specified inTell (field is the id of the specified inTell to link -- now the "cause", original center of attention)
// Mode 24: Create a complex link to specified inTell (field is the id of the specified inTell to link -- now the "effect", original center of attention)
// Mode 25: Create a complex link to specified inTell (field is the id of the specified inTell to link -- now the "cause", original center of attention)
// Mode 26: Create a similar link to specified inTell (field is the id of the specified inTell to link -- now the "effect", original center of attention)
// Mode 27: Create a similar link to specified inTell (field is the id of the specified inTell to link -- now the "cause", original center of attention)
// ----------------------------------------------------------------------
// Created new inTell (newly created inTell is now in focus), then create link-idea-agent
// Mode 30: Create a support link to specified waveMaker (field is the id of the specified waveMaker to link -- now the "agent", original center of attention)
// Mode 31: Create an oppose link to specified waveMaker (field is the id of the specified waveMaker to link -- now the "agent", original center of attention)
// ----------------------------------------------------------------------
// Created new waveMaker (newly created waveMaker is now in focus), then create link-idea-agent
// Mode 40: Create a support link to specified inTell (field is the id of the specified inTell to link -- now the "idea", original center of attention)
// Mode 41: Create an oppose link to specified inTell (field is the id of the specified inTell to link -- now the "idea", original center of attention)
// ----------------------------------------------------------------------
// Created new waveMaker (newly created waveMaker is now in focus), then create link-agent-agent
// Mode 50: Create a support link to specified waveMaker (field is the id of the specified waveMaker to link -- now the "agent2", original center of attention)
// Mode 51: Create a support link to specified waveMaker (field is the id of the specified waveMaker to link -- now the "agent1", original center of attention)
// Mode 52: Create an oppose link to specified waveMaker (field is the id of the specified waveMaker to link -- now the "agent2", original center of attention)
// Mode 53: Create an oppose link to specified waveMaker (field is the id of the specified waveMaker to link -- now the "agent1", original center of attention)
// Mode 54: Create a similar link to specified waveMaker (field is the id of the specified waveMaker to link -- now the "agent2", original center of attention)
// Mode 55: Create a similar link to specified waveMaker (field is the id of the specified waveMaker to link -- now the "agent1", original center of attention)
// ----------------------------------------------------------------------
// Mode 100: Goto link-idea-idea (after first ensuring that inTell has been interpreted by inSighter)
// Mode 200: Goto link-idea-agent (after first ensuring that inTell has been interpreted by inSighter)
// Mode 300: Goto link-idea-agent (after first ensuring that waveMaker has been interpreted by inSighter)
// Mode 400: Goto link-agent-agent (after first ensuring that waveMaker has been interpreted by inSighter)
// ----------------------------------------------------------------------
// Mode 1000: (special case to over-ride default entering with controlMode=1) Set controlMode to field (1 to 4)
//
// retrieveInterpretationStatus={setInterpreted} from <Meaning/> will then determine possible user functions
// --------------------------------------------
// ACTIONS
// - idea requested from backend via action loadingIdea/getIdea (spinner shown until idea loaded)
// - idea details displayed via component IdeaDetail
// - support/opposition lists displayed via component ShowLinkList
// - add support/opposition linkIdeaIdea (or linkIdeaAgent) via component SearchList
// - addLinkIdeaIdea action (submit to backend)
// - addLinkIdeaAgent action (submit to backend)

const Idea = ({
  getIdea,
  searchIdeas,
  loadingIdea,
  addLinkIdeaIdea,
  addLinkIdeaAgent,
  idea: { idea, loading },
  auth,
  linkIdeaIdea,
  linkIdeaAgent,
}) => {
  const { id, mode, field } = useParams();

  const defaultTransitionDuration = 250; // 250

  // Do not render anything until we request idea to be loaded (will cause spinner icon)
  const [renderReady, setRenderReady] = useState(false);

  // Before render, call loadingIdea() to set <idea> = null and <loading> = true (will cause spinner icon)
  useEffect(() => {
    loadingIdea();
  }, [loadingIdea]);

  // Load idea on first render
  useEffect(() => {
    getIdea(id);
    setRenderReady(true);
  }, [getIdea, id]);

  // Store initial linkIdeaIdea and linkIdeaAgent; monitor for changes to exit
  const [initLinkIdeaIdea] = useState(linkIdeaIdea.linkIdeaIdea);
  const [initLinkIdeaAgent] = useState(linkIdeaAgent.linkIdeaAgent);

  // Add buttons
  const [searchingIdeasFromBottomRight, setSearchingIdeasFromBottomRight] = useState(false);
  const [searchingIdeasFromBottomLeft, setSearchingIdeasFromBottomLeft] = useState(false);
  const [searchingIdeasFromTopRight, setSearchingIdeasFromTopRight] = useState(false);
  const [searchingIdeasFromTopLeft, setSearchingIdeasFromTopLeft] = useState(false);
  const [searchingAgentsFromBottomRight, setSearchingAgentsFromBottomRight] = useState(false);
  const [searchingAgentsFromBottomLeft, setSearchingAgentsFromBottomLeft] = useState(false);
  const handleUnload = () => {
    setSearchingIdeasFromBottomRight(false);
    setSearchingIdeasFromBottomLeft(false);
    setSearchingIdeasFromTopRight(false);
    setSearchingIdeasFromTopLeft(false);
    setSearchingAgentsFromBottomRight(false);
    setSearchingAgentsFromBottomLeft(false);
  };

  // Reset ideas=[] on every mode shift (prevents old search lists from being rendered)
  useEffect(() => {
    searchIdeas("", 10);
  }, [searchIdeas]);

  // 1=Meaning, 2=Rationality-Tree, 3=Complexity, 4=Agents-Related
  const [controlMode, setControlMode] = useState(mode === "1000" && field ? Number(field) : 1);
  const [loadingLinkObject, setLoadingLinkObject] = useState(false);

  // Has inTell been interpreted by current inSighter? For interpreted to be true, we require the inSighter to either be:
  // (1) the author of the inTell; OR
  // (2) the inTell has been interpreted by the user
  const [interpreted, setInterpreted] = useState(false);

  // Upon transition from editing meaning to viewing meaning (while controlMode===1):
  // - Submit new link-idea-idea/link-idea-agent once inTell is viewable "viewable" (and if instructed by optional parameters); or
  // - Set control mode=2
  //
  // This can be called upon first entry into component or triggered by <Meaning> component alerting parent component that the inTell has been interpreted
  const navigate = useNavigate();
  const handleInterpretation = (e) => {
    if (controlMode === 1 && !interpreted && e) {
      // Transition from not interpreted (and/or initial state) to interpreted
      if (mode && typeof mode !== "undefined") {
        switch (mode) {
          case "20":
            // Create a support linkIdeaIdea to specified inTell (field is now the ideaEffect, original center of attention)
            console.log("Submit link-idea-idea support link [20]");
            setLoadingLinkObject(true);
            addLinkIdeaIdea({
              ideaCause: idea._id, // inTell currently in focus (just added)
              ideaEffect: field, // linked inTell
              modeOnSubmit: "20",
            });
            break;
          case "21":
            // Create a support linkIdeaIdea to specified inTell (field is now the ideaCause, original center of attention)
            console.log("Submit link-idea-idea support link [21]");
            setLoadingLinkObject(true);
            addLinkIdeaIdea({
              ideaCause: field, // linked inTell
              ideaEffect: idea._id, // inTell currently in focus (just added)
              modeOnSubmit: "21",
            });
            break;
          case "22":
            // Create an oppose linkIdeaIdea to specified inTell (field is now the ideaEffect, original center of attention)
            console.log("Submit link-idea-idea oppose link [22]");
            setLoadingLinkObject(true);
            addLinkIdeaIdea({
              ideaCause: idea._id, // inTell currently in focus (just added)
              ideaEffect: field, // linked inTell
              modeOnSubmit: "22",
            });
            break;
          case "23":
            // Create an oppose linkIdeaIdea to specified inTell (field is now the ideaCause, original center of attention)
            console.log("Submit link-idea-idea oppose link [23]");
            setLoadingLinkObject(true);
            addLinkIdeaIdea({
              ideaCause: field, // linked inTell
              ideaEffect: idea._id, // inTell currently in focus (just added)
              modeOnSubmit: "23",
            });
            break;
          case "24":
            // Create a complex linkIdeaIdea to specified inTell (field is now the ideaEffect, original center of attention)
            console.log("Submit link-idea-idea complex link [24]");
            setLoadingLinkObject(true);
            addLinkIdeaIdea({
              ideaCause: idea._id, // inTell currently in focus (just added)
              ideaEffect: field, // linked inTell
              modeOnSubmit: "24",
            });
            break;
          case "25":
            // Create a complex linkIdeaIdea to specified inTell (field is now the ideaCause, original center of attention)
            console.log("Submit link-idea-idea complex link [25]");
            setLoadingLinkObject(true);
            addLinkIdeaIdea({
              ideaCause: field, // linked Intell
              ideaEffect: idea._id, // inTell currently in focus (just added)
              modeOnSubmit: "25",
            });
            break;
          case "26":
            // Create a similar linkIdeaIdea to specified inTell (field is now the ideaEffect, original center of attention)
            console.log("Submit link-idea-idea similar link [26] to specified inTell");
            setLoadingLinkObject(true);
            addLinkIdeaIdea({
              ideaCause: idea._id, // inTell currently in focus
              ideaEffect: field, // linked inTell
              modeOnSubmit: "26",
            });
            break;
          case "27":
            // Create a similar linkIdeaIdea to specified inTell (field is now the ideaCause, original center of attention)
            console.log("Submit link-idea-idea similar link [27] to specified inTell");
            setLoadingLinkObject(true);
            addLinkIdeaIdea({
              ideaCause: field, // linked inTell
              ideaEffect: idea._id, // inTell currently in focus
              modeOnSubmit: "27",
            });
            break;
          case "30":
            // Create a support linkIdeaAgent to specified waveMaker (field is now the agent, original center of attention)
            console.log("Submit link-idea-agent support link [30] to specified agent");
            setLoadingLinkObject(true);
            addLinkIdeaAgent({
              idea: idea._id, // inTell currently in focus
              agent: field, // linked waveMaker
              modeOnSubmit: "30",
            });
            break;
          case "31":
            // Create oppose linkIdeaAgent to specified waveMaker (field is now the agent, original center of attention)
            console.log("Submit link-idea-agent oppose link [31] to specified agent");
            setLoadingLinkObject(true);
            addLinkIdeaAgent({
              idea: idea._id, // inTell currently in focus
              agent: field, // linked waveMaker
              modeOnSubmit: "31",
            });
            break;
          case "100":
            // inSighter has successfully interpreted the inTell; goto specified link-idea-idea
            console.log(
              "inSighter has successfully interpreted the inTell; goto specified link-idea-idea [100]"
            );
            navigate(`/link-idea-idea/${field}`);
            break;
          case "200":
            // inSighter has successfully interpreted the inTell; goto specified link-idea-agent
            console.log(
              "inSighter has successfully interpreted the inTell; goto specified link-idea-agent [200]"
            );
            navigate(`/link-idea-idea/${field}`);
            break;
          default:
            console.log("No relevent instructions encoded; do nothing");
        }
      } else {
        // Goto control mode 2 upon first view
        setControlMode(2);
      }
      setInterpreted(e);
    }
  };

  // Do not try show <IdeaDetail> until idea has been loaded and reviewed (below)
  const [initializationComplete, setInitializationComplete] = useState(false);
  useEffect(() => {
    console.log("First useEffect for idea:", idea);
    // Pull out inSighter's prior interpretation (if it exists)
    // Note: This logic is mirrored in <Meaning>
    if (
      !initializationComplete &&
      !loadingLinkObject &&
      renderReady &&
      !loading &&
      idea &&
      idea.scales &&
      auth &&
      auth.user &&
      auth.user._id
    ) {
      // Pull out inSighter's prior interpretation (if one exists)
      // Note: This logic is mirrored in <Meaning>
      console.log("useEffect idea", idea);

      let priorScaleExists = null;
      if (idea.scales.length > 0) {
        priorScaleExists = idea.scales.find((e) => e.user._id === auth.user._id);
      }
      if (priorScaleExists) {
        console.log("priorScaleExists true -- current inSighter previously interpreted");
        handleInterpretation(true);
      } else if (
        idea.user._id === auth.user._id &&
        typeof idea.goodIntentLevel !== "undefined" &&
        idea.goodIntentLevel
      ) {
        // Was this inTell submitted by the current inSighter? (if so, have they provide a goodIntentLevel?)
        console.log("Current inSighter submitted this inTell");
        handleInterpretation(true);
      }
      setInitializationComplete(true);
    }
  }, [idea, auth]);

  // Jump to link-idea-idea page when a new linkIdeaIdea has been successfully submitted.
  useEffect(() => {
    if (linkIdeaIdea.linkIdeaIdea !== initLinkIdeaIdea) {
      navigate(`/link-idea-idea/${linkIdeaIdea.linkIdeaIdea._id}`);
    }
  }, [linkIdeaIdea, initLinkIdeaIdea]);

  // Jump to link-idea-agent page when a new linkIdeaAgent has been successfully submitted.
  useEffect(() => {
    if (linkIdeaAgent.linkIdeaAgent !== initLinkIdeaAgent) {
      navigate(`/link-idea-agent/${linkIdeaAgent.linkIdeaAgent._id}`);
    }
  }, [linkIdeaAgent, initLinkIdeaAgent]);

  // Animations begun to enter? (update counter everytime which triggers useEffect)
  const [meaningEnterCount, setMeaningEnterCount] = useState(0);

  // First render transitionDuration=0ms, after that: set to 250ms+250ms
  const [transitionDuration, setTransitionDuration] = useState(0);
  const [rationalityTreeSlideDirection, setRationalityTreeSlideDirection] = useState();
  const [complexitySlideDirection, setComplexitySlideDirection] = useState();

  const [meaningView, setMeaningView] = useState(controlMode === 1);
  const [rationalityTreeView, setRationalityTreeView] = useState(controlMode === 2);
  const [complexityView, setComplexityView] = useState(controlMode === 3);
  const [agentsRelatedView, setAgentsRelatedView] = useState(controlMode === 4);

  // Scroll to middle of screen
  const centralIdeaRef = useRef();

  // Change in controlMode
  useEffect(() => {
    // Goto meaningView (#1)
    if (controlMode === 1) {
      let bChange = false;
      if (rationalityTreeView) {
        // 2->1
        bChange = true;
        setRationalityTreeView(false);
        setRationalityTreeSlideDirection("left");
      }
      if (complexityView) {
        // 3->1
        bChange = true;
        setComplexityView(false);
        setComplexitySlideDirection("left");
      }
      if (agentsRelatedView) {
        // 4->1
        bChange = true;
        setAgentsRelatedView(false);
      }
      if (bChange) {
        // Delay entry of new mode animation until prior mode animation complete
        setTransitionDuration(defaultTransitionDuration);
        setTimeout(() => {
          setMeaningView(true);
          if (centralIdeaRef.current) {
            window.scrollTo(0, centralIdeaRef.current.offsetTop - 150);
          }
        }, transitionDuration);
      }
    }

    // Goto rationalityTreeView (#2)
    if (controlMode === 2) {
      let bChange = false;
      if (meaningView) {
        // 1->2
        bChange = true;
        setMeaningView(false);
        setRationalityTreeSlideDirection("left");
      }
      if (complexityView) {
        // 3->2
        bChange = true;
        setComplexityView(false);
        setRationalityTreeSlideDirection("right");
        setComplexitySlideDirection("left");
      }
      if (agentsRelatedView) {
        // 4->2
        bChange = true;
        setRationalityTreeSlideDirection("right");
        setAgentsRelatedView(false);
      }
      if (bChange) {
        // Delay entry of new mode animation until prior mode animation complete
        setTransitionDuration(defaultTransitionDuration);
        setTimeout(() => {
          setRationalityTreeView(true);
          if (centralIdeaRef.current) {
            window.scrollTo(0, centralIdeaRef.current.offsetTop - 150);
          }
        }, transitionDuration);
      }
    }

    // Goto complexityView (#3)
    if (controlMode === 3) {
      let bChange = false;
      if (meaningView) {
        // 1->3
        bChange = true;
        setMeaningView(false);
        setComplexitySlideDirection("left");
      }
      if (rationalityTreeView) {
        // 2->3
        bChange = true;
        setRationalityTreeView(false);
        setComplexitySlideDirection("left");
        setRationalityTreeSlideDirection("right");
      }
      if (agentsRelatedView) {
        // 4->3
        bChange = true;
        setAgentsRelatedView(false);
        setComplexitySlideDirection("right");
      }
      if (bChange) {
        // Delay entry of new mode animation until prior mode animation complete
        setTransitionDuration(defaultTransitionDuration);
        setTimeout(() => {
          setComplexityView(true);
          if (centralIdeaRef.current) {
            window.scrollTo(0, centralIdeaRef.current.offsetTop - 150);
          }
        }, transitionDuration);
      }
    }

    // Goto agentsRelatedView (#4)
    if (controlMode === 4) {
      let bChange = false;
      if (meaningView) {
        // 1->4
        bChange = true;
        setMeaningView(false);
      }
      if (rationalityTreeView) {
        // 2->4
        bChange = true;
        setRationalityTreeView(false);
        setRationalityTreeSlideDirection("right");
      }
      if (complexityView) {
        // 3->4
        bChange = true;
        setComplexityView(false);
        setComplexitySlideDirection("right");
      }
      if (bChange) {
        // Delay entry of new mode animation until prior mode animation complete
        setTransitionDuration(defaultTransitionDuration);
        setTimeout(() => {
          setAgentsRelatedView(true);
          if (centralIdeaRef.current) {
            window.scrollTo(0, centralIdeaRef.current.offsetTop - 150);
          }
        }, transitionDuration);
      }
    }
  }, [
    controlMode,
    meaningView,
    rationalityTreeView,
    complexityView,
    agentsRelatedView,
    transitionDuration,
  ]);

  // Swipe handler
  const handlers = useSwipeable({
    onSwipedRight: () => {
      if (controlMode > 1) {
        setControlMode(controlMode - 1);
      }
    },
    onSwipedLeft: () => {
      if (controlMode < 4) {
        setControlMode(controlMode + 1);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return !renderReady ||
    loading ||
    loadingLinkObject ||
    !idea ||
    !auth ||
    !auth.user ||
    !initializationComplete ? (
    <Spinner />
  ) : (
    // eslint-disable-next-line
    <div {...handlers}>
      <NavbarTop transparent />
      <Container maxWidth="sm" style={{ marginTop: "60px" }}>
        <div>
          {/* LOGIC-TREE (top "branches") */}
          <Slide
            in={rationalityTreeView}
            timeout={transitionDuration}
            direction={rationalityTreeSlideDirection}
            mountOnEnter
            unmountOnExit
          >
            <div>
              <TreeBranches
                idea={idea}
                auth={auth}
                showAddButton={
                  !searchingIdeasFromTopRight &&
                  !searchingIdeasFromTopLeft &&
                  !searchingIdeasFromBottomRight &&
                  !searchingIdeasFromBottomLeft
                }
                requestSearchRight={setSearchingIdeasFromTopRight}
                requestSearchLeft={setSearchingIdeasFromTopLeft}
              />
            </div>
          </Slide>

          {/* COMPLEXITY (3D cylinders and related ideas) */}
          <Slide
            in={complexityView}
            timeout={transitionDuration}
            direction={complexitySlideDirection}
            mountOnEnter
            unmountOnExit
          >
            <div>
              <MoreComplexIdeas
                idea={idea}
                auth={auth}
                showAddButton={
                  !searchingIdeasFromTopRight &&
                  !searchingIdeasFromTopLeft &&
                  !searchingIdeasFromBottomRight &&
                  !searchingIdeasFromBottomLeft
                }
                requestSearchRight={setSearchingIdeasFromTopRight}
                requestSearchLeft={setSearchingIdeasFromTopLeft}
              />
            </div>
          </Slide>

          {/* SEARCH IDEA LIST ABOVE CENTRAL IDEA */}
          {/* Create new inTell above, then (from new inTell perspective) a support link (cause) back to inTell at center of current attention [21] (controlMode===2, searchingIdeasFromTopRight) */}
          {/* Create new inTell above, then (from new inTell perspective) an oppose link (cause) back to inTell at center of current attention [23] (controlMode===2, searchingIdeasFromTopLeft) */}
          {/* Create new inTell above, then (from new inTell perspective) a "more complex" link (cause) back to inTell at center of current attention [25] (controlMode===3, searchingIdeasFromTopLeft) */}
          {/* Create new inTell above, then (from new inTell perspective) a similar link (cause) back to inTell at center of current attention [27] (controlMode===3, searchingIdeasFromTopRight) */}
          <div style={{ color: "black", textAlign: "left", fontSize: "12px" }}>
            {auth.isAuthenticated &&
              ((controlMode === 2 && searchingIdeasFromTopLeft) ||
                (controlMode === 2 && searchingIdeasFromTopRight) ||
                (controlMode === 3 && searchingIdeasFromTopLeft) ||
                (controlMode === 3 && searchingIdeasFromTopRight)) && (
                <SearchList
                  mode={
                    controlMode === 3
                      ? searchingIdeasFromTopRight
                        ? "27"
                        : "25"
                      : searchingIdeasFromTopRight
                      ? "21"
                      : "23"
                  }
                  existingIdeaList={idea.linkedIdeas}
                  requestComponentUnload={handleUnload}
                />
              )}
          </div>

          {/* CENTRAL IDEA */}
          <div ref={centralIdeaRef} style={{ marginTop: "10px", marginBottom: "10px" }}>
            <IdeaDetail
              idea={idea}
              controlMode={controlMode === 1 && !interpreted ? 0 : controlMode}
              setControlMode={setControlMode}
              showExtended
              boldOutline
              showAgents
              hrefHiddenInternal
              requiresURLVisit
            />
          </div>

          {/* SEARCH IDEA LIST BELOW CENTRAL IDEA */}
          {/* Create new inTell below, then (from new inTell perspective) a support link (effect) back to inTell at center of current attention [20] (controlMode===2, searchingIdeasFromBottomRight) */}
          {/* Create new inTell below, then (from new inTell perspective) an oppose link (effect) back to inTell at center of current attention [22] (controlMode===2, searchingIdeasFromBottomLeft) */}
          {/* Create new inTell below, then (from new inTell perspective) a "less complex" link (effect) back to inTell at center of current attention [24] (controlMode===3, searchingIdeasFromBottomLeft) */}
          <div style={{ color: "black", textAlign: "left", fontSize: "12px" }}>
            {auth.isAuthenticated &&
              ((controlMode === 2 && searchingIdeasFromBottomLeft) ||
                (controlMode === 2 && searchingIdeasFromBottomRight) ||
                (controlMode === 3 && searchingIdeasFromBottomLeft)) && (
                <SearchList
                  mode={controlMode === 3 ? "24" : searchingIdeasFromBottomRight ? "20" : "22"}
                  existingIdeaList={idea.linkedIdeas}
                  requestComponentUnload={handleUnload}
                />
              )}
          </div>

          {/* SEARCH AGENT LIST BELOW CENTRAL IDEA */}
          {/* Create new waveMaker, then a support link back to inTell at center of current attention [40] (controlMode===4, searchingAgentsFromBottomRight) */}
          {/* Create new waveMaker, then an oppose link back to inTell at center of current attention [41] (controlMode===4, searchingAgentsFromBottomLeft) */}
          <div style={{ color: "black", textAlign: "left", fontSize: "12px" }}>
            {auth.isAuthenticated &&
              ((controlMode === 4 && searchingAgentsFromBottomLeft) ||
                (controlMode === 4 && searchingAgentsFromBottomRight)) && (
                <SearchList
                  mode={searchingAgentsFromBottomRight ? "40" : "41"}
                  existingAgentList={idea.linkedAgents}
                  requestComponentUnload={handleUnload}
                />
              )}
          </div>

          {/* MEANING */}
          <Slide
            in={meaningView}
            timeout={transitionDuration}
            direction="right"
            onEntering={() => setMeaningEnterCount(meaningEnterCount + 1)}
            onEntered={() => setMeaningEnterCount(meaningEnterCount + 1)}
            mountOnEnter
            unmountOnExit
          >
            <div style={{ marginTop: "20px" }}>
              <p>Interpretations here...</p>
              {/*  <Chart />  */}
              {/*  <Meaning idea={idea} retrieveInterpretationStatus={handleInterpretation} /> */}
            </div>
          </Slide>

          {/* LOGIC-TREE (bottom "roots") */}
          <Slide
            in={rationalityTreeView}
            timeout={transitionDuration}
            direction={rationalityTreeSlideDirection}
            mountOnEnter
            unmountOnExit
          >
            <div>
              <TreeRoots
                idea={idea}
                auth={auth}
                showAddButton={
                  !searchingIdeasFromBottomRight &&
                  !searchingIdeasFromBottomLeft &&
                  !searchingIdeasFromTopRight &&
                  !searchingIdeasFromTopLeft
                }
                requestSearchRight={setSearchingIdeasFromBottomRight}
                requestSearchLeft={setSearchingIdeasFromBottomLeft}
              />
            </div>
          </Slide>

          {/* COMPLEXITY ("essense" and "quotations") */}
          <Slide
            in={complexityView}
            timeout={transitionDuration}
            direction={complexitySlideDirection}
            mountOnEnter
            unmountOnExit
          >
            <div>
              <LessComplexIdeas
                idea={idea}
                auth={auth}
                showAddButton={
                  !searchingIdeasFromBottomRight &&
                  !searchingIdeasFromBottomLeft &&
                  !searchingIdeasFromTopRight &&
                  !searchingIdeasFromTopLeft
                }
                requestSearchLeft={setSearchingIdeasFromBottomLeft}
              />
            </div>
          </Slide>

          {/* AGENTS-RELATED */}
          <Slide
            in={agentsRelatedView}
            timeout={transitionDuration}
            direction="left"
            mountOnEnter
            unmountOnExit
          >
            <div>
              <RelatedAgents
                idea={idea}
                auth={auth}
                showAddButton={!searchingAgentsFromBottomRight && !searchingAgentsFromBottomLeft}
                requestSearchRight={setSearchingAgentsFromBottomRight}
                requestSearchLeft={setSearchingAgentsFromBottomLeft}
              />
            </div>
          </Slide>
        </div>
      </Container>
    </div>
  );
};

Idea.propTypes = {
  getIdea: PropTypes.func.isRequired,
  searchIdeas: PropTypes.func.isRequired,
  loadingIdea: PropTypes.func.isRequired,
  addLinkIdeaIdea: PropTypes.func.isRequired,
  addLinkIdeaAgent: PropTypes.func.isRequired,
  idea: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
  linkIdeaIdea: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
  linkIdeaAgent: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
  auth: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
};

const mapStateToProps = (state) => ({
  idea: state.idea,
  linkIdeaIdea: state.linkIdeaIdea,
  linkIdeaAgent: state.linkIdeaAgent,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getIdea,
  searchIdeas,
  loadingIdea,
  addLinkIdeaIdea,
  addLinkIdeaAgent,
})(Idea);
