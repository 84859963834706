import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// MUI
import Button from "components/MaterialKit/MKButton";
import Badge from "components/MaterialKit/MKBadge";
import Typography from "components/MaterialKit/MKTypography";
import {
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

import Spinner from "../layout/Spinner";
import EditConfirm from "../meaning/EditConfirm";
import { addAgentScale } from "../../actions/agent";

// PROPS
// Name                 Type    Description
// agent                object  agent to add/edit meaning to
// retrieveInterpretationStatus func    Returns whether calling component has access to view children components
// --------------------------------------------
// Props used (from calling components):
// AgentScale: agent retrieveInterpretationStatus
// --------------------------------------------
// ACTIONS
// - no input requests to backend
// - display agent meaning via: MeaningView component
// - edit agent meaning via: MeaningEditCoord, EditConfirm
// - addAgentScale action (submit agent scale to backend after user has confirmed his/her confidence)
// - wait to render (until we have checked for a previous user specified meaning)

const Meaning = ({ agent, retrieveInterpretationStatus, addAgentScale, auth }) => {
  console.log(agent.member && agent.member._id === auth.user._id ? "It's Me" : "Not Me");

  // State Map & Transitions. There are 3 key variables (8 states):
  // - one view state
  // - four edit states
  // - three transitionary states
  //
  // coord.existsInDb coord.changed coord.editing    State_Meaning
  // false            false         false         #1 TRANSITIONARY STATE: !coord.existsInDb means current user has not yet provided a scale -> set coord.editing=true (goto #5)
  //                                                 NOTE: Different functionality than idea
  // true             false         false         #2 VIEW: Display all scales on graph (if picture clicked, edit)
  //                                                 If picture clicked -> set coord.editing=true (goto #3)
  // true             false         true          #3 EDIT: Display user coord & 4 buttons: 3xlevels(darken_user_prior) & AbortEdit
  //                                                 If scale picture -> update coordinates & set coord.changed = true (goto #4)
  //                                                 If <AbortEdit> or <dark.button> -> set coord.editing=false (goto #2)
  //                                                 If other 2 buttons -> Update db & set coord.editing=false (goto #2)
  // true             true          true          #4 EDIT: Display updated user coord & 4 buttons: 3xlevels & AbortEdit
  //                                                 If scale picture -> update coordinates (stay @ #4)
  //                                                 If <AbortEdit> -> set coord.editing=false & set coord.changed=false (goto #2)
  //                                                 If other 3 buttons -> Update db & set coord.editing=false (goto #2)
  // false            false         true          #5 EDIT: Display empty graph
  //                                                 If scale picture -> update coord.x/y & set coord.changed=true (goto #6)
  // false            true          true          #6 EDIT: Display updated user coord & 4 buttons: 3xlevels) & AbortEdit
  //                                                 If scale picture -> update coordinates (stay @ #6)
  //                                                 If <AbortEdit> -> set coord.editing=false & set coord.changed=false (goto #1)
  //                                                 If other 3 buttons -> Update db & set coord.editing=false & set coord.x/y[valid]=true & coord.changed=false (goto #2)
  // The following 2 states are undefined / not expected:
  // false            true          false         #7 TRANSITIONARY STATE: If we enter this state, return to default (state 1)
  // true             true          false         #8 TRANSITIONARY STATE: If we enter this state, return to default (state 1)
  //
  // Note: Even if the user is the author of the agent, the user needs to add scale before he/she can reply

  const [renderReady, setRenderReady] = useState(false);
  const [coord, setCoord] = useState({
    changed: false,
    confidence: 0,
    existsInDb: false,
    editing: false,
  });

  // Extract current inSighter's interpretation scales
  // [typical entry states: @start or #4/#6(after submit) or #4/#6(after cancel)] -> [possible exit states: #0 or #2]
  const extractCurrentInsighterInterpretationScales = () => {
    // Pull out inSighter's prior interpretation (if one exists)
    // Note: This logic is mirrored in <Agent>
    if (agent && agent.scales && auth && auth.user && auth.user._id) {
      let priorScaleExists = null;
      if (agent.scales.length > 0) {
        priorScaleExists = agent.scales.find(
          (e) =>
            (e.user && e.user._id && e.user._id === auth.user._id) ||
            (e.member && e.member._id && e.member._id === auth.user._id)
        );
      }
      if (priorScaleExists) {
        // Current inSighter previously interpreted. Set: changed:false, x/y/confidence:priorScaleExists, existsInDb:true, editing:false
        console.log("priorScaleExists true -- got state#2 (view)");
        setCoord({
          changed: false,
          x: priorScaleExists.x,
          y: priorScaleExists.y,
          confidence: priorScaleExists.confidence,
          existsInDb: true,
          editing: false,
        });
        retrieveInterpretationStatus(true);
      } else if (agent.member && agent.member._id && agent.member._id === auth.user._id) {
        // inSighter is the waveMaker, and therefore does not need to interpret
        console.log("Current inSighter and waveMaker ae the same (self) -- got state#2 (view)");
        setCoord({ changed: false, confidence: 0, existsInDb: true, editing: false });
        retrieveInterpretationStatus(true);
      } else {
        // Set coord back to default (force editing)
        console.log("priorScaleExists false -- goto state #1");
        setCoord({ changed: false, confidence: 0, existsInDb: false, editing: true });
      }
      setRenderReady(true);
    }
  };

  useEffect(() => {
    console.log("useEffect() Change in agent or auth");
    extractCurrentInsighterInterpretationScales();
    // eslint-disable-next-line
  }, [agent, auth]);

  // Confidence click
  // [possible entry states: #4 or #6] ->
  //   if submit successful: will trigger agent useEffect() & extractCurrentInsighterInterpretationScales() and coord/state will be updated
  //   if submit not successful: show busy icon and error message from backend
  const handleConfidenceClick = (confidence) => {
    console.log("handleConfidenceClick", confidence);
    addAgentScale(agent._id, { x: coord.x, y: coord.y, confidence }); // New scale creation (or update existing tag)
    setRenderReady(false); // Given agent will need to reload, wait until new data has been fetched (coord updated) before re-rendering
  };

  // Abort scale edit
  // [possible entry states: #4 or #6] -> will trigger extractCurrentInsighterInterpretationScales() and coord/state will be updated
  const handleAbortClick = () => {
    extractCurrentInsighterInterpretationScales(); // coord will be updated (coord.editing=false)
  };

  // Change meaning user click
  // [possible entry states: #3, #4, #5 or #6] -> [possible exit states: #4 or #6]
  //  const handleChangeMeaning = (e) => {
  // Set: changed:true, x, y
  //    setCoord({
  //      changed: true,
  //      x: e.x,
  //      y: e.y,
  //      confidence: coord.confidence,
  //      existsInDb: coord.existsInDb,
  //      editing: coord.editing,
  //    });
  //  };

  // "User requires confirmation to continue editing" modal control
  const [confirmationModal, setConfirmationModal] = useState(false);
  // [possible entry states: #2 (VIEW)] -> [possible exit states: #2 (continue VIEW) or #3 (EDIT existing)]
  const userConfirmedEditPrior = () => {
    setConfirmationModal(false);
    // Set: editing:true
    setCoord({
      changed: coord.changed,
      x: coord.x,
      y: coord.y,
      confidence: coord.confidence,
      existsInDb: coord.existsInDb,
      editing: true,
    });
    retrieveInterpretationStatus(false);
  };

  // Cluge: In ../meaning/ViewScale.js & ../meaning/View2x2.js
  //   ImageMapper can throw out an event "onClick" even when it is not clicked
  // We now double check that the click is coming from "IMG"/"P"/"DIV"/"LABEL" (This component is interpreting it)
  // [possible entry states: #2 (VIEW)] -> [possible exit states: #2 (continue VIEW) or #3 (EDIT existing) or #5 (EDIT new)]
  //  const viewClick = (e) => {
  //    console.log("viewClick", e.target.tagName);
  //    if (
  //      e.target.tagName === "IMG" ||
  //      e.target.tagName === "P" ||
  //      e.target.tagName === "DIV" ||
  //      e.target.tagName === "LABEL"
  //    ) {
  //      if (coord.existsInDb) {
  // User requires confirmation before editing pre-existing scale
  //        setConfirmationModal(true);
  //      } else {
  // User does not require confirmation (no pre-existing scale exists related to current user)
  //        setConfirmationModal(false);
  // Set: editing:true
  //        setCoord({
  //          changed: coord.changed,
  //          x: coord.x,
  //          y: coord.y,
  //          confidence: coord.confidence,
  //          existsInDb: coord.existsInDb,
  //          editing: true,
  //        });
  //        retrieveInterpretationStatus(false);
  //      }
  //    }
  //  };

  // Transition state check (if coord or renderReady changes)
  useEffect(() => {
    console.log("useEffect() coord/renderReady changed: check for state transition");

    // State #7 or #8 (should never get here); return back to default
    if (coord.changed && !coord.editing) {
      console.log("SHOULD NEVER GET HERE! State #7 or #8. Return to default");
      // Pull out inSighter's prior interpretation (if one exists)
      // Note: This logic is mirrored in <Agent>
      if (agent && agent.scales && auth && auth.user && auth.user._id) {
        let priorScaleExists = null;
        if (agent.scales.length > 0) {
          priorScaleExists = agent.scales.find(
            (e) =>
              (e.user && e.user._id && e.user._id === auth.user._id) ||
              (e.member && e.member._id && e.member._id === auth.user._id)
          );
        }
        if (priorScaleExists) {
          // Current inSighter previously interpreted. Set: changed:false, x/y/confidence:priorScaleExists, existsInDb:true, editing:false
          console.log("priorScaleExists true -- got state#2 (view)");
          setCoord({
            changed: false,
            x: priorScaleExists.x,
            y: priorScaleExists.y,
            confidence: priorScaleExists.confidence,
            existsInDb: true,
            editing: false,
          });
          retrieveInterpretationStatus(true);
        } else if (agent.member && agent.member._id && agent.member._id === auth.user._id) {
          // inSighter is the waveMaker, and therefore does not need to interpret
          console.log("Current inSighter and waveMaker ae the same (self) -- got state#2 (view)");
          setCoord({ changed: false, confidence: 0, existsInDb: true, editing: false });
          retrieveInterpretationStatus(true);
        } else {
          // Set coord back to default (force editing)
          console.log("priorScaleExists false -- goto state #1");
          setCoord({ changed: false, confidence: 0, existsInDb: false, editing: true });
        }
        setRenderReady(true);
      }
    }

    // State #1 to State #5 transition (wait until render ready)
    // Current user is not asked to judge meaning if it is himself/herself
    if (
      renderReady &&
      !coord.existsInDb &&
      !coord.changed &&
      !coord.editing &&
      agent._id !== auth.user._id
    ) {
      // User has not yet provided a scale -- force him/her to EDIT
      console.log(
        "State #1 to State #5 transition. User has not yet provided a scale. Set: editing:true"
      );
      // Set: editing:true
      setCoord({
        changed: coord.changed,
        x: coord.x,
        y: coord.y,
        confidence: coord.confidence,
        existsInDb: coord.existsInD,
        editing: true,
      });
      retrieveInterpretationStatus(false);
    }
  }, [coord, renderReady]);

  return !renderReady ? (
    <Spinner />
  ) : (
    <>
      <Card style={{ margin: "8px", background: "transparent", boxShadow: "none" }}>
        <h6>Interpretation of waveMaker</h6>

        {/* VIEW mode: #2 */}
        {/*        {!coord.editing && (    */}
        {/*          <div onClick={(e) => viewClick(e)}>   */}
        {/*            <ViewCombo key={agent.id} agent={agent} auth={auth} coord={coord} />  */}
        {/*          </div>  */}
        {/*        )}  */}

        {/* EDIT mode */}
        {coord.editing && (
          <>
            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Badge color="rose">
                <Typography style={{ fontSize: "14px" }}>Click below to rate waveMaker</Typography>
              </Badge>
            </div>

            {/*              <Edit2x2  */}
            {/*                width={200} */}
            {/*                userInit={coord}  */}
            {/*                updated={handleChangeMeaning}  */}
            {/*                userDotRadius={6} */}
            {/*              /> */}

            {/* Submit/confirm */}
            <EditConfirm
              coord={coord}
              readyToSubmit={typeof coord.x !== "undefined" && typeof coord.y !== "undefined"}
              hideAbortButton={typeof coord.x === "undefined" || typeof coord.y === "undefined"}
              handleConfidenceClick={handleConfidenceClick}
              handleAbortClick={handleAbortClick}
            />
          </>
        )}
      </Card>

      {/* Modal: Confirm request to edit */}
      <Dialog
        open={confirmationModal}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">You already intrerpreted.</DialogTitle>
        <DialogContent id="dialog-description">
          <DialogContentText id="dialog-description">
            We awarded bonus reputation points when you first provided your rating. We subtract
            reputation points whenever you make a change.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmationModal(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => userConfirmedEditPrior()} style={{ backgroundColor: "#291AE0" }}>
            Edit my prior choice
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

Meaning.propTypes = {
  agent: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
  retrieveInterpretationStatus: PropTypes.func.isRequired,
  addAgentScale: PropTypes.func.isRequired,
  auth: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { addAgentScale })(Meaning);
