import React, { useState, useEffect, useRef } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

// MUI
import { Container } from "@mui/material";

import NavbarTop from "../navbar/NavbarTop";
import CreateMyContent from "../create/CreateMyContent";

const Interpret1 = ({ activity, setting, numericalLabel, auth }) => {
  // When a child process gets updated due to user interation, this counter in incremented
  // This triggers a scroll to the bottom of the screen
  const [childUpdated, setChildUpdated] = useState(0);
  const divRef = useRef(null);
  useEffect(() => {
    if (childUpdated > 1) {
      setTimeout(() => {
        if (divRef && divRef.current && divRef.current.scrollIntoView) {
          divRef.current.scrollIntoView({ behavior: "smooth" });
        }
        setTimeout(() => {
          if (divRef && divRef.current && divRef.current.scrollIntoView) {
            divRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }, 500);
      }, 500);
    }
  }, [childUpdated]);

  return (
    <>
      <NavbarTop sticky title={`ACTIVITY #${numericalLabel}`} content1="What do you think?" />
      <Container maxWidth="sm" sx={{ mt: { xs: 9, sm: 12 }, mb: 12 }}>
        <CreateMyContent
          instructions={activity.instructions1}
          activity={activity}
          initSelection={1}
          setting={setting}
          requestSelfEval={activity.newInTellSelfEvaluate}
          auth={auth}
          notifyParent={childUpdated}
          setNotifyParent={setChildUpdated}
        />
      </Container>
      <div ref={divRef} />
    </>
  );
};

Interpret1.propTypes = {
  activity: PropTypes.oneOfType([PropTypes.object]).isRequired,
  numericalLabel: PropTypes.number,
  auth: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setting: PropTypes.oneOfType([PropTypes.object]),
};

Interpret1.defaultProps = {
  numericalLabel: 0,
  setting: null,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Interpret1);
