import {
  ACTIVITY_ERROR,
  ADD_ACTIVITY,
  MODIFY_ACTIVITY,
  DELETE_ACTIVITY,
  GET_ACTIVITY,
  GET_ACTIVITIES,
  LOADING_ACTIVITY,
} from "../actions/types";

const initialState = {
  activities: [],
  activity: null,
  loading: true,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ACTIVITIES:
    case MODIFY_ACTIVITY:
    case DELETE_ACTIVITY:
      return {
        ...state,
        activity: null,
        activities: payload,
        loading: false,
      };
    case GET_ACTIVITY:
    case ADD_ACTIVITY:
      return {
        ...state,
        activity: payload,
        activities: [],
        loading: false,
      };
    case ACTIVITY_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case LOADING_ACTIVITY:
      return {
        ...state,
        activities: [],
        activity: null,
        loading: true,
      };
    default:
      return state;
  }
}
