import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useMediaQuery } from "@react-hook/media-query";

// MUI
import { Card, Accordion, AccordionSummary, Tooltip } from "@mui/material";
import Typography from "components/MaterialKit/MKTypography";
import { ExpandMore, PictureAsPdf, OndemandVideo } from "@mui/icons-material";
import { styled } from "@mui/system";

import defaultAvatar from "assets/images/faces/placeholder.jpg";
import IdeaDetailHeader from "./IdeaDetailHeader";
import ViewFrame from "./ViewFrame";
import ViewVideo from "./ViewVideo";
import ViewPdf from "./ViewPdf";
import getTimeAgo from "../../utils/getTimeAgo";

const ActiveLink = styled("a")(({ theme }) =>
  theme.unstable_sx({
    color: "grey",
    "&:hover,&:focus": {
      color: "#291AE0", // inSight blue
    },
  })
);

const IdeaDetail = ({
  idea: {
    _id,
    user,
    date,
    image,
    isPdf, // <image> contains the url reference (treated like an image)
    isVideo, // <url> contains url address (treated like a webpage)
    url,
    myPoint, // title: max 60 characters
    myContent, // if more than a short 60 char message/tweet
    frameContext, // frame
    frameResolution, // frame
    myConcern, // frame
    errorCorrection, // frame
    proxyLevel, // frame
    proxySources, // frame
    criticContent, // frame
    criticConcern, // frame
    atCore, // frame
    ideaReferenced, // quoted reference
    text, // quoted text
    scales,
    agents,
  },
  communityTopic,
  instructions,
  controlMode,
  setControlMode,
  showExtended, // Show extended frame & all text (by default: frame info not visible and text will truncateed to a single line)
  hideImage, // Do not show associated image of idea (this includes an assolciated pdf file, as this is an image file too) & will reduce all text lines to only 30/40 characters
  greyOut, // Minimized/grey-ed out text/element,& hides footer
  boldOutline, // Show heavy shadow outline
  showAgents, // Show associated waveMakers(s)
  hrefHiddenInternal, // hide/disable links to other insight platform inro
  hrefHiddenExternal, // hide/disable links to external URL
  requiresURLVisit, // If true, make sure the current user has seen the associated external URL (if exists)
  setCardHeight,
  auth,
}) => {
  // Card height
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [pdfLoaded, setPdfLoaded] = useState(false);
  const ref = useRef(null);
  useLayoutEffect(() => {
    if (setCardHeight) {
      if (ref && ref.current && ref.current.offsetHeight) {
        setCardHeight(ref.current.offsetHeight);
        //      console.log(ref.current.offsetHeight);
      }
    }
  }, []);
  useEffect(() => {
    function handleWindowResize() {
      if (setCardHeight) {
        if (ref && ref.current && ref.current.offsetHeight) {
          setCardHeight(ref.current.clientHeight);
          //       console.log(ref.current.offsetHeight);
        }
      }
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  const [expanded, setExpanded] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      if (setCardHeight) {
        if (ref && ref.current && ref.current.offsetHeight) {
          setCardHeight(ref.current.offsetHeight);
          //        console.log(ref.current.offsetHeight);
        }
      }
    }, 250);
  }, [expanded, videoLoaded, pdfLoaded]);

  const smSize = useMediaQuery("only screen and (min-width: 600px)");
  const mdSize = useMediaQuery("only screen and (min-width: 900px)");
  const lgSize = useMediaQuery("only screen and (min-width: 1200px)");

  const IdeaTitle = styled("a")(({ theme }) =>
    theme.unstable_sx({
      // Small screens
      fontSize: greyOut ? "12px" : "14px",
      // Larger screens
      [theme.breakpoints.up("sm")]: {
        fontSize: greyOut ? "12px" : "16px",
      },
      color: greyOut ? "grey" : "#291AE0", // inSight blue
      "&:hover,&:focus": {
        color: "#291AE0", // inSight blue
      },
    })
  );

  const IdeaUrl = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
      marginTop: "5px",
      marginBottom: "5px",
      //    fontSize: greyOut ? "9px" : "12px",
      fontSize: "12px",
      color: "#397D02",
      "&:hover,&:focus": {
        color: "#291AE0", // inSight blue
      },
    })
  );

  const [showImage] = useState(!hideImage && image);

  // Store idea _id of last external url visited
  //  const navigate = useNavigate();
  const handleExternalLink = () => {
    console.log("Going to External link...");
    //    localStorage.setItem("insightLastIdea", `${_id}`);
    window.open(url);
    //    navigate(`/idea/${_id}`);
  };

  useEffect(() => {
    //  console.log("useEffect() Change in idea or auth");
    if (requiresURLVisit && scales && auth && auth.user && auth.user._id) {
      // Handle both cases: .user is populated or unpopulated
      let priorScaleExists = null;
      if (scales.length > 0) {
        if (scales[0].user && scales[0].user._id) {
          priorScaleExists = scales.find((e) => e.user._id === auth.user._id);
        } else {
          priorScaleExists = scales.find((e) => e.user === auth.user._id);
        }
      }
      if (
        !priorScaleExists &&
        url &&
        user._id !== auth.user._id &&
        _id !== localStorage.getItem("insightLastIdea")
      ) {
        // Current user scale DOES NOT exist && idea is an URL && idea was not submitted by the current user && its URL not recently opened
        console.log(
          "This idea (external URL link) was not submitted by the current user and wa not recently opened -- open external idea URL"
        );
        localStorage.setItem("insightLastIdea", `${_id}`);
        window.open(url);
      }
    }
  }, [requiresURLVisit, scales, url, _id, user, auth]);

  // Is submitter accountable for this inTell?
  const [isAccountable, setIsAccountable] = useState(false);
  useEffect(() => {
    for (let i = 0; i < agents.length; i += 1) {
      for (let j = 0; j < user.agent.length; j += 1) {
        if (agents[i]._id === user.agent[j]) {
          setIsAccountable(true);
        }
      }
    }
  }, [agents, user]);

  return (
    <div ref={ref}>
      <Card
        style={{
          margin: "0px",
          padding: "15px",
          boxShadow: boldOutline ? "1px 3px 3px 2px grey" : "",
          backgroundColor: communityTopic ? "#FDE5C9" : instructions ? "#f6e4e4" : "",
        }}
      >
        {typeof controlMode !== "undefined" && controlMode !== "" && controlMode > 0 && (
          <IdeaDetailHeader
            ideaID={_id}
            controlMode={controlMode}
            setControlMode={setControlMode}
          />
        )}

        <div>
          {/* Quotation:* Author explanation held in myPoint (and overflowed into myContent if necessary) */}
          {ideaReferenced && (
            <div>
              {!hrefHiddenInternal && myPoint && (
                <IdeaTitle href={`/idea/${_id}`}>
                  <b> {myPoint} </b>
                </IdeaTitle>
              )}
              {hrefHiddenInternal && myPoint && (
                <Typography>
                  <b> {myPoint} </b>
                </Typography>
              )}
              {myContent && <Typography noWrap={!showExtended}>{myContent}</Typography>}
            </div>
          )}

          {/* URL */}
          {url && (
            <>
              {!hrefHiddenInternal && myPoint && (
                <IdeaTitle href={`/idea/${_id}`}>
                  <b> {myPoint} </b>
                </IdeaTitle>
              )}
              {hrefHiddenInternal && myPoint && (
                <Typography noWrap={!showExtended}>
                  {hideImage && isVideo && <OndemandVideo style={{ marginRight: "10px" }} />}
                  <b>{myPoint}</b>
                </Typography>
              )}
            </>
          )}

          {/* inSighter-content ) */}
          {!ideaReferenced && !url && (
            <>
              {!hrefHiddenInternal && myPoint && (
                <IdeaTitle href={`/idea/${_id}`}>
                  <b> {myPoint} </b>
                </IdeaTitle>
              )}
              {hrefHiddenInternal && myPoint && (
                <Typography noWrap={!showExtended}>
                  {!showImage && isPdf && <PictureAsPdf style={{ marginRight: "10px" }} />}
                  <b>
                    {hideImage && myPoint.length > 60
                      ? `${myPoint.substring(0, 60)}...`
                      : `${myPoint}`}
                  </b>
                </Typography>
              )}
              {myContent && (
                <Typography
                  noWrap={!showExtended}
                  style={{ fontSize: smSize ? "14px" : "11px", whiteSpace: "pre-line" }}
                >
                  {hideImage && myContent.length > 60
                    ? `${myContent.substring(0, 60)}...`
                    : myContent}
                </Typography>
              )}
            </>
          )}

          {/* Idea link (dark green) */}
          {url && !hrefHiddenExternal && (
            // eslint-disable-next-line
            <div onClick={handleExternalLink}>
              <IdeaUrl noWrap>{url}</IdeaUrl>
            </div>
          )}

          {/* Idea text (black) */}
          {ideaReferenced && text && (
            <>
              {/* Quoted text */}
              <Card style={{ margin: "0px" }}>
                <div style={{ padding: "5px 20px" }}>
                  {text && (
                    <Typography
                      noWrap={!showExtended}
                      style={greyOut ? { fontSize: "11px", color: "grey" } : {}}
                    >
                      <i>{text}</i>
                    </Typography>
                  )}
                </div>
                <div style={{ paddingBottom: "5px 20px", paddingTop: "0px" }}>
                  <i
                    style={
                      greyOut
                        ? { fontSize: "8px", color: "grey" }
                        : { fontSize: "10px", color: "grey" }
                    }
                  >
                    Quotation
                  </i>
                </div>
              </Card>
            </>
          )}
        </div>

        {/* Allow access to deeper author analysis (if it exists & was requested) */}
        {showExtended && frameContext && (
          <Accordion style={{ marginBottom: "10px", marginTop: "10px" }}>
            <AccordionSummary
              onClick={() => setExpanded(expanded + 1)}
              expandIcon={<ExpandMore />}
              aria-label="Expand frame details"
              aria-controls="frame-actions-content"
              id="frame-actions-header"
            >
              <Typography style={{ fontSize: "14px" }}>
                DEEPER ANALYSIS (PROVIDED BY AUTHOR)
              </Typography>
            </AccordionSummary>

            <ViewFrame
              atCore={atCore}
              proxyLevel={proxyLevel}
              frameContext={frameContext}
              frameResolution={frameResolution}
              myConcern={myConcern}
              criticContent={criticContent}
              criticConcern={criticConcern}
              errorCorrection={errorCorrection}
            />
          </Accordion>
        )}

        {/* Footer: Submitter, elapsed time since submission, agents accountable... */}
        {!greyOut && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "15px",
              fontSize: "10px",
              color: "grey",
            }}
          >
            {/* Display submitter avatar */}
            {showAgents && (
              <>
                <div style={{ marginTop: "-3px" }}>
                  <Tooltip title={`${user.name}`} placement="top-end">
                    <a href={`/agent/${user.agent[0]}`}>
                      <img
                        src={user.avatar ? user.avatar : defaultAvatar}
                        alt="..."
                        style={{ width: "20px", height: "20px" }}
                      />
                    </a>
                  </Tooltip>
                </div>
                <div style={{ marginLeft: "5px" }}>
                  {!ideaReferenced && <i>submitted {getTimeAgo(date)}</i>}
                  {ideaReferenced && (
                    <i>
                      quoted from{" "}
                      <ActiveLink href={`/idea/${ideaReferenced}`}>this idea</ActiveLink>{" "}
                      {getTimeAgo(date)}
                    </i>
                  )}
                </div>
              </>
            )}

            {/* Do not display submitter avatar */}
            {!showAgents && (
              <>
                {!ideaReferenced && <i>Submitted {getTimeAgo(date)}</i>}
                {ideaReferenced && (
                  <i>
                    Quoted from <ActiveLink href={`/idea/${ideaReferenced}`}>this idea</ActiveLink>{" "}
                    {getTimeAgo(date)}
                  </i>
                )}
              </>
            )}

            {/* Agents accountable */}
            {showAgents && agents && agents.length > 0 && !isAccountable && (
              <>
                <div style={{ fontSize: "10px", color: "grey" }}>
                  <i style={{ marginLeft: "4px" }}>· Accountable:</i>
                </div>
                {agents.map((e) => (
                  <div key={e._id} style={{ marginLeft: "10px", marginTop: "-3px" }}>
                    <Tooltip title={`${e.name}`} placement="top-end">
                      <a href={`/agent/${e._id}`}>
                        <img
                          src={e.image ? e.image : defaultAvatar}
                          alt="..."
                          style={{ width: "20px", height: "20px" }}
                        />
                      </a>
                    </Tooltip>
                  </div>
                ))}
              </>
            )}

            {/* Agents associated (via proxy) with this inTell */}
            {showAgents && proxySources && proxySources.length > 0 && proxyLevel > 0 && (
              <>
                <div style={{ fontSize: "10px", color: "grey" }}>
                  <i style={{ marginLeft: "4px" }}>· Proxied (${Math.round(proxyLevel * 100)}%):</i>
                </div>
                {proxySources.map((e) => (
                  <div key={e._id} style={{ marginLeft: "10px", marginTop: "-3px" }}>
                    <Tooltip title={`${e.name}`} placement="top-end">
                      <a href={`/agent/${e._id}`}>
                        <img
                          src={e.image ? e.image : defaultAvatar}
                          alt="..."
                          style={{ width: "20px", height: "20px" }}
                        />
                      </a>
                    </Tooltip>
                  </div>
                ))}
              </>
            )}
          </div>
        )}

        {/* Image display xs screen */}
        {showImage && !isPdf && (
          <div style={{ textAlign: "center" }}>
            {!smSize && (
              <img
                src={image}
                alt="..."
                style={{ marginTop: "10px", borderRadius: "4px", maxWidth: "280px" }}
              />
            )}
            {smSize && !mdSize && (
              <img
                src={image}
                alt="..."
                style={{ marginTop: "10px", borderRadius: "4px", maxWidth: "360px" }}
              />
            )}
            {mdSize && !lgSize && (
              <img
                src={image}
                alt="..."
                style={{ marginTop: "10px", borderRadius: "4px", maxWidth: "550px" }}
              />
            )}
            {mdSize && lgSize && (
              <img
                src={image}
                alt="..."
                style={{ marginTop: "10px", borderRadius: "4px", maxWidth: "950px" }}
              />
            )}
          </div>
        )}
      </Card>

      {/* Show PDF below inTell box */}
      {showImage && isPdf && (
        <div style={{ marginTop: "20px" }}>
          <ViewPdf url={image} setLoaded={setPdfLoaded} />
        </div>
      )}

      {/* Show video below inTell box */}
      {!hideImage && isVideo && (
        <div style={{ marginTop: "20px" }}>
          <ViewVideo url={url} setLoaded={setVideoLoaded} />
        </div>
      )}
    </div>
  );
};

// <div style={{ marginTop: "20px" }}>

IdeaDetail.propTypes = {
  idea: PropTypes.oneOfType([PropTypes.object]).isRequired,
  showExtended: PropTypes.bool,
  hideImage: PropTypes.bool,
  showAgents: PropTypes.bool,
  greyOut: PropTypes.bool,
  boldOutline: PropTypes.bool,
  communityTopic: PropTypes.bool,
  instructions: PropTypes.bool,
  controlMode: PropTypes.number,
  hrefHiddenInternal: PropTypes.bool,
  hrefHiddenExternal: PropTypes.bool,
  requiresURLVisit: PropTypes.bool,
  setControlMode: PropTypes.func,
  setCardHeight: PropTypes.func,
};

IdeaDetail.defaultProps = {
  showExtended: false,
  hideImage: false,
  showAgents: false,
  greyOut: false,
  boldOutline: false,
  communityTopic: false,
  instructions: false,
  controlMode: 0,
  hrefHiddenInternal: false,
  hrefHiddenExternal: false,
  requiresURLVisit: false,
  setControlMode: null,
  setCardHeight: null,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(IdeaDetail);
