import axios from "axios";
import { setAlert } from "./alert";
import {
  LINKIDEAIDEA_ERROR,
  ADD_LINKIDEAIDEA,
  GET_LINKIDEAIDEA,
  GET_LINKIDEAIDEAS,
  ADD_LINKIDEAIDEA_SCALE,
  LOADING_LINKIDEAIDEA,
} from "./types";

// Loading
export const loadingLinkIdeaIdea = () => (dispatch) => {
  dispatch({ type: LOADING_LINKIDEAIDEA });
};

// Get linkIdeaIdeas attributed to userId
export const getLinkIdeaIdeas = (userId, limitSize) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/linkIdeaIdeas/user/${userId}`, {
      params: {
        limitSize,
      },
    });
    dispatch({
      type: GET_LINKIDEAIDEAS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: LINKIDEAIDEA_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
    dispatch(
      setAlert(`Failed To Retrieve linkIdeaIdeas From Server: ${err.response.data}`, "danger")
    );
  }
};

// Add linkIdeaIdea
export const addLinkIdeaIdea = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post("/api/linkIdeaIdeas", formData, config);
    dispatch({
      type: ADD_LINKIDEAIDEA,
      payload: res.data,
    });
    //  dispatch(setAlert('linkIdeaIdea Created', 'success', 1000));
  } catch (err) {
    // err.response.status = error code - ie, 400, 404, 500...
    // err.response.statusText = Text meaning associated with err.response.status
    // err.response.data.errors[] = An array of all errors
    const { errors } = err.response.data;
    if (errors) {
      errors.forEach((error) =>
        dispatch(setAlert(`Failed To Create linkIdeaIdea On Server: ${error.msg}`, "danger"))
      );
    }
    dispatch({
      type: LINKIDEAIDEA_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get linkIdeaIdea
// API uses res.send() - error message text is in err.response.data
export const getLinkIdeaIdea = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/linkIdeaIdeas/${id}`);
    dispatch({
      type: GET_LINKIDEAIDEA,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: LINKIDEAIDEA_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
    dispatch(
      setAlert(`Failed To Retrieve linkIdeaIdea From Server: ${err.response.data}`, "danger")
    );
  }
};

// Add linkIdeaIdea scale
export const addLinkIdeaIdeaScale = (linkIdeaIdeaId, formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(`/api/linkIdeaIdeas/scale/${linkIdeaIdeaId}`, formData, config);
    dispatch({
      type: ADD_LINKIDEAIDEA_SCALE,
      payload: res.data,
    });
    //  dispatch(setAlert('linkIdeaIdea-scale Added', 'success', 1000));
  } catch (err) {
    // err.response.status = error code - ie, 400, 404, 500...
    // err.response.statusText = Text meaning associated with err.response.status
    // err.response.data.errors[] = An array of all errors
    const { errors } = err.response.data;
    if (errors) {
      errors.forEach((error) =>
        dispatch(setAlert(`Failed To Add linkIdeaIdea-scale On Server: ${error.msg}`, "danger"))
      );
    }
    dispatch({
      type: LINKIDEAIDEA_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
