import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// MUI
import { Card, Checkbox } from "@mui/material";
import MKBox from "components/MaterialKit/MKBox";
import Typography from "components/MaterialKit/MKTypography";
import MKInput from "components/MaterialKit/MKInput";
import MKButton from "components/MaterialKit/MKButton";

import bgImage from "assets/images/campfire/desertcampfire-blurred1.png";
// import BasicLayout from "pages/Authentication/components/BasicLayout";
import { EDUCATION_BUILD, REGISTER_USER_NAME } from "config/personalize";
import { register } from "../../actions/auth";
import { setAlert } from "../../actions/alert";

import BasicLayout from "./BasicLayout";
import DialogMessage from "../dialog/DialogMessage";

const Register = ({ register, setAlert, auth }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const [password2Success, setPassword2Success] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [password1Error, setPassword1Error] = useState(false);
  const [password2Error, setPassword2Error] = useState(false);

  const [termsDialog, setTermsDialog] = useState(false);

  const [checked, setChecked] = useState(false);
  const handleToggle = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    if (name.length > 0) {
      setNameError(false);
    }
  }, [name]);

  // Check if string is email
  const checkIfEmail = (str) => {
    // Regular expression to check if string is email
    const regexExp =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
    return regexExp.test(str);
  };

  useEffect(() => {
    if (checkIfEmail(email)) {
      setEmailError(false);
    }
  }, [email]);

  useEffect(() => {
    if (password1.length >= 6) {
      setPassword1Error(false);
    }
  }, [password1]);

  useEffect(() => {
    if (password1.length > 0 && password2.length > 0) {
      if (password1 === password2) {
        setPassword2Success(true);
        setPassword2Error(false);
      } else {
        setPassword2Success(false);
      }
    } else {
      setPassword2Success(false);
    }
  }, [password1, password2]);

  const onSubmitRegister = () => {
    if (REGISTER_USER_NAME && name.length < 1) {
      setAlert("Invalid User Name - mandatory field", "danger");
      setNameError(true);
    } else if (!checkIfEmail(email)) {
      setAlert("Please enter a valid email address", "danger");
      setEmailError(true);
    } else if (password1.length < 6) {
      setAlert("Password too short - must be at least 6 characters", "danger");
      setPassword1Error(true);
    } else if (password1 !== password2) {
      setAlert("Passwords entered do not match. Please enter again.", "danger");
      setPassword2Error(true);
    } else if (!checked) {
      setAlert('Registration requires acceptance of our "Terms of Use"', "danger");
    } else if (REGISTER_USER_NAME && name.length > 0) {
      register({ name, email, password: password1 });
    } else {
      // Register without a user name (use email address)
      register({ name: email, email, password: password1 });
    }
  };

  if (!auth.loading && auth.isAuthenticated && auth.user) {
    if (EDUCATION_BUILD) {
      console.log("Goto /activity-students");
      return <Navigate to="/activity-students" />;
    }
    console.log("Goto /welcome");
    return <Navigate to="/welcome" />;
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSubmitRegister();
      event.preventDefault();
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <Typography variant="h4" fontWeight="medium" color="white" mt={1}>
            Create your inSight account
          </Typography>
          <Typography display="block" variant="button" color="white" my={1}>
            Enter your details below to register
          </Typography>
        </MKBox>
        <MKBox p={3}>
          <MKBox component="form" role="form">
            {REGISTER_USER_NAME && (
              <MKBox mb={2} onChange={(e) => setName(e.target.value)}>
                <MKInput
                  error={nameError}
                  label="I will be known in the inSight platform as..."
                  fullWidth
                  onKeyDown={handleKeyDown}
                />
              </MKBox>
            )}
            <MKBox mb={2} onChange={(e) => setEmail(e.target.value)}>
              <MKInput error={emailError} label="Email" fullWidth onKeyDown={handleKeyDown} />
            </MKBox>
            <MKBox mb={2} onChange={(e) => setPassword1(e.target.value)}>
              <MKInput
                error={password1Error}
                label="Password (at least 6 characters)"
                fullWidth
                onKeyDown={handleKeyDown}
              />
            </MKBox>
            <MKBox mb={2} onChange={(e) => setPassword2(e.target.value)}>
              <MKInput
                success={password2Success}
                error={password2Error}
                label="Confirm Password"
                fullWidth
                onKeyDown={handleKeyDown}
              />
            </MKBox>
            <MKBox display="flex" alignItems="center" ml={-1}>
              <Checkbox onClick={handleToggle} onKeyDown={handleKeyDown} />
              <Typography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree to the&nbsp;
              </Typography>
              <Typography
                component="a"
                onClick={() => setTermsDialog(true)}
                variant="button"
                fontWeight="bold"
                color="primary"
                textGradient
              >
                Terms of Use
              </Typography>
            </MKBox>
            <MKBox mt={4} mb={1}>
              <MKButton variant="gradient" color="primary" fullWidth onClick={onSubmitRegister}>
                Submit
              </MKButton>
            </MKBox>
            <MKBox mt={3} mb={1} textAlign="center">
              <Typography variant="button" color="text">
                Already have an account?{" "}
                <Typography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="primary"
                  fontWeight="medium"
                  textGradient
                >
                  Sign in
                </Typography>
              </Typography>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>

      {termsDialog && <DialogMessage terms handleDialogClose={() => setTermsDialog(false)} />}
    </BasicLayout>
  );
};

Register.propTypes = {
  register: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  auth: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { register, setAlert })(Register);
