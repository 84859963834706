import React, { useState } from "react";
import PropTypes from "prop-types";

// mui
import { Card, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { Add } from "@mui/icons-material";
import Button from "components/MaterialKit/MKButton";

import BackgroundImage from "assets/images/Tree/shapes.png";
import ShowLinkList from "./ShowLinkList";

const GridImage = styled(Grid)(({ theme }) =>
  theme.unstable_sx({
    backgroundImage: `url(${BackgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "50%",
    backgroundPosition: "center",
    minHeight: "240px",
    [theme.breakpoints.up("sm")]: {
      minHeight: "360px",
      backgroundSize: "40%",
    },
  })
);

const LessComplexIdeas = ({ idea, auth, showAddButton, requestSearchLeft }) => {
  // Filter out less complex ideas to display
  const [lessComplexIdeas] = useState(
    idea.linkedIdeas
      .filter((e) => e.significanceComplex > e.significanceSimilar)
      .filter((e) => e.significanceComplex > e.significanceSupportOppose)
      .filter(
        (e) =>
          (e.ideaEffect._id === idea._id && e.avgLevelReversed <= 0.5) ||
          (e.ideaCause._id === idea._id && e.avgLevelReversed > 0.5)
      )
  );

  return (
    <GridImage container>
      {/* The selected/central idea is the reference for the essences (listed below-left) */}
      <Grid item xs>
        <Card style={{ marginTop: "10px", marginBottom: "10px", marginLeft: "0px" }}>
          <div
            style={{
              color: "white",
              backgroundColor: "black",
              padding: "0px 0px",
              fontSize: "14px",
              boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",
            }}
          >
            <Grid container>
              <Grid item xs={1} style={{ paddingTop: "2px", paddingLeft: "30px" }}>
                {auth.isAuthenticated && showAddButton && (
                  <Button
                    iconOnly
                    onClick={() => requestSearchLeft(true)}
                    style={{
                      marginLeft: "-20px",
                      backgroundColor: "transparent",
                      color: "white",
                      padding: "0px 10px",
                    }}
                  >
                    {" "}
                    <Add />{" "}
                  </Button>
                )}
              </Grid>

              <Grid item xs style={{ textAlign: "right", padding: "5px 30px" }}>
                Key points from above
              </Grid>
            </Grid>
          </div>
        </Card>

        <ShowLinkList
          relatedIdeaLinks={lessComplexIdeas}
          parentIdeaID={idea._id}
          cardColor="white"
        />
      </Grid>

      {/* Quotes from the central idea) */}
      <Grid item xs>
        <Card style={{ marginTop: "10px", marginBottom: "10px" }}>
          <div
            style={{
              color: "white",
              backgroundColor: "black",
              padding: "0px 0px",
              fontSize: "14px",
              boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",
            }}
          >
            <Grid container>
              <Grid item xs style={{ textAlign: "left", padding: "5px 30px" }}>
                Quotes from above
              </Grid>

              <Grid
                item
                xs={1}
                style={{ textAlign: "right", paddingRight: "50px", paddingTop: "2px" }}
              >
                {auth.isAuthenticated && showAddButton && (
                  <Button
                    iconOnly
                    style={{ backgroundColor: "transparent", color: "white", padding: "0px 10px" }}
                    href={`/quote-extract/${idea._id}`}
                  >
                    {" "}
                    <Add />{" "}
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>
        </Card>

        <ShowLinkList relatedIdeaLinks={idea.quotes} cardColor="white" />
      </Grid>
    </GridImage>
  );
};

LessComplexIdeas.propTypes = {
  idea: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
  auth: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
  showAddButton: PropTypes.bool.isRequired,
  requestSearchLeft: PropTypes.func.isRequired,
};

export default LessComplexIdeas;
