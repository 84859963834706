// MUI
// import { PostAdd, Search, Public, AccountCircle, Surfing, BugReport } from "@mui/icons-material";
import { PostAdd, Public, AccountCircle, Surfing } from "@mui/icons-material";

const RoutesAdmin = (profileID) => [
  {
    name: "Submit new inTell",
    icon: <PostAdd />,
    collapse: [
      {
        name: "My insights",
        route: "/create1",
      },
      {
        name: "Web link",
        route: "/create2",
      },
    ],
  },
  //  {
  //    name: "Search inSight",
  //    icon: <Search />,
  //    route: "/logout",
  //  },
  {
    name: "Activities",
    icon: <Surfing />,
    collapse: [
      {
        name: "Edit (as Admin)",
        route: "/activity-edit",
      },
      {
        name: "View (as Student)",
        route: "/activity-students",
      },
    ],
  },
  {
    name: "About inSight",
    icon: <Public />,
    route: "/about",
  },
  {
    name: "Account",
    icon: <AccountCircle />,
    collapse: [
      {
        name: "My profile",
        route: `/profile/${profileID}`,
      },
      {
        name: "Logout",
        route: "/logout",
      },
    ],
  },
  //  {
  //    name: "Suggestions and Bug Reporting",
  //    icon: <BugReport />,
  //    action: 1,
  //  },
];

export default RoutesAdmin;
