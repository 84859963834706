import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useSwipeable } from "react-swipeable";

// MUI
import { Slide } from "@mui/material";

import Spinner from "../layout/Spinner";

import AgentDetail from "./AgentDetail";
import TheirIdeas from "./TheirIdeas";
import SimilarAgents from "./SimilarAgents";
import RelatedAgents from "./RelatedAgents";
import RelatedIdeas from "./RelatedIdeas";
import Meaning from "./Meaning";
import SearchList from "./SearchList";
import { getAgent, searchAgents, loadingAgent } from "../../actions/agent";
import { addLinkAgentAgent } from "../../actions/linkAgentAgent";
import { addLinkIdeaAgent } from "../../actions/linkIdeaAgent";

// Enters via router: <PrivateRoute exact path='/agent/:id/:mode?/:field?' component={Agent} />
//
// See idea/Idea.js for an explanation of how this component operates (Agent.js and Idea.js are parallel components)
//
// retrieveInterpretationStatus={setInterpreted} from <Meaning/> will then determine possible user functions
//
// --------------------------------------------
// ACTIONS
// - agent requested from backend via action loadingAgent/getAgent (spinner shown until agent loaded)
// - agent details displayed via component AgentDetail
// - support/opposition lists displayed via component ShowLinkList
// - add support/opposition linkAgentAgent (or linkIdeaAgent) via component SearchList
// - addLinkAgentAgent action (submit to backend)
// - addLinkIdeaAgent action (submit to backend)

const Agent = ({
  getAgent,
  searchAgents,
  loadingAgent,
  addLinkAgentAgent,
  addLinkIdeaAgent,
  agent: { agent, loading },
  auth,
  linkAgentAgent,
  linkIdeaAgent,
}) => {
  const { id, mode, field } = useParams();

  const defaultTransitionDuration = 250; // 250

  // Do not render anything until we request agent to be loaded (will cause spinner icon)
  const [renderReady, setRenderReady] = useState(false);

  // Before render, call loadingAgent() to set <agent> = null and <loading> = true (will cause spinner icon)
  useEffect(() => {
    loadingAgent();
  }, [loadingAgent]);

  // Load agent on first render
  useEffect(() => {
    getAgent(id);
    setRenderReady(true);
  }, [getAgent, id]);

  // Store initial linkAgentAgent and linkIdeaAgent; monitor for changes to exit
  const [initLinkAgentAgent] = useState(linkAgentAgent.linkAgentAgent);
  const [initLinkIdeaAgent] = useState(linkIdeaAgent.linkIdeaAgent);

  // Add buttons
  const [searchingAgentsFromBottomRight, setSearchingAgentsFromBottomRight] = useState(false);
  const [searchingAgentsFromBottomLeft, setSearchingAgentsFromBottomLeft] = useState(false);
  const [searchingAgentsFromTop, setSearchingAgentsFromTop] = useState(false);
  const [searchingIdeasFromBottomRight, setSearchingIdeasFromBottomRight] = useState(false);
  const [searchingIdeasFromBottomLeft, setSearchingIdeasFromBottomLeft] = useState(false);
  const handleUnload = () => {
    setSearchingAgentsFromBottomRight(false);
    setSearchingAgentsFromBottomLeft(false);
    setSearchingAgentsFromTop(false);
    setSearchingIdeasFromBottomRight(false);
    setSearchingIdeasFromBottomLeft(false);
  };

  // Reset agents=[] on every mode shift (prevents old search lists from being rendered)
  useEffect(() => {
    searchAgents("", 10);
  }, [searchAgents]);

  // 1=Meaning, 2=Ideas-Related, 3=Ideas-Participation, 4=Agents-Related
  const [controlMode, setControlMode] = useState(mode === "1000" && field ? Number(field) : 1);
  const [loadingLinkObject, setLoadingLinkObject] = useState(false);

  // Has waveMaker been interpreted by current inSighter? For interpreted to be true, we require the inSighter to either be:
  // (1) the waveMaker is self (the inSighter); OR
  // (2) the waveMaker has been tagged by the inSighter
  const [interpreted, setInterpreted] = useState(false);

  // Upon transition from editing meaning to viewing meaning (while controlMode===1):
  // - Submit new link-agent-agent/link-idea-agent once waveMaker is viewable "viewable" (and if instructed by optional parameters); or
  // - Set control mode=2
  //
  // This can be called upon first entry into component or triggered by <Meaning> component alerting parent component that the inTell has been interpreted
  const navigate = useNavigate();
  const handleInterpretation = (e) => {
    if (controlMode === 1 && !interpreted && e) {
      // Transition from not interpreted (and/or initial state) to interpreted
      if (mode && typeof mode !== "undefined") {
        switch (mode) {
          case "40":
            // Create a support linkIdeaAgent to specified inTell (field is now the idea, original center of attention)
            console.log("Submit link-idea-agent support link [40]");
            setLoadingLinkObject(true);
            addLinkIdeaAgent({
              agent: agent._id, // agent currently in focus
              idea: field, // linked inTell
              modeOnSubmit: "40",
            });
            break;
          case "41":
            // Create an oppose linkIdeaAgent to specified inTell (field is now the idea, original center of attention)
            console.log("Submit link-idea-agent oppose link [41]");
            setLoadingLinkObject(true);
            addLinkIdeaAgent({
              agent: agent._id, // agent currently in focus
              idea: field, // linked inTell
              modeOnSubmit: "41",
            });
            break;
          case "50":
            // Create a support linkAgentAgent to specified waveMaker (field is now the agent2, original center of attention)
            console.log("Submit link-agent-agent support link [50]");
            setLoadingLinkObject(true);
            addLinkAgentAgent({
              agent1: agent._id, // agent currently in focus (just added)
              agent2: field, // linked waveMaker
              modeOnSubmit: "50",
            });
            break;
          case "51":
            // Create a support linkAgentAgent to specified waveMaker (field is now the agent, original center of attention)
            console.log("Submit link-agent-agent support link [51]");
            setLoadingLinkObject(true);
            addLinkAgentAgent({
              agent1: field, // linked waveMaker
              agent2: agent._id, // agent currently in focus (just added)
              modeOnSubmit: "51",
            });
            break;
          case "52":
            // Create an oppose linkAgentAgent to specified waveMaker (field is now the agent2, original center of attention)
            console.log("Submit link-agent-agent oppose link [52]");
            setLoadingLinkObject(true);
            addLinkAgentAgent({
              agent1: agent._id, // agent currently in focus (just added)
              agent2: field, // linked waveMaker
              modeOnSubmit: "52",
            });
            break;
          case "53":
            // Create an oppose linkAgentAgent to specified waveMaker (field is now the agent, original center of attention)
            console.log("Submit link-agent-agent oppose link [53]");
            setLoadingLinkObject(true);
            addLinkAgentAgent({
              agent1: field, // linked waveMaker
              agent2: agent._id, // agent currently in focus (just added)
              modeOnSubmit: "53",
            });
            break;
          case "54":
            // Create a similar linkAgentAgent to specified waveMaker (field is now the agent2, original center of attention)
            console.log("Submit link-agent-agent similar link [54]");
            setLoadingLinkObject(true);
            addLinkAgentAgent({
              agent1: agent._id, // agent currently in focus (just added)
              agent2: field, // linked waveMaker
              modeOnSubmit: "54",
            });
            break;
          case "55":
            // Create a similar linkAgentAgent to specified waveMaker (field is now the agent, original center of attention)
            console.log("Submit link-agent-agent similar link [55]");
            setLoadingLinkObject(true);
            addLinkAgentAgent({
              agent1: field, // linked waveMaker
              agent2: agent._id, // agent currently in focus (just added)
              modeOnSubmit: "55",
            });
            break;
          case "300":
            // inSighter has successfully interpreted the waveMaker; goto specified link-idea-agent
            console.log(
              "inSighter has successfully interpreted the waveMaker; goto specified link-idea-agent [300]"
            );
            navigate(`/link-idea-idea/${field}`);
            break;
          case "400":
            // inSighter has successfully interpreted the waveMaker; goto specified link-agent-agent
            console.log(
              "inSighter has successfully interpreted the waveMaker; goto specified link-agent-agent [400]"
            );
            navigate(`/link-agent-agent/${field}`);
            break;
          default:
            console.log("No inTell instructions encoded in instructions; do nothing");
        }
      } else {
        // Goto control mode 2 upon first view
        setControlMode(2);
      }
      setInterpreted(e);
    }
  };

  // Do not try show <AgentDetail> until agent has been loaded and reviewed (below)
  const [initializationComplete, setInitializationComplete] = useState(false);
  useEffect(() => {
    if (
      !initializationComplete &&
      !loadingLinkObject &&
      renderReady &&
      !loading &&
      agent &&
      agent.scales &&
      auth &&
      auth.user &&
      auth.user._id
    ) {
      // Pull out inSighter's prior interpretation (if one exists)
      // Note: This logic is mirrored in <Meaning>
      console.log("useEffect agent", agent);

      let priorScaleExists = null;
      if (agent.scales.length > 0) {
        priorScaleExists = agent.scales.find(
          (e) =>
            (e.user && e.user._id && e.user._id === auth.user._id) ||
            (e.member && e.member._id && e.member._id === auth.user._id)
        );
      }
      if (priorScaleExists) {
        console.log("priorScaleExists true -- current inSighter previously interpreted");
        handleInterpretation(true);
      } else if (agent.member && agent.member._id && agent.member._id === auth.user._id) {
        console.log("Current inSighter and waveMaker ae the same (self)");
        handleInterpretation(true);
      }
      setInitializationComplete(true);
    }
  }, [agent, auth]);

  // Jump to link-agent-agent page when a new linkAgentAgent has been successfully submitted.
  useEffect(() => {
    if (linkAgentAgent.linkAgentAgent !== initLinkAgentAgent) {
      navigate(`/link-agent-agent/${linkAgentAgent.linkAgentAgent._id}`);
    }
  }, [linkAgentAgent, initLinkAgentAgent]);

  // Jump to link-idea-agent page when a new linkIdeaAgent has been successfully submitted.
  useEffect(() => {
    if (linkIdeaAgent.linkIdeaAgent !== initLinkIdeaAgent) {
      navigate(`/link-idea-agent/${linkIdeaAgent.linkIdeaAgent._id}`);
    }
  }, [linkIdeaAgent, initLinkIdeaAgent]);

  // Animations begun to enter? (update counter everytime which triggers useEffect)
  const [meaningEnterCount, setMeaningEnterCount] = useState(0);

  // First render transitionDuration=0ms, after that: set to 250ms+250ms
  const [transitionDuration, setTransitionDuration] = useState(0);
  const [ideasRelatedSlideDirection, setIdeasRelatedSlideDirection] = useState();
  const [ideasParticipationSlideDirection, setIdeasParticipationSlideDirection] = useState();

  const [meaningView, setMeaningView] = useState(controlMode === 1);
  const [ideasRelatedView, setIdeasRelatedView] = useState(controlMode === 2);
  const [ideasParticipationView, setIdeasParticipationView] = useState(controlMode === 3);
  const [agentsRelatedView, setAgentsRelatedView] = useState(controlMode === 4);

  // Scroll to middle of screen
  const centralAgentRef = useRef();

  // Change in controlMode
  useEffect(() => {
    // Goto meaningView (#1)
    if (controlMode === 1) {
      let bChange = false;
      if (ideasRelatedView) {
        // 2->1
        bChange = true;
        setIdeasRelatedView(false);
        setIdeasRelatedSlideDirection("left");
      }
      if (ideasParticipationView) {
        // 3->1
        bChange = true;
        setIdeasParticipationSlideDirection("left");
        setIdeasParticipationView(false);
      }
      if (agentsRelatedView) {
        // 4->1
        bChange = true;
        setAgentsRelatedView(false);
      }
      if (bChange) {
        // Delay entry of new mode animation until prior mode animation complete
        setTransitionDuration(defaultTransitionDuration);
        setTimeout(() => {
          setMeaningView(true);
          if (centralAgentRef.current) {
            window.scrollTo(0, centralAgentRef.current.offsetTop - 150);
          }
        }, transitionDuration);
      }
    }

    // Goto ideasRelatedView (#2)
    if (controlMode === 2) {
      let bChange = false;
      if (meaningView) {
        // 1->2
        bChange = true;
        setMeaningView(false);
        setIdeasRelatedSlideDirection("left");
      }
      if (ideasParticipationView) {
        // 3->2
        bChange = true;
        setIdeasParticipationSlideDirection("left");
        setIdeasParticipationView(false);
        setIdeasRelatedSlideDirection("right");
      }
      if (agentsRelatedView) {
        // 4->2
        bChange = true;
        setAgentsRelatedView(false);
        setIdeasRelatedSlideDirection("right");
      }
      if (bChange) {
        // Delay entry of new mode animation until prior mode animation complete
        setTransitionDuration(defaultTransitionDuration);
        setTimeout(() => {
          setIdeasRelatedView(true);
          if (centralAgentRef.current) {
            window.scrollTo(0, centralAgentRef.current.offsetTop - 150);
          }
        }, transitionDuration);
      }
    }

    // Goto ideasParticipationView (#3)
    if (controlMode === 3) {
      let bChange = false;
      if (meaningView) {
        // 1->3
        bChange = true;
        setMeaningView(false);
        setIdeasParticipationSlideDirection("left");
      }
      if (ideasRelatedView) {
        // 2->3
        bChange = true;
        setIdeasParticipationSlideDirection("left");
        setIdeasRelatedView(false);
        setIdeasRelatedSlideDirection("right");
      }
      if (agentsRelatedView) {
        // 4->3
        bChange = true;
        setAgentsRelatedView(false);
        setIdeasParticipationSlideDirection("right");
      }
      if (bChange) {
        // Delay entry of new mode animation until prior mode animation complete
        setTransitionDuration(defaultTransitionDuration);
        setTimeout(() => {
          setIdeasParticipationView(true);
          if (centralAgentRef.current) {
            window.scrollTo(0, centralAgentRef.current.offsetTop - 150);
          }
        }, transitionDuration);
      }
    }

    // Goto agentsRelatedView (#4)
    if (controlMode === 4) {
      let bChange = false;
      if (meaningView) {
        // 1->4
        bChange = true;
        setMeaningView(false);
      }
      if (ideasRelatedView) {
        // 2->4
        bChange = true;
        setIdeasRelatedView(false);
        setIdeasRelatedSlideDirection("right");
      }
      if (ideasParticipationView) {
        // 3->4
        bChange = true;
        setIdeasParticipationSlideDirection("right");
        setIdeasParticipationView(false);
      }
      if (bChange) {
        // Delay entry of new mode animation until prior mode animation complete
        setTransitionDuration(defaultTransitionDuration);
        setTimeout(() => {
          setAgentsRelatedView(true);
          if (centralAgentRef.current) {
            window.scrollTo(0, centralAgentRef.current.offsetTop - 150);
          }
        }, transitionDuration);
      }
    }
  }, [
    controlMode,
    meaningView,
    ideasRelatedView,
    ideasParticipationView,
    agentsRelatedView,
    transitionDuration,
  ]);

  // Swipe handler
  const handlers = useSwipeable({
    onSwipedRight: () => {
      if (controlMode > 1) {
        setControlMode(controlMode - 1);
      }
    },
    onSwipedLeft: () => {
      if (controlMode < 4) {
        setControlMode(controlMode + 1);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return !renderReady ||
    loading ||
    loadingLinkObject ||
    !agent ||
    !auth ||
    !auth.user ||
    !initializationComplete ? (
    <Spinner />
  ) : (
    // eslint-disable-next-line
    <div {...handlers}>
      <div>
        {/* AGENTS ("similar agents") */}
        <Slide
          in={agentsRelatedView}
          timeout={transitionDuration}
          direction="left"
          mountOnEnter
          unmountOnExit
        >
          <div>
            <SimilarAgents
              agent={agent}
              auth={auth}
              showAddButton={
                !searchingAgentsFromBottomRight &&
                !searchingAgentsFromBottomLeft &&
                !searchingAgentsFromTop
              }
              requestSearch={setSearchingAgentsFromTop}
            />
          </div>
        </Slide>

        {/* SEARCH LIST ABOVE CENTRAL AGENT (adding a related agent) */}
        {/* Create new waveMaker above, then (from new waveMaker perspective) a similar link (effect) back to waveMaker at center of current attention [54] (controlMode===3, searchingAgentsFromTop) */}
        <div style={{ color: "black", textAlign: "left", fontSize: "12px" }}>
          {auth.isAuthenticated && controlMode === 4 && searchingAgentsFromTop && (
            <SearchList
              mode="54"
              existingAgentList={agent.linkedAgents}
              requestComponentUnload={handleUnload}
            />
          )}
        </div>

        {/* CENTRAL AGENT */}
        <div ref={centralAgentRef} style={{ marginTop: "15px" }}>
          <AgentDetail
            agent={agent}
            controlMode={controlMode === 1 && !interpreted ? 0 : controlMode}
            setControlMode={setControlMode}
            boldOutline
            hrefHiddenInternal
            requiresURLVisit
          />
        </div>

        {/* SEARCH IDEA LIST BELOW CENTRAL IDEA */}
        {/* Create new inTell below, then (from new inTell perspective) a support link (cause) back to waveMaker at center of current attention [30] (controlMode===3, searchingIdeasFromBottomRight) */}
        {/* Create new inTell below, then (from new inTell perspective) an oppose link (cause) back to waveMaker at center of current attention [31] (controlMode===3, searchingIdeasFromBottomLeft) */}
        <div style={{ color: "black", textAlign: "left", fontSize: "12px" }}>
          {auth.isAuthenticated &&
            ((controlMode === 3 && searchingIdeasFromBottomRight) ||
              (controlMode === 3 && searchingIdeasFromBottomLeft)) && (
              <SearchList
                mode={searchingIdeasFromBottomRight ? "30" : "31"}
                existingIdeaList={agent.linkedIdeas}
                requestComponentUnload={handleUnload}
              />
            )}
        </div>

        {/* SEARCH AGENT LIST BELOW CENTRAL AGENT */}
        {/* Create new waveMaker below, then (from new waveMaker perspective) a support link (cause) back to waveMaker at center of current attention [51] (controlMode===4, searchingAgentsFromBottomRight) */}
        {/* Create new waveMaker below, then (from new waveMaker perspective) an oppose link (cause) back to waveMaker at center of current attention [53] (controlMode===4, searchingAgentsFromBottomLeft) */}
        <div style={{ color: "black", textAlign: "left", fontSize: "12px" }}>
          {auth.isAuthenticated &&
            ((controlMode === 4 && searchingAgentsFromBottomRight) ||
              (controlMode === 4 && searchingAgentsFromBottomLeft)) && (
              <SearchList
                mode={searchingAgentsFromBottomRight ? "51" : "53"}
                existingAgentList={agent.linkedAgents}
                requestComponentUnload={handleUnload}
              />
            )}
        </div>

        {/* MEANING (top "meaning 2x2") */}
        <Slide
          in={meaningView}
          timeout={transitionDuration}
          direction="right"
          onEntering={() => setMeaningEnterCount(meaningEnterCount + 1)}
          onEntered={() => setMeaningEnterCount(meaningEnterCount + 1)}
          mountOnEnter
          unmountOnExit
        >
          <div>
            <Meaning agent={agent} retrieveInterpretationStatus={handleInterpretation} />
          </div>
        </Slide>

        {/* IDEAS (bottom "their ideas") */}
        <Slide
          in={ideasRelatedView}
          timeout={transitionDuration}
          direction={ideasRelatedSlideDirection}
          mountOnEnter
          unmountOnExit
        >
          <div>
            <TheirIdeas agent={agent} auth={auth} />
          </div>
        </Slide>

        {/* IDEAS (bottom "particatory ideas") */}
        <Slide
          in={ideasParticipationView}
          timeout={transitionDuration}
          direction={ideasParticipationSlideDirection}
          mountOnEnter
          unmountOnExit
        >
          <div>
            <RelatedIdeas
              agent={agent}
              auth={auth}
              showAddButton={!searchingIdeasFromBottomRight && !searchingIdeasFromBottomLeft}
              requestSearchRight={setSearchingIdeasFromBottomRight}
              requestSearchLeft={setSearchingIdeasFromBottomLeft}
            />
          </div>
        </Slide>

        {/* AGENTS (bottom "related agents") */}
        <Slide
          in={agentsRelatedView}
          timeout={transitionDuration}
          direction="left"
          mountOnEnter
          unmountOnExit
        >
          <div>
            <RelatedAgents
              agent={agent}
              auth={auth}
              showAddButton={
                !searchingAgentsFromBottomRight &&
                !searchingAgentsFromBottomLeft &&
                !searchingAgentsFromTop
              }
              requestSearchRight={setSearchingAgentsFromBottomRight}
              requestSearchLeft={setSearchingAgentsFromBottomLeft}
            />
          </div>
        </Slide>
      </div>
    </div>
  );
};

Agent.propTypes = {
  getAgent: PropTypes.func.isRequired,
  searchAgents: PropTypes.func.isRequired,
  loadingAgent: PropTypes.func.isRequired,
  addLinkAgentAgent: PropTypes.func.isRequired,
  addLinkIdeaAgent: PropTypes.func.isRequired,
  agent: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
  linkAgentAgent: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
  linkIdeaAgent: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
  auth: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
};

const mapStateToProps = (state) => ({
  agent: state.agent,
  linkAgentAgent: state.linkAgentAgent,
  linkIdeaAgent: state.linkIdeaAgent,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getAgent,
  searchAgents,
  loadingAgent,
  addLinkAgentAgent,
  addLinkIdeaAgent,
})(Agent);
