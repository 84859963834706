import React, { useState } from "react";
import PropTypes from "prop-types";

// MUI
import { styled } from "@mui/system";
import { Grid, Card } from "@mui/material";
import { Add } from "@mui/icons-material";
import Button from "components/MaterialKit/MKButton";

import BackgroundImage from "assets/images/Tree/similar.png";
import ShowLinkList from "./ShowLinkList";

const GridImage = styled(Grid)(({ theme }) =>
  theme.unstable_sx({
    backgroundImage: `url(${BackgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "40%",
    backgroundPosition: "center",
    [theme.breakpoints.down("xs")]: {
      minHeight: "240px",
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "360px",
      backgroundSize: "30%",
    },
  })
);

const SimilarAgents = ({ agent, auth, showAddButton, requestSearch }) => {
  // Filter out similar to display in which current agent is the "effect" (and other agents are the "causes")
  const [similarAgents] = useState(
    agent.linkedAgents.filter((e) => e.significanceSimilar > e.significanceSupportOppose)
  );

  return (
    <GridImage container alignItems="flex-end" direction="row">
      {/* The selected/central agent is similar to these other agents (listed below) */}
      <Grid item xs>
        <ShowLinkList
          relatedAgentLinks={similarAgents}
          parentAgentID={agent._id}
          cardColor="white"
        />

        <Card style={{ marginTop: "10px", marginBottom: "10px" }}>
          <div
            style={{
              color: "white",
              backgroundColor: "black",
              padding: "0px 0px",
              boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",
              fontSize: "14px",
            }}
          >
            <Grid container>
              <Grid item xs style={{ textAlign: "left", padding: "5px 30px" }}>
                Related waveMakers
              </Grid>

              <Grid
                item
                xs={1}
                style={{ textAlign: "right", paddingRight: "50px", paddingTop: "2px" }}
              >
                {auth.isAuthenticated && showAddButton && (
                  <Button
                    iconOnly
                    style={{ backgroundColor: "transparent", color: "white", padding: "0px 10px" }}
                    onClick={() => requestSearch(true)}
                  >
                    {" "}
                    <Add />{" "}
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>
        </Card>
      </Grid>
    </GridImage>
  );
};

SimilarAgents.propTypes = {
  agent: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
  auth: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
  requestSearch: PropTypes.func.isRequired,
};

export default SimilarAgents;
