import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "@react-hook/media-query";

// MUI
import { Stack } from "@mui/material";
import Typography from "components/MaterialKit/MKTypography";

import karateRed from "assets/images/slider/karate_red.png";
import liftGreen from "assets/images/slider/lift_green.png";
import Slider from "./Slider";

const supportMarks = [
  {
    value: 0,
    label: <Typography sx={{ fontSize: "12px" }}>None</Typography>,
  },
  {
    value: 0.33,
    label: <Typography sx={{ fontSize: "12px" }}>Somewhat</Typography>,
  },
  {
    value: 0.66,
    label: <Typography sx={{ fontSize: "12px" }}>Significantly</Typography>,
  },
  {
    value: 1,
    label: <Typography sx={{ fontSize: "12px", marginRight: "20px" }}>Totally</Typography>,
  },
];

const supportMarksSmall = [
  {
    value: 0,
    label: <Typography sx={{ fontSize: { xs: "9px", sm: "10px", md: "11px" } }}>None</Typography>,
  },
  {
    value: 0.5,
    label: (
      <Typography sx={{ fontSize: { xs: "9px", sm: "10px", md: "11px" } }}>Somewhat</Typography>
    ),
  },
  {
    value: 1,
    label: (
      <Typography sx={{ fontSize: { xs: "9px", sm: "10px", md: "11px" }, marginRight: "10px" }}>
        Totally
      </Typography>
    ),
  },
];

const opposeMarks = [
  {
    value: -1,
    label: <Typography sx={{ fontSize: "12px", marginLeft: "20px" }}>Totally</Typography>,
  },
  {
    value: -0.66,
    label: <Typography sx={{ fontSize: "12px" }}>Significantly</Typography>,
  },
  {
    value: -0.33,
    label: <Typography sx={{ fontSize: "12px" }}>Somewhat</Typography>,
  },
];

const opposeMarksSmall = [
  {
    value: -1,
    label: (
      <Typography sx={{ fontSize: { xs: "9px", sm: "10px", md: "11px" }, marginLeft: "10px" }}>
        Totally
      </Typography>
    ),
  },
  {
    value: -0.5,
    label: (
      <Typography sx={{ fontSize: { xs: "9px", sm: "10px", md: "11px" } }}>Somewhat</Typography>
    ),
  },
];

const SliderSupportedOppose = ({ userInitLevel, updated }) => {
  const mdSize = useMediaQuery("only screen and (min-width: 900px)");

  const onSliderChange = (e, value) => {
    if (updated) {
      updated(value);
    }
  };

  return (
    <>
      {mdSize && (
        <Stack direction="row" alignItems="center">
          <div style={{ marginRight: "20px" }}>
            <img
              src={karateRed}
              style={{
                height:
                  userInitLevel <= -2
                    ? "40px"
                    : userInitLevel < -0.9
                    ? "130px"
                    : userInitLevel < -0.8
                    ? "120px"
                    : userInitLevel < -0.7
                    ? "110px"
                    : userInitLevel < -0.6
                    ? "100px"
                    : userInitLevel < -0.5
                    ? "90px"
                    : userInitLevel < -0.4
                    ? "80px"
                    : userInitLevel < -0.3
                    ? "70px"
                    : userInitLevel < -0.2
                    ? "60px"
                    : userInitLevel < -0.1
                    ? "50px"
                    : "40px",
                width:
                  userInitLevel <= -2
                    ? "40px"
                    : userInitLevel < -0.9
                    ? "130px"
                    : userInitLevel < -0.8
                    ? "120px"
                    : userInitLevel < -0.7
                    ? "110px"
                    : userInitLevel < -0.6
                    ? "100px"
                    : userInitLevel < -0.5
                    ? "90px"
                    : userInitLevel < -0.4
                    ? "80px"
                    : userInitLevel < -0.3
                    ? "70px"
                    : userInitLevel < -0.2
                    ? "60px"
                    : userInitLevel < -0.1
                    ? "50px"
                    : "40px",
              }}
              alt="..."
            />

            {(userInitLevel <= -2 || userInitLevel > -0.1) && (
              <Typography
                style={{
                  fontSize: "10px",
                  textAlign: "center",
                  color: "red",
                }}
              >
                Damaged
              </Typography>
            )}
            {!(userInitLevel <= -2 || userInitLevel > -0.1) && (
              <Typography
                style={{
                  fontSize: "14px",
                  textAlign: "center",
                  color: "red",
                }}
              >
                Damaged
              </Typography>
            )}
          </div>

          <Slider
            visible={userInitLevel > 0 ? 0 : userInitLevel > -2 ? 1 : 0.4}
            step={0.01}
            value={userInitLevel > -2 ? userInitLevel : 0}
            min={-1}
            max={0}
            onChange={onSliderChange}
            marks={opposeMarks}
            disabled={!updated}
            track="inverted"
          />

          <Slider
            visible={userInitLevel < 0 ? 0 : 1}
            step={0.01}
            value={userInitLevel > -2 ? userInitLevel : 0}
            min={0}
            max={1}
            onChange={onSliderChange}
            marks={supportMarks}
            disabled={!updated}
          />

          <div style={{ marginLeft: "20px" }}>
            <img
              src={liftGreen}
              style={{
                height:
                  userInitLevel > 0.9
                    ? "130px"
                    : userInitLevel > 0.8
                    ? "120px"
                    : userInitLevel > 0.7
                    ? "110px"
                    : userInitLevel > 0.6
                    ? "100px"
                    : userInitLevel > 0.5
                    ? "90px"
                    : userInitLevel > 0.4
                    ? "80px"
                    : userInitLevel > 0.3
                    ? "70px"
                    : userInitLevel > 0.2
                    ? "60px"
                    : userInitLevel > 0.1
                    ? "50px"
                    : "40px",
                width:
                  userInitLevel > 0.9
                    ? "130px"
                    : userInitLevel > 0.8
                    ? "120px"
                    : userInitLevel > 0.7
                    ? "110px"
                    : userInitLevel > 0.6
                    ? "100px"
                    : userInitLevel > 0.5
                    ? "90px"
                    : userInitLevel > 0.4
                    ? "80px"
                    : userInitLevel > 0.3
                    ? "70px"
                    : userInitLevel > 0.2
                    ? "60px"
                    : userInitLevel > 0.1
                    ? "50px"
                    : "40px",
              }}
              alt="..."
            />

            {!(userInitLevel > 0.1) && (
              <Typography
                style={{
                  fontSize: "10px",
                  textAlign: "center",
                  color: "green",
                }}
              >
                Supported
              </Typography>
            )}
            {userInitLevel > 0.1 && (
              <Typography
                style={{
                  fontSize: "14px",
                  textAlign: "center",
                  color: "green",
                }}
              >
                Supported
              </Typography>
            )}
          </div>
        </Stack>
      )}

      {!mdSize && (
        <Stack direction="row" alignItems="center">
          <div style={{ marginRight: "20px" }}>
            <img
              src={karateRed}
              style={{
                height:
                  userInitLevel <= -2
                    ? "20px"
                    : userInitLevel < -0.9
                    ? "65px"
                    : userInitLevel < -0.8
                    ? "60px"
                    : userInitLevel < -0.7
                    ? "55px"
                    : userInitLevel < -0.6
                    ? "50px"
                    : userInitLevel < -0.5
                    ? "45px"
                    : userInitLevel < -0.4
                    ? "40px"
                    : userInitLevel < -0.3
                    ? "35px"
                    : userInitLevel < -0.2
                    ? "30px"
                    : userInitLevel < -0.1
                    ? "25px"
                    : "20px",
                width:
                  userInitLevel <= -2
                    ? "20px"
                    : userInitLevel < -0.9
                    ? "65px"
                    : userInitLevel < -0.8
                    ? "60px"
                    : userInitLevel < -0.7
                    ? "55px"
                    : userInitLevel < -0.6
                    ? "50px"
                    : userInitLevel < -0.5
                    ? "45px"
                    : userInitLevel < -0.4
                    ? "40px"
                    : userInitLevel < -0.3
                    ? "35px"
                    : userInitLevel < -0.2
                    ? "30px"
                    : userInitLevel < -0.1
                    ? "25px"
                    : "20px",
              }}
              alt="..."
            />

            {(userInitLevel <= -2 || userInitLevel > -0.1) && (
              <Typography
                style={{
                  fontSize: "10px",
                  textAlign: "center",
                  color: "red",
                }}
              >
                Damaged
              </Typography>
            )}
            {!(userInitLevel <= -2 || userInitLevel > -0.1) && (
              <Typography
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  color: "red",
                }}
              >
                Damaged
              </Typography>
            )}
          </div>

          <Slider
            visible={userInitLevel > 0 ? 0 : userInitLevel > -2 ? 0.7 : 0.4}
            step={0.01}
            value={userInitLevel > -2 ? userInitLevel : 0}
            min={-1}
            max={0}
            onChange={onSliderChange}
            marks={opposeMarksSmall}
            disabled={!updated}
            track="inverted"
          />

          <Slider
            visible={userInitLevel < 0 ? 0 : 0.7}
            step={0.01}
            value={userInitLevel > -2 ? userInitLevel : 0}
            min={0}
            max={1}
            onChange={onSliderChange}
            marks={supportMarksSmall}
            disabled={!updated}
          />

          <div style={{ marginLeft: "20px" }}>
            <img
              src={liftGreen}
              style={{
                height:
                  userInitLevel > 0.9
                    ? "65px"
                    : userInitLevel > 0.8
                    ? "60px"
                    : userInitLevel > 0.7
                    ? "55px"
                    : userInitLevel > 0.6
                    ? "50px"
                    : userInitLevel > 0.5
                    ? "45px"
                    : userInitLevel > 0.4
                    ? "40px"
                    : userInitLevel > 0.3
                    ? "35px"
                    : userInitLevel > 0.2
                    ? "30px"
                    : userInitLevel > 0.1
                    ? "25px"
                    : "20px",
                width:
                  userInitLevel > 0.9
                    ? "65px"
                    : userInitLevel > 0.8
                    ? "60px"
                    : userInitLevel > 0.7
                    ? "55px"
                    : userInitLevel > 0.6
                    ? "50px"
                    : userInitLevel > 0.5
                    ? "45px"
                    : userInitLevel > 0.4
                    ? "40px"
                    : userInitLevel > 0.3
                    ? "35px"
                    : userInitLevel > 0.2
                    ? "30px"
                    : userInitLevel > 0.1
                    ? "25px"
                    : "20px",
              }}
              alt="..."
            />

            {!(userInitLevel > 0.1) && (
              <Typography
                style={{
                  fontSize: "10px",
                  textAlign: "center",
                  color: "green",
                }}
              >
                Supported
              </Typography>
            )}
            {userInitLevel > 0.1 && (
              <Typography
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  color: "green",
                }}
              >
                Supported
              </Typography>
            )}
          </div>
        </Stack>
      )}
    </>
  );
};

SliderSupportedOppose.propTypes = {
  userInitLevel: PropTypes.number.isRequired,
  updated: PropTypes.func,
};

SliderSupportedOppose.defaultProps = {
  updated: null,
};

export default SliderSupportedOppose;
