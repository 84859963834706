import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// @mui
import MKAlert from "components/MaterialKit/MKAlert";
import MKButton from "components/MaterialKit/MKButton";
import { Dialog } from "@mui/material";
import { Close } from "@mui/icons-material";

import { removeAlert } from "../../actions/alert";

const Alert = ({ removeAlert, alerts }) => {
  const handleClick = (e) => {
    removeAlert(e);
  };

  return (
    <Dialog
      open={alerts.length > 0}
      keepMounted
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      {alerts.map((alert) => (
        <div key={alert.id}>
          {alert.alertType === "danger" && (
            <MKAlert color="error">
              <b style={{ marginLeft: "10px" }}>{alert.msg}</b>
              <MKButton variant="text" onClick={() => handleClick(alert.id)}>
                <Close style={{ marginLeft: "20px", marginRight: "-15px" }} />
              </MKButton>
            </MKAlert>
          )}

          {alert.alertType === "warning" && (
            <MKAlert color="warning">
              <b style={{ marginLeft: "10px" }}>{alert.msg}</b>
              <MKButton variant="text" onClick={() => handleClick(alert.id)}>
                <Close style={{ marginLeft: "20px", marginRight: "-15px" }} />
              </MKButton>
              <b>{alert.msg}</b>
            </MKAlert>
          )}

          {alert.alertType === "success" && (
            <MKAlert color="success">
              <b style={{ marginLeft: "10px" }}>{alert.msg}</b>
              <MKButton variant="text" onClick={() => handleClick(alert.id)}>
                <Close style={{ marginLeft: "20px", marginRight: "-15px" }} />
              </MKButton>
            </MKAlert>
          )}

          {alert.alertType === "info" && (
            <MKAlert color="info">
              <b style={{ marginLeft: "10px" }}>{alert.msg}</b>
              <MKButton variant="text" onClick={() => handleClick(alert.id)}>
                <Close style={{ marginLeft: "20px", marginRight: "-15px" }} />
              </MKButton>
            </MKAlert>
          )}
        </div>
      ))}
    </Dialog>
  );
};

Alert.propTypes = {
  removeAlert: PropTypes.func.isRequired,
  alerts: PropTypes.array.isRequired, // eslint-disable-line
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps, { removeAlert })(Alert);
