const MultiSelect = {
  control: (styles) => ({ ...styles, backgroundColor: "white", fontSize: "14px" }),
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      // Dropdown text
      backgroundColor: isFocused ? "#E5F1FD" : null,
      fontSize: "14px",
      color: "#0074F4",
    };
  },
  //  Select options background in this style
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "#E5F1FD",
    };
  },
  //  Selected options (Label) shown in this style
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#0074F4",
  }),
  //  Selected options (X) shown in this style
  multiValueRemove: (styles) => ({
    ...styles,
    color: "#0074F4",
    ":hover": {
      backgroundColor: "#0074F4",
      color: "white",
    },
  }),
};

export default MultiSelect;
