import React, { useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// MUI
import { styled } from "@mui/material/styles";
import Button from "components/MaterialKit/MKButton";
import { Clear } from "@mui/icons-material";

import { setAlert } from "../../actions/alert";
import ViewPdf from "../idea/ViewPdf";

const isImage = require("is-image");

const HiddenInput = styled("input")({
  display: "none",
});

const ImageUpload = ({ pdf, title, image, setImage, setAlert, setPdfImageSuccess }) => {
  // Upload file (from https://codepen.io/team/Cloudinary/pen/QgpyOK)
  // In Cloudindary settings, all uploads are cropped to a width of 300px / good quality
  function uploadFile(file) {
    console.log("file uploaded", file, file.size);

    if (!pdf && !isImage(file.name)) {
      setAlert("Not an image file", "danger");
      return;
    }
    if (pdf && file.name.split(".").pop().toLowerCase() !== "pdf") {
      setAlert("Not a PDF file", "danger");
      return;
    }
    if (file.size >= 10000000) {
      setAlert("Unable to load image (image file exceeds 10MB)", "danger");
      return;
    }

    const cloudName = "big-idea-lab-inc";
    const unsignedUploadPreset = "xjj3zo91";
    const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;
    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        const response = JSON.parse(xhr.responseText);
        setImage(response.secure_url);
      } else if (xhr.status >= 300) {
        setAlert("Failed to load image", "danger");
        console.log("WTF", xhr.readyState, xhr.status);
      }
    };
    fd.append("upload_preset", unsignedUploadPreset);
    fd.append("file", file);
    xhr.send(fd);
  }

  // from https://github.com/creativetimofficial/ct-material-kit-pro-react/issues/64
  const onFileInputChange = (e) => {
    if (e && e.length) {
      // Upload selected file
      uploadFile(e[0]);
    } else {
      // File removed
      setImage("");
    }
  };

  const fileUpload = useRef(null);

  return (
    <>
      <div>
        <HiddenInput
          ref={fileUpload}
          type="file"
          onChange={(e) => onFileInputChange(e.target.files)}
        />
        <Button onClick={() => fileUpload.current.click()} color="dark" variant="outlined" circular>
          {title}
        </Button>
        {image && (
          <Button onClick={() => setImage("")} color="dark" variant="text" size="large">
            <Clear />
          </Button>
        )}
      </div>
      <div style={{ marginTop: "20px" }}>
        {image && !pdf && (
          <img src={image} alt="..." style={{ width: "250px", borderRadius: "4px" }} />
        )}
        {image && pdf && <ViewPdf url={image} setLoaded={setPdfImageSuccess} />}
      </div>
    </>
  );
};

ImageUpload.propTypes = {
  title: PropTypes.string,
  pdf: PropTypes.bool,
  image: PropTypes.string,
  setImage: PropTypes.func.isRequired,
  setPdfImageSuccess: PropTypes.func,
};

ImageUpload.defaultProps = {
  title: "Upload",
  pdf: false,
  image: "",
  setPdfImageSuccess: null,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { setAlert })(ImageUpload);
