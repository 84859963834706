import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import AgentSelectItem from "./AgentSelectItem";

// const AgentSelect = ({ initAgent, idea, updated, hideAuthorName }) => {
const AgentSelect = ({ initAgent, idea, setting, updated, hideAuthorName }) => {
  // {label:"name", value:"_id"}
  const [selectedAgent, setSelectedAgent] = useState();

  // .user.agent = inTell submitter
  // .agents
  // - If no "agentAuthors" supplied on inTell submission, submitter is specified as the inTell author (agents[0])
  // - If "agentAuthors" supplied on inTell submission, agents is the inTell author(s) (agentAuthors)
  // .proxySources
  // - If "proxySources" supplied on inTell submission, proxySources are all the sources that the author(s) claim to have proxy'ed
  // .players
  // - If "players" supplied on inTell submission, players are all the participants in the inTell
  const makeAgentList = () => {
    const newAgents = [];

    if (setting && setting.defaultWaveMakers && setting.defaultWaveMakers.length > 0) {
      for (let i = 0; i < setting.defaultWaveMakers.length; i += 1) {
        const obj = { ...setting.defaultWaveMakers[i] };
        newAgents.push(obj);
      }
    } else {
      // Add author(s)
      if (idea.agents) {
        for (let i = 0; i < idea.agents.length; i += 1) {
          const obj = { ...idea.agents[i] };
          if (idea.agents.length > 1) {
            if (hideAuthorName) {
              obj.name = `[inTell co-author${i + 1}]`;
            } else {
              obj.name += " [inTell co-author]";
            }
          } else if (hideAuthorName) {
            obj.name = "[inTell author]";
          } else {
            obj.name += " [inTell author]";
          }
          newAgents.push(obj);
        }
      }

      // Add proxy source(s)
      if (idea.proxySources) {
        for (let i = 0; i < idea.proxySources.length; i += 1) {
          const obj = { ...idea.proxySources[i] };
          obj.name += " [author proxied meaning-making to this waveMaker]";
          newAgents.push(obj);
        }
      }

      // Add inTell players
      if (idea.players) {
        for (let i = 0; i < idea.players.length; i += 1) {
          const obj = { ...idea.players[i] };
          newAgents.push(obj);
        }
      }
    }

    // Add selectedAgent (if exists)
    if (selectedAgent) {
      const obj = {
        _id: selectedAgent.value,
        name: selectedAgent.label,
      };
      newAgents.push(obj);
    }

    // N/A
    const obj = {
      _id: "0",
      name: "None of the above",
    };
    newAgents.push(obj);

    return newAgents;
  };

  const [agents, setAgents] = useState(makeAgentList());

  const handleOnSelect = (e) => {
    setSelectedAgent(e);
    console.log("Selected", e.value, e.label);
    updated(e.value);
  };

  // Update when selectedAgent changes
  useEffect(() => {
    setAgents(makeAgentList());
  }, [selectedAgent]);

  return (
    <>
      {agents.map((e) => (
        <AgentSelectItem
          key={e._id}
          agent={e}
          updated={updated}
          onSelect={handleOnSelect}
          checked={initAgent !== null && initAgent._id === e._id}
        />
      ))}
    </>
  );
};

AgentSelect.propTypes = {
  initAgent: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]),
  updated: PropTypes.func.isRequired,
  hideAuthorName: PropTypes.bool,
  idea: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setting: PropTypes.oneOfType([PropTypes.object]),
};

AgentSelect.defaultProps = {
  hideAuthorName: false,
  initAgent: null,
  setting: null,
};

export default AgentSelect;
