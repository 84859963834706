import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import profile from "./profile";
import idea from "./idea";
import linkIdeaIdea from "./linkIdeaIdea";
import scales from "./scales";
import linkScales from "./linkScales";
import agent from "./agent";
import linkAgentAgent from "./linkAgentAgent";
import linkIdeaAgent from "./linkIdeaAgent";
import activity from "./activity";
import report from "./report";
import setting from "./setting";

// eslint-disable-next-line
export default combineReducers({
  alert,
  auth,
  profile,
  idea,
  linkIdeaIdea,
  scales,
  linkScales,
  agent,
  linkAgentAgent,
  linkIdeaAgent,
  activity,
  report,
  setting,
});
