import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "@react-hook/media-query";

import happyImage from "assets/images/slider/happy.gif";
import madImage from "assets/images/slider/mad.gif";

import Slider from "./Slider";

const marks = [
  {
    value: -1,
    label: <img src={madImage} style={{ width: "40px", marginRight: "-40px" }} alt="..." />,
  },
  {
    value: 1,
    label: <img src={happyImage} style={{ width: "45px", marginLeft: "-40px" }} alt="..." />,
  },
];

const SliderEmotion = ({ userInitLevel, updated }) => {
  const smSize = useMediaQuery("only screen and (min-width: 600px");
  const onSliderChange = (e, value) => {
    if (updated) {
      updated(value);
    }
  };

  // Increments: 0.01
  // Min: -1 (image label)
  // Max: 1 (image label)

  return (
    <div style={{ paddingLeft: "20px", paddingRight: "20px", paddingTop: "0px" }}>
      <Slider
        visible={userInitLevel < -1 ? 0 : smSize ? 1 : 0.7}
        step={0.01}
        aria-label="Slider"
        value={userInitLevel}
        min={-1}
        max={1}
        onChange={onSliderChange}
        marks={marks}
        disabled={!updated}
      />
    </div>
  );
};

SliderEmotion.propTypes = {
  userInitLevel: PropTypes.number.isRequired,
  updated: PropTypes.func,
};

SliderEmotion.defaultProps = {
  updated: null,
};

export default SliderEmotion;
