import axios from "axios";
import { setAlert } from "./alert";
import {
  REGISTER_FAIL,
  REGISTER_FAIL_UNINVITED,
  USER_LOADED,
  AUTH_ERROR,
  CONFIRMATION_SUCCESS,
  CONFIRMATION_WAITING,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_PROFILE,
} from "./types";
import setAuthToken from "../utils/setAuthToken";

// Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get("/api/auth");
    // if returned without error, dispatch USER_LOADED action & payload=user
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    // if error(s), display alert for every error and then dispatch AUTH_ERROR action
    const { errors } = err.response.data;
    //  const { errors } = err.response.data;
    if (errors) {
      errors.forEach((error) =>
        dispatch(setAlert(`Load user server error: ${error.msg}`, "danger"))
      );
    }
    dispatch({ type: AUTH_ERROR });
  }
};

// Request Access
export const requestAccess =
  ({ name, email, note }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ name, email, note });

    try {
      const res = await axios.post("/api/users/request-access", body, config);
      dispatch(setAlert(res.data, "Success!", 10000));
    } catch (err) {
      // if error(s), display alert for every error and then dispatch AUTH_ERROR action
      const { errors } = err.response.data;
      if (errors) {
        errors.forEach((error) => {
          dispatch(
            //          setAlert(`Server Could Not Send Your Request For Access: ${error.msg}`, "danger")
            setAlert(error.msg, "danger")
          );
        });
      }
    }
  };

// Register User
export const register =
  ({ name, email, password }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ name, email, password });

    try {
      await axios.post("/api/users", body, config);
      // if returned without error, dispatch CONFIRMATION_WAITING action & no payload
      dispatch({ type: CONFIRMATION_WAITING });
      dispatch(
        setAlert(
          "To complete registration, you will first need to confirm the email we sent to your email address. (Check your spam folder if you do not see a message in your inbox)",
          "success",
          30000
        )
      );
    } catch (err) {
      // if error(s), display alert for every error
      const { errors } = err.response.data;
      let bUninvited = false;
      if (errors) {
        errors.forEach((error) => {
          if (error.msg.includes("User not invited")) {
            bUninvited = true;
          }
          // Display error message alert to user
          //        dispatch(setAlert(`Server Rejected Registration: ${error.msg}`, "danger"));
          dispatch(setAlert(error.msg, "danger"));
        });
      }
      if (bUninvited) {
        dispatch({ type: REGISTER_FAIL_UNINVITED });
      } else {
        dispatch({ type: REGISTER_FAIL });
      }
    }
  };

// Change Password
export const changePassword =
  ({ userId, confirmationString, password }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ userId, confirmationString, password });

    try {
      await axios.post("/api/users/password-change", body, config);
      dispatch({ type: CONFIRMATION_SUCCESS });
      dispatch(setAlert("Password change successful", "success", 5000));
    } catch (err) {
      // if error(s), display alert for every error and then dispatch REGISTER_FAIL action
      const { errors } = err.response.data;
      if (errors) {
        errors.forEach((error) =>
          //        dispatch(setAlert(`Server rejected password change: ${error.msg}`, "danger"))
          dispatch(setAlert(error.msg, "danger"))
        );
      }
      dispatch({ type: REGISTER_FAIL });
    }
  };

// Password Reset Request
export const resetPassword = (email) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email });

  try {
    const res = await axios.post("/api/users/password-reset", body, config);
    dispatch(setAlert(res.data, "success", 30000));
  } catch (err) {
    const { errors } = err.response.data;
    if (errors) {
      errors.forEach((error) =>
        //      dispatch(setAlert(`Server Rejected Password Change Request: ${error.msg}`, "danger"))
        dispatch(setAlert(error.msg, "danger"))
      );
    }
  }
};

// Login User
export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post("/api/auth", body, config);
    // if returned without error, dispatch LOGIN_SUCCESS action & payload=token
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
    //  dispatch(setAlert('Successful Login', 'success', 1000));
  } catch (err) {
    // if "Email address unconfirmed" error: do not display alert but dispatch CONFIRMATION_WAITING action
    // Else, display alert for every error and then dispatch LOGIN_FAIL action
    const { errors } = err.response.data;
    let bWaitingConfirmation = false;
    if (errors) {
      errors.forEach((error) => {
        //      dispatch(setAlert(`Server Rejected Login: ${error.msg}`, "danger"));
        dispatch(setAlert(error.msg, "danger"));
        if (error.msg.includes("An email to confirm your email address")) {
          bWaitingConfirmation = true;
        }
      });
    }
    if (bWaitingConfirmation) {
      dispatch({ type: CONFIRMATION_WAITING });
    } else {
      dispatch({ type: LOGIN_FAIL });
    }
  }
};

// Logout / Clear Profile
export const logout = () => (dispatch) => {
  //  dispatch(setAlert("Logged out", "success", 3000));
  setAuthToken("");
  localStorage.clear("token");
  dispatch({ type: CLEAR_PROFILE });
  dispatch({ type: LOGOUT });
};
