import Container from "@mui/material/Container";
import { Grid } from "@mui/material";

import Box from "components/MaterialKit/MKBox";
import Typography from "components/MaterialKit/MKTypography";
import DefaultInfoCard from "./DefaultInfoCard";

function Information() {
  return (
    <Box component="section" py={8}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <Box mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="Deep understanding"
                    description="Stop swimming in the noise. Immerse yourself in multiple perspectives, and cut through the spin."
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box mb={5}>
                  <DefaultInfoCard
                    icon="timeline"
                    title="Reputation matters"
                    description="Everyone is accountable. Past behaviors impact one's influence in future discussions."
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="groups"
                    title="Collective intelligence"
                    description="When well-organized and empowered, groups are far smarter than any individual."
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="flare"
                    title="Unleash yourself"
                    description="Navigate the issues of the day before breakfast, and then help save the world before dinner. Repeat tomorrow."
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={5} sx={{ ml: "auto", mt: { xs: 2, lg: 0 } }}>
            <div style={{ textAlign: "center" }}>
              <Typography variant="subtitle1">
                <i>Question everything.</i>
              </Typography>
              <Typography variant="body2"> — ALBERT EINSTEIN</Typography>

              <Typography variant="subtitle1" style={{ marginTop: "50px" }}>
                <i>Truth never damages a cause that is just.</i>
              </Typography>
              <Typography variant="body2"> — MAHATMA GANDHI</Typography>
              <Typography variant="subtitle1" style={{ marginTop: "50px" }}>
                <i>
                  Nothing in life is to be feared, it is only to be understood. Now is the time to
                  understand more, so that we may fear less.
                </i>
              </Typography>
              <Typography variant="body2"> — MARIE CURIE</Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Information;
