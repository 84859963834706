import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// MUI
import { Checkbox } from "@mui/material";
import Button from "components/MaterialKit/MKButton";
import Box from "components/MaterialKit/MKBox";
import Input from "components/MaterialKit/MKInput";

import ImageScrape from "../image/ImageScrape";
import QuestionText from "../styles/QuestionText";
import LabelText from "../styles/LabelText";
import DialogMessage from "./DialogMessage";
import { addAgent } from "../../actions/agent";
import { setAlert } from "../../actions/alert";

const CreateAgent = ({ newCreatedAgent, hideSubmitNotReady, addAgent, setAlert, agent }) => {
  const [submitting, setSubmitting] = useState(false);
  const divRef = useRef(null);
  useEffect(() => {
    if (!submitting) {
      divRef.current.scrollIntoView({ behavior: "smooth" });
    }
  });

  useEffect(() => {
    if (submitting) {
      setTimeout(() => {
        setSubmitting(false);
      }, 500);
    }
  }, [submitting]);

  const [url, setUrl] = useState("");
  const [name, setName] = useState("");
  const [validUrl, setValidUrl] = useState(false);
  const [image, setImage] = useState("");
  const [associateImage, setAssociateImage] = useState(false);
  const [textDialog, setTextDialog] = useState(false);
  const [urlDialog, setUrlDialog] = useState(false);

  const onSubmitAgent = () => {
    let bValid = true;
    if (!name || name.length <= 2) {
      setAlert("Invalid agent name. Please be more descriptive.", "danger");
      bValid = false;
    }
    if (!url || url.length <= 2 || !validUrl) {
      setAlert("Invalid url.", "danger");
      bValid = false;
    }
    if (bValid) {
      setSubmitting(true);
      if (!image || image.length < 1) {
        // No image
        addAgent({ name, url });
      } else {
        // With image
        addAgent({ name, url, image });
      }
    }
  };

  // Exit strategy to exit this component and scale new agent (report back to parent once back-end confirms submission)
  // - Store initial agent (when entering this component)
  const [initAgentID] = useState(agent && agent.agent && agent.agent._id ? agent.agent._id : 0);
  useEffect(() => {
    if (!submitting && agent && agent.agent && agent.agent._id) {
      if (agent.agent._id !== initAgentID) {
        console.log("New agent created");
        newCreatedAgent(agent.agent);
      }
    }
  }, [agent, submitting]);

  return (
    <>
      <QuestionText>Specify a URL profiling the waveMaker to be created.</QuestionText>
      <QuestionText>(i.e. URL of a Wikipedia article...)</QuestionText>

      <Box mb={2} mt={2} onChange={(e) => setUrl(e.target.value)}>
        <Input type="url" success={validUrl} label="URL" fullWidth />
      </Box>

      {validUrl && (
        <>
          <Box mb={2} onChange={(e) => setName(e.target.value)}>
            <Input type="name" label="waveMaker Name" autoFocus fullWidth value={name} />
          </Box>

          <LabelText>
            <Checkbox checked={associateImage} onClick={() => setAssociateImage(!associateImage)} />
            Select an image representative of the waveMaker (optional)
          </LabelText>
        </>
      )}

      {!hideSubmitNotReady && !(validUrl && name && name.length > 0) && (
        <div style={{ textAlign: "center", margin: "30px" }}>
          {!validUrl && (
            <Button onClick={() => setUrlDialog(true)} color="dark" variant="outlined" circular>
              Submit waveMaker
            </Button>
          )}
          {validUrl && (
            <Button onClick={() => setTextDialog(true)} color="dark" variant="outlined" circular>
              Submit waveMaker
            </Button>
          )}
        </div>
      )}

      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <ImageScrape
          url={url}
          showGallery={associateImage}
          setImage={setImage}
          setValidUrl={setValidUrl}
          setTitle={setName}
        />
      </div>

      {/* Submit button */}
      {!submitting && (
        <div style={{ textAlign: "center", margin: "30px" }}>
          {validUrl && name && name.length > 0 && (
            <Button onClick={() => onSubmitAgent()} color="primary" circular>
              Submit waveMaker
            </Button>
          )}
        </div>
      )}

      {/* DIALOG: Button press help */}
      {textDialog && <DialogMessage text handleDialogClose={() => setTextDialog(false)} />}
      {urlDialog && <DialogMessage url handleDialogClose={() => setUrlDialog(false)} />}

      <div ref={divRef} />
    </>
  );
};

CreateAgent.propTypes = {
  newCreatedAgent: PropTypes.func.isRequired,
  hideSubmitNotReady: PropTypes.bool,
};

CreateAgent.defaultProps = {
  hideSubmitNotReady: false,
};

const mapStateToProps = (state) => ({
  agent: state.agent,
});

export default connect(mapStateToProps, { addAgent, setAlert })(CreateAgent);
