import React from "react";
import PropTypes from "prop-types";

// MUI
import { styled } from "@mui/system";
import { Dialog, DialogActions, DialogContent, Stack } from "@mui/material";
import Button from "components/MaterialKit/MKButton";
import Typography from "components/MaterialKit/MKTypography";

import imageLarge from "assets/images/water/WaterRipple.gif";
import imageSmall from "assets/images/water/WaterRippleSmall.gif";

const DialogImage = styled(Dialog)(({ theme }) =>
  theme.unstable_sx({
    ".MuiDialog-paper": {
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
      backgroundImage: `url(${imageSmall})`,
      [theme.breakpoints.up("sm")]: {
        backgroundImage: `url(${imageLarge})`,
      },
    },
  })
);

const TitleText = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    color: "lightblue",
    fontSize: "24px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "32px",
    },
  })
);

const DialogTriggered = ({ handleDialogClose }) => {
  return (
    <DialogImage open fullScreen>
      <DialogContent>
        <div style={{ textAlign: "center", padding: "40px" }}>
          <TitleText>Give yourself a little time to reflect on your emotions.</TitleText>
          <TitleText>When you are ready, click below to continue.</TitleText>
        </div>
      </DialogContent>
      <DialogActions>
        <Stack flexWrap="wrap" justifyContent="center" flexDirection="row" width={1.0}>
          <Button
            onClick={() => handleDialogClose()}
            color="primary"
            circular
            style={{ margin: "20px" }}
          >
            Continue...
          </Button>
        </Stack>
      </DialogActions>
    </DialogImage>
  );
};

DialogTriggered.propTypes = {
  handleDialogClose: PropTypes.func.isRequired,
};

export default DialogTriggered;
